import React from 'react';
import head1 from './../images/slider1.jpg';
import head4 from './../images/slider2.jpg';
import head6 from './../images/slider33.jpg';
import { useStateValue } from './StateProvider.js';
import { HashLink as Link } from 'react-router-hash-link';
function Slider(){
	const [{ global }, dispatch] = useStateValue();
	return(
	<section>
		<div id="demo" className="carousel slide" data-ride="carousel">
			  <ul className="carousel-indicators">
				<li data-target="#demo" data-slide-to="0" className="active"></li>
				<li data-target="#demo" data-slide-to="1"></li>
				<li data-target="#demo" data-slide-to="2"></li>
			  </ul>

			  
			  <div className="carousel-inner">
				<div className="carousel-item active">
				  <img src={head1} alt=""/>
				  <div className="outer">
				  <div className="container container-width justify-content-center main_slider" data-aos="fade-up">
						<h1 className="fadeInDown heading" data-wow-delay=".4s">{global.siteText[0].slider1_text1}<br></br>{global.siteText[0].slider1_text1_2}</h1>
						<p className="fadeInDown heading1" data-wow-delay=".4s">{global.siteText[0].slider1_text2}</p>
						{global.token!='' && global.token!=undefined && global.token!=null ?
						(<div className="fadeInUp slidep" data-wow-delay=".6s">
							<a className="reg_btn" style={{marginLeft:"0px",padding:"8px",cursor:"no-drop"}} >{global.siteText[0].reg_menu}</a>
							<a className="log_btn" style={{padding:"8px 12px 8px 12px",cursor:"no-drop"}}><i className="fa fa-lock"></i> {global.siteText[0].login_menu}</a>
						</div>):
							(<div className="fadeInUp slidep" data-wow-delay=".6s">
							<a className="reg_btn" style={{marginLeft:"0px",padding:"8px"}} onClick={() => dispatch({
							type: 'changeRegModal',
							newRegModal: {showRegModal: !global.showRegModal}
							})}>{global.siteText[0].reg_menu}</a>
							<a className="log_btn" style={{padding:"8px 12px 8px 12px"}} onClick={() => dispatch({
							type: 'changeLogModal',
							newLogModal: {showLogModal: !global.showLogModal}
							})}><i className="fa fa-lock"></i> {global.siteText[0].login_menu}</a>
						</div>)}
					</div>
				  </div>
				</div>
				
				<div className="carousel-item">
				  <img src={head4} alt=""/>
				  <div className="outer">
				  <div className="container container-width justify-content-center main_slider" data-aos="fade-up">
						<h1 className="fadeInDown heading" data-wow-delay=".4s">{global.siteText[0].slider2_text1}<br></br>{global.siteText[0].slider2_text1_2}</h1>
						<p className="fadeInDown heading1" data-wow-delay=".4s">{global.siteText[0].slider2_text2}<br></br>{global.siteText[0].slider2_text2_2}</p>
						{global.token!='' && global.token!=undefined && global.token!=null ?
						(<div className="fadeInUp slidep" data-wow-delay=".6s">
							<a className="reg_btn" style={{marginLeft:"0px",padding:"8px",cursor:"no-drop"}} >{global.siteText[0].reg_menu}</a>
							<a className="log_btn" style={{padding:"8px 12px 8px 12px",cursor:"no-drop"}}><i className="fa fa-lock"></i> {global.siteText[0].login_menu}</a>
						</div>):
							(<div className="fadeInUp slidep" data-wow-delay=".6s">
							<a className="reg_btn" style={{marginLeft:"0px",padding:"8px"}} onClick={() => dispatch({
							type: 'changeRegModal',
							newRegModal: {showRegModal: !global.showRegModal}
							})}>{global.siteText[0].reg_menu}</a>
							<a className="log_btn" style={{padding:"8px 12px 8px 12px"}} onClick={() => dispatch({
							type: 'changeLogModal',
							newLogModal: {showLogModal: !global.showLogModal}
							})}><i className="fa fa-lock"></i> {global.siteText[0].login_menu}</a>
						</div>)}
					</div>
				  </div>
				</div>
				<div className="carousel-item">
				  <img src={head6} alt=""/>
				  <div className="outer">
				  <div className="container container-width justify-content-center main_slider" data-aos="fade-up">
						<h1 className="fadeInDown heading" data-wow-delay=".4s">{global.siteText[0].slider3_text1}</h1>
						<p className="fadeInDown heading1" data-wow-delay=".4s">{global.siteText[0].slider3_text2}<br></br>{global.siteText[0].slider3_text2_2}</p>						
						<div className="fadeInUp slidep" data-wow-delay=".6s">
							<Link to="/#team_div" className="reg_btn" style={{marginLeft:"0px",padding:"8px"}}>{global.siteText[0].About_us}</Link>
							<Link to="/#about_us" className="log_btn" style={{padding:"8px 12px 8px 12px"}}>{global.siteText[0].menu2}</Link>
						</div>
					</div>
				  </div>
				</div>
				
				
				
				
			  </div>

			  
			  <a className="carousel-control-prev" href="#demo" data-slide="prev">
				<span className="carousel-control-prev-icon" style={{display:"none"}} ></span><i className="fa fa-arrow-left arrowss"></i>
			  </a>
			  <a className="carousel-control-next" href="#demo" data-slide="next">
				<span className="carousel-control-next-icon" style={{display:"none"}} ></span><i className="fa fa-arrow-right arrowss"></i>
			  </a>

		</div>
			  
	</section>
	)
}
export default Slider;