import React,{useState,useEffect,useRef} from 'react';
import { useStateValue } from './StateProvider.js';
import { Path } from './Path.js';
const Languages=()=>{
    const [{ global }, dispatch] = useStateValue();
    const get_ul_list = useRef(null);
    const [allLanguages, setAllLanguages] = useState('');
    const [allLanguagesSpanish, setAllLanguagesSpanish] = useState('');
    const get_languages=()=>{
        fetch(Path+"get_languages.php", { 
            method: "POST",       
            body: JSON.stringify({
                site_lang:global.showSiteLang
            }), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            }    			
		})
		.then(res => res.json())
		.then(response=>{
            setAllLanguages(response.languages);
            setAllLanguagesSpanish(response.languages_spanish);
		}
		)	
    }  
    React.useEffect(() => {
        get_languages();
    },[]); 	
		return(
        <React.Fragment>
        {global.showSiteLang=='English' ?
            (<React.Fragment>
                {allLanguages?Object.keys(allLanguages).map((key,i)=>
                    <option value={allLanguages[key].id} key={allLanguages[key].id} abc={global.showSiteLang}> {allLanguages[key].lang_name}</option>
                ):('')} 
            </React.Fragment>):
            (<React.Fragment>
                {allLanguagesSpanish?Object.keys(allLanguagesSpanish).map((key,i)=>
                    <option value={allLanguagesSpanish[key].id} key={allLanguagesSpanish[key].id} abc1={global.showSiteLang}> {allLanguagesSpanish[key].lang_name_spanish}</option>
                ):('')} 
            </React.Fragment>)
        }
        </React.Fragment>
	)
}
export default Languages;