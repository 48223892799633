import React,{useState,useEffect,useRef} from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useStateValue } from './StateProvider.js';
import { Path } from './Path.js';
const Languages1=(props)=>{
	const [{ global }, dispatch] = useStateValue();
    const get_ul_list = useRef(null);
    const [allLanguages, setAllLanguages] = useState('');
    const [allLanguagesSpanish, setAllLanguagesSpanish] = useState('');
    const setValue=(e)=>{
        if(e.target.checked){
            props.selectedLangs.push(e.target.value);          
        }else{
            var index_value=props.selectedLangs.indexOf(e.target.value);
            props.selectedLangs.splice(index_value,1);
        }
        console.log('array',props.selectedLangs);
    } 
    const get_languages=()=>{
        fetch(Path+"get_languages.php", {
            method: "POST",       
            body: JSON.stringify({
                site_lang:global.showSiteLang
            }), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            }       			
		})
		.then(res => res.json())
		.then(response=>{
            setAllLanguages(response.languages);
            setAllLanguagesSpanish(response.languages_spanish);
            //props.show_lang_data(true);
            Object.keys(get_ul_list.current.children).map((value,index)=>{
                if(props.langs.indexOf(get_ul_list.current.children[index].children[0].defaultValue)>=0)
                    get_ul_list.current.children[index].children[0].defaultChecked=true;
            })
		}
		)	
    }   
	React.useEffect(() => {
        get_languages();
    
    },[props.langs]);
    
		return(
		<div>
            {global.showSiteLang=='English' ?
            (<ul className="languages_div" ref={get_ul_list}>
                {allLanguages?Object.keys(allLanguages).map((key,i)=>
                    <li key={allLanguages[key].id}><input type="checkbox" className="form-control" name="language" value={allLanguages[key].id} onClick={setValue} /> {allLanguages[key].lang_name}</li>
                ):('')}    
            </ul>):
            (<ul className="languages_div" ref={get_ul_list}>
               {allLanguagesSpanish?Object.keys(allLanguagesSpanish).map((key,i)=>
                   <li key={allLanguagesSpanish[key].id}><input type="checkbox" className="form-control" name="language" value={allLanguagesSpanish[key].id} onClick={setValue} /> {allLanguagesSpanish[key].lang_name_spanish}</li>
               ):('')}    
           </ul>)} 
		</div>
		)
}
export default Languages1;