import React,{useState,useEffect,useRef} from 'react';
import {Button} from 'react-bootstrap';
import { Path } from './Path.js';
import { useStateValue } from './StateProvider.js';
import Countries from './Countries.js';
import Languages1 from './Languages1.js';
import parser from 'html-react-parser';
const Edit_profile=(props)=>{
	const [{ global }, dispatch] = useStateValue();
	const get_name = useRef(null);
	const get_email = useRef(null);
	const get_lname = useRef(null);
	const get_file = useRef(null);
	const get_bio = useRef(null);
	const get_org = useRef(null);
	const get_exp = useRef(null);
	//const categories = useRef(null);
	const get_country = useRef(null);
	// const get_industry = useRef(null);
	//const get_languages = useRef(null);
	const [Image, setImage] = useState('');
	const [all_categories, setAll_categories] = useState('');
	const [allCountries, setAllCountries] = useState('');
	const [allCountriesSpanish, setAllCountriesSpanish] = useState('');
	const [all_industries, setAll_industries] = useState('');
	const [bio, SetBio] = useState('');
	const [org, SetOrg] = useState('');
	const [show_exp, SetExpe] = useState('');
	const [imageValid, setImageValid] = useState('');
	const [viewImageName, setImageName] = useState('');
	const [langs, Setlanguages] = useState([]);
	const [exp, SetExp] = useState('');
	//const [showindustries, SetIndustries] = useState([]);
	const [category, SetCategory] = useState([]);
	const [industry, SetIndustry] = useState([]);
	const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
	const [FailedError, setFailedError] = useState(false);
	const [SuccessError, setSuccessError] = useState(false);
	const [showLangComp,setShowLangComp] = useState(false);
	const [Loader, setLoader] = useState(false);
	const [Check_user, setCheck_user] = useState(false);
	const [Check_user1, setCheck_user1] = useState(true);
	const [errors, setErrors] = useState('');
	const [selectedLangs, setSelectedLangs] = useState([]);
	const [validExperts, setValidExperts] = useState([]);
	const [validIndustry, setValidIndustry] = useState([]);
	const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
	const [GetData, setGetData] = useState({GetName:'',GetEmail:''});
	const [token, setToken] = useState(localStorage.getItem('token'));
	var show_expp=[];
	
	const get_profile_data=()=>{
		for (var j = 1; j < 51; j++) {
		show_expp.push(<option value={j} key={j}>{j}</option>);
		}
		SetExpe(show_expp);
		fetch(Path+"get_profile_data.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				site_lang:global.showSiteLang
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
				setAllCountries(response.countries);
				setAllCountriesSpanish(response.countries_spanish);
				setAll_categories(response.all_categories);	
				setAll_industries(response.all_industries);
				get_name.current.value=response.updated_data.name;
				get_email.current.value=response.updated_data.email;
				get_lname.current.value=response.updated_data.lname;
				get_country.current.value=response.updated_data.countrycode;
				SetBio(response.updated_data.bio);				
				SetOrg(response.updated_data.org);
				SetExp(response.updated_data.total_exp);
				SetCategory(response.updated_data.cat_ids.split(","));
				Setlanguages(response.updated_data.languages.split(","));
				SetIndustry(response.updated_data.industries.split(","));
				setCheck_user(response.updated_data.show_coach);
				setSelectedLangs(response.updated_data.languages.split(","));
				//selected=response.updated_data.cat_ids.split(",");
				if(response.updated_data.industries!=''){
					setValidIndustry(response.updated_data.industries.split(","));
				}
				if(response.updated_data.cat_ids!=''){
					setValidExperts(response.updated_data.cat_ids.split(","));
				}
				console.log('experts',response.updated_data.cat_ids);
			}
		)		
	}
	React.useLayoutEffect(() => {
		get_profile_data();		
	},[]);
	
    const handleValidation=()=>{
		let formIsValid = false;
		if(get_name.current.value===''){
		setErrors(global.siteText[0].error_msg_26);		   
		}else if(get_email.current.value===''){
			setErrors(global.siteText[0].error_msg_27);
		}else if(get_country.current.value===''){
			setErrors(global.siteText[0].error_msg_28);
		}else if(Image!=''){
			if(Image.type!='image/jpeg' && Image.type!='image/jpg' && Image.type!='image/png'){
				setErrors(global.siteText[0].error_msg_66);
			}else{
				setErrors('');
				formIsValid = true;
			}
		}else if(selectedLangs.length===0){
			setErrors(global.siteText[0].error_msg_29);
		}else if(get_bio.current.value===''){
			setErrors(global.siteText[0].error_msg_33);
		}else if(Check_user!=''){
			if(validExperts.length===0){
				setErrors(global.siteText[0].error_msg_30);
			}else if(validIndustry.length===0){
				setErrors(global.siteText[0].error_msg_31);
			}else if(get_exp.current.value===''){
				setErrors(global.siteText[0].error_msg_32);
			}else {
				setErrors('');
				formIsValid = true;
			}
		}else {
			setErrors('');
			formIsValid = true;
		}	
		return formIsValid;
	}
	const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
   }
	const HandleImage=(event)=>{
		console.log(event.target.files[0]);
		setImage(event.target.files[0]);
		setImageName(event.target.files[0].name);
		if(event.target.files[0].type=='image/jpeg' || event.target.files[0].type=='image/jpg' || event.target.files[0].type=='image/png'){
			setImageValid('true');
		}else{
			setImageValid('false');
		}
  	}
   const show_notification=(response)=>{
	   if(response.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
	   }else if(response.success!=null){
			if(response.image!=null){
				dispatch({
					type: 'changeUserName',
					newUserName: { showUserName: response.username},newUserImg: { showUserImg: response.image}
				})
			}else{
				dispatch({
					type: 'changeUserName',
					newUserName: { showUserName: response.username},newUserImg: { showUserImg: global.showUserImg}
				})
			}
			localStorage.setItem('coach', response.iscoach);	
			dispatch({
				type: 'changeCoachTab',
				newCoachTab: { showCoachTab: response.iscoach}
			})
			setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});
	   }
	   setTimeout(hide_notification, 4000);
   }
	const setExpertValue=(e)=>{
		if(e.target.checked){
			validExperts.push(e.target.value);          
		}else{
			var index_value=validExperts.indexOf(e.target.value);
			validExperts.splice(index_value,1);
		}		
	}
	const setIndustryValue=(e)=>{
		if(e.target.checked){
			validIndustry.push(e.target.value);          
		}else{
			var index_value=validIndustry.indexOf(e.target.value);
			validIndustry.splice(index_value,1);
		}	
	}
	const Profile_data=()=>{
		const data = new FormData();
		console.log('languages_array',selectedLangs);
		data.append('image', Image);
		data.append('Name', get_name.current.value);
		data.append('Lname', get_lname.current.value);
		data.append('Email', get_email.current.value);
		data.append('country', get_country.current.value);
		data.append('site_lang', global.showSiteLang);		
		data.append('token', token);
		data.append('Bio', get_bio.current.value);	
		data.append('Org', get_org.current.value);		
		data.append('languages', selectedLangs);
		if(Check_user !=''){						
			data.append('total_exp', get_exp.current.value);
			data.append('industries', validIndustry);
			console.log('categories:',validExperts);
			data.append('Category', validExperts);
		}else{
			data.append('Category', '');
			data.append('total_exp', '');
			data.append('industries', '');
		}
		
		if(handleValidation()){	
			setLoader(true);
			setOpacity('0.5');
			setPointerEvents('none');
			fetch(Path+"ajax_edit_profile.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
					setLoader(false);
					setOpacity('');
					setPointerEvents('');
					show_notification(response);				
				}
			)
			.catch(err => {
				return err;
			})
		}
	}	
	
		return(
		<section>
			<form id="" className="formm contact_formm" onSubmit={Profile_data}>		
				<div className="row">
					<div className="row" style={{margin:"0",width:"100%"}}>
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].first_name}</label>
							<input type="text" className="form-control" name="Name" ref={get_name}/>
						</div>
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].last_name}</label>
							<input type="text" className="form-control" name="Lname" ref={get_lname}/>
						</div>
					</div>
					<div className="row" style={{margin:"0",width:"100%"}}>
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].contact_4}</label>
							<input type="text" className="form-control" name="Email" ref={get_email}/>
							<label>{global.siteText[0].country}</label>
							{global.showSiteLang=='English' ?
							(<select className="form-control" name="country" id="country" style={{borderColor:"black"}} ref={get_country}>
								<option value="">Choose country</option>
								{allCountries?Object.keys(allCountries).map((key,i)=>
								<React.Fragment key={i}>
								<option value={allCountries[key].countrycode}>{allCountries[key].countryname}</option>
								</React.Fragment>):('')}
							</select>):
							(<select className="form-control" name="country" id="country" style={{borderColor:"black"}} ref={get_country}>
								<option value="">Choose country</option>
								{allCountriesSpanish?Object.keys(allCountriesSpanish).map((key,i)=>
								<React.Fragment key={i}>
								<option value={allCountriesSpanish[key].countrycode}>{allCountriesSpanish[key].countryname_spanish}</option>
								</React.Fragment>):('')}
							</select>)}
							<label style={{marginBottom:"0"}}>{global.siteText[0].edit_profile1}</label>
							<p style={{fontSize:"13px",marginBottom:"4px"}}>{global.siteText[0].edit_profile7}</p>
							<div className="upload-btn-wrapper" id="vac_up">
								<button className="btn btn-primary" style={{background: "#FCFCFC",border: "1px solid black",color: "#686868",borderRadius: "4px",padding:"5px 33px",fontSize:"16px",width:"100%",height:"36px",paddingLeft:"28px"}}> Choose file</button>
								<input type="file" className="form-control" name="Image" style={{padding:"0.3rem 0.4rem 0.7rem 0.5rem"}} accept=".jpg, .png, .jpeg" onChange={HandleImage} />
								<span style={{color:"green"}}>{viewImageName}</span>
								{imageValid!='' ?
								(<span>{imageValid=='true'?
								(<span style={{color:"green",fontSize:"20px"}}> (<i className="fa fa-check"></i>)</span>):
								(<span style={{color:"red",fontSize:"20px"}}> (<i className="fa fa-times"></i>)</span>)}</span>):('')}
							</div>

						</div>
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].view_profile_3}</label>	
							<Languages1 selectedLangs={selectedLangs} langs={langs} show_lang_data={setShowLangComp}/>	
						</div>
						
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].organization}</label>
							<input type="text" className="form-control" name="org" ref={get_org} defaultValue={org}/>
						</div>

						<div className="col-md-12 col-sm-12">
							<label>{global.siteText[0].edit_profile5}</label>
							<p style={{marginBottom:"0",fontSize:"13px"}}>{global.siteText[0].edit_profile6}</p>
							<textarea style={{height:"120px"}} className="form-control" name="bio" id="bio" ref={get_bio} defaultValue={bio}></textarea>
						</div>
					</div>
					<div className="row" style={{margin:"0",width:"100%"}}>
						<div className="col-md-4 col-sm-4" style={{marginTop:"32px"}}>
						<label>{global.siteText[0].edit_profile3}</label>
						</div>
						<div className="col-md-2 col-sm-2" style={{marginTop:"44px"}}>
							<input type="checkbox" className="form-control" name="Check_user" onChange={event => setCheck_user(!Check_user)} style={{width: "25px",height: "25px"}} checked={Check_user?'checked':''}/> 
						</div>
						{Check_user? (
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].view_profile_8}</label>
							<select className="form-control" name="total_exp" ref={get_exp} defaultValue={exp}>
							{show_exp}
							</select>
						</div>						
						):(<div className="col-md-6 col-sm-6"></div>)}

					</div>

		
					{Check_user? (
						<div className="row" style={{margin:"0",width:"100%"}}>
							<div className="row" style={{margin:"0",width:"100%"}}>
								<div className="col-md-6 col-sm-6">
								<label>{global.siteText[0].view_profile_6}</label>
								<ul className="languages_div">
									{all_categories?Object.keys(all_categories).map((key,i)=>
									<React.Fragment key={i}>
									<li><input type="checkbox" defaultChecked={category.indexOf(all_categories[key].id)>=0?true:false} className="form-control" name="Category" value={all_categories[key].id} status="0" onClick={setExpertValue}/> {global.showSiteLang=='English' ?all_categories[key].name : all_categories[key].name_spanish}</li>
									</React.Fragment>):('')}
								</ul>
								</div>
								<div className="col-md-6 col-sm-6">
									<label>{global.siteText[0].edit_profile4}</label>
									<ul className="languages_div">
										{all_industries?Object.keys(all_industries).map((key,i)=>
										<React.Fragment key={i}>
											<li><input type="checkbox"  defaultChecked={industry.indexOf(all_industries[key].id)>=0?true:false}  className="form-control" name="Category" value={all_industries[key].id} status="0" onClick={setIndustryValue} /> {global.showSiteLang=='English' ? all_industries[key].ind_name : all_industries[key].ind_name_spanish}</li>
										</React.Fragment>):('')}
									</ul>
								</div>
							</div>													
					</div>):('')}
					{notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
						<button type="button" className="close" data-dismiss="alert"></button>
					</div>) : ('')}		
					{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
						<button type="button" className="close" data-dismiss="alert"></button>
					</div>) : ('')}
					{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
					<div className="col-md-12 col-sm-12 text-right">
						<Button variant="primary" className="modal_btn" onClick={Profile_data} style={{opacity:Opacity,pointerEvents:PointerEvents}}>
							{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')}{global.siteText[0].contact_7}
						</Button>
					</div>					
				</div>				
			</form>	
		</section>
		)
}
export default Edit_profile;