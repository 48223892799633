import React,{useState,useRef}  from 'react';
import {Button} from 'react-bootstrap';
import { Path } from './Path.js';
import loader from './../images/loading.gif';
import { useStateValue } from './StateProvider.js';

function Contact(){
	const [{ global }, dispatch] = useStateValue();
	const name = useRef(null);
	const email = useRef(null);	
	const subject = useRef(null);
	const message = useRef(null);
	const [BtnStyle, setBtnStyle] = useState({Opacity:'1',PointerEvents:''});	
	const [Loader, setLoader] = useState(false);
	const [errors, setErrors] = useState('');
	const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
	const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
		setErrors('');
   }
   const show_notification=(response)=>{
	   if(response.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
	   }else if(response.success!=null){
		   setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});
	   }
	   setTimeout(hide_notification, 6000);
   }
	const add_data=()=>{
		if(name.current.value===''){
			setErrors(global.siteText[0].error_msg_26);		   
		 }else if(email.current.value===''){
			 setErrors(global.siteText[0].error_msg_27);
		 }else if(subject.current.value===''){
			 setErrors(global.siteText[0].error_msg_64);
		 }else if(message.current.value===''){
			setErrors(global.siteText[0].error_msg_65);
		}else{
			const data = new FormData();
			data.append('name', name.current.value);
			data.append('email', email.current.value);
			data.append('subject', subject.current.value);
			data.append('message', message.current.value);	
			setLoader(true);
			setBtnStyle(prevState => ({
				...prevState,
				Opacity: '0.5'
			}));
			setBtnStyle(prevState => ({
				...prevState,
				PointerEvents: 'none'
			}));
			fetch(Path+"contact_us.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
				//console.log(response);
				setLoader(false);
				document.getElementById("add_proj").reset();
				setBtnStyle(prevState => ({
					...prevState,
					Opacity: ''
				}));
				setBtnStyle(prevState => ({
					...prevState,
					PointerEvents: ''
				}));
				show_notification(response);
			})
			.catch(err => {
				setLoader(false);
				setBtnStyle(prevState => ({
					...prevState,
					Opacity: ''
				}));
				setBtnStyle(prevState => ({
					...prevState,
					PointerEvents: ''
				}));
				setErrors(global.siteText[0].error_msg_14);
				console.log(err);
				setTimeout(hide_notification, 4000);
				return err;
			})
		}
	}	
	return(
	<section>			
		<div id="contact_box" className="anim1"><br></br><br></br>
			<div className="row cont_row" style={{margin:"0"}}>
				<div className="container container-width">
					<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 pull-left">
					<div className="cont_box_div">
						<div className="clipped" style={{textAlign:"center"}} >
							<h1>{global.siteText[0].contact_1}</h1>
							<p>{global.siteText[0].contact_2}</p>
						</div>
						<form className="form contact_formm" id="add_proj">
							<div className="row" style={{margin:"0",width:"100%",padding:"12px"}}>
								{notification.show_success ? (<div className="alert alert-success" id="success2" style={{height:"70px",lineHeight:"40px"}}>{notification.success}
								<button type="button" className="close" data-dismiss="alert"></button>
								</div>) : ('')}		
								{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
									<button type="button" className="close" data-dismiss="alert"></button>
								</div>) : ('')}
								{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
							</div>
							<div className="row" style={{margin:"0"}}>
							<div className="col-lg-6 form_inputt">
								<label>{global.siteText[0].contact_3}</label>
								<input className="form-control" id="name3" type="text" ref={name} />
							</div>
							<div className="col-lg-6 form_inputt">
								<label>{global.siteText[0].contact_4}</label>
								<input className="form-control" id="email3" type="text" ref={email} />
							</div>
							</div>
							<div className="col-lg-12 form_inputt">
								<label>{global.siteText[0].contact_5}</label>
								<input className="form-control" id="subject3" type="text" ref={subject} />
							</div>
							<div className="col-lg-12 form_inputt">
								<label>{global.siteText[0].contact_6}</label>
								<textarea className="form-control" id="message3" ref={message}></textarea>
							</div><br></br>
							<div className="col-lg-12" style={{textAlign:"center"}}>
								<Button variant="primary" className="modal_btn" onClick={add_data} style={{opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>
								{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].contact_7}
							</Button>
							</div>
						</form>
					</div>	
					</div>	
				</div>
			</div>
		</div>  
	</section>
	)
}
export default Contact;