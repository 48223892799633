import React from 'react';
import icon from './../images/icon.png';
import { useStateValue } from './StateProvider.js';
function Faq(){
	const [{ global }, dispatch] = useStateValue();
	return(
	<section>	
		<div className="faq">
			<div className="container" id="faq">
				<h1 className="head_text" style={{color:"white"}}>{global.siteText[0].faq_heading}<span style={{color:"#0ea5df"}}>{global.siteText[0].faq_headin2}</span></h1>
				 <div className="divider text-center">
					<span className="outer-line" style={{borderColor:"#0ea5df"}}></span>
					<span className="image"><img src={icon} alt=""/></span>
					<span className="outer-line" style={{borderColor:"#0ea5df"}}></span>
				</div>
				  <br></br>
				<div className="row">
					<div className="col-xl-6 col-lg-12 col-md-12 col-xs-12 col-sm-12">
					<section className="accordion-section clearfix mt-3" aria-label="Question Accordions">
					  <div className="">
						  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
							<div className="panel panel-default wow fadeInUp">
							  <div className="panel-heading p-3 mb-3" role="tab" id="heading0">
							  <i className="fa fa-angle-down"></i>
								<h3 className="panel-title">
								  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse0" aria-expanded="false" aria-controls="collapse0">
								  {global.siteText[0].faq_1}
								  </a>
								</h3>
							  </div>
							  <div id="collapse0" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading0">
								<div className="panel-body px-3 mb-4">
								  <p>{global.siteText[0].faq_1_1}</p>								  
								</div>
							  </div>
							</div>
							
							<div className="panel panel-default wow fadeInUp">
							  <div className="panel-heading p-3 mb-3" role="tab" id="heading1">
							  <i className="fa fa-angle-down"></i>
								<h3 className="panel-title">
								  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true" aria-controls="collapse1">
								  {global.siteText[0].faq_2}
								  </a>
								</h3>
							  </div>
							  <div id="collapse1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading1">
								<div className="panel-body px-3 mb-4">
								  <p>{global.siteText[0].faq_2_1}</p>
								</div>
							  </div>
							</div>
							
							<div className="panel panel-default wow fadeInUp">
							  <div className="panel-heading p-3 mb-3" role="tab" id="heading2">
							  <i className="fa fa-angle-down"></i>
								<h3 className="panel-title">
								  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="true" aria-controls="collapse2">
								  {global.siteText[0].faq_3}
								  </a>
								</h3>
							  </div>
							  <div id="collapse2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
								<div className="panel-body px-3 mb-4">
								  <p>{global.siteText[0].faq_3_1}</p>
								</div>
							  </div>
							</div>
							
							<div className="panel panel-default wow fadeInUp">
							  <div className="panel-heading p-3 mb-3" role="tab" id="heading3">
							  <i className="fa fa-angle-down"></i>
								<h3 className="panel-title">
								  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-expanded="true" aria-controls="collapse3">
								  {global.siteText[0].faq_4}
								  </a>
								</h3>
							  </div>
							  <div id="collapse3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
								<div className="panel-body px-3 mb-4">
								  <p>{global.siteText[0].faq_4_1}</p>
								  <p>{global.siteText[0].faq_4_2}</p>
								  <p>{global.siteText[0].faq_4_3}</p>
								</div>
							  </div>							  
							</div>
							
							<div className="panel panel-default wow fadeInUp">
							  <div className="panel-heading p-3 mb-3" role="tab" id="heading3">
							  <i className="fa fa-angle-down"></i>
								<h3 className="panel-title">
								  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse9" aria-expanded="true" aria-controls="collapse9">
								  {global.siteText[0].faq_5}
								  </a>
								</h3>
							  </div>
							  <div id="collapse9" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
								<div className="panel-body px-3 mb-4">
								  <p>{global.siteText[0].faq_5_1}</p>								  
								</div>
							  </div>							  
							</div>
							
							<div className="panel panel-default wow fadeInUp">
							  <div className="panel-heading p-3 mb-3" role="tab" id="heading3">
							  <i className="fa fa-angle-down"></i>
								<h3 className="panel-title">
								  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse10" aria-expanded="true" aria-controls="collapse10">
								  {global.siteText[0].faq_6}
								  </a>
								</h3>
							  </div>
							  <div id="collapse10" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
								<div className="panel-body px-3 mb-4">
								  <p>{global.siteText[0].faq_6_1}</p>
									<p>{global.siteText[0].faq_6_2}</p>
									<p>{global.siteText[0].faq_6_3}</p>
									<p>{global.siteText[0].faq_6_4}</p>
									<p>{global.siteText[0].faq_6_5}</p>
								</div>
							  </div>							  
							</div>
							
						  </div>					  
					  </div>
					</section>
					</div>
					<div className="col-xl-6 col-lg-12 col-md-12 col-xs-12 col-sm-12">
						<section className="accordion-section clearfix mt-3" aria-label="Question Accordions">
						  <div className="">
							  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
								<div className="panel panel-default wow fadeInUp">
								  <div className="panel-heading p-3 mb-3" role="tab" id="heading0">
								  <i className="fa fa-angle-down"></i>
									<h3 className="panel-title">
									  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse4" aria-expanded="false" aria-controls="collapse4">
									  {global.siteText[0].faq_7}
									  </a>
									</h3>
								  </div>
								  <div id="collapse4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading0">
									<div className="panel-body px-3 mb-4">
									  <p>{global.siteText[0].faq_7_1}</p>
									 
									</div>
								  </div>
								</div>
								
								<div className="panel panel-default wow fadeInUp">
								  <div className="panel-heading p-3 mb-3" role="tab" id="heading1">
								  <i className="fa fa-angle-down"></i>
									<h3 className="panel-title">
									  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse5" aria-expanded="true" aria-controls="collapse5">
									  {global.siteText[0].faq_8}
									  </a>
									</h3>
								  </div>
								  <div id="collapse5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading1">
									<div className="panel-body px-3 mb-4">
									  <p>{global.siteText[0].faq_8_1}</p>
									</div>
								  </div>
								</div>
								
								<div className="panel panel-default wow fadeInUp">
								  <div className="panel-heading p-3 mb-3" role="tab" id="heading2">
								  <i className="fa fa-angle-down"></i>
									<h3 className="panel-title">
									  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse6" aria-expanded="true" aria-controls="collapse6">
									  {global.siteText[0].faq_9}
									  </a>
									</h3>
								  </div>
								  <div id="collapse6" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
									<div className="panel-body px-3 mb-4">
									  <p>{global.siteText[0].faq_9_1}</p>
									  <ul>
										<li>{global.siteText[0].faq_9_2}</li>
										<li>{global.siteText[0].faq_9_3}</li>
										<li>{global.siteText[0].faq_9_4}</li>
										<li>{global.siteText[0].faq_9_5}</li>
										<li>{global.siteText[0].faq_9_6}</li>
										<li>{global.siteText[0].faq_9_7}</li>
										<li>{global.siteText[0].faq_9_8}</li>
										<li>{global.siteText[0].faq_9_9}</li>
										<li>{global.siteText[0].faq_9_10}</li>
										<p>{global.siteText[0].faq_9_11}</p>
										<p>{global.siteText[0].faq_9_12}</p>
									  </ul>
									</div>
								  </div>
								</div>
								
								<div className="panel panel-default wow fadeInUp">
								  <div className="panel-heading p-3 mb-3" role="tab" id="heading3">
								  <i className="fa fa-angle-down"></i>
									<h3 className="panel-title">
									  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse7" aria-expanded="true" aria-controls="collapse7">
									  {global.siteText[0].faq_10}
									  </a>
									</h3>
								  </div>
								  <div id="collapse7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
									<div className="panel-body px-3 mb-4">
									  <p>{global.siteText[0].faq_10_1}</p>
									  <ul>
										<li>{global.siteText[0].faq_10_2}</li>
										<li>{global.siteText[0].faq_10_3}</li>
										<li>{global.siteText[0].faq_10_4}</li>
										<li>{global.siteText[0].faq_10_5}</li>									
									  </ul>
									  <p>{global.siteText[0].faq_10_6}</p>
									  <p>{global.siteText[0].faq_10_7}</p>
									</div>
								  </div>
								</div>
							  </div>
								
								<div className="panel panel-default wow fadeInUp">
								  <div className="panel-heading p-3 mb-3" role="tab" id="heading3">
								  <i className="fa fa-angle-down"></i>
									<h3 className="panel-title">
									  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse8" aria-expanded="true" aria-controls="collapse8">
									  {global.siteText[0].faq_11}
									  </a>
									</h3>
								  </div>
								  <div id="collapse8" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
									<div className="panel-body px-3 mb-4">
									  <p>{global.siteText[0].faq_11_1}</p>
									  <p>{global.siteText[0].faq_11_2}</p>								  
									</div>
								  </div>
								</div>
								
								<div className="panel panel-default wow fadeInUp">
								  <div className="panel-heading p-3 mb-3" role="tab" id="heading3">
								  <i className="fa fa-angle-down"></i>
									<h3 className="panel-title">
									  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse11" aria-expanded="true" aria-controls="collapse11">
									  {global.siteText[0].faq_12}
									  </a>
									</h3>
								  </div>
								  <div id="collapse11" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
									<div className="panel-body px-3 mb-4">
									  <p>{global.siteText[0].faq_12_1}</p>
									  <p>{global.siteText[0].faq_12_2}</p>
									  <p>{global.siteText[0].faq_12_3}</p>							  
									</div>
								  </div>
								</div>
								
							</div>
						</section>
					</div>
					<div className="col-xl-1 col-lg-12 col-md-12 col-xs-12 col-sm-12"></div>
					<div className="col-xl-10 col-lg-12 col-md-12 col-xs-12 col-sm-12">
						
						<section className="accordion-section clearfix mt-3" aria-label="Question Accordions">
						  <div className="">
							  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
								<div className="panel panel-default wow fadeInUp">
								  <div className="panel-heading p-3 mb-3" role="tab" id="heading3">
								  <i className="fa fa-angle-down"></i>
									<h3 className="panel-title">
									  <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse12" aria-expanded="true" aria-controls="collapse12">
									  {global.siteText[0].faq_13}
									  </a>
									</h3>
								  </div>
								  <div id="collapse12" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
									<div className="panel-body px-3 mb-4">
									  <p>{global.siteText[0].faq_13_1}</p>
									  <p>{global.siteText[0].faq_13_2}</p>
									  <p>{global.siteText[0].faq_13_3}</p>							  
									</div>
								  </div>
								</div>
																
							</div>
							</div>
						</section>
						
					</div>
					<div className="col-xl-1 col-lg-12 col-md-12 col-xs-12 col-sm-12"></div>
				</div>				
			</div>					
		</div>			  
	</section>
	)
}
export default Faq;