import React,{useState} from 'react';
import { Path } from './Path.js';
import { useStateValue } from './StateProvider.js';
function ArtModal(){
	const [{ global }, dispatch] = useStateValue();
	const [artModal, setArtModal] = useState(false);
	return(
	<section>	
		<div className="row" style={{margin:"0"}}>
            <div className="art_modal_detail">
                <div className="row">
                    <div className="col-sm-6">
                        <img src={Path+`art_images/art1.jpg`} style={{width:"100%"}}></img>
                    </div>
                    <div className="col-sm-6">
                        <img src={Path+`art_images/art11.jpg`} style={{width:"100%"}}></img>
                    </div>
                </div>
                <h4>{global.siteText[0].art_modal1_1}</h4>
                <p>{global.siteText[0].art_modal1_2}</p>
                <p>{global.siteText[0].art_modal1_3}</p>
                <p>{global.siteText[0].art_modal1_4}</p>
                <p>{global.siteText[0].art_modal1_5}</p>
                <p>{global.siteText[0].art_modal1_6}</p>
                <p style={{fontSize:"14px"}}><i>{global.siteText[0].art_modal1_7}</i></p>
            </div>
        </div>
	</section>
	)
}
export default ArtModal;