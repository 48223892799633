import React,{useState,useRef} from 'react';
import {Button} from 'react-bootstrap';
import { Path } from './Path.js';
const Feedback=(props)=>{
	const get_review = useRef(null);
    const get_hrs = useRef(null);
    const [errors, setErrors] = useState('');
    const [rating, setRating] = useState('1');
    const [rating_text, setRatingText] = useState('');
    const [feedbackDiv, setFeedbackDiv] = useState(true);
    const [ratingOverValue, setRatingOverValue] = useState('');
    const [ratingClickValue, setRatingClickValue] = useState('');
    const [BtnStyle, setBtnStyle] = useState({Opacity:'1',PointerEvents:''});	
	const [Loader, setLoader] = useState(false);	
	const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [hrs, SetHrs] = useState('');
    var show_hrss=[];
	const handleValidation=()=>{
        let formIsValid = false;
        if(ratingClickValue===''){
		   setErrors(global.siteText[0].error_msg_48);		   
        }else if(get_review.current.value===''){
            setErrors(global.siteText[0].error_msg_49);		   
         }else if(get_hrs.current.value===''){
            setErrors(global.siteText[0].error_msg_50);		   
         }else {
			setErrors('');
			formIsValid = true;
		}	
        return formIsValid;
   }
    const setRatingClick=(value)=>{
        setRatingClickValue(value);
    }
    const setRatingOver=(value)=>{
        //setRatingText(text);
        setRatingOverValue(value);
        console.log('text',value);
        switch (value) {
            case 1:
            setRatingText('Poor');		
            break;
            case 2:
            setRatingText('Average');		
            break;
            case 3:
            setRatingText('Good');		
            break;
            case 4:
            setRatingText('Very good');		
            break;
            case 5:
            setRatingText('Excellent');		
            break;
        }
        
    }
    const hide_notification=()=>{
        setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
        setErrors('');
    }
    const show_notification=(response)=>{
        if(response.failed!=null){
            setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
        }else if(response.success!=null){
            setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});
        }
        setFeedbackDiv(false);
        //setTimeout(hide_notification, 4000);
    }
    React.useLayoutEffect(() => {
		for (var j = 1; j < 51; j++) {
        show_hrss.push(<option value={j} key={j}>{j}</option>);
        }
        SetHrs(show_hrss);
	},[]);
	 const add_data=()=>{
		const data = new FormData();
        data.append('review', get_review.current.value);
        data.append('rating', ratingClickValue);
        data.append('hrs', get_hrs.current.value);
        data.append('project_id', props.close_projectid);
		if(handleValidation()){	
			setLoader(true);
			setBtnStyle(prevState => ({
				...prevState,
				Opacity: '0.5'
			 }));
			setBtnStyle(prevState => ({
				...prevState,
				PointerEvents: 'none'
			 }));
			fetch(Path+"ajax_send_feedback.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{	
				setLoader(false);
				document.getElementById("add_proj").reset();
				setBtnStyle(prevState => ({
					...prevState,
					Opacity: ''
				 }));
				setBtnStyle(prevState => ({
					...prevState,
					PointerEvents: ''
				 }));
				show_notification(response);				
				}
			)
			.catch(err => {
                setLoader(false);
				setBtnStyle(prevState => ({
					...prevState,
					Opacity: ''
				 }));
				setBtnStyle(prevState => ({
					...prevState,
					PointerEvents: ''
				 }));
				setErrors(global.siteText[0].error_msg_14);
				setTimeout(hide_notification, 4000);
				return err;
			})
		}
	}			
		return(
		<section>
			<form id="add_proj" className="formm contact_formm" onSubmit={add_data}>
				{notification.show_success ? (<div className="alert alert-success feed_success" id="success2">{notification.success}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}		
				{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}
				{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
				{feedbackDiv? 
                (<div className="row">
                    <div className="row" style={{margin:"0"}}>
                        <div className="col-md-5 col-sm-5">
                        <div className="rating-group">
                            <i className={`rating__icon rating__icon--star fa fa-star ${ratingOverValue>=1 || ratingClickValue>=1? "selected_rating" : ""}`} onMouseOver={()=>setRatingOver(1)} onClick={()=>setRatingClick(1)}></i>

                            <i className={`rating__icon rating__icon--star fa fa-star ${ratingOverValue>=2 || ratingClickValue>=2 ? "selected_rating" : ""}`}  onMouseOver={()=>setRatingOver(2)}  onClick={()=>setRatingClick(2)}></i>
                            <i className={`rating__icon rating__icon--star fa fa-star ${ratingOverValue>=3 || ratingClickValue>=3 ? "selected_rating" : ""}`}  onMouseOver={()=>setRatingOver(3)}  onClick={()=>setRatingClick(3)}></i>
                            <i className={`rating__icon rating__icon--star fa fa-star ${ratingOverValue>=4 || ratingClickValue>=4 ? "selected_rating" : ""}`}  onMouseOver={()=>setRatingOver(4)}  onClick={()=>setRatingClick(4)}></i>
                            <i className={`rating__icon rating__icon--star fa fa-star ${ratingOverValue>=5 || ratingClickValue>=5 ? "selected_rating" : ""}`}   onMouseOver={()=>setRatingOver(5)}  onClick={()=>setRatingClick(5)}></i>
                        </div>        
                        </div>
                        <div className="col-md-7 col-sm-7">
                        <p style={{fontSize:"18px"}}>{rating_text}</p>
                        </div>
                    </div>                  
					<div className="col-md-12 col-sm-12">
                        <label>Tell us how it was to work with your coach</label>
						<textarea type="text" className="form-control" name="review" ref={get_review}  style={{height:"140px"}}></textarea>						 
					</div>
                    <div className="col-md-12 col-sm-12">
						<label>Total coaching time (hours)</label>
                        <p style={{marginBottom:"0",fontSize:"13px"}}>For example, 8	coaching sessions of 45	minutes	each = 6 hours total coaching time</p>
                        <select className="form-control" name="hrs" ref={get_hrs}>
							{hrs}
						</select>				 
					</div>
					<div className="col-md-12 col-sm-12 text-right" style={{marginTop:"20px"}}>
						<Button variant="primary" className="modal_btn" onClick={add_data} style={{opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>
							{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} Submit
						</Button>
					</div>					
				</div>):('')}
			</form>	
		</section>
		)
}
export default Feedback;