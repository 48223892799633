import React,{useState,useEffect,useRef} from 'react';
import {Button} from 'react-bootstrap';
import { Path } from './Path.js';
import { useStateValue } from './StateProvider.js';
import Feedback from 'react-bootstrap/esm/Feedback';
const View_profile=()=>{
	const [{ global }, dispatch] = useStateValue();
    const [profileData, setProfileData] = useState('');
    const [feedback, setFeedback] = useState(false);
    const [details, setDetails] = useState(true);
    const [loader, setLoader] = useState(true);
    const [tableOpacity, setTableOpacity] = useState('0.5');
    const [feedbackData, setFeedbackData] = useState(false);
	const [token, setToken] = useState(localStorage.getItem('token'));
	const ShowTab=(value)=>{
        if(value=='details'){
            setFeedback(false);
            setDetails(true);
        }else if(value=='feedback'){
            setDetails(false);
            setFeedback(true);
        }
    }
	const get_profile_data=()=>{
		fetch(Path+"get_profile_data.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
                setLoader(false);
                setTableOpacity('');
                setProfileData(response.updated_data);
                setFeedbackData(response.feedbacks);			
			}
		)		
	}
	React.useLayoutEffect(() => {
		get_profile_data();
	},[]);  
	
		return(
		<section>
            {loader?(<div className="spinner-border" role="status" style={{position:"absolute",top:"50%",left:"46%"}}>
					  <span className="sr-only">Loading...</span>
			</div>):('')}
			<div className="profile_div" style={{opacity:tableOpacity}}>               
                <div className="row" style={{margin:0}}>
                    <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 col-sm-12" style={{paddingLeft:"0px"}}>
                        <div className="prf_img">
                            {profileData.show_coach=='true' ? 
                                (<p className={profileData.badgeclass}>{profileData.badge}</p>):('')
                            }
                            {profileData.image ? 
                                (<img src={Path+`uploads/${profileData.image}`}></img>):
                                (<img src={Path+`uploads/placeholder.png`}></img>)
                            }                       
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 col-sm-12" style={{paddingLeft:"0"}}>
                    <table className="project_det">
                        <tbody>
                            <tr className="table_row">
                                <td className="project_head" style={{width:"108px"}}>{global.siteText[0].contact_3}:</td>
                                <td className="project_detail">{profileData.name} {profileData.lname}</td>
                            </tr>
                            {profileData.show_coach=='true' ? 
                                (<tr className="table_row">
                                    <td className="project_head" style={{width:"108px"}}>{global.siteText[0].view_profile_1}:</td>
                                    <td className="project_detail" style={{width:"100%"}}>
                                        <div className="rating-group">
                                            <span className="rating_box">{profileData.avg_rating==null ? '0' : profileData.avg_rating}</span>
                                            <i className={`rating__icon rating__icon--star fa fa-star show_star ${profileData.avg_rating>=1 ? "selected_rating" : ""}`}></i>
                                            <i className={`rating__icon rating__icon--star fa fa-star show_star ${profileData.avg_rating>1.5 ? "selected_rating" : ""}`}>
                                                {profileData.avg_rating>=1.5 && profileData.avg_rating<2 ?
                                                    (<div className="halfstar">&#x2605;</div>):('')
                                                }
                                            </i>
                                            <i className={`rating__icon rating__icon--star fa fa-star show_star ${profileData.avg_rating>2.5 ? "selected_rating" : ""}`}>
                                                {profileData.avg_rating>=2.5 && profileData.avg_rating<3 ?
                                                    (<div className="halfstar">&#x2605;</div>):('')
                                                }
                                            </i>
                                            <i className={`rating__icon rating__icon--star fa fa-star show_star ${profileData.avg_rating>3.5 ? "selected_rating" : ""}`}>
                                                {profileData.avg_rating>=3.5 && profileData.avg_rating<4 ?
                                                    (<div className="halfstar">&#x2605;</div>):('')
                                                }
                                            </i>
                                            <i className={`rating__icon rating__icon--star fa fa-star show_star ${profileData.avg_rating>4.5 ? "selected_rating" : ""}`}>
                                                {profileData.avg_rating>=4.5 && profileData.avg_rating<5 ?
                                                    (<div className="halfstar">&#x2605;</div>):('')
                                                } 
                                            </i><span style={{paddingLeft:"6px"}}>{profileData.total_feedback} {global.siteText[0].view_profile_2}</span>                         
                                        </div>
                                          
                                    </td>
                                </tr>):(<tr></tr>)
                            }
                            <tr className="table_row">
                                <td className="project_head" style={{width:"108px"}}>{global.siteText[0].country}:</td>
                                <td className="project_detail"><img src={Path+`flags/${profileData.flag}.png`} style={{height:"20px"}}></img> {global.showSiteLang=='English' ? profileData.countryname : profileData.countryname_spanish}</td>
                            </tr>
                            <tr className="">
                                <td className="project_head" colSpan="2">{global.siteText[0].view_profile_3}:</td>
                            </tr>
                            <tr className="table_row">
                                <td className="project_detail" colSpan="2" style={{textAlign:"left"}}>{global.showSiteLang=='English' ? profileData.show_languages :  profileData.show_languages_spanish}</td>
                            </tr>
                            <tr className="table_row">
                                <td className="project_head">{global.siteText[0].organization}:</td>
                                <td className="project_detail">{profileData.org} </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div> 
                <table style={{width:"100%",marginTop:"12px"}}>
                <tr className="">
                    <td className="project_head" colSpan="2">{global.siteText[0].view_profile_9}</td>
                </tr>
                <tr className="table_row">
                    <td className="project_detail" colSpan="2">{profileData.bio}</td>
                </tr>
                </table>
                {profileData.show_coach=='true'?
                    (<div className="container container-width" style={{marginTop:"28px",paddingLeft:"0"}}>
                    <ul className="nav nav-tabs" role="tablist" style={{width:"100%"}}>
                        <li className="nav-item">
                            <span className={`nav-link ${details ? "active" : ""}`} style={{fontSize:"16px"}} onClick={()=>ShowTab('details')}>{global.siteText[0].view_profile_4}</span>
                        </li>
                        <li className="nav-item">
                            <span className={`nav-link ${feedback ? "active" : ""}`} style={{fontSize:"16px"}} onClick={()=>ShowTab('feedback')}>{global.siteText[0].view_profile_5}</span>
                        </li>
                    </ul>
                    </div>):('')
                }              
                {profileData.show_coach=='true' && details? 
                    (
                    <div className="row" style={{margin:"0",paddingTop:"20px"}}>                       
                        <table className="project_det" style={{width:"100%"}}>
                            <tbody>
                                <tr className="">
                                    <td className="project_head" colSpan="2">{global.siteText[0].view_profile_6}</td>
                                </tr>
                                <tr className="table_row">
                                    <td className="project_detail" colSpan="2">{global.showSiteLang=='English' ?  profileData.category_name : profileData.category_name_spanish}</td>
                                </tr>
                                <tr className="">
                                    <td className="project_head" colSpan="2">{global.siteText[0].view_profile_7}</td>
                                </tr>
                                <tr className="table_row">
                                    <td className="project_detail" colSpan="2">{global.showSiteLang=='English' ? profileData.industry_name : profileData.industry_name_spanish}</td>
                                </tr>
                                <tr className="">
                                    <td className="project_head" colSpan="2">{global.siteText[0].view_profile_8}</td>
                                </tr>
                                <tr className="table_row">
                                    <td className="project_detail" colSpan="2">{profileData.total_exp} {global.siteText[0].view_profile_10}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>): (<div></div>)
                }                          
                {profileData.show_coach=='true' && feedback ? 
                (<div style={{height:"372px",overflowY:"scroll"}}>
                    {feedbackData?Object.keys(feedbackData).map((key,i)=>
                    <div className="row feedbck" key={i}>
                        <div className="col-xl-2 rating_div_img">
                            {feedbackData[key].image ?
                                (<img src={Path+`uploads/${feedbackData[key].image}`}></img>):
                                (<span className="profile_letter" style={{backgroundColor:"rgb(14, 165, 223)",marginRight:"4px"}}><b className="top_letter">{feedbackData[key].name.charAt(0)}</b></span>)
                            }    
                        </div>
                        <div className="col-xl-10 rating_div">    
                            <p>{feedbackData[key].name} <span>{feedbackData[key].hrs} {global.siteText[0].view_profile_11}</span></p>
                            <div className="rating-group">
                                <i className={`rating__icon rating__icon--star fa fa-star show_star ${feedbackData[key].rating>=1 ? "selected_rating" : ""}`}></i>
                                <i className={`rating__icon rating__icon--star fa fa-star show_star ${feedbackData[key].rating>=2 ? "selected_rating" : ""}`}></i>
                                <i className={`rating__icon rating__icon--star fa fa-star show_star ${feedbackData[key].rating>=3 ? "selected_rating" : ""}`}></i>
                                <i className={`rating__icon rating__icon--star fa fa-star show_star ${feedbackData[key].rating>=4 ? "selected_rating" : ""}`}></i>
                                <i className={`rating__icon rating__icon--star fa fa-star show_star ${feedbackData[key].rating>=5 ? "selected_rating" : ""}`}></i>                                  
                            </div>
                            <p className="rev">{feedbackData[key].review}</p>  
                        </div> 
                    </div>):(<div>{global.siteText[0].view_profile_12}</div>)}
                </div>):('')}          
            </div>        
		</section>
		)
}
export default View_profile;