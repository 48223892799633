import React,{useState,useRef} from 'react';
import {Modal} from 'react-bootstrap';
import icon from './../images/icon.png';
import { Path } from './Path.js';
import Pagination from './Pagination.js';
import Languages from './Languages.js';
import Project_details from './Project_details.js';
import {Button} from 'react-bootstrap';
import { useStateValue } from './StateProvider.js';
import { useHistory } from 'react-router-dom';
const AllProjects=()=>{	
	const [{ global }, dispatch] = useStateValue();
	let history = useHistory();	
	const get_expert = useRef(null);
    const get_lang = useRef(null);	
	const [allProjects, setAllProjects] = useState('');
	const [all_categories, setAll_categories] = useState('');
	const [all_categories_spanish, setAll_categories_spanish] = useState('');
	const [showId, setShowId] = useState('');
	const [BtnStyle, setBtnStyle] = useState({Opacity:'1',PointerEvents:''});	
	const [Loader, setLoaderr] = useState(false);
	const [show_project_details, setProjectDetails] = useState(false);
	const [page, setPage] = useState('1');
	const [projectId, setProjectId] = useState('');
	const [loader, setLoader] = useState(true);
	const [showOfferModal,setOfferModal] = useState(false);
	const [tableOpacity, setTableOpacity] = useState('');
	const [startFrom, setStartFrom] = useState('');
	const [totalPages, setTotalpages] = useState('');
	const [projectRecords, setProjectRecords] = useState('');
	const [token, setToken] = useState(localStorage.getItem('token'));
	const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
	const view_details_modal=(show_id)=>{
		setProjectDetails(!show_project_details);
		setShowId(show_id);
	}
	const view_offer_modal=(project_id)=>{
		setOfferModal(!showOfferModal);
		setProjectId(project_id);
	}
	const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
	}
    const show_notification=(response)=>{	   
	   if(response.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
	   }else if(response.success!=null){
		   setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});
	   }
	   setTimeout(hide_notification, 4000);
    }
	const SendOffer=()=>{
		setLoaderr(true);
		setBtnStyle(prevState => ({
			...prevState,
			Opacity: '0.5'
			}));
		setBtnStyle(prevState => ({
			...prevState,
			PointerEvents: 'none'
			}));
		fetch(Path+"send_offer.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				project_id:projectId,
				page: page
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			setLoaderr(false);
			setBtnStyle(prevState => ({
				...prevState,
				Opacity: ''
				}));
			setBtnStyle(prevState => ({
				...prevState,
				PointerEvents: ''
				}));
			setOfferModal(!showOfferModal);
			setAllProjects(response.project_data);	
			setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			setProjectRecords(response.pagination_data.total_records);
			show_notification(response.notifications);
		})
		.catch(err => {
			setLoader(false);
			setTableOpacity('');
            return err;
        })
	}
	const get_project_data=(pagee)=>{
		setLoader(true);
		setTableOpacity('0.5');
		fetch(Path+"get_all_projects.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				page: pagee,
				site_lang:global.showSiteLang
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			setLoader(false);
			setTableOpacity('');			
			setAllProjects(response.project_data);	
			setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			setProjectRecords(response.pagination_data.total_records);
		})
		.catch(err => {
            return err;
        })		
		
	}
	const search_project=()=>{
		setLoader(true);
		setTableOpacity('0.5');
		fetch(Path+"get_all_projects.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				expert_id: get_expert.current.value,
				language: get_lang.current.value,
				page: 1
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			setLoader(false);
			setTableOpacity('');			
			setAllProjects(response.project_data);	
			setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			setProjectRecords(response.pagination_data.total_records);			
		})
		.catch(err => {
            return err;
        })		
		
	}
	const Category_data=()=>{		
		fetch(Path+"get_category.php", {
			method: "POST",       
            body: JSON.stringify({
                site_lang:global.showSiteLang
            }), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            }      			
		})
		.then(res => res.json())
		.then(response=>{
			setAll_categories(response.categories);
			setAll_categories_spanish(response.categories_spanish);					
			}
		)		
	}
	const Onload=()=>{
		{localStorage.getItem('token')!=null ? (history.push('/ViewProjects')):(history.push('/'))}
	}		
	React.useEffect(() => {
		Onload();
		Category_data();
		get_project_data(page);
	},[startFrom]);	
	return(
		<section style={{background:"rgb(246, 252, 253)",marginTop:"56px"}}>
			<div className="row" style={{margin:"0",textAlign:"center"}}>
				<div className="" style={{width:"100%"}}>
					<div className="container container-width">
						<h1 className="head_text" style={{paddingTop:"64px"}}>{global.siteText[0].my_projects_55} <span style={{color:"#0ea5df"}}>{global.siteText[0].my_projects_56}</span></h1>
						<div className="divider text-center">
							<span className="outer-line"></span>
							<span className="image"><img src={icon} alt=""/></span>
							<span className="outer-line"></span>
						</div>						
					</div>					
				</div>
			</div>
			<div className="container container-width" style={{paddingTop:"40px",paddingBottom:"40px"}}>
				<div className="row" style={{margin:"0"}}>
					<div className="container container-width" style={{paddingBottom:"40px"}}>
					{notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
						<button type="button" className="close" data-dismiss="alert"></button>
					</div>) : ('')}		
					{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
						<button type="button" className="close" data-dismiss="alert"></button>
					</div>) : ('')}
					<div className="row" style={{margin:"0",marginTop:"40px"}}>
						<div className="left_div" style={{padding:"0"}}>
							<h4 style={{fontSize:"17px",fontWeight:"600",marginBottom:"0"}}>{global.siteText[0].my_projects_6} {allProjects ? <span>{projectRecords}</span>:''}</h4>
						</div>
						<div className="right_div" style={{padding:"0",textAlign:"right"}}>
							<div className="row" style={{margin:"0",float:"right"}}>
								<div className="" style={{paddingRight:"8px"}}>
									{global.showSiteLang=='English' ?
									(<select className="form-control" name="Category" ref={get_expert}>
										<option value="">{global.siteText[0].my_projects_5}</option>
										{all_categories?Object.keys(all_categories).map((key,i)=>
										<React.Fragment key={i}>
										<option value={all_categories[key].id}>{all_categories[key].name}</option>
										</React.Fragment>):('')}
									</select>):
									(
									<select className="form-control" name="Category" ref={get_expert}>
										<option value="">{global.siteText[0].my_projects_5}</option>
										{all_categories_spanish?Object.keys(all_categories_spanish).map((key,i)=>
										<React.Fragment key={i}>
										<option value={all_categories_spanish[key].id}>{ all_categories_spanish[key].name_spanish}</option>
										</React.Fragment>):('')}
									</select>	
									)}
								</div>
								<div className="" style={{paddingRight:"0",marginRight:"16px",width:"184px"}}>	
									<select className="form-control" name="language" ref={get_lang}>
										<option value="">{global.siteText[0].my_projects_61}</option>
										<React.Fragment>
											<Languages/>
										</React.Fragment>
									</select>    
								</div>
								<div className="" style={{paddingLeft:"0px"}}>
									<Button variant="primary" className="add_btn" onClick={search_project}>{global.siteText[0].my_projects_4}</Button>
								</div>
							</div>
						</div>
					</div>

					<div className="table-responsive div_show" id="no-more-tables" style={{position:"relative"}}>
						{loader?(<div className="spinner-border" role="status" style={{position:"absolute",top:"50%",left:"50%"}}>
						<span className="sr-only">Loading...</span>
						</div>):('')}
						<table className="table" style={{opacity:tableOpacity}}>
						<thead className="thh">
							<tr>
								<th>{global.siteText[0].my_projects_22}</th>
								<th>{global.siteText[0].my_projects_23}</th>
								<th>{global.siteText[0].my_projects_21}</th>
								<th>{global.siteText[0].my_projects_57}</th>
								<th>{global.siteText[0].my_projects_24}</th>
								<th>{global.siteText[0].my_projects_25}</th>
							</tr>
						</thead>
						<tbody id="tbo">{allProjects?Object.keys(allProjects).map((key,i)=>
						<tr key={i}>
							<td className='count_rec' data-title={global.siteText[0].my_projects_22} style={{width:"100px"}}>{startFrom+i+1}</td>
							<td className='count_rec proj_title' data-title={global.siteText[0].my_projects_23} style={{width:"320px"}} onClick={() =>view_details_modal(allProjects[key].project_id)}>{allProjects[key].title}</td>
							<td className='count_rec' data-title={global.siteText[0].my_projects_21}>{global.showSiteLang=='English' ? allProjects[key].name : allProjects[key].name_spanish}</td>
							<td className='count_rec' data-title={global.siteText[0].my_projects_57}>{global.showSiteLang=='English' ? allProjects[key].type : allProjects[key].type_spanish}</td>
							<td className='count_rec' data-title={global.siteText[0].my_projects_24}>{allProjects[key].sessions}</td>
							<td className='count_rec action_icon'  data-title={global.siteText[0].my_projects_25}  style={{width:"200px"}}>
								{allProjects[key].view_button =='yes'?
								(<span className='edit' title='Send offer' onClick={() => view_offer_modal(allProjects[key].project_id)}><i className='fa fa-external-link-square edit_icon action_icons' aria-hidden='true'></i></span>):
								(<span className='edit' style={{color:"green",fontWeight:"600"}}>Offer sent</span>)
								}		
							</td>
						</tr>):(<tr><td colSpan="5" style={{textAlign:"center"}}>{global.siteText[0].my_projects_69}</td></tr>)}
						</tbody>
						</table>
					</div>
				</div>
									
				</div>
				<Pagination page={page} totalpages={totalPages} onUpdatePage={get_project_data}/>		
				<div>
					<Modal show={show_project_details} animation={false}>
						<Modal.Header closeButton onClick={() => setProjectDetails(!show_project_details)} className="modal_head">
						<Modal.Title className="modal_title">{global.siteText[0].my_projects_58}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Project_details showId={showId}/>		
						</Modal.Body>
					</Modal>
				</div>
				<div>
					<Modal show={showOfferModal} animation={false}>
						<Modal.Header closeButton onClick={() => setOfferModal(!showOfferModal)} className="modal_head">
						<Modal.Title className="modal_title">{global.siteText[0].my_projects_59}</Modal.Title>
						</Modal.Header>
						<Modal.Body className="delete_modal">
							<h4>{global.siteText[0].my_projects_30}</h4>
							<p>	{global.siteText[0].my_projects_60}</p>
						</Modal.Body>
						<Modal.Footer className="delete_modal_footer">
							<Button variant="primary" className="modal_del_btn del_btnn" onClick={SendOffer} style={{opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].my_projects_43}</Button>
							<Button variant="secondary" className="modal_cencel_btn" onClick={() => setOfferModal(!showOfferModal)}>{global.siteText[0].my_projects_32}</Button>
						</Modal.Footer>
					</Modal>
				</div>	
			</div>			
		</section>
	);
}
export default AllProjects;