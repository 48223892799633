import React,{useState,useRef} from 'react';
import { Path } from './Path.js';
import { useStateValue } from './StateProvider.js';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
const Chatbox=(props)=>{
	const [{ global }, dispatch] = useStateValue();
    const get_message = useRef(null);
    const [allMessages, setAllMessages] = useState('');
     const [token, setToken] = useState(localStorage.getItem('token'));
    // const [loader, setLoader] = useState(true);
    // const [request_div, setRequest_div] = useState(false);
    // const [tableOpacity, setTableOpacity] = useState('');
     const [coach_id, setCoach_id] = useState('');
     const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});	
    const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
    }
    const show_notification=(response)=>{
        if(response.failed!=null){
            setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
        }else if(response.success!=null){
            setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});
        }
        setTimeout(hide_notification, 4000);
    }
    const messages_data=()=>{	        
		fetch(Path+"ajax_get_messages.php", { 
			method: "POST",       
			body: JSON.stringify({
                project_id: props.project_msgid,
                token:token
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
                setAllMessages(response.messages_data);
                props.onUpdate(response.project_data);		
            }
        )
        .catch(err => {
            return err;
        })		
    }
    const Send_message=()=>{	        
		fetch(Path+"ajax_send_message.php", { 
			method: "POST",       
			body: JSON.stringify({
                project_id: props.project_msgid,
                message: get_message.current.value,
                token:token
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
                get_message.current.value='';
                setAllMessages(response);		
            }
        )
        .catch(err => {
            return err;
        })		
    }
	React.useEffect(() => {
        messages_data();
        const timer = setInterval(() => {
			messages_data();
		}, 7000);
		return () => clearInterval(timer);
	},[]);	
		return(
		<section>
            <div className="chat_box">
                <div className="coach_messages">
                    {allMessages?Object.keys(allMessages).map((key,i)=>
                    <div className="msg" key={i}><p className="p1">{allMessages[key].message}</p><p><span>{global.siteText[0].by}: {allMessages[key].name}, {global.siteText[0].date}: {allMessages[key].date}</span></p></div>
                    ):(<p></p>)}                    
                </div>
                <div className="send_message">
                    <textarea placeholder={global.siteText[0].my_projects_42} ref={get_message} className="form-control" style={{height:"120px"}}></textarea>
                    <span onClick={Send_message}><i className="fa fa-paper-plane"></i> {global.siteText[0].my_projects_43}</span>
                </div>                
            </div>
		</section>
		)
}
export default Chatbox;