import React,{useEffect} from 'react';
import icon from './../images/icon.png';
import { useStateValue } from './StateProvider.js';
function Guidelines(){	
	const [{ global }, dispatch] = useStateValue();
	return(
	<section>	
		<div className="container" style={{maxWidth:"1100px",paddingBottom:"40px"}}>
		<div className="main_guide">
			<h1 className="head_text">{global.siteText[0].community} <span style={{color:"#0ea5df"}}>{global.siteText[0].rules}</span></h1>	
			<div className="divider text-center" style={{marginBottom:"20px"}}>
				<span className="outer-line"></span>
				<span className="image"><img src={icon} alt=""/></span>
				<span className="outer-line"></span>
			</div>
			<div className="guide">
				<p>{global.siteText[0].guidelines_1}</p>
				<p> {global.siteText[0].guidelines_2}</p>
				<p>{global.siteText[0].guidelines_3}</p>
				<ul>
					<li>{global.siteText[0].guidelines_4}</li>
					<li>{global.siteText[0].guidelines_5}</li>
					<li>{global.siteText[0].guidelines_6}</li>
					<li>{global.siteText[0].guidelines_7}</li>
					<li>{global.siteText[0].guidelines_8}</li>
					<li>{global.siteText[0].guidelines_9}</li>
					<li>{global.siteText[0].guidelines_10}</li>
					<li>{global.siteText[0].guidelines_11}</li>
					<li>{global.siteText[0].guidelines_12}</li>
				</ul>
				<p>{global.siteText[0].guidelines_13}</p>
				<p>{global.siteText[0].guidelines_14}</p>
				<p>{global.siteText[0].guidelines_15}</p>
				<p> {global.siteText[0].guidelines_16}</p>
				<p>{global.siteText[0].guidelines_17}:</p>
				<p><i>"{global.siteText[0].guidelines_18}."</i></p>
				<p>{global.siteText[0].guidelines_19}.</p>
				<p> {global.siteText[0].guidelines_20}</p>
				<p>{global.siteText[0].guidelines_21}:</p>
				<p>1. {global.siteText[0].guidelines_22}:</p>
				<ul>
					<li>{global.siteText[0].guidelines_23}</li>
					<li>{global.siteText[0].guidelines_24}</li>
					<li>{global.siteText[0].guidelines_25}</li>
				</ul>
				<p>{global.siteText[0].guidelines_26}</p>
				<p>2. {global.siteText[0].guidelines_27}</p>
				<p> {global.siteText[0].guidelines_28}</p>
				<p>{global.siteText[0].guidelines_29}</p>
				<p>{global.siteText[0].guidelines_30}</p>
				<p> {global.siteText[0].guidelines_31}</p>
				<p>{global.siteText[0].guidelines_32} </p>
			</div>
			</div>
		</div>			  
	</section>
	)
}
export default Guidelines;