import React,{useState,useEffect,fetchContent,useRef} from 'react';
import {Button} from 'react-bootstrap';
import { Path } from './Path.js';
import parser from 'html-react-parser';
import { useStateValue } from './StateProvider.js';
const Pagination=(props)=>{
	const [{ global }, dispatch] = useStateValue();
	var show_pages="";
	var ActivePage="";
	const [paginationBox, setPaginationBox] = useState('');		
	const [activePage, setActivePage] = useState('active');
	const [currentPage, setCurrentPage] = useState(props.page);
	const [totalPages, setTotalPages] = useState(props.totalpages);
	// console.log('top total pages:',props.totalpages);
	React.useEffect(() => {
		setTotalPages(props.totalpages);
		setCurrentPage(props.page);
		// console.log('total pages:',props.totalpages);			
		for(var i=1;i<=props.totalpages;i++){
			if(props.page==i){					
				show_pages+="<li class='active_links page-item page-link activee' id="+i+">"+i+"</li>";
			}else{				
				show_pages+="<li class='active_links page-item page-link' id="+i+">"+i+"</li>";
			}
		}
		setPaginationBox(show_pages);
	},[props.totalpages,props.page]);
		const Update_next_page=(e)=>{
			var dots = document.querySelectorAll(".page_box li");
			  for (var n = 0; n < dots.length; ++n) {
				  dots[n].className = "active_links page-item page-link";
				}			
			var new_page = currentPage;
			dots[new_page].className = "active_links page-item page-link activee";			
			new_page++;			
			props.onUpdatePage(new_page);
		}
		const Update_prev_page=(e)=>{
			var dots1 = document.querySelectorAll(".page_box li");
			  for (var z = 0; z < dots1.length; ++z) {
				  dots1[z].className = "active_links page-item page-link";
				}
			var new_page1 = currentPage;					
			new_page1--;	
			dots1[new_page1].className = "active_links page-item page-link activee";
			props.onUpdatePage(new_page1);
		}
		const current_page=(e)=>{
			// console.log(e.target);
			var dots = document.querySelectorAll(".active_links");
			  for (var n = 0; n < dots.length; ++n) {
				  dots[n].className = "active_links page-item page-link";
				}
			e.target.className += " activee";
			props.onUpdatePage(e.target.id);
		}				
		
		return(
			<section>
				<div className="pagination justify-content-center" style={{marginBottom:"20px"}}>
					{
					props.page>1 ? (<li className='page-item page-link' style={{cursor:"pointer"}} onClick={Update_prev_page}>{global.siteText[0].my_projects_7}</li>): (<li className='page-item page-link disabledd'>{global.siteText[0].my_projects_7}</li>)
					}
					<div onClick={current_page} className="page_box" style={{display:"flex"}} dangerouslySetInnerHTML={{ __html: paginationBox }} />					
					{
					props.page < totalPages ? (<li className='page-item page-link justify-content-end' onClick={Update_next_page} style={{cursor:"pointer"}}>{global.siteText[0].my_projects_8}</li>): (<li className='page-item page-link disabledd justify-content-end'>{global.siteText[0].my_projects_8}</li>)
					}
				</div>
			</section>
		)
}
export default Pagination;