import React, {Component} from 'react';
import './App.css';
import Navbar from './html/Navbar.js';
import Slider from './html/Slider.js';
import Divider from './html/Divider.js';
import Divider1 from './html/Divider1.js';
import About from './html/About.js';
import Team from './html/Team.js';
import Resources from './html/Home-Resources.js';
import Faq from './html/Faq.js';
import Contact from './html/Contact.js';
import Footer from './html/Footer.js';
import './fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { StateProvider } from './html/StateProvider.js';
import { reducer,initialState} from './html/reducer.js';
import Project_Table from './html/Project_Table.js';
import ReqProjects from './html/ReqProjects.js';
import AllProjects from './html/AllProjects.js';

const HomePage=()=>{
	return(
		<div>
			<Navbar/>
			<Slider/>
			<About/>
			<Divider/>
			<Team/>
			<Resources/>
			<Faq/>
			<Contact/>
			<Footer/>
		</div>	
	);
}
const RequestedProjects=()=>{
	return(
		<div>
			<Navbar/>
			<ReqProjects/>
			<Footer/>			
		</div>
	);
}
const ViewProjects=()=>{
	return(
		<div>
			<Navbar/>
			<AllProjects/>
			<Footer/>			
		</div>
	);
}
const MyProjects=()=>{
	return(
		<div>
			<Navbar/>
			<Project_Table/>
			<Footer/>			
		</div>
	);
}
class App extends Component {
	render() {
	  return (
	  <StateProvider initialState={initialState} reducer={reducer}>
		  <Router basename='/'>			
			  <Switch>
				  <Route exact path="/" component={HomePage} />
				  <Route exact path="/MyProjects" component={MyProjects} />
				  <Route exact path="/RequestedProjects" component={RequestedProjects} />
				  <Route exact path="/ViewProjects" component={ViewProjects} />																
			  </Switch>
		  </Router>
	  </StateProvider>
	);
	}
  }

export {App,HomePage,MyProjects,RequestedProjects,ViewProjects};
