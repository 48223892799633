import React,{useState} from 'react';
import {Button} from 'react-bootstrap';
//import Recaptcha from 'react-gcaptcha';
import Recaptcha from "react-google-recaptcha";
import { useHistory } from 'react-router-dom';
import { useStateValue } from './StateProvider.js';
import { Path } from './Path.js';

const Login=()=>{
	var codee;
	let history = useHistory();
	const [{ global }, dispatch] = useStateValue();
	const [username, setUsername] = useState('');
	const [pwd, setPwd] = useState('');
	const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
	const [captcha, setCaptcha] = useState(true); // was set to true to bypass captcha
	const [showPassValue, setShowPassValue] = useState('');
	const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
	const [Loader, setLoader] = useState(false);
	const [errors, setErrors] = useState('');
	const [siteKey,setSiteKey] = useState(global.siteKey);
	const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
		setErrors('');
	}
    const show_notification=(response)=>{	   
	   if(response.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
	   }else if(response.success!=null){
		   setNotification({success:response.success,failed:'',show_failed:false,show_success:true});
	   }
	   setTimeout(hide_notification, 4000);
    }	
   	const handleValidation=()=>{
		let formIsValid = false;
		if(username===''){
		setErrors(global.siteText[0].error_msg_27);
		}else if(pwd===''){
			setErrors(global.siteText[0].error_msg_7);
		}
		else if(captcha==false) {
			setErrors(global.siteText[0].error_msg_68);
		}
		else {
			setErrors('');
			formIsValid = true;
		}
		return formIsValid;
	}
	const ShowModal=()=>{
		dispatch({
			type: 'changeLogModal',
			newLogModal: {showLogModal: !global.showLogModal}
		});
		dispatch({
			type: 'changeForgotModal',
			newForgotModal: {showForgotModal: !global.showForgotModal}
		});
		// console.log('testing');
	}
	const ChangeModal=()=>{
		dispatch({
			type: 'changeLogModal',
			newLogModal: {showLogModal: false}
			})
		dispatch({
		type: 'changeRegModal',
		newRegModal: {showRegModal: true}
		})
	}
	const verifyBack = (response) => {
		if (response) {
			setCaptcha(true);
		}
	}
	const Login_data=()=>{
	if(handleValidation()){
		setLoader(true);
		setOpacity('0.5');
		setPointerEvents('none');
			fetch(Path+"ajax_login.php", {
				method: "POST",
				body: JSON.stringify({
					username: username,
					pwd: pwd
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				}
			})
			.then(res => res.json())
			.then(response=>{
					setLoader(false);
					setOpacity('');
					setPointerEvents('');
					// console.log('token is:',response.user_name);
					if(response.failed==''){
						localStorage.setItem('token', response.token);
						localStorage.setItem('coach', response.coach);
						dispatch({
							type: 'changeToken',
							newToken: { token: response.token}
						})
						dispatch({
						type: 'changeLogModal',
						newLogModal: {showLogModal: !global.showLogModal}
						})
						dispatch({
							type: 'changeCoachTab',
							newCoachTab: { showCoachTab: response.coach}
						})
						//history.push('/');
					}else{
						show_notification(response);
						console.log('not login');
					}
				}
			)
			.catch(err => {
				setLoader(false);
				setOpacity('');
				setPointerEvents('');
				console.log('err',err);
				setErrors(global.siteText[0].error_msg_14);
				setTimeout(hide_notification, 4000);
				return err;
			})
		}
	}
	const enterPressed=(event)=>{
		var code = event.keyCode || event.which;
		if(code === 13) { //13 is the enter keycode
			Login_data();
		}
	}
	React.useLayoutEffect(() => {
		//createCaptcha();
	},[]);
		return(
		<section>
			<form id="login_form" className="formm contact_formm" onSubmit={Login_data} onKeyPress={enterPressed}>
				{notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}		
				{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}
				{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
				<div className="row">
					<div className="col-md-12 col-sm-12">
						
						<label>{global.siteText[0].email_address}</label>
						<input type="text" className="form-control" name="username" id="login_username"  onChange={event => setUsername(event.target.value)}/>
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].pass}</label>
						<input type={showPassValue!='' ? 'text' : 'password'} className="form-control" name="pwd" id="login_pwd"  onChange={event => setPwd(event.target.value)}/>
						{showPassValue!='' ? 
						(<i className="fa fa-eye-slash show_pass" onClick={event => setShowPassValue('')}></i>):
						(<i className="fa fa-eye show_pass"  onClick={event => setShowPassValue('true')}></i>)}
					</div>
					{/* <div className="col-md-12 col-sm-12" style={{marginTop:"20px"}}>
						<Recaptcha
							sitekey={siteKey}
							render="explicit"
							hl={global.showSiteLang=='English' ? 'en' :'es'}
							onChange={verifyBack}
						/>
					</div> */}
					<div className="col-md-12 col-sm-12 text-right" style={{marginTop:"20px"}}>
						<Button variant="primary" className="modal_btn" onClick={Login_data} style={{opacity:Opacity,pointerEvents:PointerEvents}}>
							{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].contact_7}
						</Button>
					</div>
					<div className="col-md-12 col-sm-12 text-center">
						<a onClick={ShowModal} style={{cursor:"pointer"}}>{global.siteText[0].frgt_pass}</a>
					</div>
					<div style={{textAlign:"center",width:"100%"}}>
						<p style={{color: "black",cursor:"pointer",marginBottom: "0",fontSize:"17px",paddingTop:"10px"}} onClick={ChangeModal}> {global.siteText[0].not_member}</p>
					</div>
				</div>
			</form>
		</section>
		)
}
export default Login;