import React,{useState} from 'react';
import {Button} from 'react-bootstrap';
import { Path } from './Path.js';
import { useStateValue } from './StateProvider.js';
const Forgot_pass=()=>{
	const [{ global }, dispatch] = useStateValue();
	const [Email, setEmail] = useState('');
	const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');	
	const [FailedError, setFailedError] = useState(false);
	const [FailedError1, setFailedError1] = useState(false);
	const [SuccessError, setSuccessError] = useState(false);
	const [Loader, setLoader] = useState(false);	
	const [errors, setErrors] = useState('');
	
   	const handleValidation=()=>{
		let formIsValid = false;
		if(Email===''){
		setErrors(global.siteText[0].error_msg_27);		   
		}else {
			setErrors('');
			formIsValid = true;
		}	
		return formIsValid;
	}
   const seterror=()=>{
	    setFailedError(false);	
	    setFailedError1(false);	
		setSuccessError(false);
		setErrors('');	
	} 
	const Profile_data=()=>{
		const data = new FormData();
		data.append('Email', Email);
		if(handleValidation()){	
			setLoader(true);
			setOpacity('0.5');
			setPointerEvents('none');
			fetch(Path+"ajax_forgot_pwd.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
					setLoader(false);
					setOpacity('');
					setPointerEvents('');
					// console.log(response);
					if(response=='1'){
						setSuccessError(true);						
						setTimeout(seterror, 4000);
						document.getElementById("frgt_form").reset();
					}else if(response=='0'){
						setFailedError(true);
						setTimeout(seterror, 4000);
					}else if(response=='2'){
						setFailedError1(true);
						setTimeout(seterror, 4000);
					}
				}
			)
			.catch(err => {
                setLoader(false);
				setOpacity('');
				setPointerEvents('');
				setErrors(global.siteText[0].error_msg_14);
				setTimeout(seterror, 4000);
				return err;
			})
		}
	}	
		return(
		<section>
			<form id="frgt_form" className="formm contact_formm" onSubmit={Profile_data}>
				{SuccessError? (<div className="alert alert-success" id="success2">{global.siteText[0].error_msg_15}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>):('')}				
				{FailedError? (<div className="alert alert-danger" id="danger2">{global.siteText[0].error_msg_2}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>):('')}
				{FailedError1? (<div className="alert alert-danger" id="danger2">{global.siteText[0].error_msg_16}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>):('')}
				{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
				<div className="row">					
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].contact_4}</label>
						<input type="text" className="form-control" name="Email" onChange={event => setEmail(event.target.value)}/>
					</div>					
					<div className="col-md-12 col-sm-12 text-right" style={{marginTop:"20px"}}>
						<Button variant="primary" className="modal_btn" onClick={Profile_data} style={{opacity:Opacity,pointerEvents:PointerEvents}}>
							{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].contact_7}
						</Button>
					</div>					
				</div>
			</form>	
		</section>
		)
}
export default Forgot_pass;