import React,{useState} from 'react';
import { Path } from './Path.js';
import ArtModal from './ArtModal.js';
import Article2 from './Article2.js';
import Article3 from './Article3.js';
import icon from './../images/icon.png';
import {Modal} from 'react-bootstrap';
import { useStateValue } from './StateProvider.js';
function Divider(){
	const [{ global }, dispatch] = useStateValue();
	const [artModal, setArtModal] = useState(false);
	const [artModal2, setArtModal2] = useState(false);
	const [artModal3, setArtModal3] = useState(false);
	return(
	<section id="stories">	
		<div className="divider_main_div">
		<div>
			<div className="container" style={{padding:"68px 0px",maxWidth:"1000px"}}>
				<h1 className="head_text" style={{color:"white"}}>{global.siteText[0].success} <span style={{color:"#0ea5df"}}>{global.siteText[0].stories}</span></h1>	
				<div className="divider text-center" style={{marginBottom:"40px"}}>
					<span className="outer-line" style={{borderColor:"white"}}></span>
					<span className="image"><img src={icon} alt=""/></span>
					<span className="outer-line" style={{borderColor:"white"}}></span>
				</div>
				<div className="row" style={{margin:"0",width:"100%"}}>
					<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{marginBottom:"16px"}}>
						<div className="articles" onClick={() => setArtModal(!artModal)}>
							<img src={Path+`art_images/art1.jpg`}></img>
							<h4>{global.siteText[0].art1_1}</h4>
							<p>{global.siteText[0].art1_2}</p>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{marginBottom:"16px"}}>
						<div className="articles" onClick={() => setArtModal2(!artModal2)}>
							<img src={Path+`art_images/art2.jpg`}></img>
							<h4>{global.siteText[0].art2_1}</h4>
							<p>{global.siteText[0].art2_2}</p>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{marginBottom:"16px"}}>
						<div className="articles" onClick={() => setArtModal3(!artModal3)}>
						<img src={Path+`art_images/art3.jpg`}></img>
							<h4>{global.siteText[0].art3_1}</h4>
							<p>{global.siteText[0].art3_2}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<Modal show={artModal} animation={false} dialogClassName="art_modal">
				<Modal.Header closeButton className="modal_head" onClick={() => setArtModal(!artModal)}>
				<Modal.Title className="modal_title">{global.siteText[0].art1_1}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ArtModal/>
				</Modal.Body>
				<div style={{textAlign:"right",paddingRight:"40px",paddingBottom:"20px"}}><button className="btn art_close" onClick={() => setArtModal(!artModal)}>{global.siteText[0].close}</button></div>
			</Modal>
		</div>
		<div>
			<Modal show={artModal2} animation={false} dialogClassName="art_modal">
				<Modal.Header closeButton className="modal_head" onClick={() => setArtModal2(!artModal2)}>
				<Modal.Title className="modal_title">{global.siteText[0].art2_1}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Article2/>
				</Modal.Body>
				<div style={{textAlign:"right",paddingRight:"40px",paddingBottom:"20px"}}><button className="btn art_close" onClick={() => setArtModal2(!artModal2)}>{global.siteText[0].close}</button></div>
			</Modal>
		</div>
		<div>
			<Modal show={artModal3} animation={false} dialogClassName="art_modal">
				<Modal.Header closeButton className="modal_head" onClick={() => setArtModal3(!artModal3)}>
				<Modal.Title className="modal_title">{global.siteText[0].art3_1}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Article3/>
				</Modal.Body>
				<div style={{textAlign:"right",paddingRight:"40px",paddingBottom:"20px"}}><button className="btn art_close" onClick={() => setArtModal3(!artModal3)}>{global.siteText[0].close}</button></div>
			</Modal>
		</div>		
		</div>
			  
	</section>
	)
}
export default Divider;