//export const Path = "http://localhost/testing/testing/src/html/backend/";
//export const Path = "https://demoserver44.com/files/women1v/backend/";
export const Path = 'https://fromwomantowoman.org/backend/';
export const lang_en = [
    {
        "menu1": "Home",
        "menu2": "How it works",
        "how_it":"How it",
        "works":"works",
        "menu3": "Testimonials",
        "menu4": "Our team",
        "menu5": "FAQs",
        "menu6": "Contact us",
        "menu7": "Resources",
        "resources": "Resources",
        "organization": "Organization",
        
        "reg_menu": "Become a member",
        "login_menu": "Sign in",
        "slider1_text1": "Do you need coaching for your",
        "slider1_text1_2": "private or professional project?",
        "slider1_text2": "Upload your project description and find a free coach.",
        "slider2_text1": "Are you an expert in",
        "slider2_text1_2": "a particular field?",
        "slider2_text2": "Sign up and share your know-how for free",
        "slider2_text2_2": "with women who need your expertise!",
        "slider3_text1": "Our mission is simple.",
        "slider3_text2": "To provide a global platform where women",
        "slider3_text2_2": "connect with female coaches for free.",
        "About_us":"About us",
        "about1":"Our platform connects women who need coaching to improve their skills, we call them helpseekers, with coaches – women whohave experience and know-how in a wide range of specific fields and are willing to coach other women for free.",
        "about2":"A coaching project created by a help-seeker could for example entail:",
        "about3":"Preparing for your future studies or deciding on a career path",
        "about4":"Becoming better at writing business plans or drawing up budgets",
        "about5":"Getting valuable insight on how to organize your life-work balance as a mother",
        "about6":"Are you a woman who would like to benefit from the expertise of a coach on our platform? Or are you a specialist in a particular field, willing to donate your coaching time to other women?",
        "about7":"Then sign up to our platform! Create a user profile, upload your coaching request and connect with a coach who will plan coaching sessions* with you – it is absolutely free and will always stay free!",
        "about8":"*Once matched, the help-seeker and the coach will use our chat function to agree how many coaching sessions will be held and which medium (for example Skype, WhatsApp, Zoom, etc.) is most suitable for both parties.",
        "success":"Success",
        "stories":"Stories",
        "art1_1":"Magnifica Warmi",
        "art1_2":"Coaching Bolivian women to achieve environmental sustainability in their communities.",
        "art_modal1_1":"Magnifica Warmi: Empowering women to promote environmental sustainability in their communities",
        "art_modal1_2":"Magnifica Warmi is a socio-environmental project that seeks to empower women from rural areas around the city of Sucre, Bolivia, as ambassadors and promoters of environmentally sustainable practices in their communities. The main objective of the workshops given by Magnifica Warmi is to train women to understand the value of their role as mothers, wives, daughters and leaders in decision-making to achieve environmental sustainability in their communities. Technics on solid waste management and recycling are part of the curricula.",
        "art_modal1_3":"In 2019, a coach from the from woman to woman platform conducted 10 online coaching sessions for the young female members of the project. Their goal was to receive input on how to establish a structured methodology for the workshops. After successful completion of the coaching sessions, Magnifica Warmi has since then managed to give workshops to 200 women from rural areas who live in areas vulnerable to contamination, benefiting more than 500 families.",
        "art_modal1_4":"The team of Magnifica Warmi summarized their coaching experience in the following lines: The coaching sessions facilitated by a coach on the from woman to woman platform were crucial so that we could design the workshops and the corresponding methodology. In the beginning of our project we did not know how to start and what to do, but with the coaching sessions we learned many technical competencies that have helped us to improve the concept we had for our workshops. We are really thankful because our coach believed in our idea and helped us to make it happen!",
        "art_modal1_5":"Find out more about the work of this magnificent group at:",
        "art_modal1_6":"https://tubecabolivia.com/magnifica-warmi/",
        "art_modal1_7":"Article uploaded  on September 7, 2020",
        "art2_1":"I AM LIKE YOU! Agents for Inclusion",
        "art2_2":"Preparing students and teachers to be agents of change who promote the educational and social inclusion of people with disabilities.",
        "art_modal2_1":"I'M LIKE YOU! Agents for Inclusion: A community who is changing the perspective towards people with disabilities",
        "art_modal2_2":"I'M LIKE YOU! Agents for Inclusion (SOY COMO TU! AxI) is a community, led by people with and without disabilities promoting the inclusion of people with disabilities in the educational system in La Paz, Bolivia. Their activities focus on changing negative attitudes and increasing knowledge about how to interact with people with disabilities. They achieve this through awareness-raising activities and workshops in their program Sensitization of Good Treatment towards People with Disabilities.",
        "art_modal2_3":"In 2020, a coach from the from woman to woman platform has gone through 10 coaching sessions with the SOY COMO TU! AxI team, to help them organize their outreach program 'Sensitization of Good Treatment towards People with Disabilities' in schools of La Paz. The development of a work plan and a budget, development of strategic alliances and advice on the adaptation of their work to the digital format (given the current COVID 19 crisis) have been topics discussed with the group.",
        "art_modal2_4":"In autumn 2020, the team of SOY COMO TU! AxI will deliver the first workshop of their program to students and teachers of a school in La Paz. They plan to benefit 50 people, students and teachers, training them as agents of change who will advance the educational and social inclusion of people with disabilities in their academic communities.",
        "art_modal2_5":"The team of “I AM LIKE YOU! AxI” has summarized their coaching experience in the following lines:",
        "art_modal2_6":"Our coach from the from woman to woman platform is part of I AM LIKE YOU! She has been with us in every step along the way. We were lost many times, but our coach supported us and walked with us, highlighting important aspects of the project. We are so grateful and blessed by this platform and share a vision: let's make the world a better place and to create a positive impact. We know that we are not alone, and we always will the support of our coach with us!",
        "art_modal2_7":"You can visit their page to read more about their incredible projects:",
        "art_modal2_8":"https://www.facebook.com/SOYCOMOTUAgentesporlaInclusion",
        "art3_1":"Launch of the new from woman to woman platform",
        "art3_2":"A more intuitive and leaner coaching platform will help us accomplish our mission!",
        "art_modal3_3":"WE DID IT: Launch of the new from woman to woman platform",
        "art_modal3_4":"Since the end of 2017, the Swiss non-profit organization from woman to woman has run and maintained an online coaching platform. On this platform, women who need coaching to improve their skills, we call them helpseekers, are connected with coaches – women who have experience and know-how in a wide range of specific fields and are willing to coach other women for free.",
        "art_modal3_5":"After having gone through detailed feedback from platform users and our Board of Directors, from woman to woman re-defined the essential features of the coaching platform and implemented a new version of it that is visually more attractive, more intuitive and leaner.",
        "art_modal3_6":"Sandra Leicht-Cabrera, Executive Director of from woman to woman, could not be happier with the result: 'The new coaching platform is free and easy to use. Its main added value – our network of excellent, experienced coaches in many fields – can now be even more easily accessed by women who look for a coach with experience in their fields. This will lead to many fruitful coaching partnerships across the globe and will help accomplish our mission: to promote the borderless exchange of know-how and expertise among women.'",
        "art_modal3_7":"The new platform will initially be available in English and Spanish. We are looking for volunteers who can translate the page in more languages. Interested? We would love to hear from you through our contact form on the main page.",
        "our":"Our",
        "team":"Team",
        "team1_1":"Sandra Leicht",
        "team1_2":"Sustainability standards & value chains expert",
        "team1_3":"Based in Switzerland",
        "team1_4":"From an early age I was involved in social projects in my home country Bolivia. Many women have bright ideas to help themselves and others, but they do not know where to start. from woman to woman is a contact point for them and also for coaches who want to help but do not know how. My vision is that through our platform, we contribute in a concrete and measurable way to the UN's sustainability goals.",
        "team1_5":"Executive Director",
        "team2_1":"Damaris Diethelm",
        "team2_2":"Social pedagogue",
        "team2_3":"Based in Switzerland",
        "team2_4":"With my involvement in from woman to woman, I want to ensure that women around the world can access the know-how of others without the barriers they might face in their surroundings. I use my professional network in social work to motivate women to make their knowledge available to other women free of charge as coaches on our platform.",
        "team2_5":"President of the Board",
        "team3_1":"Dr. Tshilidzi Van der Lecq",
        "team3_2":"Ophthalmologist",
        "team3_3":"Based in South Africa",
        "team3_4":"I am an African South African, a wife, and a mother. Currently, I work as a consultant ophthalmologist based at the University of Cape Town. My country has a long legacy of women who overcome challenges to bring about change for themselves and their communities. Through my engagement with from woman to woman, I wish to be a part of that change.",
        "team3_5":"Board member",
        "team4_1":"Monica Wilson",
        "team4_2":"Cyber security specialist",
        "team4_3":"Based in the USA",
        "team4_4":"My desire to help women is linked to my wish to eliminate poverty and suffering of children. There should not exist in this world a child who does not have the basic needs covered. If we help women, like we aim to do with from woman to woman, they will have the means to raise their children with dignity.",
        "team4_5":"Board member",
        "team5_1":"David Leicht",
        "team5_2":"Human capital consultant & coach",
        "team5_3":"Based in Switzerland",
        "team5_4":"I am passionate about using know-how as a cross-border resource for self-help: women all over the world have immense expertise that they want to share with other women. from woman to woman offers a unique free platform for those seeking help. I am honored to make a small contribution to the transfer of knowledge for the benefit of women in need.",
        "team5_5":"Board member",

        "faq_heading":"FAQ",
        "faq_heading1":"s",
        "faq_1":"I am looking for coaching. Why should I register on the from woman to woman platform?",
        "faq_1_1":"Because we have a great network of very capable coaches from many fields and countries. You can profit from their experience and know-how for free.",
        "faq_2":"I am a specialist in my field – why should I become a coach?",
        "faq_2_1":"Because many women around the world could use your know-how. We give you the opportunity to help other women,while you can be at home, work, or any other place – and you decide how many coaching projects you can take on in your time available.",
        "faq_3":"How do I create a user account?",
        "faq_3_1":"Both coaches and help-seekers can create a user account by clicking on the 'Register' button at the top of the page.",
        "faq_4":"What is the motivation behind the from woman to woman platform?",
        "faq_4_1":"It is simple: Many women face hurdles that stop them from receiving know-how. They might for example lack the money to enroll in a school, be unable to make a trip to a teaching institution (cost of trip, unsafe public transport, unreliable schedule, etc.), have family commitments that stop them from regularly attending classes, be in a place where there simply are no suitable coaching programs or face societal hurdles that limit their capabilities.",
        "faq_4_2":"Luckily, the internet has created new possibilities in knowledge transfer – which we use by matching help-seekers with knowledgeable coaches through our platform. This borderless exchange of know-how and expertise breaks down geographical, cultural or financial barriers. The knowledge that is transferred to help-seekers helps them to shape successful and sustainable personal or professional projects, community development programs and much more. Coaches in return use their knowledge to improve the circumstances of other women.",
        "faq_4_3":"Through the knowledge transfer that we facilitate, we want to contribute to improving the circumstances of women around the globe and to the UN Sustainability Goals. Regularly check our testimonials / success stories to see how exactly we make a difference.",
        "faq_5":"Do the coaching sessions take place on this platform?",
        "faq_5_1":"No. Here, only help-seekers and coaches are connected. Once matched, the help-seeker and the coach will use our chat function to agree how many coaching sessions will be held and which medium (for example Skype, WhatsApp, Zoom, etc.) is most suitable for both parties.",
        "faq_6":"Will a coach tell me how to do my project / which decision to take?",
        "faq_6_1":"Not really! Coaches on our platform commit to act in line with our credo of coaching:",
        "faq_6_2":"Coaching is a shared learning process. By sharing her experience, know-how and an unbiased outside perspective, a coach supports and empowers the help-seeker in understanding and defining a certain issue and creating her own solutions.",
        "faq_6_3":"In this relationship, the help-seeker is always the one who decides, acts and leads; she is responsible for the outcome of her project.",
        "faq_6_4":"A coach never pushes a help-seeker towards a specific decision or action or decides for her. Rather, she listens carefully, has a positive attitude, asks questions, shows a wider context, relates her own experience and challenges the help-seeker to think deeper/outside of the box.",
        "faq_6_5":"If you think that your coach does not act according to this credo, please do not hesitate to contact us immediately by using the contact form.",
        "faq_7":"Is there any cost involved?",
        "faq_7_1":"No, our platform is – and will always remain – free to use for help-seekers and coaches. If you want to support our work by contributing to the costs of running our platform, you can do so by donating. This is, however, fully up to you and will not change the functionalities you have on our platform.",
        "faq_8":"Can I use the platform to raise funds or create publicity for my project?",
        "faq_8_1":"No, this is a violation of our terms of service. Our platform is designed for personal coaching. No funds are exchanged, raised, solicited, or demanded in any way.",
        "faq_9":"In which areas can I get coaching through your platform?",
        "faq_9_1":"Through our platform, women receive free coaching for their personal or professional projects. Our Community Rules define that such projects cannot be about, contain or refer to:",
        "faq_9_2":"Sexually explicit activities, prostitution, pornography and similar",
        "faq_9_3":"Violence and destructive behavior against humans, animals or nature",
        "faq_9_4":"Warlike purposes, organized crime, terrorism and similar",
        "faq_9_5":"Weapons, ammunition and similar items including accessories",
        "faq_9_6":"Raising funds, financial schemes, betting, lottery, gambling and similar",
        "faq_9_7":"Controlled substances, drugs and similar",
        "faq_9_8":"Human or animal remains",
        "faq_9_9":"Any and all illegal activities in the country of the help-seeker and/or coach and/or the domicile of from woman to woman;",
        "faq_9_10":"Other immoral or unethical activities as deemed by the from woman to womanteam, including sectarian initiatives in conflict with Human Rights and/or from woman to woman values.",
        "faq_9_11":"This list is not exhaustive. If you think that a user (coach or help-seeker) or a project violates our Community Rules, please do not hesitate to contact us immediately by using the contact form.",
        "faq_9_12":"Please also refer to our detailed Terms & Conditions for more information about the use of our platform.",
        "faq_10":"Can others see my coaching requests? How is my privacy protected?",
        "faq_10_1":"Yes, others can see your coaching requests. Only in this way, coaches can see if they have the expertise you are looking for. We encourage you to make detailed help requests, but not to include any personal data. For example:",
        "faq_10_2":"I need guidance on how to choose my career path.",
        "faq_10_3":"I need coaching in creating a budget for a non-profit organization.",
        "faq_10_4":"I want to improve my presentation skills.",
        "faq_10_5":"The details of your coaching need are only discussed with your coach after you have successfully connected through our platform.",
        "faq_10_6":"As from woman to woman, we do not – and never will – transfer or sell any user-created data to third parties.",
        "faq_11":"Who are the coaches? How do I know a coach is really an expert in her field?",
        "faq_11_1":"All women have expert know-how in particular fields, so anyone can be a coach on our platform.",
        "faq_11_2":"You can check the profile page of a coach to see more details about her expertise and knowhow. And, through your coaching sessions you will quickly see how competent your coach is(and you can ask her directly if you need further clarification about her expertise)! You can also see how other help-seekers have rated a particular coach through their star rating.",
        "faq_12":"Can I hold a coach responsible for bad advice given?",
        "faq_12_1":"According to our credo of coaching:",
        "faq_12_2":"A coach never pushes a help-seeker towards a specific decision or action or decides for her –the help-seeker is always responsible for the outcome of her project.",
        "faq_12_3":"Only you as a help-seeker know your exact circumstances and the context of your personal or professional project. That is why every decision is taken and owned by you, not your coach.",
        "faq_13":"My company encourages female staff to volunteer. How can we make from woman to woman part of our gender and sustainability strategy?",
        "faq_13_1":"Are you a company that wishes to invest in empowering women?",
        "faq_13_2":"from woman to woman is the ideal place for you. Your female staff can register here as coaches for women in need. We can compile all the coaching hours of your female staff and write up verified and customizable impact reports and testimonials that can be used in sustainability / corporate responsability reports.",
        "faq_13_2":"Contact us by using the contact form, and our Sustainability Manager will connect with you to assess your exact needs and offer you a customized solution.",
        "contact_us":"Contact Us:",
        "contact_1":"Get in touch with us",
        "contact_2":"Leave your message and will get back to you shortly.",
        "contact_3":"Name",
        "contact_4":"E-mail",
        "contact_5":"Subject",
        "contact_6":"Message",
        "contact_7":"Submit",
        "footer_1":"from woman to woman is a Swiss not-for profit organization. We aim to promote the exchange of know-how and expertise among women. We do this by providing an online platform that can be freely accessed across the globe. Here, women are coached by other women, and this borderless exchange promotes successful and sustainable personal and professional ventures, community development programs, and much more.",
        "social_media":"SOCIAL MEDIA",
        "footer_2":"Let's spread the word on how collaborative and compassionate sharing of know-how and expertise is making a real difference for women across the globe. Follow us on our social media channels and share your experience with us!",
        "copyright":"© Copyright 2020",
        "verify_msg":"Your email is verified. Now you can login and start using our platform.",
        "congrats":"Congratulations!",
        "register":"Register",
        "first_name":"First name",
        "last_name":"Last name",
        "pass":"Password",
        "conf_pass":"Confirm Password",
        "country":"Country",
        "choose_country":"Choose country",
        "languages":"Languages",
        "agree_text":"I agree to the from woman to woman",
        "community_rules":"community rules",
        "and":"and",
        "terms_cond":"terms & conditions.",
        "our_community_rules":"Our community rules",
        "terms_conditions":"Terms & conditions",
        "login":"Login",
        "login_heading":"Sign into your account",
        "email_address":"E-mail address",
        "frgt_pass":"Forgot password?",
        "not_member":"Not a member? Create new account.",
        "logout":"Logout",

        "usermenu_1":"View my profile",
        "usermenu_2":"Edit my profile",
        "usermenu_3":"Change my password",
        "usermenu_4":"My projects",
        "usermenu_5":"Send donation",
        "usermenu_6":"Projects looking for a coach",
        "view_profile_heading":"View my profile:",
        "view_profile_1":"Feedback",
        "view_profile_2":"review(s)",
        "view_profile_3":"Languages spoken",
        "view_profile_4":"Profile details",
        "view_profile_5":"Feedback & Ratings",
        "view_profile_6":"Areas of expertise:",
        "view_profile_7":"I have experience in these industries:",
        "view_profile_8":"Total years of experience:",
        "view_profile_9":"A brief description about me:",
        "view_profile_10":"Year(s)",
        "view_profile_11":"coaching hours spent",
        "view_profile_12":"No feedback and ratings",
        "coach":"Coach",
        "edit_profile":"Edit profile",
        "chg_pwd":"Change password",
        "forgot_pass":"Forgot Password",
        "verify_email":"Verify your e-mail",
        "edit_profile1":"Select your profile picture",
        "edit_profile2":"Choose file",
        "edit_profile3":"Am I a coach?",
        "edit_profile4":"Industry",
        "edit_profile5":"A brief description about me",
        "edit_profile6":"Tell others something about you! If you are a coach, please describe your expertise/ work experience/professional qualification.",
        "edit_profile7":"You can choose picture in jpeg, jpg & png.",
        "chg_pwd1":"Old password",
        "chg_pwd2":"New password",
        "chg_pwd3":"Confirm Password",
        "donation1":"Amount",
        "my_projects_heading1":"My",
        "my_projects_heading2":"projects",
        "my_projects_1":"Finding a coach",
        "my_projects_2":"Coaching others",
        "my_projects_3":"Add new",
        "my_projects_4":"Search",
        "my_projects_5":"All expertise",
        "my_projects_6":"Total records:",
        "my_projects_7":"Previous",
        "my_projects_8":"Next",
        "my_projects_9":"Project title",
        "my_projects_10":"I need coaching in",
        "my_projects_11":"Choose type of project",
        "my_projects_12":"Choose expertise",
        "my_projects_13":"Choose type",
        "my_projects_14":"I can be coached in these languages",
        "my_projects_15":"Coaching sessions (informative indication for your coach)",
        "my_projects_16":"A typical coaching session lasts for about 45-60 minutes.",
        "my_projects_17":"Please describe your project for your coach.",
        "my_projects_18":"What do you want to achieve? What skills do you want to expand? etc.",
        "my_projects_19":"Add",
        "my_projects_20":"Title",
        "my_projects_21":"Area of expertise",
        "my_projects_22":"No.",
        "my_projects_23":"Title",
        "my_projects_24":"Sessions",
        "my_projects_25":"Status",
        "my_projects_26":"Action",
        "my_projects_27":"Add project",
        "my_projects_28":"Edit project",
        "my_projects_29":"Delete project",
        "my_projects_30":"Are you sure?",
        "my_projects_31":"Do you want to delete this project?",
        "my_projects_32":"Cancel",
        "my_projects_33":"Do you want to close this project?",
        "my_projects_34":"Yes",
        "my_projects_35":"No",
        "my_projects_36":"Close project",
        "my_projects_37":"Leave a public feedback for your coach",
        "my_projects_38":"Tell us how it was to work with your coach",
        "my_projects_39":"Total coaching time (hours)",
        "my_projects_40":"For example, 8 coaching sessions of 45 minutes each = 6 hours total coaching time",
        "my_projects_41":"Messages",
        "my_projects_42":"Type your message...",
        "my_projects_43":"Send",
        "my_projects_44":"Choose language",
        "my_projects_45":"List of coaches",
        "my_projects_46":"Request sent",
        "my_projects_47":"Are you sure you want to send the request ?",
        "my_projects_48":"Are you sure you want to cancel the request ?",
        "my_projects_49":"Accepted & Pending",
        "my_projects_50":"All projects",
        "my_projects_51":"Rejected",
        "my_projects_52":"Closed",
        "my_projects_53":"Accept/Reject request",
        "my_projects_54":"Choose status",
        "my_projects_55":"Projects looking",
        "my_projects_56":"for a coach",
        "my_projects_57":"Type of project",
        "my_projects_58":"Project details",
        "my_projects_59":"Send offer",
        "my_projects_60":"Do you want to propose your help as a coach for this project?",
        "my_projects_61":"All languages",
        "my_projects_62":"Help-Seeker",
        "my_projects_63":"View profile",
        "my_projects_64":"End collaboration",
        "my_projects_65":"Cancel offer",
        "my_projects_66":"Edit",
        "my_projects_67":"remove",
        "my_projects_68":"View offer",
        "my_projects_69":"No projects found",
        "my_projects_70":"Coach details",
        "my_projects_71":"Accept/Reject request",
        "my_projects_72":"Do you want to cancel the offer of this project?",
        "my_projects_73":"Are you sure you want to do this?",
        "my_projects_74":"If you want to finish the project instead (and create a review for a finished project), please talk to your help-seeker, who can initiate the closing down of a successfully finished project",
        "my_projects_75":"You can accept or reject the offer",
        "my_projects_76":"Industries list",
        "my_projects_77":"hours spent",
        "my_projects_78":"Accept/Reject",
        "update":"Update",
        "by":"By",
        "date":"Date",
        "close":"Close",
        "description":"Description",
        "accept":"Accept",
        "reject":"Reject",
        "already_act":"Already have an account? Login now.",
        "languages":"Languages",
        "reg_email_content_1":"Welcome to from woman to woman community.",
        "reg_email_content_2":"Dear",
        "reg_email_content_3":"Thank you for  registering on the from woman to woman platform.",
        "reg_email_content_4":"You can click the button below to log into the website and start using it:",
        "reg_email_content_5":"Click here to Login",
        "reg_email_content_6":"If you need anything or have questions,please do not hesitate to get in touch with us by using the contact form on our main page.",
        "reg_email_content_7":"To many enriching moments!",
        "reg_email_content_8":"The from woman to woman team",
        "forgot_pwd_content_1":"Reset your account Password",
        "forgot_pwd_content_2":"Hi",
        "forgot_pwd_content_3":"Someone requested that the password for your registered account be reset.",
        "forgot_pwd_content_4":"Reset my password.",
        "forgot_pwd_content_5":"This link is valid for 24 hours.",
        "forgot_pwd_content_6":"If you did not request this,you can ignore this email or let us know.",
        "forgot_pwd_content_7":"Your password would not change until you create a new password.",
        "sendcancelreq_email_content_1":"Request for new project.",
        "sendcancelreq_email_content_2":"A new request has been requested by",
        "sendcancelreq_email_content_3":"Help-Seeker details:",
        "sendcancelreq_email_content_4":"Project details:",
        "sendcancelreq_email_content_5":"A request sent by john has been cancelled.",
        "sendcancelreq_email_content_6":"Regards",
        "sendcancelreq_email_content_7":"Request cancelled.",
        "reqexpired_email_content_1":"A request sent by you for john has been expired because the time limit of 3 days is over.",
        "reqexpired_email_content_2":"Please send a request to new coach.",
        "reqexpired_email_content_3":"Regards!",
        "reqexpired_email_content_4":"A request sent by john has been expired because the time limit of 3 days is over.",
        "reqexpired_email_content_5":"Request expired.",
        "error_msg_1":"You are registered successfully.",
        "error_msg_2":"Some problem occured.",
        "error_msg_3":"This email id is already registered.",
        "error_msg_4":"Please enter your first name.",
        "error_msg_5":"Please enter your last name.",
        "error_msg_6":"Please enter your e-mail.",
        "error_msg_7":"Please enter your password.",
        "error_msg_8":"Please confirm your password.",
        "error_msg_9":"Password and confirm password not matched.",
        "error_msg_10":"Please enter your country.",
        "error_msg_11":"Please choose languages.",
        "error_msg_12":"Please check the above checkbox.",
        "error_msg_13":"Invalid user ID or password.",
        "error_msg_14":"Some problem occured. Please try again!",
        "error_msg_15":"We have sent a link to your email for changing password.",
        "error_msg_16":"This email id is not registered.",
        "error_msg_17":"Please enter your new password.",
        "error_msg_18":"Please confirm your password.",
        "error_msg_19":"Password and confirm password not matched.",
        "error_msg_20":"Password updated successfully.",
        "error_msg_21":"Invalid token.",
        "error_msg_22":"This token has been expired.",
        "error_msg_23":"Please enter your old password.",
        "error_msg_24":"Password changed successfully.",
        "error_msg_25":"Old password is not correct.",
        "error_msg_26":"Please enter your name",
        "error_msg_27":"Please enter your e-mail",
        "error_msg_28":"Please enter your country",
        "error_msg_29":"Please choose languages",
        "error_msg_30":"Please choose areas of expertise",
        "error_msg_31":"Please choose industry / work in which you have experience",
        "error_msg_32":"Please enter your total yrs. of experience",
        "error_msg_33":"Please enter your Bio",
        "error_msg_34":"Profile updated successfully",
        "error_msg_35":"Thanks for your donation.",
        "error_msg_36":"Please enter the title of project",
        "error_msg_37":"Please choose expertise",
        "error_msg_38":"Please choose type of project",
        "error_msg_39":"Please choose languages",
        "error_msg_40":"Please enter the description of project",
        "error_msg_41":"Project added successfully",
        "error_msg_42":"Project deleted successfully.",
        "error_msg_43":"Project updated successfully",
        "error_msg_44":"You have already sent a request to a coach. Please wait for her response or cancel the last request.",
        "error_msg_45":"Message sent successfully.",
        "error_msg_46":"Request sent successfully.",
        "error_msg_47":"Request cancelled successfully.",
        "error_msg_48":"Please give star rating.",
        "error_msg_49":"Please give your review.",
        "error_msg_50":"Please enter hours spent by coach.",
        "error_msg_51":"Thank you for your feedback.",
        "error_msg_52":"Status changed successfully",
        "error_msg_53":"Are you sure you want to send the request ?",
        "error_msg_54":"Are you sure you want to cancel the request ?",
        "error_msg_55":"You have already sent a request to a coach. If your coach has not replied within three days, the request expires and you can send a request to a different coach.",
        "error_msg_56":"A coach already sent you a help-offer for this project.",
        "error_msg_57":"Are you sure you want to accept this offer?",
        "error_msg_58":"Are you sure you want to reject this offer?",
        "error_msg_59":"Offer accepted",
        "error_msg_60":"Offer rejected",
        "error_msg_61":"Now you can communicate with your coach by sending messages.",
        "error_msg_62":"Now you can look through the list of coaches and send out a coaching request to another coach.",
        "error_msg_63":"Your password must be at least 8 characters long, must contain at least 1 capital letter, 1 number and 1 symbol (/[!@#$%^&*()_+\-=\[\]{};:\\|,.<>\/?]/) and cannot have two consecutive characters.",
        "error_msg_64":"Please choose your subject",
        "error_msg_65":"Please type your message",
        "error_msg_66":"Please choose picture in jpeg, jpg & png format.",
        "error_msg_67":"Please choose status.",
        "error_msg_68":"Invalid captcha. Please try again",
        "thanks":"Thank you!",
        "community":"Our community",
        "rules":"Rules",
        "pass_note":"Your password must be at least 8 characters long, must contain at least 1 capital letter, 1 number and 1 symbol (for example Abcd123@ or 1234Abc$) and cannot have two consecutive characters.",
        "created_on":"Project created on",
        "guidelines_1":"Effective August, 2020",
        "guidelines_2":"Excluded projects, prohibition of fundraising & compensation",
        "guidelines_3":"The from woman to woman platform is a website where women receive free coaching for their personal or professional projects. Such projects cannot be about, contain or refer to:",
        "guidelines_4":"Sexually explicit activities, prostitution, pornography and similar",
        "guidelines_5":"Violence and destructive behavior against humans, animals or nature",
        "guidelines_6":"Warlike purposes, organized crime, terrorism and similar",
        "guidelines_7":"Weapons, ammunition and similar items including accessories",
        "guidelines_8":"Raising funds, financial schemes, betting, lottery, gambling and similar",
        "guidelines_9":"Controlled substances, drugs and similar",
        "guidelines_10":"Human or animal remains",
        "guidelines_11":"Any and all illegal activities in the country of the help-seeker and/or coach and/or the domicile of W2W",
        "guidelines_12":"Other immoral or unethical activities as deemed by the W2W team, including sectarian initiatives in conflict with Human Rights and/or W2W values.",
        "guidelines_13":"This list is not exhaustive. If you think that a user (coach or help-seeker) or a project violates our Community Rules, please do not hesitate to contact us immediately through the contact form on our homepage.",
        "guidelines_14":"Moreover, our platform is not a fundraising platform. No money is exchanged and users are not allowed to ask for money, other contributions of any kind, publicity or for that matter any kind of compensation, reward or benefit for themselves or their projects. Please inform us immediately if another user violates this.",
        "guidelines_15":"In case of violations, W2W will – depending on the assessed degree of violation – issue warnings against users or delete and block users and / or projects indefinitely.",
        "guidelines_16":"The definition of coaching & responsibilities of coach and help-seeker",
        "guidelines_17":"Is coaching not just receiving advice by somebody else? Not really! Coaches on our platform commit to act in line with our credo of coaching",
        "guidelines_18":"Coaching is a shared learning process. By sharing her experience, know-how and an unbiased outside perspective, a coach supports and empowers the help-seeker in understanding and defining a certain issue and creating her own solutions. In this relationship, the help-seeker is always the one who decides, acts and leads; she is responsible for the outcome of her project. A coach never pushes a help-seeker towards a specific decision or action or decides for her. Rather, she listens carefully, has a positive attitude, asks questions, shows a wider context, relates her own experience and challenges the help-seeker to think deeper/outside of the box",
        "guidelines_19":"If you think that your coach does not act according to this credo, please do not hesitate to contact us immediately through the contact form on our homepage",
        "guidelines_20":"The two user roles",
        "guidelines_21":"On our platform, we have two roles",
        "guidelines_22":"Every woman or group (cooperative, association, etc.) who is looking for help regarding a private or professional project is a help-seeker. Examples of such projects could be",
        "guidelines_23":"How to create a financial budget for my household/family",
        "guidelines_24":"How to best prepare for my future studies as a registered nurse",
        "guidelines_25":"Writing a business plan for our community development program",
        "guidelines_26":"Help-seekers create their project on our platform, describing what they want to achieve and in which area they need help. They can then browse through a list of available coaches and ask them for help",
        "guidelines_27":"Coaches are the main resource of this platform. They are all volunteers and share their know-how and expertise with help-seekers for free. Like help-seekers, our coaches come from a wide variety of sectors/industries and countries. Coaches are not hired or paid by our organization, they are simply users of this platform. They act according to our credo of coaching (see item 2)",
        "guidelines_28":"Promise of a free use of the website & data protection",
        "guidelines_29":"The from woman to woman team promises that you will always be able to use our website and its resources and functionalities for free",
        "guidelines_30":"We furthermore guarantee that we do not transfer or sell any data to third parties. Our terms and conditions explain the protection of your data and your rights in detail",
        "guidelines_31":"How from woman to woman is financed",
        "guidelines_32":"All of our costs are covered by people who believe in our mission and donate to our cause. If you also wish to donate, you can do so online in the member area – this will not affect your use of the website and the functionalities available to you. We sincerely appreciate every contribution. Every penny helps, thank you very much",
        "terms":"Terms",
        "conditions":"conditions",
        "service_1":"Effective August, 2020",
        "service_2":"These Terms and Conditions (The “Terms”) govern the use of the website and services (the “Services”) of from woman to woman ('W2W'), a non-profit association registered in Switzerland, in addition to any supplemental terms applicable to any particular features, content and functionality of the Services, which such supplemental terms are incorporated by reference into the Terms of Use. By using the Services, Users agree to be bound by these Terms and Conditions, our privacy policy, all applicable laws and all conditions or policies referenced here (collectively, the “Terms”). from woman to woman may amend the Terms at any time by posting a revised version of the Terms on our Services. Access to or continued use of the Services after the effective date of the revised Terms constitutes your acceptance of the revised Terms",
        "service_3":"Definitions",
        "service_4":"In these Terms, we refer to help-seekers as “Project Owners” and to their initiatives as “Projects.” We refer to those contributing technical expertise/experiences, knowhow and similar as “Coaches” and to the technical expertise/experiences, knowhow and similar they contribute as “Contributions”. Project Owners, Coaches and other visitors to the Services are referred to collectively as “Users.”",
        "service_5":"from woman to woman is a Venue",
        "service_6":"from woman to woman is an online venue for women and organizations seeking to share expertise/experiences, knowhow and similar to develop and implement initiatives/projects, helping each other to achieve their full potential through coaching. from woman to woman makes no representations about the quality, safety, morality or legality of any Project or Contribution or the truth or accuracy of any and all User Content (as defined below) posted on the Services. from woman to woman does not represent that Projects will deliver outcomes as described in the Description of the Project. Users use the Services at their own risk. Project Owners are always responsible for the outcome of their own projects. The Services do not include the offer or sale of securities or guarantees",
        "service_7":"Eligibility to Use the Services",
        "service_8":"Users under 18 years of age are not eligible to use the Services without consent. Users between the ages of 14 and 17 can use the Services with the consent and supervision of a parent or legal guardian who is at least 18 years old, provided such parent or legal guardian also agrees to be bound by the Terms and agrees to be responsible for such use of the Services. Users suspended from using the Services are not eligible to use the Services. from woman to woman reserves the right to refuse use of the Services to anyone and to reject, cancel, interrupt, remove or suspend any Project, Contribution, or the Services at any time for any reason without liability",
        "service_9":"Prohibited Campaigns",
        "service_10":"from woman to woman is not a fundraising platform: Project Owners or Coaches are not permitted to ask for money or other financial means or resources, either directly or indirectly. Moreover, Project Owners are not permitted to create a Project asking for help in the form of technical expertise, knowhow (through coaching) or similar for",
        "service_11":"If the Project is claiming to do the impossible or is just plain phony, do not post it. And if as a Coach, you find a project difficult to support, do not offer coaching for it. Users must comply with all applicable laws and regulations in connection with their Projects, including offering technical help. Project Owners shall not make any false or misleading statements in connection with their Projects",
        "service_12":"Project Owners must not: (1) include threatening, abusive, harassing, defamatory, libelous, or profane content in any Project, or content that is invasive of another’s privacy; (2) impersonate any person or entity, including any employee or representative of from woman to woman, or (3) infringe any patent, trademark, trade secret, copyright, right of publicity, or other right of any other person or entity, or violate any law or contract",
        "service_13":"Rewards Are Prohibited",
        "service_14":"Users are not permitted to offer, ask for or accept rewards in any form. Any and all coaching sessions, sharing of experience/knowhow, expertise and similar imparted between Coach and Project Owner and agreed through the from woman to woman platform shall be offered, imparted and received entirely free of cost and without any form of compensation",
        "service_15":"Community Guidelines",
        "service_16":"from woman to woman is not a place for hatred, abuse, discrimination, disrespect, profanity, meanness, harassment, or spam. Do not",
        "service_17":"use the Services to promote violence, degradation, subjugation, discrimination or hatred against individuals or groups based on race, ethnic origin, religion, disability, gender, age, veteran status, sexual orientation, or gender identity;",
        "service_18":"post images, videos or comments/statements that are sexually explicit or post links to sites that contain sexually explicit material or show people or animals being hurt or degraded;",
        "service_19":"spam any part of the from woman to woman platform with unsolicited or unauthorized offers of goods and services, advertising, promotional material, junk mail, spam or chain letters, or inappropriate messages;",
        "service_20":"engage in any activity that interferes with or disrupts the proper working of the Services or any activities conducted on the Services; or",
        "service_21":"bypass any measures from woman to woman may use to prevent or restrict access to the Service;",
        "service_22":"run any form of auto-responder or “spam” on the Service;",
        "service_23":"use manual or automated software, devices, or other processes to “crawl” or “spider” any page of the Site; or",
        "service_24":"take any action that imposes, in from woman to woman’s sole discretion, an unreasonable load on from woman to woman’s infrastructure;",
        "service_25":"represent products or initiatives created by others as your own creation, or act as a reseller of others’ products or initiatives;",
        "service_25_1":"",
        "service_25_2":"",
        "service_33_1":"",
        "service_26":"from woman to woman reserves the right to remove Projects and terminate User Accounts for such activities at any time without liability or explanation",
        "service_27":"Prohibited Visual Elements in Projects Images",
        "service_28":"Projects Owners are not permitted to do any of the following with User Profile and Project images",
        "service_29":"incorporate from woman to woman colors and branding elements, including but not limited to logos, colored frames, borders or badges",
        "service_30":"include any visual element that could be interpreted to have been produced by from woman to woman, including but not limited to banners, progress bars or funding stats",
        "service_31":"include any visual element that communicates endorsement by from woman to woman",
        "service_32":"include any visual element that violates our guidelines in sections 'Prohibited Campaigns' and 'Community Guidelines'",
        "service_33":"Coaching Code of Conduct",
        "service_34":"from woman to woman is not responsible for any and all advise given, or knowledge, knowhow, experiences and similar shared or omitted between Coaches and Project Owners. Project Owners are always fully responsible for the outcome of their own Projects. Because we want the Coaching of Users to be successful and have a positive impact on Projects, we have established a Coaching Code of Conduct which has to be read and agreed upon by all our coaches. It is binding and in case of violation, a coach can get suspended from our Platform",
        "service_35":"Coaching is a shared learning process. By sharing her experience, know-how and an unbiased outside perspective, a coach supports and empowers the help-seeker in understanding and defining a certain issue and creating her own solutions. In this relationship, the help-seeker is always the one who decides, acts and leads; she is responsible for the outcome of her project.  A coach never pushes a help-seeker towards a specific decision or action or decides for her. Rather, she listens carefully, has a positive attitude, asks questions, shows a wider context, relates her own experience and challenges the help-seeker to think deeper/outside of the box",
        "service_36":"If you become aware of a Coach violating this Code of Conduct, immediately contact us through the contact form on our website",
        "service_37":"Verified Coach",
        "service_38":"For a small fee (paid by the Coach herself), from woman to woman verifies the identity of the Coach as registered on our platform and interviews her about her know-how and expertise. Upon successful review, from woman to woman awards the 'Verified Coach' label which is visible on the respective User's profile picture and page. 'Verified Coach' is a label that builds trust for all: Project Owners are assured that their Coach is who she claims to be. Coaches, on the other hand, know that their profile is safe and nobody can impersonate them on the platform",
        "service_39":"Awarding this label or performing any steps in assessing its merit does not imply that from woman to woman is in any way liable for actions performed or omitted by a 'Verified Coach' or that from woman to woman vouches for the identity of the Coach as portrayed on our platform. The label does not evaluate particular knowhow or advice given or shared. As stated in these Terms, a Project Owner is always responsible for her own project and its outcome",
        "service_40":"Disputes between Project Owners and Coaches",
        "service_41":"from woman to woman is under no obligation to become involved in disputes between Project Owners and Coaches, or Users and any third party. In addition, from woman to woman is under no obligation to become involved in disputes regarding the determination of the rightful Project Owner, and will not be obligated to make any changes to Project Owner accounts or transfer of ownership. In the event of any dispute and at the sole request of both Parties, the Project Owner and the Coach, we may provide contact information to both parties so that the two partiesmay resolve their dispute",
        "service_42":"User Accounts",
        "service_43":"All information submitted in connection with a User account on the Services (each, a “User Account”) must be accurate and truthful. Users agree to notify from woman to woman immediately if their User Account has been used without authorization or there has been any other breach of security of the User Account. Each User also agrees to provide additional information from woman to woman may reasonably request and to answer truthfully and completely any questions from woman to woman might ask you in order to verify such User’s identity",
        "service_44":"License to Users and Use of from woman to woman Trademarks",
        "service_45":"from woman to woman grants each User a limited, non-exclusive, non-transferable, revocable license to use the Services subject to such User’s eligibility and continued compliance with these Terms",
        "service_46":"With the exception of User Content (defined in the User Content paragraph), all content made available through the from woman to woman platform, such as, but not limited to, text, graphics, logos, button icons, images, audio clips, and software, is the property of from woman to woman or other Project Owners and is protected by Swiss and international intellectual property laws",
        "service_47":"The trademark from woman to woman® is a registered trademark owned by from woman to woman. Users are strictly prohibited from registering domain names, social media accounts, user names, handles, trademarks, copyrights, apps, trade names, or corporate names that incorporate the from woman to woman trademark or are confusingly similar to from woman to woman’s trademarks",
        "service_48":"Service Fees",
        "service_49":"Setting up an account on the Services is free and the platform and its functionalities / use fully accessible to all women. from woman to woman reserves the right to change its fee structure in the future and abandon, change or introduce new or existing fees. Basic memberships will, however, always remain free and accessible to all women",
        "service_50":"from woman to woman may offer premium features to Users or organizations for additional fees, in which case separate agreements on fulfilment shall be entered between the parties concerned",
        "service_51":"Changes to Service Fees are effective after we post notice of the changes on the Services",
        "service_52":"User Content",
        "service_53":"While using the Services, Users may post photos, videos, text, graphics, logos, artwork and other audio or visual materials (collectively, “User Content”). As between Users and from woman to woman, Users continue to hold all ownership interest in their User Content. Each User represents and warrants that its User Content and our use of such User Content will not infringe any third party’s intellectual property rights, proprietary rights, privacy rights, confidentiality, rights of publicity or otherwise violate these Terms or applicable law",
        "service_54":"Promotions",
        "service_55":"Project Owners may not offer any contest, competition, giveaway, sweepstakes or similar activity (each, a “Promotion”) on the Services",
        "service_56":"Third-Party Websites, Advertisers or Services",
        "service_57":"The Services may contain links to third-party websites, advertisers, or services that are not owned or controlled by from woman to woman. Access to or use of third-party sites or services are at each User’s own risk. Except as otherwise expressly provided herein, from woman to woman is not responsible for any loss or damage of any sort relating to User dealings with these third-party sites or services. We encourage Users to be aware of when they leave the Services, and to read the terms and privacy policy of any third-party website or service that they visit",
        "service_58":"Payment Services",
        "service_59":"While the basic use of our platform is and will always be free to all Users, fees may apply for premium features. To settle such fees, Users who accept and use PayPal in connection with a premium service offered by from woman to woman acknowledge and agree to comply with PayPal’s Crowdfunding and Acceptable Use Policies. Credit card payment processing services on from woman to woman are provided by Stripe, Inc. (“Stripe”) and are subject to the Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the “Stripe Services Agreement”). By continuing to operate User on from woman to woman, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition to from woman to woman enabling credit card payment processing services through Stripe, you agree to provide from woman to woman accurate and complete information about you and your organization, and you authorize from woman to woman to share any such information with Stripe, as well as transaction information related to your use of the payment processing services provided by Stripe. In all cases, standard credit card or other third-party processing fees apply in addition to any Service fees. We are not responsible for the performance of any third-party credit card processing or third-party payment services",
        "service_60":"Disputes regarding paid Services",
        "service_61":"Disputes between Users and from woman to woman in connection with a paid Service / premium service shall be settled between the two parties in an amicable manner, by arbitration or ultimately by a court at the jurisdiction of from woman to woman",
        "service_62":"Indemnity",
        "service_63":"Each User agrees to defend, indemnify and hold harmless from woman to woman, its subsidiaries and affiliated companies, and their officers, directors, Board members, employees, contractors and agents from and against any and all claims, causes of action, damages, obligations, losses, liabilities, costs or debt, and expenses (including attorneys’ fees and costs) and all amounts paid in settlement arising from or relating to use of the Services, breach of these Terms or violation of any laws. from woman to woman may assume the exclusive defense and control of any matter for which Users have agreed to indemnify from woman to woman and each User agrees to assist and cooperate with from woman to woman in the defense or settlement of any such matters",
        "service_64":"No Warranty",
        "service_65":"from woman to woman has no fiduciary duty to any User. The services are provided on an “as is” and “as available” basis without any warranties, expressed or implied, including, without limitation, implied warranties of merchantability or fitness for a particular purpose, noninfringement or course of performance. Use of the Services is at User’s own risk",
        "service_66":"Limitation of Liability",
        "service_67":"from woman to woman (including its affiliates, directors, board members, officers, employees, agents, subcontractors or licensors) is not liable for any indirect, punitive, incidental, special, consequential or exemplary damages or, in any event, for damages exceeding the lesser of one hundred swiss francs (CHF 100.00) or the fees paid to from woman to woman for the transaction from which the cause of action arose. This limitation of liability section applies whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if from woman to woman has been advised of the possibility of such damage and to the fullest extent permitted by law in the applicable jurisdiction",
        "service_68":"from woman to woman is not responsible nor liable for the completeness or accuracy of User content (such as, but not limited to, project descriptions, identity of Users, desired project outcomes, project background, etc.). from woman to woman is under no obligation to verify User information provided, but may do so",
        "service_69":"For jurisdictions that do not allow from woman to woman to limit its liability: Notwithstanding any provision of the Terms, for Users in a jurisdiction that has provisions specific to waiver or liability that conflict with the foregoing, then from woman to woman’s liability is limited to the smallest extent possible by law. Specifically, in those jurisdictions not allowed, from woman to woman does not disclaim liability for: (a) death or personal injury caused by its negligence or that of any of its officers, board members, employees or agents; or (b) fraudulent misrepresentation or intentional misconduct; or (c) any liability which it is not lawful to exclude either now or in the future",
        "service_70":"Assignment",
        "service_71":"The Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by any User, but may be assigned by from woman to woman without restriction or consent",
        "service_72":"Termination; Survival",
        "service_73":"Termination may result in the forfeiture and destruction of all information associated with any User Account. Users may terminate their User Account by following the instructions on the Services, but from woman to woman may retain the User Account information after termination in accordance with regulatory, accounting, and legal compliance procedures. All provisions of the Terms that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability",
        "service_74":"Governing Law",
        "service_75":"Users agree that: (a) the Services shall be deemed solely based in Switzerland (the principal place of business and headquarters of from woman to woman); and (b) the Services shall be deemed a passive one that does not give rise to personal jurisdiction over from woman to woman, either specific or general, in jurisdictions other than Switzerland. This Agreement shall be governed by the laws of Switzerland, without respect to its conflict of laws principles",
        "service_76":"Arbitration",
        "service_77":"from woman to woman and User agree that any and all disputes or claims shall be resolved exclusively through final and binding arbitration, rather than in court, except that a User at its election may assert claims in small claims court, if the User’s claims otherwise qualify for adjudication in that court. from woman to woman and User manifest their assent to arbitrate by providing and using the Services. Arbitrable claims are those that from woman to woman asserts against User, and that User asserts against from woman to woman, any related or affiliated entity, and the officers, directors, board members, agents or employees of any of them. To the maximum extent permitted by law, the Parties agree to arbitrate claims on an individual basis only, and they waive any right to bring, participate in, or recover under, a class, collective, consolidated or representative action. The arbitrator shall apply the Terms and the same substantive law to the dispute as a court would, and the same law of remedies. To begin an arbitration proceeding against from woman to woman or a related party, a User must send a letter requesting arbitration and describing the claim to from woman to woman’s registered headquarters",
        "service_78":"Disclaimers",
        "service_79":"Project Owners and other Users should be aware that many countries impose charitable fund solicitation laws to guide direct or indirect fund solicitation activities in those countries. While provisions vary country to country, these country laws typically include registration and reporting requirements. from woman to woman merely provides a technology platform to allow Project Owners to connect with Coaches. We are not a fundraising platform and Users shall not ask for direct or indirect financial means or other resources, either from other Users or from woman to woman. Users who access or use the Services do so at their own volition and are entirely responsible for compliance with applicable law",
        "service_80":"Data Transfer",
        "service_81":"As a principle, we do not transfer any User data to third parties except for data required for the processing and handling of payments. You are entitled at any time, and without costs, to obtain information regarding your stored data, and to correct, block or delete your stored data. To do so, please contact us through our contact form on the website",
        "service_82":"Anonymized Statistics",
        "service_83":"By using the Services, Users agree that any and all of their data entered and generated through the use of the Services may be used by from woman to woman in an anonymized form for the purpose of creating statistics that show User frequencies and the positive impact of our Services and platform",
        "service_84":"The Protection of Your Data & Privacy Statement",
        "service_85":"Based on Article 13 of the Swiss Federal Constitution and the provisions of the Swiss data protection act, everyone has the right to the protection of privacy, as well as to protection from misuse of their personal details. We are strictly upholding these regulations. Personal data are kept strictly confidential and will not be disclosed, given, sold or transferred to third parties. In close cooperation with our hosting providers we make every effort to protect our databases from external access, loss of data, misuse or falsification. When accessing our websites, the following data are saved to log files: IP address, date, time, browser request and generally transmitted information regarding operating system and browser. These User data parameters are the basis for statistical, anonymous analyses which help us to recognize trends in order to further improve our product offering",
        "service_86":"Moreover, we comply with the EU General Data Protection Regulation (EU GDPR). At any time, a User can a) access her data, b) correct errors in her personal data, c) have her personal data deleted, d) object to the processing of her personal data, and e) export her personal data. Furthermore, f) in case of a data breach, all users will be informed immediately, g) we take all necessary and reasonable measures to secure user data, and h) we have appointed and trained a Data Protection Officer (one of our Board members), who is the contact point for all inquiries relating to data protection – you can use our contact form on the website to get in touch with our Data Protection Officer (DPO)",
        "service_87":"English Language",
        "service_88":"In the event of a conflict between these the English language version of the Terms and any foreign language translation versions thereof, the English language version of the Terms shall govern and control. All disputes, claims and causes of action (and related proceedings) will be communicated in English",
        "service_89":"Cooperation with Authorities and Police Enforcement",
        "service_90":"We will cooperate with law enforcement authorities as required by law. We will cooperate with law enforcement agencies in any investigation of alleged illegal activity regarding the use of the Services when requested",
        "service_91":"Notification Procedures",
        "service_92":"Users agree from woman to woman may provide notifications to such User via email, written or hard copy notice, or through conspicuous posting of such notice on our website. Users may opt out of certain means of notification or to receive certain notifications",
        "service_93":"Unsolicited Idea Submissions",
        "service_94":"We are always pleased to hear from our Users, and welcome their comments or suggestions. However, products, services, and features developed by from woman to woman or its Users might be similar or even identical to a submission received by from woman to woman or its Users. When we refer to a “submission” in this paragraph, we mean: any submission, comment, or suggestion (including, but not limited to, ideas, products, or services and suggested changes) made either on the from woman to woman platform or to from woman to woman about an existing product or service on, or a feature of, or a proposed addition to, the from woman to woman platform",
        "service_95":"With regard to User submissions",
        "service_96":"all such submissions are non-confidential and non-proprietary and will be treated as non-confidential and non-proprietary",
        "service_97":"from woman to woman and its Users will have no express or implied obligation or liability of any kind concerning the submissions, including, for example, any use or disclosure of the submissions; and",
        "service_98":"from woman to woman and its Users are entitled to unrestricted use or disclosure of the submissions for any purpose whatsoever, all without compensation to the User that submitted the submission",
        "service_99":"Entire Agreement/Severability",
        "service_100":"These Terms are the entire agreement between each User and from woman to woman regarding its subject matter. If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. from woman to woman’s failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision",
        "service_101":"Changes to Terms",
        "service_102":"from woman to woman may alter the Terms at any time, so please review them frequently. If a material change is made, from woman to woman may notify Users in the Services, by email, by means of a notice on the Services, or other places we think appropriate. A “material change” will be determined at from woman to woman’s sole discretion, in good faith, and using common sense and reasonable judgment",
        "service_103":"Google Analytics",
        "service_104":"This website uses Google Analytics, a web analytics service provided by Google, Inc. ('Google'). Google Analytics uses so-called 'cookies', which are text files placed on your computer to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. In case of activation of the IP anonymization, Google will truncate/anonymize the last octet of the IP address for Member States of the European Union as well as for other parties to the Agreement on the European Economic Area. Only in exceptional cases, the full IP address is sent to and shortened by Google servers in the USA. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser; however please note that if you do this you may not be able to use the full functionality of this website. By using our website and Services, you consent to the processing of data about you by Google in the manner and for the purposes set out above",
        "contact_noti":"Thank you for your message. We will respond shortly.",
    }
]
export const lang_sp = [
    {
        "contact_noti":"Gracias por su mensaje. Le responderemos en breve.",
        "menu1": "Página principal",
        "menu2": "Cómo funciona",
        "how_it":"Cómo",
        "works":"funciona",
        "menu3": "Testimonios",
        "menu4": "Nuestro equipo",
        "menu5": "Preguntas frecuentes",
        "menu6": "Contáctenos",
        "menu7": "Resources",
        "resources": "Resources",        
        "organization": "organización",
        "reg_menu": "Hazte miembro",
        "login_menu": "Registrarse",
        "slider1_text1": "¿Necesitas asesoramiento para tu",
        "slider1_text1_2": "proyecto privado o profesional?",
        "slider1_text2": "Sube la descripción de tu proyecto y encuentra una asesora de forma gratuita.",
        "slider2_text1": "¿Eres una experta en",
        "slider2_text1_2": "un campo en particular?",
        "slider2_text2": "Regístrate y comparte tus conocimientos de forma gratuita",
        "slider2_text2_2": "con mujeres que necesitan tu experiencia.",
        "slider3_text1": "Nuestra misión es simple.",
        "slider3_text2": "Proporcionar una plataforma global donde las mujeres",
        "slider3_text2_2": "conecten de forma gratuita con asesoras de diversas áreas.",
        "About_us":"Sobrenosotros",
        "about1":"Nuestra plataforma conecta a mujeres que necesitan asesoramiento para mejorar sus habilidades, las llamamos buscadoras de ayuda, con asesoras: mujeres que tienen experiencia y conocimientos en una amplia gama de campos específicos y están dispuestas a asesorar a otras mujeres de forma gratuita.",
        "about2":"Un proyecto de asesoría (coaching) creado por una mujer buscando ayuda podría implicar, por ejemplo :",
        "about3":"Preparación para sus futuros estudios o decidir sobre su futura carrera",
        "about4":"Mejorar sus habilidades en la creación de planes de negocios o calcular presupuestos.",
        "about5":"Conseguir una valiosa visión sobre cómo lograr un equilibrio entre su vida personal y el trabajo.",
        "about6":"¿Es usted una mujer que le gustaría beneficiarse de la experiencia de una asesora en nuestra plataforma? O es usted una especialista en un determinado campo, dispuesta a donar su conocimiento y su tiempo a otras mujeres? ",
        "about7":"Entonces, si es así, te invitamos a registrarte en nuestra plataforma! Crea un nombre de usuario para tu perfil, sube tu solicitud para conectar con una asesora quién planificará las sesiones de asesoría* con usted –la asesoría es totalmente gratuita y permanecerá así siempre!",
        "about8":"* Una vez acuerden, la buscadora de ayuda y la asesora, ambas pueden utilizar nuestro chat para acordar el número de sesiones de asesoramiento que se llevarán a cabo y el medio (por ejemplo Skype, WhatsApp, Zoom, etc.) que sea más adecuado para ambas partes.",
        "success":"Historias",
        "stories":"de Éxito",
        "art1_1":"Magnifica Warmi",
        "art1_2":"Capacitando a mujeres bolivianas para lograr la sustentabilidad ambiental en sus comunidades.",
        "art_modal1_1":"Magnifica Warmi: empoderando a las mujeres para promover la sustentabilidad ambiental en sus comunidades",
        "art_modal1_2":"Magnifica Warmi es un proyecto socio-ambiental que busca capacitar a las mujeres de las zonas rurales alrededor de la ciudad de Sucre, Bolivia, como embajadoras y promotoras de prácticas ambientalmente sostenibles en sus comunidades. El principal objetivo de los talleres impartidos por Magnifica Warmi es capacitar a las mujeres para que comprendan el valor de su rol como madres, esposas, hijas y líderes en la toma de decisiones para lograr la sustentabilidad ambiental en sus comunidades. Las técnicas de manejo y reciclaje de residuos sólidos forman parte del contenido de la capacitación.",
        "art_modal1_3":"En 2019, una asesora de la plataforma de mujer a mujer (W2W) llevó a cabo 10 sesiones de asesoría en línea para las jóvenes mujeres miembros del proyecto. El objetivo era recibir asesoramiento en la forma de establecer una metodología estructurada para los talleres. Después de la exitosa finalización de las sesiones de entrenamiento, Magnifica Warmi ha logrado dar asesoramiento a 200 mujeres de las zonas rurales que viven en zonas vulnerables a la contaminación, beneficiando a más de 500 familias.",
        "art_modal1_4":"El equipo de Magnifica Warmi resume su experiencia con el proceso de asesoría, en las siguientes líneas: 'Las sesiones de entrenamiento facilitadas por una entrenadora en la  plataforma de mujer a mujer fueron cruciales para diseñar los talleres y la correspondiente metodología de los mismos. En el inicio de nuestro proyecto no sabiamos cómo empezar o que debíamos hacer, pero con las sesiones de entrenamiento hemos aprendido muchas lecciones técnicas que nos han ayudado a mejorar el concepto que tenemos de nuestros talleres. Nosotros estamos muy agradecidos porque nuestra asesora creyó en nuestra idea y nos ayudó a que suceda! '",
        "art_modal1_5":"Encuentra más información acerca de la obra de este magnífico grupo en:",
        "art_modal1_6":"https://tubecabolivia.com/magnifica-warmi/",
        "art_modal1_7":"Artículo subido el 7 de septiembre de 2020",
        "art2_1":"YO SOY COMO TU! Agentes por la Inclusión",
        "art2_2":"Preparando a estudiantes y docentes para ser agentes de cambio que promuevan la inclusión educativa y social de las personas con discapacidad.",
        "art_modal2_1":"¡SOY COMO TÚ! Agentes de inclusión: una comunidad que está cambiando la perspectiva hacia las personas con discapacidad",
        "art_modal2_2":"¡SOY COMO TÚ! Agentes por la Inclusión (SOY COMO TU! AxI) es una comunidad liderada por personas con y sin discapacidad que promueve la inclusión de las personas con discapacidad en el sistema educativo de La Paz, Bolivia. Sus actividades se centran en el cambio en las actitudes negativas y el aumento del conocimiento sobre la forma de interactuar con las personas con discapacidad. Ellos logran esto a través de actividades de sensibilización y talleres, llevados a cabo dentro de su programa 'Sensibilización del Buen Trato hacia personas con discapacidad'.",
        "art_modal2_3":"En 2020, una asesora de la plataforma de mujer a mujer (W2W) llevó a cabo 10 sesiones de asesoría en línea con el equipo SOY COMO TU! AxI , para ayudarles a organizar su programa 'Sensibilización del buen trato hacia las personas con discapacidad' en las escuelas de La Paz. El desarrollo de un trabajo del plan y un presupuesto, el desarrollo de alianzas estratégicas y consejos sobre la adaptación de su trabajo al formato digital (dada la actual crisis con el virus COVID 19) fueron los temas tratados con el grupo.",
        "art_modal2_4":"En otoño del 2020, el equipo de SOY COMO TU! AxI realizará el primer taller de su programa a los estudiantes y profesores de una escuela en La Paz. Ellos planean llegar a 50 personas, estudiantes y profesores, para capacitarlos como agentes de cambio que van a compartir el conocimiento sobre la inclusión social de las personas con discapacidad en suscírculos académicos.",
        "art_modal2_5":"El equipo de “¡YO SOY COMO USTED! AxI ” ha resumido su experiencia de asesoramiento en las siguientes líneas:",
        "art_modal2_6":"'Nuestra asesora de la plataforma de mujer a mujer es parte de SOY COMO TU! Ella ha estado con nosotros en cada paso a lo largo del camino. Nos hemos perdido muchas veces, pero nuestra asesora siempre nos apoyó y caminó con nosotros, destacando importantes aspectos del proyecto. Estamos tan agradecidos y bendecidos por esta plataforma y compartimos una visión: vamos a hacer del mundo un mejor lugar y crear un impacto positivo. Nosotros sabemos que no nos encontramos solos, y que siempre tendremos el apoyo de nuestra asesoracon nosotros!'",
        "art_modal2_7":"Usted puede visitar su página para leer más acerca de sus increíbles proyectos:",
        "art3_1":"Lanzamiento de la nueva plataforma de mujer a mujer (W2W)",
        "art3_2":"¡Una plataforma de asesoramiento más intuitiva y ágil nos ayudará a cumplir nuestra misión!",
        "art_modal3_3":"LO HICIMOS : Lanzamiento de la nueva plataforma de mujer a mujer (W2W)",
        "art_modal3_4":"Desde el final de 2017, la organización suiza sin fines de lucro de mujer a mujer ha dirigido y mantenido unaplataforma de asesoramiento en línea. En esta plataforma, las mujeres que necesitan de asesoramiento para mejorar sus habilidades, las llamamos buscadoras de ayuda, son conectadas con asesoras - las mujeres que tienen experiencia y conocimientos técnicos en una amplia gama de determinados campos y están dispuestas a asesorar a otras mujeres gratuitamente.",
        "art_modal3_5":"Después de haber analizado detalladamente la retroalimentación obtenida desde la plataforma de usuarios y nuestra Junta de Directores, de mujer a mujer redefinió las característicasesenciales de la plataforma e implementó una nueva versión de lo que es visualmente más atractivo, más intuitivo y más limpio a la vista.",
        "art_modal3_6":"Sandra Leicht-Cabrera, directora ejecutiva de De mujer a mujer, no podría estar más contenta con el resultado: 'La nueva plataforma de asesoramiento es gratuita y fácil de usar. Su principal valor añadido, nuestra red de excelentes asesoras con experiencia en muchos campos, puede ahora ser incluso más fácilmente accesible por las mujeres que buscan asesoramiento de un entrenador con experiencia en sus campos. Esto va a dar lugar a muchas colaboraciones fructíferas con instituciones que promocionan el asesoramiento a mujeres en todo el mundo y ayudará a lograr nuestra misión: promover, más allá de las fronteras, el intercambio de conocimientos y experiencia entre las mujeres'",
        "art_modal3_7":"La nueva plataforma estará inicialmente disponible en Inglés y Español. Nosotros estamos buscando a voluntarios que puedan traducir la página en más idiomas. ¿Interesado? Nos encantaría saber más de usted a través de nuestro formulario de contacto en la página principal.",
        "our":"Nuestro",
        "team":"equipo",
        "team1_1":"Sandra Leicht",
        "team1_2":"Experta en estándares de sostenibilidad y cadenas de valor",
        "team1_3":"Basada en Suiza",
        "team1_4":"Desde una temprana edad, estuve involucrada en proyectos sociales en mi país de origen Bolivia. Muchas mujeres tienen brillantes ideas para ayudarse a sí mismas y a suscomunidades, pero que no saben dónde a empezar. De mujer a mujer (W2W) es un lugar de encuentro para ellas y también para las asesoras que quieren ayudar pero no saben cómo. Mi visión es que a través de nuestra plataforma, aportemos en una manera concreta y medible a las metas de sostenibilidad la Organización de Naciones Unidas.",
        "team1_5":"Directora Ejecutiva",
        "team2_1":"Damaris Diethelm",
        "team2_2":"Pedagoga social",
        "team2_3":"Basada en Suiza",
        "team2_4":"Con mi participación en De mujer a mujer, quiero garantizar que las mujeres de todo el mundo puedan acceder al conocimiento de los demás sin las barreras que podrían enfrentar en sus alrededores. Yo utilizo mi red profesional en el trabajo social, para motivar a las mujeres a compartir su conocimiento gratuitamente con otras mujeres registrándose como asesoras en nuestra plataforma.",
        "team2_5":"Presidente de la Junta de Directores",
        "team3_1":"Dr. Tshilidzi Van der Lecq",
        "team3_2":"Oftalmólogo",
        "team3_3":"Basada en Sudáfrica",
        "team3_4":"Yo soy una africana del sur de África, una esposa, y una madre. Actualmente, yo trabajo como consultora oftalmóloga con base en la Universidad de la ciudad El Cabo. Mi país tiene una larga historia de mujeres que superan los retos para poder conseguir cambios para ellas mismas y sus comunidades. A través de mi participacion con De mujer a mujer, me gustaría a ser parte de ese cambio.",
        "team3_5":"Miembro de la Junta Directiva",
        "team4_1":"Monica Wilson",
        "team4_2":"Especialistaen seguridad cibernética",
        "team4_3":"Basada en los EE. UU.",
        "team4_4":"Mi deseo de ayudar a las mujeres está vinculado a mi deseo de eliminar la pobreza y el sufrimiento de los niños. No debería existir en este mundo un niño que no tenga las necesidades básicas cubiertas. Si ayudamos a las mujeres, así comonos lo proponemoscon De mujer a mujer, ellas van a tener los medios para poder criar a sus hijos con dignidad.",
        "team4_5":"Miembro de la Junta Directiva ",
        "team5_1":"David Leicht ",
        "team5_2":"Consultor y asesor/coach de capital humano",
        "team5_3":"Basado en Suiza ",
        "team5_4":"Creo firmemente en el uso de conocimiento como un recurso, que no conoce fronteras,de autoayuda: las mujeres alrededor del mundo tienen una inmensa cantidad de conocimiento que quisieran compartir con otras mujeres. De mujer a mujer ofrece una herramienta gratuita para aquellas mujeres en búsqueda de conocimiento. Yo estoy honrado de poder hacer una pequeña contribución en esta iniciativa que sobre transferencia de conocimiento para el beneficio de las mujeres que lo necesiten.",
        "team5_5":"Miembro de la Junta Directiva",

        "faq_heading":"Preguntas más frecuentes",
        "faq_heading1":"s",
        "faq_1":"Busco asesoramiento/coaching. ¿Por qué debería registrarme en la plataforma De mujer a mujer?",
        "faq_1_1":"Porque tenemos una gran red de asesoras con amplia experiencia, basadas en diferentes países. Puede beneficiarse de sus experiencias y conocimientos de forma gratuita.",
        "faq_2":"Soy una especialista en mi campo, ¿por qué debería convertirme en asesora?",
        "faq_2_1":"Porque muchas mujeres de todo el mundo podrían beneficiarse de sus conocimientos/experiencia. Te damos la oportunidad de ayudar a otras mujeres, mientras puedes estar en casa, en el trabajo o en cualquier otro lugar, y tú decides cuántos proyectos de asesoramiento/coaching puedes emprender en tu tiempo disponible.",
        "faq_3":"¿Cómo creo una cuenta de usuario?",
        "faq_3_1":"Tanto las asesoras como quiénes buscan ayuda pueden crear una cuenta de usuario haciendo clic en el botón 'Registrarse' en la parte superior de la página.",
        "faq_4":"¿Cuál es la motivación para crear la plataforma De mujer a mujer?",
        "faq_4_1":"Es simple: muchas mujeres enfrentan obstáculos que les impiden acceder a conocimientos técnicos/asesoramiento. Pueden, por ejemplo, carecer de dinero para inscribirse en una escuela, no poder hacer un viaje a una institución de enseñanza (costo del viaje, transporte público inseguro, horario poco confiable, etc.), tener compromisos familiares que les impidan asistir regularmente a clases, estar en un lugar donde simplemente no existan programas de asesoramiento adecuados o enfrentar obstáculos sociales que limiten sus capacidades.",
        "faq_4_2":"Afortunadamente, Internet ha creado nuevas posibilidades en la transferencia de conocimientos/experiencias, que utilizamos al unir a las solicitantes de ayuda con asesoras expertas a través de nuestra plataforma. Este intercambio de conocimientos y experiencia, derriba barreras geográficas, culturales o financieras. El conocimiento que se transfiere a quienes buscan ayuda les ayuda a dar forma a proyectos personales o profesionales exitosos y sostenibles, programas de desarrollo comunitario y mucho más. Las asesoras, a cambio, utilizan sus conocimientos/experiencias para mejorar las circunstancias de otras mujeres.",
        "faq_4_3":"A través de la transferencia de conocimiento que facilitamos, queremos contribuir a mejorar las circunstancias de las mujeres en todo el mundo y a los Objetivos de Sostenibilidad de la ONU. Revise regularmente nuestros testimonios / historias de éxito para ver exactamente cómo marcamos la diferencia.",
        "faq_5":"¿Las sesiones de coaching tienen lugar en esta plataforma?",
        "faq_5_1":"No. Aquí, sólo se conecta a las solicitantes de ayuda y las asesoras. Una vez conectadas, ambas utilizarán nuestra función de chat para acordar cuántas sesiones de asesoramiento se realizarán y el medio (por ejemplo, Skype, WhatsApp, Zoom, etc.) más adecuado para ambas partes.",
        "faq_6":"¿Me dirá una asesora cómo hacer mi proyecto / qué decisión tomar?",
        "faq_6_1":"¡Realmente no! Las asesoras de nuestra plataforma se comprometen a actuar de acuerdo con nuestro credo de asesoramiento:",
        "faq_6_2":"La asesoría es un proceso de aprendizaje compartido. Al compartir su experiencia, conocimientos y una perspectiva externa imparcial, una asesora apoya y empodera a la persona que busca ayuda para comprender y definir un problema determinado y crear sus propias soluciones.",
        "faq_6_3":"En esta relación, la mujer que busca ayuda es siempre la que decide, actúa y lidera; ella es responsable del resultado de su proyecto.",
        "faq_6_4":"Una asesora nunca empuja a una mujer que busca ayuda hacia una decisión o acción específica ni decide por ella. Por el contrario, escucha atentamente, tiene una actitud positiva, hace preguntas, muestra un contexto más amplio, relata su propia experiencia y desafía ala buscadora de ayuda a pensar de manera más profunda / innovadora.",
        "faq_6_5":"Si cree que su asesora no actúa de acuerdo con este credo, no dude en contactarnos inmediatamente mediante el formulario de contacto.",
        "faq_7":"¿Tiene algún costo el uso de la plataforma?",
        "faq_7_1":"No, nuestra plataforma es, y siempre será, de uso gratuito para las mujeres que buscan ayuda y las asesoras. Si desea apoyar nuestro trabajo contribuyendo a los costos de funcionamiento de nuestra plataforma, puede hacerlo mediante una donación. Sin embargo, esto depende totalmente de usted y no cambiará el grado de acceso de las funcionalidades de nuestra plataforma.",
        "faq_8":"¿Puedo usar la plataforma para recaudar fondos o crear publicidad para mi proyecto?",
        "faq_8_1":"No, esto es una violación de nuestros términos de servicio. Nuestra plataforma está diseñada para el coaching/asesoramiento personal. No se intercambian, recaudan, solicitan ni exigen fondos de ninguna manera.",
        "faq_9":"¿En qué áreas puedo recibir asesoramiento/coaching a través de su plataforma?",
        "faq_9_1":"A través de nuestra plataforma, las mujeres reciben asesoramiento/coaching gratuito para sus proyectos personales o profesionales. Nuestras “Reglas de la Comunidad” definen que dichos proyectos no pueden tratar, contener o hacer referencia a:",
        "faq_9_2":"Actividades sexualmente explícitas, prostitución, pornografía y afines",
        "faq_9_3":"Violencia y comportamiento destructivo contra humanos, animales o la naturaleza.",
        "faq_9_4":"Fines bélicos, crimen organizado, terrorismo y afines",
        "faq_9_5":"Armas, municiones y artículos similares, incluidos accesorios",
        "faq_9_6":"Recaudación de fondos, esquemas financieros, apuestas, lotería, juegos de azar y afines",
        "faq_9_7":"Sustancias controladas, medicamentos y afines",
        "faq_9_8":"Restos humanos o de animales",
        "faq_9_9":"Todas y cada una de las actividades ilegales en el país de domicilio delamujer que solicita ayuda y / o asesora y / o el domicilio de la organización De mujer a mujer;",
        "faq_9_10":"Otras actividades inmorales o poco éticas consideradas por el equipo De mujer a mujer, incluyendo iniciativas sectarias en conflicto con los derechos humanos y/o los valores de la organización De mujer a mujer.",
        "faq_9_11":"Esta lista no es exhaustiva. Si cree que una usuaria (asesora o solicitante de ayuda) o un proyecto viola nuestras “Reglas de la Comunidad”, no dude en ponerse en contacto con nosotros de inmediato mediante el formulario de contacto.",
        "faq_9_12":"Consulte también nuestros “Términos y Condiciones” para obtener más información detallada sobre el uso de nuestra plataforma.",
        "faq_10":"¿Pueden las demás ver mis solicitudes de asesoría? ¿Cómo es protegida mi privacidad?",
        "faq_10_1":"Sí, otros pueden ver sus solicitudes de asesoría. Solo de esta manera, las asesoras pueden ver si tienen la experiencia/conocimiento requerido para atender la solicitud y conectarse con usted. Le recomendamos que realice solicitudes de ayuda detalladas, pero que no incluya ningún dato personal. Por ejemplo:",
        "faq_10_2":"Necesito orientación sobre cómo elegir mi trayectoria profesional",
        "faq_10_3":"Necesito asesoramiento para crear un presupuesto para una organización sin fines de lucro",
        "faq_10_4":"Quiero mejorar mis habilidades de presentación",
        "faq_10_5":"Los detalles de su solicitud de asesoría solo se discuten con su asesora después de que se haya conectado con éxito a través de nuestra plataforma.",
        "faq_10_6":"De mujer a mujer, no transfiere ni vende - ni nunca lo hará – los datos de usuarios creados por terceras partes.",
        "faq_11":"¿Quiénes son lasasesoras? ¿Cómo sé que una asesora es realmente una experta en su campo?",
        "faq_11_1":"Todas las mujeres tienen conocimientos especializados en campos particulares, por lo que cualquier mujer puede ser asesora en nuestra plataforma.",
        "faq_11_2":"Puede consultar la página de perfil de una asesora para ver más detalles sobre su experiencia y conocimientos. Y, a través de sus sesiones de asesoramiento/coaching, verá rápidamente cuán competente es su asesora/coach (y puede preguntarle directamente si necesita más aclaraciones sobre su experiencia). También puede ver cómo otras solicitantes de ayuda han calificado a una asesora en particular a través del sistema de calificación(incluye número de estrellas y/o comentarios).",
        "faq_12":"¿Puedo responsabilizar a una asesora por un mal consejo?",
        "faq_12_1":"Según nuestro credo de asesoramiento/coaching:",
        "faq_12_2":"Unaasesora nunca empuja a una mujer que busca ayuda hacia una decisión o acción específica, ni decide por ella; la persona que busca ayuda siempre es responsable del resultado de su proyecto",
        "faq_12_3":"Solo usted, como solicitante de ayuda, conoce sus circunstancias exactas y el contexto de su proyecto personal o profesional. Es por eso que cada decisión la toma usted y es responsabilidad suya, no de su asesora.",
        "faq_13":"Mi empresa anima al personal femenino a hacer voluntariado. ¿Cómo podemos hacer De mujer a mujer parte de nuestra estrategia de sostenibilidad y equidad de género?",
        "faq_13_1":"¿Eres una empresa que desea invertir en el empoderamiento de las mujeres?",
        "faq_13_2":"De mujer a mujer es el lugar ideal para ti. Su personal femenino puede registrarse aquí como asesoras para mujeres que lo necesiten. Podemos recopilar todas las horas de asesoramiento/coaching brindado por su personal femenino y redactar informes y testimonios de impacto verificados y personalizables que se pueden utilizar en informes de sostenibilidad / responsabilidad corporativa.",
        "faq_13_3":"Contáctenos usando el formulario de contacto, y nuestro Gerente de Sostenibilidad se comunicará con usted para evaluar sus necesidades exactas y ofrecerle una solución personalizada.",
        "contact_us":"Contáctenos:",
        "contact_1":"Póngase en contacto con nosotros",
        "contact_2":"Deje su mensaje y le responderemos a la brevedad.",
        "contact_3":"Nombre",
        "contact_4":"Correo electrónico",
        "contact_5":"Asunto",
        "contact_6":"Mensaje",
        "contact_7":"Enviar",

        "footer_1":"De mujer a mujer es una organización suiza sin fines de lucro. Nuestro objetivo es promover el intercambio de conocimientos y experiencia entre las mujeres. Hacemos esto al proporcionar una plataforma en línea a la que se puede acceder gratuitamente en todo el mundo. Aquí, las mujeres son asesoradas por otras mujeres, y este intercambio sin fronteras promueve emprendimientos personales y profesionales exitosos y sostenibles, programas de desarrollo comunitario y mucho más.",
        "social_media":"REDES SOCIALES",
        "footer_2":"Hagamos correr la voz sobre cómo el intercambio colaborativo y compasivo de conocimientos y experiencia está marcando una diferencia real para las mujeres de todo el mundo. ¡Síguenos en nuestros canales de redes sociales y comparte tu experiencia con nosotros!",
        "copyright":"© Copyright 2020",
        "verify_msg":"Su correo electrónico ha sido verificado. Ahora puede iniciar sesión y comenzar a usar nuestra plataforma",
        "congrats":"¡Felicidades!",
        "register":"Registrarse",
        "first_name":"Nombre",
        "last_name":"Apellido",
        "pass":"Contraseña",
        "conf_pass":"Confirmarcontraseña",
        "country":"País",
        "choose_country":"Eligirpaís",
        "languages":"Idiomas",
        "agree_text":"Estoy de acuerdo con los términos de uso de De mujer a mujer","community_rules":"Reglas de la comunidad W2W",
        "and":"y",
        "terms_cond":"Términos y condiciones.",
        "our_community_rules":"Nuestras reglas de la comunidad",
        "terms_conditions":"términos&condiciones",
        "login":"Iniciarsesión",
        "login_heading":"Iniciar sesión en su cuenta",
        "email_address":"Correoelectrónico",
        "frgt_pass":"Contraseña Olvidada",
        "not_member":"¿No es un miembro? Crear una nueva cuenta.",
        "logout":"Cerrarsesión",

        "usermenu_1":"Vista deperfil",
        "usermenu_2":"Editar mi perfil",
        "usermenu_3":"Cambiar mi contraseña",
        "usermenu_4":"Mis proyectos",
        "usermenu_5":"Enviardonación",
        "usermenu_6":"Proyectos que requierenunaasesora",
        "view_profile_heading":"Vista deperfil:",
        "view_profile_1":"Retroalimentación",
        "view_profile_2":"Calificación (es)",
        "view_profile_3":"Idiomas hablados",
        "view_profile_4":"Detalles del perfil",
        "view_profile_5":"Comentarios y calificaciones",
        "view_profile_6":"Áreas de especialización:",
        "view_profile_7":"Tengo experiencia en estos sectores/temas:",
        "view_profile_8":"Añostotales de experiencia:",
        "view_profile_9":"Una breve descripción sobre mí:",
        "view_profile_10":"Años",
        "view_profile_11":"Horas acumuladasde asesoramiento",
        "view_profile_12":"Sin comentarios ni valoraciones",
        "coach":"Entrenadora",
        "edit_profile":"Editar perfil",
        "chg_pwd":"Cambiar contraseña",
        "forgot_pass":"Se te olvidó tu contraseña",
        "verify_email":"Verifica tu correo electrónico",
        "edit_profile1":"Selecciona tu foto de perfil",
        "edit_profile2":"Elije el archivo",
        "edit_profile3":"¿Soy unaasesora?",
        "edit_profile4":"Industria",
        "edit_profile5":"Una breve descripción sobre mi",
        "edit_profile6":"¡Dile a los demás algo sobre ti! Si es unaasesora, describa su experiencia técnica / experiencia laboral / calificación profesional.",
        "edit_profile7":"Puedeelegir la imagen en jpeg, jpg o png",
        "chg_pwd1":"Contraseña anterior",
        "chg_pwd2":"Nueva contraseña",
        "chg_pwd3":"Confirmarcontraseña",
        "donation1":"Cantidad",
        "my_projects_heading1":"Mis",
        "my_projects_heading2":"proyectos",
        "my_projects_1":"Encontrarunaasesora",
        "my_projects_2":"Asesorar a otras",
        "my_projects_3":"Agregar nuevo",
        "my_projects_4":"Buscar",
        "my_projects_5":"Todas las áreas de experiencia",
        "my_projects_6":"Registrostotales:",
        "my_projects_7":"Anterior",
        "my_projects_8":"Próximo",
        "my_projects_9":"Título del Proyecto",
        "my_projects_10":"Necesitoasesoramientoen",
        "my_projects_11":"Eligetipo de proyecto",
        "my_projects_12":"Eligeexperiencia",
        "my_projects_13":"Elige el tipo",
        "my_projects_14":"Me pueden asesorar en estos idiomas",
        "my_projects_15":"Sesiones de coaching (indicación informativa para su asesora)",
        "my_projects_16":"Una sesión de asesoramiento típica dura entre 45 y 60 minutos.",
        "my_projects_17":"Describe tu proyecto para tu asesora.",
        "my_projects_18":"¿Qué quieres lograr? ¿Qué habilidades quieres expandir? etc.",
        "my_projects_19":"Añadir",
        "my_projects_20":"Título",
        "my_projects_21":"Área de experiencia",
        "my_projects_22":"No.",
        "my_projects_23":"Título",
        "my_projects_24":"Sesiones",
        "my_projects_25":"Estado",
        "my_projects_26":"Acción",
        "my_projects_27":"Agregarproyecto",
        "my_projects_28":"Editarproyecto",
        "my_projects_29":"Eliminarproyecto",
        "my_projects_30":"¿Estásseguro?",
        "my_projects_31":"¿Quiereseliminaresteproyecto?",
        "my_projects_32":"Cancelar",
        "my_projects_33":"¿Quierescerraresteproyecto?",
        "my_projects_34":"Si",
        "my_projects_35":"No",
        "my_projects_36":"Cerrarproyecto",
        "my_projects_37":"Deja un comentario público para tu asesora",
        "my_projects_38":"Cuéntanos cómo fue trabajar con tu asesora",
        "my_projects_39":"Tiempo total de asesoría (horas)",
        "my_projects_40":"Por ejemplo, 8 sesiones de asesoría de 45 minutos cada una = 6 horas de tiempo total de asesoría",
        "my_projects_41":"Mensajes",
        "my_projects_42":"Escribe tumensaje...",
        "my_projects_43":"Enviar",
        "my_projects_44":"Elige el idioma",
        "my_projects_45":"Lista de asesoras",
        "my_projects_46":"Solicitudenviada",
        "my_projects_47":"¿Estás seguro de que deseas enviar la solicitud?",
        "my_projects_48":"¿Estás seguro de que deseas cancelar la solicitud?",
        "my_projects_49":"Aceptado y pendiente",
        "my_projects_50":"Todoslosproyectos",
        "my_projects_51":"Rechazado",
        "my_projects_52":"Cerrado",
        "my_projects_53":"Aceptar / rechazarsolicitud",
        "my_projects_54":"Eligeestado",
        "my_projects_55":"Proyectos buscando",
        "my_projects_56":"Para una entrenadora",
        "my_projects_57":"Tipo de proyecto",
        "my_projects_58":"Detalles del proyecto",
        "my_projects_59":"Enviaroferta",
        "my_projects_60":"¿Quieres proponer tu ayuda como asesora para este proyecto?",
        "my_projects_61":"Todoslosidiomas",
        "my_projects_62":"Solicitante de ayuda",
        "my_projects_63":"Vista de perfil",
        "my_projects_64":"Finalizar la colaboración",
        "my_projects_65":"Cancelar la oferta",
        "my_projects_66":"Editar",
        "my_projects_67":"Remover",
        "my_projects_68":"Ver oferta",
        "my_projects_69":"No se encuentranproyectos",
        "my_projects_70":"Detalles de la Asesora",
        "my_projects_71":"Aceptar/Rechazarsolicitud",
        "my_projects_72":"¿Quierescancelartuofertaparaeste proyecto?",
        "my_projects_73":"¿Seguro que quieres hacer esto?",
        "my_projects_74":"Si lo que desea es indicar que las sesiones de asesoría han finalizado,(y dar la posibilidad a su asesorada de comentar sobre los beneficios de la asesoría), hable con su asesorada, quien puede iniciar el cierre de su proyecto terminado con éxito.",
        "update":"Actualizar",
        "close":"cerrar",
        "description":"Descripción",
        "accept":"Aceptar",
        "reject":"Rechazar",
        "already_act":"Ya tienes una cuenta? Inicia sesión ahora.",
        "my_projects_75":"Puedes aceptar o rechazar la oferta.",
        "my_projects_76":"Lista de industrias",
        "my_projects_77":"Horas  invertidas",
        "my_projects_78":"Aceptar/Rechazar",
        "by":"Por",
        "date":"Fecha",
        "languages":"Idiomas",
        "reg_email_content_1":"Bienvenidos a la comunidad de mujer a mujer.",
        "reg_email_content_2":"Estimada",
        "reg_email_content_3":"Gracias por registrarse en la plataforma De mujer a mujer.",
        "reg_email_content_4":"Puede hacer clic en el botón de abajo para iniciar sesión en el sitio web y comenzar a usarla :",
        "reg_email_content_5":"Haga clic aquí para iniciar sesión",
        "reg_email_content_6":"Si ustedtiene alguna pregunta, por favor no dude en ponerse en contacto con nosotros mediante el uso del formulario de contacto de nuestra página principal.",
        "reg_email_content_7":"Esperamos que sea el inicio de muchos proyectos y sueños realizados, ¡disfrute nuestra plataforma y el intercambio de conocimientos e ideas con la comunidad!",
        "reg_email_content_8":"El equipo de mujer a mujer.",
        "forgot_pwd_content_1":"Restablecer la contraseña de su cuenta",
        "forgot_pwd_content_2":"Hola",
        "forgot_pwd_content_3":"Alguien solicitó que se restableciera la contraseña de su cuenta registrada.          ",
        "forgot_pwd_content_4":"Restablecer mi contraseña.",
        "forgot_pwd_content_5":"Este enlace es válido por 24 horas.",
        "forgot_pwd_content_6":"Si usted no hizo esta solicitud, por favorignore este correo electrónico e infórmenos inmediatamente.",
        "forgot_pwd_content_7":"Su contraseña no cambiará hasta que usted cree una nueva contraseña.",
        "sendcancelreq_email_content_1":"Solicitud de nuevo proyecto.",
        "sendcancelreq_email_content_2":"Una nueva solicitud ha sido solicitada por",
        "sendcancelreq_email_content_3":"Detalles de la solicitante de ayuda :",
        "sendcancelreq_email_content_4":"Detalles del proyecto :",
        "sendcancelreq_email_content_5":"Una petición enviada por John ha sido cancelada.",
        "sendcancelreq_email_content_6":"Saludos",
        "sendcancelreq_email_content_7":"Solicitud cancelada.",
        "reqexpired_email_content_1":"Una petición enviada por usted para John ha caducado, porque el tiempo límite de 3 días ha terminado.",
        "reqexpired_email_content_2":"Por favor, envíe una solicitud ala nueva asesora.",
        "reqexpired_email_content_3":"¡Saludos!",
        "reqexpired_email_content_4":"Una petición enviada por usted para John ha caducado, porque el tiempo límite de 3 días ha terminado.",
        "reqexpired_email_content_5":"Solicitud vencida.",
        "reqexpired_email_content_6":"Hola",
        "error_msg_1":"Usted has sido registrada con éxito.",
        "error_msg_2":"Ha ocurrido un problema.",
        "error_msg_3":"Esta dirección de correo electrónico ya está registrada.",
        "error_msg_4":"Por favor ingrese su primer nombre.",
        "error_msg_5":"Por favor, introduzca su apellido.",
        "error_msg_6":"Por favor, introduzca su dirección de correo electrónico.",
        "error_msg_7":"Por favor, introduzca su contraseña.",
        "error_msg_8":"Por favor confirme su contraseña.",
        "error_msg_9":"La contraseña y la contraseña de confirmación no coinciden.",
        "error_msg_10":"Por favor, indique su país.",
        "error_msg_11":"Por favor seleccione idiomas.",
        "error_msg_12":"Por favor, seleccione la anterior casilla de verificación.",
        "error_msg_13":"ID de usuaria o contraseña no válidos.",
        "error_msg_14":"Ha ocurrido un problema. ¡Inténtelo de nuevo!",
        "error_msg_15":"Hemos enviado un enlace a su correo electrónico para el cambio de contraseña.",
        "error_msg_16":"Esta dirección de correo electrónico no está registrada.",
        "error_msg_17":"Por favor, introduzca su nueva contraseña.",
        "error_msg_18":"Por favor confirme su contraseña.",
        "error_msg_19":"La contraseña y la contraseña de confirmación no coinciden.",
        "error_msg_20":"Contraseña ha sido actualizada exitosamente.",
        "error_msg_21":"Invalid token.",
        "error_msg_21":"Token no válido.",
        "error_msg_22":"Este token ha caducado.",
        "error_msg_23":"Por favor, introduzca su antigua contraseña.",
        "error_msg_24":"Contraseña ha sido cambiada exitosamente.",
        "error_msg_25":"La contraseña anterior no es correcta.",
        "error_msg_26":"Por favor ingrese su nombre",
        "error_msg_27":"Por favor ingrese su correo electrónico",
        "error_msg_28":"Por favor ingrese su país",
        "error_msg_29":"Por favor seleccione idiomas",
        "error_msg_30":"Por favor, seleccione sus áreas de conocimiento",
        "error_msg_31":"Por favor seleccione la industria / área en las que usted tiene experiencia",
        "error_msg_32":"Por favor, introduzca su total de años de experiencia",
        "error_msg_33":"Por favor ingrese su Biografía",
        "error_msg_34":"Perfil actualizado con éxito",
        "error_msg_35":"Gracias por su donación.",
        "error_msg_36":"Por favor, ingrese el título del proyecto",
        "error_msg_37":"Por favor seleccione el área de experiencia",
        "error_msg_38":"Por favor seleccione el tipo de proyecto",
        "error_msg_39":"Por favor seleccione idiomas",
        "error_msg_40":"Por favor, introduzca la descripción del proyecto",
        "error_msg_41":"Proyecto agregado exitosamente",
        "error_msg_42":"Proyecto eliminado correctamente.",
        "error_msg_43":"Proyecto actualizado con éxito",
        "error_msg_44":"Usted ha ya enviado una solicitud a una asesora. Por favor espere para su respuesta o cancele la última petición.",
        "error_msg_45":"Mensaje enviado con éxito.",
        "error_msg_46":"Solicitud enviada con éxito.",
        "error_msg_47":"Solicitud cancelada con éxito.",
        "error_msg_48":"Por favor, dé una calificación a la asesora (número de estrellas)",
        "error_msg_49":"Por favor, dé su opinión.",
        "error_msg_50":"Por favor, introduzca las horas de asesoría con las que se ha beneficiado",
        "error_msg_51":"Gracias porsus comentarios.",
        "error_msg_52":"El estado cambió con éxito",
        "error_msg_53":"¿Estás seguro de que deseas enviar la solicitud?",
        "error_msg_54":"¿Estás seguro de que deseas cancelar la solicitud?",
        "error_msg_55":"Ya ha enviado una solicitud a una asesora. Si la potencial asesora no responde dentro de los tres días, la solicitud caduca. Entonces podrá enviar una nueva solicitud a otra potencial asesora.",
        "error_msg_56":"Una asesora ya le envió una oferta de ayuda para este proyecto.",
        "error_msg_57":"¿Estás seguro de que deseas aceptar esta oferta?",
        "error_msg_58":"¿Estás seguro de que deseas rechazar esta oferta?",
        "error_msg_59":"Oferta aceptada",
        "error_msg_60":"Oferta rechazada",
        "error_msg_61":"Ahora puede comunicarse con su asesora por medio de mensajes en el chat.",
        "error_msg_62":"Ahora puede consultar la lista de asesoras y enviar una solicitud de asesoría a otra asesora.",
        "thanks":"¡Gracias!",
        "error_msg_63":"Su contraseña debe tener al menos 8 caracteres, debe contener al menos 1 letra mayúscula, 1 número y 1 símbolo (/[!@#$%^&*()_+\-=\[\]{};:\\|,.<>\/?]/) y no puede tener dos caracteres consecutivos.",
        "error_msg_64":"Por favor elija su tema",
        "error_msg_65":"Por favor escriba su mensaje",
        "error_msg_66":"Elija la imagen en formatojpeg, jpg y png",
        "error_msg_67":"Elija su estado.",
        "error_msg_68":"Captcha inválido. Inténtalo de nuevo",
        "community":"Nuestra comunidad",
        "rules":"Reglas",
        "pass_note":"Su contraseña debe tener al menos 8 caracteres, debe contener al menos 1 letra mayúscula, 1 número y 1 símbolo (porejemplo Abcd123@ o 1234Abc$) y no puede tener dos caracteres consecutivos.",
        "created_on":"Proyecto creado el",
        "guidelines_1":"Actualizado en agosto de 2020",
        "guidelines_2":"Proyectos excluidos, prohibición de recaudación de fondos y compensación",
        "guidelines_3":"La plataforma from woman to woman(W2W) es un sitio web donde las mujeres reciben asesoría gratuita para sus proyectos personales o profesionales. Dichos proyectos no pueden tratar, contener o hacer referencia a:",
        "guidelines_4":"Actividades sexualmente explícitas, prostitución, pornografía y similares ",
        "guidelines_5":"Violencia y comportamiento destructivo contra humanos, animales o la naturaleza",
        "guidelines_6":"Fines bélicos, crimen organizado, terrorismo y afines ",
        "guidelines_7":"Armas, municiones y artículos similares, incluidos accesorios ",
        "guidelines_8":"Recaudación de fondos, esquemas financieros, apuestas, lotería, juegos de azar y similares Sustancias controladas, medicamentos y similares",
        "guidelines_9":"Restos humanos o animales",
        "guidelines_10":"Todas y cadauna de las actividades ilegales en el país de la solicitante de ayuda y / o asesora y / o el domicilio de W2W ",
        "guidelines_11":"Otras actividades inmorales o poco éticas que considere el equipo de W2W, incluidas las iniciativas sectarias en conflicto con los derechos humanos y / o los valores de W2W.",
        "guidelines_12":"Esta lista no es exhaustiva.",
        "guidelines_13":"Si cree que un usuario (asesora o solicitante de ayuda) o un proyecto viola nuestras Reglas de la comunidad, no dude en contactarnos de inmediato a través del formulario de contacto en nuestra página de inicio.",
        "guidelines_14":"Además, nuestra plataforma no es una plataforma de recaudación de fondos. No se intercambia dinero y los usuarios no pueden solicitar dinero, u otras contribuciones de ningún tipo, publicidad o, en realidad, ningún tipo de compensación, recompensa o beneficio para ellos mismos o sus proyectos. Infórmenos inmediatamente si un usuario incumple esta regla. En caso de incumplimiento, W2W, según el grado de violación evaluado, emitirá advertencias contra los usuarios o eliminará y/o bloqueará usuarios y / o proyectos de manera indefinida.",
        "guidelines_15":"La definición de asesoría (coaching) y responsabilidades de la asesora (coach) y la solicitante de ayuda",
        "guidelines_16":"¿Es una asesoría (el coaching) el recibir consejos de una asesora (coach) otra persona? ¡Realmente no!  Las asesoras (las coaches) de nuestra plataforma se comprometen a actuar de acuerdo con nuestro credo de asesoramiento (coaching).La asesoría (el coaching)es un proceso de aprendizaje compartido. Al compartir su experiencia, conocimientos y una perspectiva externa imparcial, una asesora apoya y empodera a la persona que busca ayuda para comprender y definir un problema determinado y crear sus propias soluciones. En estarelación, la mujer que buscaayuda es siempre la que decide, actúa y lidera; ella es responsable del resultado de su proyecto. Una asesoranuncaempuja a una persona que busca ayuda hacia una decisión o acción específica ni decide por ella. Más bien, escucha con atención, tiene una actitud positiva, hace preguntas, muestra un contexto más amplio, relata su propia experiencia y desafía al buscador de ayuda a pensar más profundo (out of the box). Si cree que suasesora no actúa de acuerdo con este credo, no dude en contactarnos de inmediato a través del formulario de contacto en nuestra página de inicio.",
        "guidelines_17":"Los dos roles de usuario",
        "guidelines_18":"En nuestra plataforma, tenemos dos roles:",
        "guidelines_19":"Toda mujer o grupo (organización, institución, cooperativa, asociación, etc.) que busca ayuda en un proyecto privado o profesional es una solicitante de ayuda. Ejemplos de tales proyectos podrían ser:",
        "guidelines_20":"Cómo crear un presupuesto financiero para mi hogar / familia o para mi negocio",
        "guidelines_21":"Cómo prepararme mejor para mis futuros estudios como enfermera titulada:",
        "guidelines_22":"Redacción de un plan de negocios para nuestro programa de desarrollo comunitario.",
        "guidelines_23":"Las solicitantes de ayuda crean su proyecto en nuestra plataforma, describiendo lo que quieren lograr y en qué área necesitan ayuda. Luego pueden navegar a través de una lista de asesoras disponibles y solicitarles la ayuda.",
        "guidelines_24":"Las asesoras son el principal recurso de esta plataforma. Todas son voluntarias y comparten sus conocimientos y experiencia con las solicitantes de ayuda de forma gratuita. De igual manera que las solicitantes de ayuda, nuestras asesoras provienen de una amplia variedad de sectores / industrias y países. Las asesoras no son contratadas ni pagadas por nuestra organización, simplemente son usuarias de esta plataforma. Actúan de acuerdo con nuestro credo de asesorías (coaching) (ver punto 2)",
        "guidelines_25":"Uso gratuito del sitio web y protección de datos",
        "guidelines_26":"El equipo de from woman to woman (W2W) se compromete a proveerle los recursos y funcionalidades de nuestra plataforma en línea de forma gratuita. Además garantizamos que no transferimos ni vendemos ningún dato a terceros. Nuestros términos y condiciones explican detalladamente líneas abajo nuestra política sobre la protección de los datos y derechos de usuario",
        "guidelines_27":"Cómo se financia from woman to woman(W2W)",
        "guidelines_28":"Todos nuestros costos están cubiertos por personas que creen en nuestra misión y donan a nuestra causa. Si también desea donar, puede hacerlo en línea cuando se convierte en miembro de nuestra plataforma; esto no afectará su uso del sitio web y las funcionalidades disponibles para usted. Agradecemos sinceramente cada contribución. Cada centavo nos ayuda, muchas gracias!",
        "guidelines_29":"",
        "guidelines_30":"",
        "guidelines_31":"",
        "guidelines_32":"",
        "terms":"Términos&",
        "conditions":"Condiciones",
        "service_1":"Actualizado en Agosto de 2020 ",
        "service_2":"Nota: En caso de diferencias de traducción, prevalecerá la versión en inglés de estos Términos y condiciones, tal como estä publicada en nuestra página web..",
        "service_3":"Estos Términos y condiciones (los 'Términos') rigen el uso del sitio web y los servicios (los 'Servicios') de from woman to woman ('W2W'), una asociación sin fines de lucro registrada en Suiza, además de cualquier término complementario aplicable a cualquier característica, contenido y funcionalidad particulares de los Servicios, cuyos términos complementarios se incorporan por referencia en los Términos de uso. Al utilizar los Servicios, los Usuarios aceptan regirse por estos “Términos y Condiciones”, nuestra política de privacidad, todas las leyes aplicables y todas las condiciones o políticas a las que se hace referencia aquí (colectivamente, los 'Términos'). From woman to woman puede modificarlos Términos en cualquier momento mediante la publicación de una versión revisada de los Términos en nuestros Servicios. El acceso o uso continuo de los Servicios después de la fecha de vigencia de los Términos revisados constituye su aceptación de los Términos revisados.",
        "service_4":"Definiciones",
        "service_5":"En estos Términos, nos referimos a las solicitantes de ayuda como 'Propietarias de proyectos' y a sus iniciativas como 'Proyectos'. Nos referimos a aquellas que aportan conocimientos técnicos / experiencias y similares como 'Asesoras' y a los conocimientos técnicos / experiencias técnicos y similares que aportan como 'Contribuciones'. Las propietarias del proyecto, las asesoras y otras visitantes de los Servicios se denominan colectivamente 'Usuarios'.",
        "service_6":"From woman to woman (W2W) es una plataforma",
        "service_7":"From woman to woman (W2W) es una plataforma en línea para mujeres y organizaciones que buscan compartir conocimientos / experiencias y similares para desarrollar e implementar iniciativas / proyectos, ayudándose mutuamente a alcanzar su máximo potencial a través de sesiones de asesoría. ",
        "service_8":"From woman to woman (W2W) no se responsabiliza de la calidad, seguridad, moralidad o legalidad de ningún Proyecto o Contribución o de la veracidad o precisión de todo el Contenido del Usuario (como se define a continuación) publicado en los Servicios. From woman to woman (W2W) no respalda que los Proyectos entregarán los resultados descritos en la Descripción del Proyecto. Las usuarias utilizan los Servicios por su cuenta y riesgo. Las propietarias de proyectos siempre son responsables del resultado de sus propios proyectos. Los Servicios no incluyen la oferta o venta de valores o garantías.",
        "service_9":"Elegibilidad para utilizar los servicios",
        "service_10":"Los usuarios menores de 18 años no son elegibles para utilizar los Servicios. Los usuarios entre las edades de 14 y 17 pueden usar los Servicios con el consentimiento y la supervisión de un padre o tutor legal que tenga al menos 18 años de edad, siempre que dicho padre o tutor legal también acepte estar sujeto a los Términos y acepte ser responsable para dicho uso de los Servicios.",
        "service_11":"Las usuarias que sean suspendidas del uso de los Servicios no son elegibles para usar nuevamente los Servicios. From woman to woman (W2W) se reserva el derecho de rechazar el uso de los Servicios a cualquier persona y de rechazar, cancelar, interrumpir, eliminar o suspender cualquier Proyecto, Contribución o los Servicios en cualquier momento y por cualquier motivo sin responsabilidad",
        "service_12":"Campañas prohibidas",
        "service_13":"From woman to woman (W2W) no es una plataforma de recaudación de fondos: las propietarias de proyectos o las asesoras no pueden solicitar dinero u otros medios o recursos financieros, ya sea directa o indirectamente.",
        "service_14":"Además, las propietarias del proyecto no pueden crear un proyecto que solicite ayuda en forma de experiencia técnica, conocimiento (a través de asesoramiento) o similar que: pretende hacer lo imposible o es simplemente falso, en esos casos por favor no lo publique. Y si como asesora le resulta difícil apoyar un proyecto, no ofrezca asesoramiento para ello.",
        "service_15":"Las usuarias deben cumplir con todas las leyes y regulaciones aplicables en relación con sus Proyectos, incluida la oferta de ayuda técnica.",
        "service_16":"Las propietarias de proyectos no deberán realizar declaraciones falsas o engañosas en relación con sus proyectos. Las propietarias del proyecto no deben: (1) incluir contenido amenazante, abusivo, acosador, difamatorio, calumnioso o profano en ningún proyecto, o contenido que invada la privacidad de otra persona; (2) hacerse pasar por cualquier persona o entidad, incluido cualquier empleado o representante de from woman to woman(W2W), o (3) infringir cualquier patente, marca comercial, secreto comercial, derecho de autor, derecho de publicidad u otro derecho de cualquier otra persona o entidad, o violar cualquier ley o contrato",
        "service_17":"Las recompensas están prohibidas",
        "service_18":"Las usuarias no pueden ofrecer, solicitar ni aceptar recompensas de ninguna forma. Todas y cada una de las sesiones de asesoramiento (coaching), intercambio de experiencia / conocimientos, pericia y similares impartidas entre la asesora (la Coach) y la Propietaria del proyecto y acordadas a través de la plataforma from woman to woman(W2W) se ofrecerán, impartirán y recibirán de forma totalmente gratuita y sin ningún tipo de compensación.",
        "service_19":"Reglas de la Comunidad",
        "service_20":"Nota: En caso de diferencias de traducción, prevalecerá la versión en inglés de Reglas de la Comunidad, tal como estä publicada en nuestra página web.",
        "service_21":"From woman to woman (W2W) no es una plataforma para el odio, el abuso, la discriminación, la falta de respeto, las blasfemias, la mezquindad, el acoso o el spam. No haga uso de los Servicios para:",
        "service_22":"promover la violencia, degradación, subyugación, discriminación u odio contra personas o grupos por motivos de raza, origen étnico, religión, discapacidad, género, edad, condición de veterano, orientación sexual o identidad de género;",
        "service_23":"publicar imágenes, videos o comentarios / declaraciones que sean sexualmente explícitos o publicar enlaces a sitios que contengan material sexualmente explícito o que muestren personas o animales siendo lastimados o degradados;",
        "service_24":"enviar spam a cualquier parte de la plataforma from woman to woman(W2W) con ofertas de bienes y servicios no solicitados o no autorizados, publicidad, material promocional, correo basura, spam o cartas en cadena, o mensajes inapropiados; ",
        "service_25":"participar en cualquier actividad que interfiera o interrumpa el correcto funcionamiento de los Servicios o cualquier actividad realizada en los Servicios; o evitar medidas implementadas por from woman to woman(W2W) para utilizar o restringir el acceso al Servicio; ",
        "service_25_1":"ejecutar cualquier forma de respuesta automática o 'spam' en el Servicio; utilizar software, dispositivos u otros procesos manuales o automatizados para 'rastrear o 'infiltrar' cualquier página del Sitio; o emprender cualquier acción que imponga, a discreción exclusiva de from woman to woman(W2W), una carga irrazonable sobre la infraestructura del sitio  from woman to woman (W2W);",
        "service_25_2":"representar productos o iniciativas creados por otros como su propia creación, o actuar como revendedor de productos o iniciativas de otros;",
        "service_26":"",
        "service_27":"",
        "service_28":"From woman to woman (W2W) se reserva el derecho de eliminar Proyectos, en cualquier momento, y/o rescindir de las cuentas de usuaria para tales actividades, sin responsabilidad ni explicación.",
        "service_29":"Elementos visuales prohibidos en imágenes de proyectos",
        "service_30":"Los propietarios de proyectos no pueden incluir en las imágenes de sus proyectos y perfil de usuaria, los colores o elementos de marca (branding) de la plataforma (y la organización) from woman to woman (W2W).  Incluidos, entre otros, logotipos, marcos de colores, bordes o insignias. Esto incluye cualquier elemento visual que pueda interpretarse que ha sido producido por From woman to woman (W2W) , incluidos, entre otros, carteles, barras de progreso o estadísticas de financiación incluir cualquier elemento visual que comunique el respaldo de From woman to woman (W2W) o incluircualquierelemento visual que infrinja nuestras directrices en las secciones 'Campañas prohibidas' y 'Reglas de la comunidad'",
        "service_31":"Código de conducta de asesoramiento",
        "service_32":"From woman to woman (W2W) no se hace responsable de todos y cada uno de los consejos dados, o de los conocimientos, conocimientos técnicos, experiencias y similares compartidos u omitidos entre las asesoras (coaches) y las Propietarias de los proyectos. Las propietarias de proyectos son totalmente responsables del resultado de sus propios proyectos.",
        "service_33":"Porque queremos que el proceso de asesoría de usuarias sea exitoso y tenga un impacto positivo en los Proyectos, hemos establecido un Código de Conducta de asesoramiento que debe ser leído y acordado por todos nuestras asesoras (coaches). Es vinculante y, en caso de infracción, una asesora puede ser suspendida de nuestra Plataforma.",
        "service_33_1":"El asesoramiento (coaching) es un proceso de aprendizaje compartido. Al compartir su experiencia, conocimientos y una perspectiva externa imparcial, una asesora apoya y empodera a la persona que busca ayuda para comprender y definir un problema determinado y crear sus propias soluciones. En esta relación, la mujer que busca ayuda es siempre quien decide, actúa y lidera; ella es responsable del resultado de su proyecto. Una asesora nunca empuja a una persona que busca ayuda hacia una decisión o acción específica o decide por ella. Más bien, escucha atentamente, tiene una actitud positiva, hace preguntas, muestra un contexto más amplio, relata su propia experiencia y desafía a la persona asesorada a pensar más profundo / fuera de la caja. Si se da cuenta de que una asesora infringe este Código de conducta, comuníquese con nosotros de inmediato a través del formulario de contacto en nuestro sitio web.",
        "service_34":"Disputas entre propietarias de proyectos y asesoras",
        "service_35":"From woman to woman (W2W) no tiene la obligación de involucrarse en disputas entre las Propietarias y Asesoras del Proyecto, o los Usuarios y cualquier tercero. Además, From woman to woman (W2W) no tiene la obligación de involucrarse en disputas con respecto a la determinación del propietario legítimo del proyecto, y no estará obligada a realizar ningún cambio en las cuentas del propietario del proyecto o transferencia de propiedad. En el caso de cualquier disputa y a solicitud exclusiva de ambas Partes, la Propietaria del Proyecto y la Asesora, podemos proporcionar información de contacto a ambas partes para que ambas partes puedan resolver su disputa.",
        "service_36":"Cuentas de usuario",
        "service_37":"Toda la información suministrada en relación con una cuenta de Usuario en los Servicios (cada una de las cuentas, cada 'Cuenta de Usuario') debe ser precisa y veraz. Los usuarios acuerdan notificar a from woman to woman (W2W) inmediatamente si su cuenta de usuario se ha utilizado sin autorización o si ha habido cualquier otra violación de seguridad de la cuenta de usuario. Cada Usuario también acepta proporcionar información adicional a from woman to woman (W2W) que razonablemente pueda solicitar y a responder de manera veraz y completa cualquier pregunta from woman to woman (W2W) pueda hacerle para verificar la identidad de dicha Usuaria.",
        "service_38":"Licencia para usuarios y uso de marcasregistradasde from woman to woman (W2W)",
        "service_39":"From woman to woman (W2W) otorga a cada Usuario una licencia limitada, no exclusiva, intransferible y revocable para utilizar los Servicios, sujeto a la elegibilidad de dicho Usuario y al cumplimiento continuo de estos Términos. Con la excepción del Contenido del usuario (definido en el párrafo Contenido del usuario), todo el contenido disponible a través de la plataforma from woman to woman (W2W),como, entre otros, texto, gráficos, logotipos, iconos de botones, imágenes, clips de audio y software, es propiedad de from woman to woman (W2W) y está protegido por las leyes de propiedad intelectual suizas e internacionales.",
        "service_40":"La marca comercial from woman to woman® (W2W) es una marca registrada, propiedad de from woman to woman(W2W). Los usuarios tienen estrictamente prohibido registrar nombres de dominio, cuentas de redes sociales, nombres de usuario, identificadores, marcas comerciales, derechos de autor, aplicaciones, nombres comerciales o nombres corporativos que incorporen la marca comercial de from woman to woman® (W2W), o que sean confusamente similares a las marcas comerciales de from woman to woman® (W2W).",
        "service_41":"Tarifas de servicios ",
        "service_42":"La creación de una cuenta en los Servicios es gratuita y la plataforma y sus funcionalidades / uso son totalmente accesibles para todas las mujeres.",
        "service_43":"From woman to woman (W2W) se reserva el derecho de cambiar su estructura de tarifas en el futuro y abandonar, cambiar o introducir tarifas nuevas o existentes. Sin embargo, las membresías básicas siempre serán gratuitas y accesibles para todas las mujeres. From woman to woman (W2W) puede ofrecer funciones premium a los usuarios u organizaciones por tarifas adicionales, en cuyo caso se establecerán acuerdos separados sobre el cumplimiento de las condiciones acordadas entre las partes interesadas.",
        "service_44":"Los cambios en las Tarifas de servicio entran en vigencia una vez se ha publicado el aviso de los cambios en los Servicios.",
        "service_45":"Contenido del usuario",
        "service_46":"Mientras usan los Servicios, los Usuarios pueden publicar fotos, videos, texto, gráficos, logotipos, ilustraciones y otros materiales de audio o visuales (colectivamente, 'Contenido del Usuario').",
        "service_47":"Entre los Usuarios y from woman to woman (W2W), los Usuarios continúan teniendo todos los intereses de propiedad en su Contenido de Usuario. Cada Usuario declara y garantiza que su Contenido de Usuario y nuestro uso de dicho Contenido de Usuario no infringirá los derechos de propiedad intelectual, derechos de propiedad, derechos de privacidad, confidencialidad, derechos de publicidad de terceros ni violará estos Términos o la ley aplicable.",
        "service_48":"Promociones",
        "service_49":"Las Propietarias de Proyectos no pueden ofrecer ningún concurso, competencia, sorteo, sorteo o actividad similar (cada uno, una 'Promoción') en los Servicios.",
        "service_50":"Sitios web, anunciantes o servicios de terceros",
        "service_51":"Los Servicios pueden contener enlaces a sitios web, anunciantes o servicios de terceros que no son propiedad ni están controlados por from woman to woman (W2W). El acceso o el uso de sitios o servicios de terceros son por cuenta y riesgo de cada Usuaria. Salvo que se indique expresamente lo contrario en este documento, from woman to woman (W2W), no se hace responsable de ninguna pérdida o daño de ningún tipo relacionado con las relaciones dela Usuaria con estos sitios o servicios de terceros. ",
        "service_52":"Alentamos a las Usuarias a que sepan cuándo abandonan los Servicios y a leer los términos y la política de privacidad de cualquier sitio web o servicio de terceros que visiten.",
        "service_53":"Servicios de pago",
        "service_54":"Si bien el uso básico de nuestra plataforma es y siempre será gratuito para todas las Usuarias, es posible que se apliquen tarifas para las funciones avanzadas (Premium). Para liquidar dichas tarifas, las Usuarias que aceptan y utilizan PayPal en relación con un servicio premium ofrecido por from woman to woman (W2W) reconocen y aceptan cumplir con las Políticas de uso y financiación colectiva de PayPal.",
        "service_55":"Los servicios de procesamiento de pagos con tarjeta de crédito de from woman to woman (W2W) son proporcionados por Stripe, Inc. ('Stripe') y están sujetos al Acuerdo de Cuenta Conectada de Stripe, que incluye los Términos de Servicio de Stripe (colectivamente, el 'Acuerdo de Servicios de Stripe'). De esta manera usted acepta estar sujeto al Acuerdo de Servicios de Stripe, ya que Stripe puede modificar el mismo de vez en cuando. Como condición para permitir from woman to woman (W2W) los servicios de procesamiento de pagos con tarjeta de crédito a través de Stripe, usted acepta proporcionar a from woman to woman (W2W) información precisa y completa sobre usted y su organización, y autoriza a from woman to woman(W2W) a compartir dicha información con Stripe. De igual manera la información de transacciones relacionada con su uso de los servicios de procesamiento de pagos proporcionados por Stripe. En todos los casos, se aplican tarifas estándares de procesamiento de tarjetas de crédito u otros terceros además de las tarifas del Servicio.",
        "service_56":"No somos responsables del desempeño de ningún procesamiento de tarjetas de crédito de terceros o servicios de pago de terceros.",
        "service_57":"Disputas relacionadas con los servicios pagados",
        "service_58":"Las disputas entre los Usuarios y from woman to woman(W2W) en relación con un Servicio pago / servicio premium se resolverán entre las dos partes de manera amistosa, mediante arbitraje o, en última instancia, por un tribunal en la jurisdicción de from woman to woman(W2W).",
        "service_59":"Indemnidad",
        "service_60":"Cada Usuario acepta defender, indemnizar y eximir de responsabilidad a from woman to woman(W2W), sus subsidiarias y compañías afiliadas, y sus funcionarios, directores, miembros de la Junta, empleados, contratistas y agentes de y contra todas y cada una de las reclamaciones, causas de acción, daños, obligaciones, pérdidas, responsabilidades, costos o deudas y gastos (incluidos los honorarios y costos de abogados) y todos los montos pagados en el acuerdo que surjan o se relacionen con el uso de los Servicios, el incumplimiento de estos Términos o la violación de cualquier ley. From woman to woman(W2W) puede asumir la defensa y el control exclusivos de cualquier asunto por el cual los Usuarios hayan acordado indemnizara from woman to woman (W2W) y cada Usuario se compromete a asistir y cooperar con from woman to woman (W2W) en la defensa o solución de tales asuntos",
        "service_61":"Sin garantía ",
        "service_62":"From woman to woman (W2W) no tiene ningún deber fiduciario para con ningún Usuario. Los servicios se proporcionan 'tal cual' y 'según disponibilidad' sin ninguna garantía, expresa o implícita, incluidas, entre otras, las garantías implícitas de comerciabilidad o idoneidad para un propósito particular, no infracción o curso de ejecución. El uso de los Servicios es por cuenta y riesgo del Usuario.",
        "service_63":"Limitación de responsabilidad",
        "service_64":"From woman to woman (W2W) (incluidas sus afiliadas, directores, miembros de la junta, funcionarios, empleados, agentes, subcontratistas o otorgantes de licencias) no es responsable de ningún daño indirecto, punitivo, incidental, especial, consecuente o ejemplar o, en cualquier caso, de daños que excedan el monto de cien francos suizos (CHF 100,00) o los honorarios pagados de from woman to woman(W2W) por la transacción de la que surgió la causa de la acción. Esta sección de limitación de responsabilidad se aplica si la supuesta responsabilidad se basa en contrato, agravio, negligencia, responsabilidad estricta o cualquier otra base, incluso si from woman to woman (W2W) se le ha informado de la posibilidad de tal daño y en la mayor medida permitida por la ley en la jurisdicción aplicable",
        "service_65":"From woman to woman (W2W) no es responsable de la integridad o precisión del contenido del usuario (como, entre otros, descripciones del proyecto, identidad de los usuarios, resultados deseados del proyecto, antecedentes del proyecto, etc.). From woman to woman(W2W) no tiene la obligación de verificar la información del usuario proporcionada, pero puede hacerlo para jurisdicciones que no permiten que from woman to woman (W2W) limite su responsabilidad.",
        "service_66":"Sin perjuicio de cualquier disposición de los Términos, para los Usuarios en una jurisdicción que tenga disposiciones específicas de exención o responsabilidad que entren en conflicto con lo anterior, la responsabilidad de from woman to woman se limita a la menor medida posible por ley. Específicamente, en aquellas jurisdicciones con disposiciones específicas, from woman to woman (W2W) se exime de responsabilidad por: (a) muerte o lesiones personales causadas por su negligencia o la de cualquiera de sus funcionarios, miembros de la junta, empleados o agentes; o (b) tergiversación fraudulenta o mala conducta intencional; o (c) cualquier responsabilidad que no sea lícito excluir ahora o en el futuro.",
        "service_67":"Asignación ",
        "service_68":"Los Términos, y cualquier derecho y licencia otorgados en virtud del presente, no pueden ser transferidos o asignados por ningún Usuario, pero pueden ser asignados por from woman to woman (W2W) sin restricción o consentimiento.",
        "service_69":"Terminación; Supervivencia",
        "service_70":"La rescisión puede resultar en la pérdida y destrucción de toda la información asociada con cualquier Cuenta de Usuario. Los usuarios pueden cancelar su cuenta de usuario siguiendo las instrucciones de los servicios, pero from woman to woman (W2W) puede retener la información de la cuenta de usuario después de la terminación de acuerdo con los procedimientos de cumplimiento normativo, contable y legal. Todas las disposiciones de los Términos que por su naturaleza deberían sobrevivir a la terminación sobrevivirán a la terminación, incluidas, entre otras, las disposiciones de propiedad, las renuncias de garantía, la indemnización y las limitaciones de responsabilidad.",
        "service_71":"Legislación aplicable",
        "service_72":"Los usuarios aceptan que: (a) los Servicios se considerarán basados únicamente en Suiza (el lugar principal de negocios y la sede de from woman to woman (W2W)); y (b) los Servicios se considerarán pasivos que no dan lugar a jurisdicción personal sobre from woman to woman (W2W), ya sea específica o general, en jurisdicciones distintas de Suiza. Este Acuerdo se regirá por las leyes de Suiza, sin respetar los principios de conflicto de leyes.",
        "service_73":"Arbitraje ",
        "service_74":"From woman to woman (W2W) y el Usuario aceptan que todas y cada una de las disputas o reclamos se resolverán exclusivamente a través de un arbitraje final y vinculante, en lugar de en un tribunal, excepto que un Usuario a su elección puede presentar reclamos en un tribunal de reclamos menores, si los reclamos del Usuario califican de otra manera para la adjudicación en ese tribunal.",
        "service_75":"From woman to woman (W2W) y el Usuario manifiestan su consentimiento para arbitrar mediante la prestación y utilización de los Servicios. Las reclamaciones arbitrales son aquellas que from woman to woman(W2W) hacenvaler contra el Usuario, y que el Usuario hace valer contra from woman to woman (W2W), cualquier entidad relacionada o afiliada, y los funcionarios, directores, consejeros, agentes o empleados de cualquiera de ellos. En la medida máxima permitida por la ley, las Partes acuerdan arbitrar las reclamaciones de forma individual únicamente, y renuncian a cualquier derecho a iniciar, participar o recuperarse en virtud de una acción colectiva, colectiva, consolidada o representativa. El árbitro aplicará los Términos y la misma ley sustantiva a la disputa como lo haría un tribunal, y la misma ley de recursos. Para iniciar un procedimiento de arbitraje en contra de from woman to woman (W2W) o una parte relacionada, un Usuario debe enviar una carta solicitando el arbitraje y describiendo el reclamo a la sede de from woman to woman (W2W)",
        "service_76":"Descargos de responsabilidad",
        "service_77":"Las propietarias de proyectos y otros usuarios deben ser conscientes de que muchos países imponen leyes de solicitud de fondos benéficos para guiar las actividades de solicitud de fondos directa o indirecta en esos países. Si bien las disposiciones varían de un país a otro, estas leyes nacionales generalmente incluyen requisitos de registro y presentación de informes. From woman to woman (W2W) simplemente proporciona una plataforma tecnológica que permite a las propietarias de proyectos conectarse con las asesoras. No somos una plataforma de recaudación de fondos y los Usuarios no solicitarán medios económicos directos o indirectos u otros recursos, ya sea de otros Usuarios o de from woman to woman (W2W). Los usuarios que acceden o utilizan los Servicios lo hacen por su propia voluntad y son totalmente responsables del cumplimiento de la ley aplicable.",
        "service_78":"Transferencia de datos",
        "service_79":"Como principio, no transferimos ningún dato de Usuario a terceros, excepto los datos necesarios para el procesamiento y manejo de pagos. Tiene derecho en cualquier momento, y sin costo alguno, a obtener información sobre sus datos almacenados y a corregir, bloquear o eliminar sus datos almacenados. Para ello, póngase en contacto con nosotros a través de nuestro formulario de contacto en el sitio web.",
        "service_80":"Estadísticas anónimas de datos",
        "service_81":"Al usar los Servicios, los Usuarios aceptan que todos y cada uno de sus datos ingresados y generados a través del uso de los Servicios pueden ser utilizados por from woman to woman (W2W) de forma anónima con el fin de crear estadísticas que muestren las frecuencias de los Usuarios y el impacto positivo de nuestros Servicios y plataforma.",
        "service_82":"La protección de sus datos y la declaración de privacidad",
        "service_83":"Según el artículo 13 de la Constitución federal suiza y las disposiciones de la ley suiza de protección de datos, toda persona tiene derecho a la protección de la privacidad, así como a la protección contra el uso indebido de sus datos personales. Cumplimos estrictamente con estas regulaciones. Los datos personales se mantienen estrictamente confidenciales y no se divulgarán, cederán, venderán ni cederán a terceros. En estrecha cooperación con nuestros proveedores de alojamiento, hacemos todo lo posible para proteger nuestras bases de datos del acceso externo, la pérdida de datos, el uso indebido o la falsificación.",
        "service_84":"Al acceder a nuestros sitios web, los siguientes datos se guardan en archivos de registro: dirección IP, fecha, hora, solicitud del navegador e información generalmente transmitida sobre el sistema operativo y el navegador. Estos parámetros de datos de usuario son la base para análisis estadísticos y anónimos que nos ayudan a reconocer tendencias para mejorar aún más nuestra oferta de productos. Además, cumplimos con el Reglamento general de protección de datos de la UE (RGPD UE). En cualquier momento, un Usuario puede a) acceder a sus datos, b) corregir errores en sus datos personales, c) borrar sus datos personales, d) oponerse al procesamiento de sus datos personales, y e) exportar sus datos personales. Además, f) en caso de violación de privacidad, todos los usuarios serán informados de inmediato, g) tomamos todas las medidas necesarias y razonables para proteger los datos del usuario, y h) hemos designado y capacitado a un Oficial de Protección de Datos (uno de los miembros de nuestra Junta), que es el punto de contacto para todas las consultas relacionadas con la protección de datos; puede utilizar nuestro formulario de contacto en el sitio web para ponerse en contacto con nuestro Oficial de Protección de Datos (DPO)",
        "service_85":"Prevalencia de la versión en inglés",
        "service_86":"En caso de conflicto entre la versión en inglés de los Términos y cualquier versión de traducción en idiomas extranjeros de los mismos, la versión en inglés de los Términos regirá y prevalecerá. Todas las disputas, reclamaciones y causas de acción (y procedimientos relacionados) se comunicarán en inglés.",
        "service_87":"Cooperación con las autoridades y la policía",
        "service_88":"Cooperaremos con las autoridades policiales según lo requiera la ley. Cooperaremos con los organismos encargados de hacer cumplir la ley en cualquier investigación de presunta actividad ilegal con respecto al uso de los Servicios cuando se solicite.",
        "service_89":"Procedimientos de notificación",
        "service_90":"Los usuarios acuerdan que from woman to woman (W2W) puede proporcionar notificaciones a dicho Usuario por correo electrónico, notificación escrita o impresa, o mediante la publicación visible de dicha notificación en nuestro sitio web.",
        "service_91":"Los usuarios pueden optar por no recibir determinados medios de notificación o recibir determinadas notificaciones.",
        "service_92":"Envíos de ideas no solicitados",
        "service_93":"Siempre nos complace saber de nuestros usuarios y agradecemos sus comentarios o sugerencias. Sin embargo, los productos, servicios y características desarrollados por from woman to woman (W2W) o sus Usuariospueden ser similares o incluso idénticos a un envío recibido por from woman to woman (W2W) o sus Usuarios. Cuando nos referimos a un 'envío' en este párrafo, nos referimos a: cualquier envío, comentario o sugerencia (incluidos, entre otros, ideas, productos o servicios y cambios sugeridos) realizados en la plataforma de from woman to woman (W2W) o a from woman to woman (W2W) sobre un producto o servicio existente en, o una característica de, o una adición propuesta a, la plataforma from woman to woman (W2W).",
        "service_94":"Con respecto a los envíos/contribuciones de los usuarios en la plataforma",
        "service_95":"Todas estas envíos/contribuciones en la plataforma son no confidenciales y no propietarias y serán tratadas como no confidenciales y no propietarias.",
        "service_96":"From woman to woman (W2W) y sus Usuarios no tendrán obligación o responsabilidad expresa o implícita de ningún tipo con respecto a los envíos, incluido, por ejemplo, cualquier uso o divulgación de los envíos; y From woman to woman(W2W) y sus Usuarios tienen derecho al uso o divulgación sin restricciones de los envíos para cualquier propósito, todo sin compensación para el Usuario que hizo el envío.",
        "service_97":"Acuerdo completo / divisibilidad",
        "service_98":"Estos Términos son el acuerdo completo entre cadaUsuario y from woman to woman (W2W) con respecto al uso de la plataforma. Si alguna disposición de estos Términos es considerada inválida por un tribunal de jurisdicción competente, la invalidez de dicha disposición no afectará la validez de las disposiciones restantes de este Acuerdo, que permanecerán en pleno vigor y efecto. El hecho de que from woman to woman (W2W) no haya hecho valer cualquier derecho o disposición en virtud del presente Acuerdo no constituirá una renuncia a dicho derecho o disposición.",
        "service_99":"Cambios en los términos",
        "service_100":"From woman to woman (W2W) puede modificar los Términos en cualquier momento, así que revíselos con frecuencia. Si se realiza un cambio material, from woman to woman (W2W) podrá notificar a los Usuarios en los Servicios, por correo electrónico, mediante un aviso en los Servicios, o en otros lugares que consideremos oportunos. Un 'cambio material' se determinará a discreción de from woman to woman(W2W), de buena fe y utilizando el sentido común y el juicio razonable.",
        "service_101":"Google Analytics",
        "service_102":"Este sitio web utiliza Google Analytics, un servicio de análisis web proporcionado por Google, Inc. ('Google'). Google Analytics utiliza las llamadas 'cookies', que son archivos de texto que se colocan en su computadora para ayudar al sitio web a analizar cómo los usuarios usan el sitio. La información generada por las cookies sobre su uso del sitio web (incluida su dirección IP) será transmitida y almacenada por Google en servidores en los Estados Unidos. En caso de activación de la anonimización de IP, Google truncará / anonimizará el último octeto de la dirección IP para los Estados miembros de la Unión Europea, así como para otras partes del Acuerdo sobre el Espacio Económico Europeo. Solo en casos excepcionales, los servidores de Google en EE. UU. envían y acortan la dirección IP completa. Google utilizará esta información con el fin de evaluar su uso del sitio web, compilar informes sobre la actividad del sitio web para los operadores del sitio web y proporcionar otros servicios relacionados con la actividad del sitio web y el uso de Internet. Google también puede transferir esta información a terceros cuando así lo requiera la ley, o cuando dichos terceros procesen la información en nombre de Google. Google no asociará su dirección IP con ningún otro dato que tenga Google. Puede rechazar el uso de cookies seleccionando la configuración adecuada en su navegador; sin embargo, tenga en cuenta que si lo hace, es posible que no pueda utilizar todas las funciones de este sitio web. Al utilizar nuestro sitio web y nuestros Servicios, usted da su consentimiento para que Google procese sus datos de la manera y para los fines establecidos anteriormente.",
        "service_103":"",
        "service_104":"",
    }
]
export const lang_pt = [
    {
      "contact_noti": "Obrigada por sua mensagem. Responderemos em breve.",
      "menu1": "Página de Início",
      "menu2": "Como funciona",
      "how_it": "Como",
      "works": "funciona",
      "menu3": "Testemunhos",
      "menu4": "Nossa Equipe",
      "menu5": "Perguntas Frequentes",
      "menu6": "Fale conosco", 
      "menu7": "Resources",
      "resources": "Resources",      
      "organization": "organização",
      "reg_menu": "Seja membro (torne-se um membro)",
      "login_menu": "Check-in (Login)",
      "slider1_text1": "Precisa de consultoria para um",
      "slider1_text1_2": "projeto pessoal ou profissional?",
      "slider1_text2": "Envie a descrição do seu projeto e encontre um consultor gratuitamente.",
      "slider2_text1": "Você é um especialista em",
      "slider2_text1_2": "um determinado campo?",
      "slider2_text2": "Cadastre-se e compartilhe seu conhecimento",
      "slider2_text2_2": "com mulheres que precisam da sua experiência.",
      "slider3_text1": "Nossa missão é simples.",
      "slider3_text2": "Proporcionar uma plataforma global onde as mulheres",
      "slider3_text2_2": "se conectem de forma gratuita com assessoras de diversas áreas.",
      "About_us": "Sobre nós",
      "about1": "Nossa plataforma conecta as mulheres que precisam de consultoria para melhorar as suas habilidades a mulheres que têm experiência e conhecimento em uma ampla gama de campos específicos e estão dispostas a aconselhar outras mulheres gratuitamente.",
      "about2": "Um projeto criado por uma mulher buscando consultoria, pode envolver, por exemplo:",
      "about3": "Preparação para seus futuros estudos ou escolhas sobre sua futura carreira.",
      "about4": "Melhorar suas habilidades na criação de planos de negócios ou cálculos de orçamentos.",
      "about5": "Obter informações valiosas sobre como equilibrar a vida pessoal e profissional.",
      "about6": "Você é uma mulher que gostaria de se beneficiar da experiência de um consultor em nossa plataforma? Ou você é um especialista em determinada área, disposto a doar seu conhecimento e tempo para outras mulheres?",
      "about7": "Então, se sua resposta foi sim, convidamos você a se cadastrar em nossa plataforma! Crie um usuário e envie seu pedido para se conectar com um consultor que agendará sessões de consultoria com você. A assessoria é totalmente gratuita e permanecerá assim.",
      "about8": "Após a interessada em ajuda e o consultor concordarem com o projeto, podem usar o nosso chat para combinar o número de sessões a serem realizadas e o melhor meio, por exemplo: Skype, WhatsApp, Zoom, etc.",
      "success": "Artigos:",
      "stories": "Artigo 1",
      "art1_1": "Magnifica Warmi",
      "art1_2": "Capacitar mulheres bolivianas para alavancar a sustentabilidade em suas comunidades.",
      "art_modal1_1": "Magnifica Warmi: Empoderando as mulheres para promover a sustentabilidade em suas comunidades.",
      "art_modal1_2": "Magnifica Warmi é um projeto socioambiental que visa capacitar mulheres de áreas rurais no município de Sucre, na Bolívia, como embaixadoras e promotoras de práticas ambientalmente sustentáveis em suas comunidades. O objetivo principal das oficinas ministradas pela Magnifica Warmi é capacitar as mulheres a compreender o valor de seu papel como mãe, esposa, filha e líder na tomada de decisões, para alcançar a sustentabilidade em suas comunidades. Técnicas de gerenciamento e reciclagem de residuos sólidos fazem parte do conteúdo do treinamento.",
      "art_modal1_3": "Em 2019, uma consultora da plataforma Woman to Woman (W2W) conduziu 10 sessões de aconselhamento online para as jovens integrantes do projeto. O objetivo era receber orientações sobre como establecer uma metodología estruturada para as oficinas. Após a conclusão bem-sucedida das sessões de treinamento, Magnifica Warmi conseguiu assessorar 200 mulheres rurais que vivem em áreas vulneráveis à poluição, beneficiando mais de 500 famílias.",
      "art_modal1_4": "A equipe da Magnifica Warmi resume a sua experiência com a nossa consultoria: “As sessões de treinamento facilitadas por um consultor na plataforma W2W, foram cruciais para desenhar as oficinas e a respectiva metodologia. No início do nosso projeto, não sabíamos como começar, nem o que deveríamos fazer, mas com os treinamentos aprendemos muitas lições técnicas que nos ajudaram a melhorar o conceito que temos de nossos workshops. Agradecemos muito nossa consultora que acreditou em nossa ideia e nos ajudou a fazer isso acontecer!”",
      "art_modal1_5": "Encontre mais informações sobre o trabalho deste magnífico grupo em:",
      "art_modal1_6": "https://tubecabolivia.com/magnifica-warmi/",
      "art_modal1_7": "Artigo publicado em 7 de setembro de 2020",
      "art2_1": "Eu sou como você! Agentes de inclusão",
      "art2_2": "Preparando alunos e profesores para serem agentes de mudança que promovem a inclusão educacional e social de pessoas com deficiência",
      "art_modal2_1": "Eu sou como você! Agentes de inclusão: uma comunidade que está mudando a perspectiva em relação às pessoas com deficiência.",
      "art_modal2_2": "Eu sou como você! Agentes pela inclusão é uma comunidade liderada por pessoas com e sem deficiência que promove a inclusão de pessoas com deficiência no sistema educacional de La Paz, Bolívia. Suas atividades se concentram na mudança de atitudes negativas e no aumento do conhecimento sobre como interagir com pessoas com deficiência. Conseguem isso por meio de atividades e workshops de sensibilização, realizados no ámbito do programa “Conscientização para o inclusão das Pessoas com Deficiência”.",
      "art_modal2_3": "Em 2020, um consultor da plataforma Woman to Womam (W2W) conduziu 10 sessões de consultoria online com a equipe Eu sou como você!, para ajudá-los a organizar seu programa “Conscientização para a inclusão das Pessoas com Deficiência” nas escolas de La Paz. O desenvolvimento de um plano de trabalho, de orçamento, o desenvolvimento de alianças estratégicas e o aconselhamento na adaptação do seu trabalho ao formato digital (período durante a pandemia do COVID 19), foram os temas debatidos com o grupo.",
      "art_modal2_4": "No outono de 2020, a equipe Eu sou como você!, realizou a primeira oficina de seu programa para alunos e professores de uma escola em La Paz. A equipe pretende chegar a 50 pessoas, entre alunos e professores, para capacitá-los como agentes de mudança compartilhando conhecimentos sobre a inclusão social de pessoas com deficiência no meio acadêmico.",
      "art_modal2_5": "O Eu sou como você! resumiu sua experiência em consultorias nas seguintes linhas:",
      "art_modal2_6": "“Nossa orientadora da plataforma Woman to Woman (W2W), faz parte da Eu sou como você! Ela tem estado conosco em todas as etapas do caminho. Já nos perdemos muitas vezes, mas nossa consultora sempre nos apoiou e caminhou conosco, destacando aspectos importantes do projeto. Somos muito gratos e abençoados por esta plataforma e compartilhamos uma visão: vamos fazer do mundo um lugar melhor e criar um impacto positivo. Sabemos que não estamos sós e que sempre teremos o apoio de nossa consultora!”",
      "art_modal2_7": "Você pode visitar e ler mais sobre este projeto incrível: https://www.facebook.com/SOYCOMOTUAgentesporlaInclusion",
      "art3_1": "Lançamento da nova plataforma de Woman to Woman (W2W)",
      "art3_2": "Uma plataforma de consultoria mais intuitiva e ágil que nos ajudará a cumprir nossa missão!",
      "art_modal3_3": "Lançamento da nova plataforma de Woman to Woman (W2W)",
      "art_modal3_4": "Desde o final de 2017, a organização suiça sem fins lucrativos tem administrado e mantido uma plataforma de consultoria online. Nesta plataforma, mulheres que precisam de aconselhamento para melhorar suas habilidades, estão conectadas com consultores – mulheres que tem experiência e conhecimento técnico em uma ampla gama de determinados campos e estão dispostas a aconselhar outras mulheres gratuitamente.",
      "art_modal3_5": "Depois de analizar detalhadamente o feedback obtido da plataforma de usuários e de nosso Conselho de Administração, from woman to woman redefiniu as características essenciais da plataforma e implementou uma nova versão do mais intuitiva e mais clean.",
      "art_modal3_6": "Sandra Leicht-Cabrera, CEO da from woman to woman, não poderia estar mais feliz com o resultado: “A nova plataforma de consultoria é gratuida e fácil de usar. Seu principal valor agregado é a nossa rede de excelentes consultores com experiência em diversos campos. E agora está mais acessível para mulheres que procuram a consultoria de um especialista. Isso levará a muitas colaborações frutíferas, com instituições que promovam o consultoria para mulheres em todo o mundo e ajudará a cumprir nossa missão: promover além das fronteras, o intercâmbio de conhecimento e experiência entre as mulheres”.",
      "art_modal3_7": "A nova plataforma estará inicialmente em inglês e espanhol. Procuramos voluntários que possam traduzir a página para mais idiomas. Interessado? Gostaríamos muito de ouvir mais de você através do nosso formulário de contato na página principal.",
      "our": "Nossa",
      "team": "equipe",
      "team1_1": "Sandra Leicht",
      "team1_2": "Especialista em padrões de sustentabilidade e cadeias de valor.",
      "team1_3": "Sede na Suíça",
      "team1_4": "Desde cedo, estive envolvida em projetos sociais na Bolivia, meu país de origem. Muitas mulheres têm ideias brilhantes para ajudar a si mesmas e as suas comunidades. from woman to woman (W2W) é um ponto de encontro de mulheres e também de consultoras que querem ajudar mas não sabem como.s não sabem por onde começar. Minha visão é que, por meio de nossa plataforma, possamos contribuir de forma concreta e mensurável com os objetivos de sustentabilidade da Organização das Nações Unidas.",
      "team1_5": "Diretora Executiva",
      "team2_1": "Damaris Diethelm",
      "team2_2": "Pedagoga Social",
      "team2_3": "Sede na Suíça",
      "team2_4": "Com minha participação em from woman to woman (W2W), quero garantir que as mulheres em todo o mundo possam ter acesso ao conhecimento umas das outras sem barreiras que poderiam enfrentar ao seu redor. Eu uso minha rede profissional de trabalho social para motivar mulheres a compartilhar seus conhecimentos gratuitamente com outras mulheres, cadastrando-se como consultoras em nossa plataforma.",
      "team2_5": "Presidente do conselho de diretores",
      "team3_1": "Dr. Tshilidzi Van der Lecq",
      "team3_2": "Oftalmologista",
      "team3_3": "Sede na África do Sul",
      "team3_4": "Sou uma africana da África do Sul, esposa e mãe. Atualmente trabalho como consultora oftalmologista baseada na Universidade do Cabo. Meu país tem uma longa história de mulheres superando desafios para conseguir mudanças para si mesmas e para suas comunidades. Através da minha participação com from woman to woman, gostaria de fazer parte dessa mudança.",
      "team3_5": "Membro do Conselho",
      "team4_1": "Monica Wilson",
      "team4_2": "Especialista em Segunça Cibernética",
      "team4_3": "Sede nos EUA",
      "team4_4": "Meu desejo de ajudar as mulheres está ligado ao desejo de eliminar a pobreza e o sofrimento das crianças. Não deveria existir crianças que não tivessem suas necessidades atendidas. Se ajudarmos as mulheres, como propomos na from woman to woman, elas terão os meios para criar seus filhos com dignidade.",
      "team4_5": "Membro do Conselho",
      "team5_1": "David Leicht",
      "team5_2": "Consultor de capital Humano e Conselheiro/coach",
      "team5_3": "Sede na Suíça",
      "team5_4": "Acredito firmemente no uso do conhecimento como um recurso que não conhece fronteiras de autoajuda: as mulheres em todo o mundo têm uma quantidade imensa de conhecimentos que gostariam de compartilhar com outras mulheres. from woman to woman oferece uma ferramenta gratuita para mulheres em busca de conhecimento. Estou honrado em poder dar uma pequena contribuição nesta iniciativa de transferência de conhecimento para ajudar mulheres que precisam.",
      "team5_5": "Membro do Conselho",
      "faq_heading": "Dúvidas",
      "faq_heading1": "frequentes",
      "faq_1": "Estou procurando consultoria/treinamento. Por que devo me inscrever na plataforma from woman to woman?",
      "faq_1_1": "Porque contamos com uma grande rede de consultores com vasta experiência em diversos países. Você pode se beneficiar de suas experiências e conhecimentos gratuitamente.",
      "faq_2": "Eu sou um especialista na minha área, por que devo me tornar um consultor?",
      "faq_2_1": "Porque muitas mulheres ao redor do mundo podem se beneficiar de seu conhecimento/experiência. Damos a você a oportunidades de ajudar outras mulheres, enquanto você pode estar em casa, no trabalho ou em qualquer outro lugar, e você decide quantos projetos de consultoria pode realizar em seu tempo disponível.",
      "faq_3": "Como criar uma conta de usuário?",
      "faq_3_1": "Tanto os consultores, quanto aquelas que buscam ajuda, podem criar uma conta de usuário clicando no botão “Cadastrar” na parte superior da página.",
      "faq_4": "Qual é a motivação para criar a plataforma Woman to Woman?",
      "faq_4_1": "É muito simples: muitas mulheres enfrentam obstáculos qua as impedem de acessar o conhecimento/consultoria técnica. Por exemplo, podem apresentar dificuldades financeiras para  matricular-se em uma escola ou ter dificuldades para ir até a instituições de ensino (custo da viagem, transporte público inseguro, horários, etc.) ou ainda, ter compromissos familiares que as impeçam de frequentar a escola regularmente. Residir em um local onde simplesmente não existam programas de consultoria adequado ou ainda por existirem obstáculos sociais que limitam suas habilidades.",
      "faq_4_2": "Felizmente a internet criou novas posibilidades na transferência de conhecimento /experiência que usamos ao combinar quem procura por ajuda com os consultores especializados por meio de nossa plataforma. Essa troca de conhecimento e experiência quebra barreiras geográficas, culturais ou financeiras. O conhecimento que é transferido para aqueles que procuram ajuda auxilia a moldar projetos pessoais ou profissionais bem-sucedidos e  sustentáveis. As consultoras, em troca, usam seus conhecimentos /experiências para melhorar as condições de outras mulheres.",
      "faq_4_3": "Por meio da facilitação de transferência de conhecimento, queremos contribuir para melhorar a situação das mulheres em todo o mundo e para os Objetivos de Desenvolvimento Sustentáveis da ONU. Leia regularmente nossos depoimentos e histórias de sucesso para ver a diferença que fazemos.",
      "faq_5": "As sessões de aconselhamento acontecem nesta plataforma?",
      "faq_5_1": "Não. Aquí, conectamos quem busca ajuda com consultores. Uma vez conectados, poderão usar nossa função de bate-papo para combinar quantas sessões acontecerão e qual o meio utilizado, por exemplo: Skype, WhatsApp, Zomm, etc. O mais adequado para ambos.",
      "faq_6": "O consultor me dirá como fazer o projeto e qual decisão tomar?",
      "faq_6_1": "Realmente não! Os consultores em nossa plataforma, estão comprometidos em agir de acordo com o que acreditamos:",
      "faq_6_2": "“A consultoria é um processo de aprendizagem compartilhada. Ao compartilhar sua experiência, o consultor tem uma visão externa e imparcial e assim apoia e orienta na busca do entendimento de um problema específico e criar as próprias soluções.",
      "faq_6_3": "Nessa relação, a mulher que busca ajuda é sempre quem decide, atua e lidera. Ela é responsável pelo resultado de seu projeto.",
      "faq_6_4": "Um consultor nunca pressiona uma mulher para a decisão ou ação especifica ou decide por ela. Em vez disso, ele ouve com atenção, tem uma atitude positiva, faz perguntas, mostra um contexto mais amplo, relata sua própria experiência e desafia  quem busca ajuda a pensar de forma innovadora.",
      "faq_6_5": "Se você acredita que seu consultor não esta agindo de acordo com esta visão, não hesite em nos contatar imediatamente através do formulário de contato.",
      "faq_7": "O uso da plataforma tem algum custo?",
      "faq_7_1": "Não. Nossa plataforma é gratuita. Se você deseja apoiar nosso trabalho contribuindo com os custos de funcionamento de nossa plataforma, pode fazer uma doação. No entanto, isso não mudará o acesso às funcionalidades da plataforma.",
      "faq_8": "Posso usar a plataforma para arrecadar fundos ou criar publicidade para meu projeto?",
      "faq_8_1": "Não. Esta é uma violação dos nossos termos. Nossa plataforma foi pensada para consultoria pessoal. Não existe nenhuma forma de troca ou coleta de dinheiro.",
      "faq_9": "Em que área eu posso receber consultoria através da plataforma?",
      "faq_9_1": "Através da nossa plataforma, as mulheres poderão receber consultoria gratuita para seus projetos pessoais ou profissionais. Não aceitamos os projetos que tenham a ver com",
      "faq_9_2": "atividades sexualemente explícitas, prostituição, pornografía e similares.",
      "faq_9_3": "Violência e comportamento destrutivo contra humanos, animais ou natureza.",
      "faq_9_4": "Fins militares, crime organizado, terrorismo e semelhantes.",
      "faq_9_5": "Armas, munições e itens semelhantes, incluido acessórios.",
      "faq_9_6": "Arrecadação de fundos, esquemas financeiros, apostas, loteria, jogos de azar e outros.",
      "faq_9_7": "Substâncias controladas, drogas e similares.",
      "faq_9_8": "Restos humanos ou de animais.",
      "faq_9_9": "Toda e qualquer atividade ilícita no país de domicilio da responsável pelo projeto, do consultor ou da sede da from woman to woman.",
      "faq_9_10": "Outras atividades antiéticas ou imorais consideradas pela equipe Woman to Woman, incluíndo iniciativas que conflitam com direitos humanos.",
      "faq_9_11": "Essa lista não é detalhada. Se você acredita que um usuário (consultor ou solicitante de ajuda) ou projeto viola nossas “Regras da Comunidade”, por favor, não hesite em nos contatar imediatamente através do formulário de contato.",
      "faq_9_12": "Consulte também nossos “Termos e condições” para obter informações mais detalhadas sobre a utilização da nossa plataforma.",
      "faq_10": "Meus pedidos de consultoria podem ser visualizados por outras pessoas? Como é a  proteção da minha privacidade?",
      "faq_10_1": "Sim, outras pessoas podem ver seus pedidos de consultoría. Somente dessa forma os consultores podem ver se eles têm o conhecimento necessário para atender sua solicitação e se conectar com você. Recomendamos que você faça solicitações detalhadas de ajuda, mas não inclua nenhuma informação pessoal. Por exemplo:",
      "faq_10_2": "“Preciso de orientação sobre como escolher minha carreira profissional.”",
      "faq_10_3": "“Preciso de concultoria para criar um orçamento para uma organização sem fins lucrativos.”",
      "faq_10_4": "“Quero melhorar minhas habilidades de apresentação”.",
      "faq_10_5": "Os detalhes da sua solicitação são discutidos apenas com o consultor. Nas sessões agendadas pela plataforma.",
      "faq_10_6": "from woman to woman, não transfere e nem vende – e nem o fará – dados de usuários para terceiros.",
      "faq_11": "Quem são os consultores? Como posso ter certeza que um consultor é realmente um especialista na área?",
      "faq_11_1": "Todas as mulheres possuem conhecimentos em suas áreas específicas, por tanto, qualquer mulher pode ser uma consultora em nossa plataforma.",
      "faq_11_2": "Você pode consultar a página de perfil de um consultor para ver mais detalhes sobre sua experiência e conhecimento. E por meio de suas sessões de consultoria, você verá o quão competente é o seu consultor. Você também poderá perguntar diretamente a ele, se precisar de mais esclarecimentos. Você também pode ver como outros solicitantes classificam um determinado consultor por meio de suas avaliações (Avaliação com estrelas).",
      "faq_12": "Posso responsabilizar um consultor por um mal conselho?",
      "faq_12_1": "Segundo nossas regras de consultoria:",
      "faq_12_2": "Um consultor não deve forçar uma decisão ou ação. O responsável por uma ação é sempre a pessoa solicitante da consultoría.",
      "faq_12_3": "Somente o responsável pelo projeto conhece o contexto em que está inserido. É por isso que cada decisão tomada é de total responsabilidade de quem solicitou e não do consultor.",
      "faq_13": "Minha empresa incentiva as funcionárias a se voluntariarem. Como podemos fazer da Woman to Woman parte da nossa estratégia de sustentabilidade e igualdade de gênero?",
      "faq_13_1": "Você é uma empresa que deseja investir no empoderamento femenino?",
      "faq_13_2": "from woman to woman é o lugar ideal para você. Sua equipe feminina pode se cadastrar como consultora. Podemos contabilizar as horas de consultoria prestada por sua equipe e emitir relatórios de sustentabilidade e responsabilidade corporativa.",
      "faq_13_3": "Entre em conato conosco através do nosso formulário de contato e nosso Gerente de Sustentabilidade entrará em contato com você para avaliar suas necessidades específicas.",
      "contact_us": "Fale Conosco",
      "contact_1": "Entre em contato conosco",
      "contact_2": "Deixe uma mensagem e entraremos em contato em breve.",
      "contact_3": "Nome",
      "contact_4": "e-mail",
      "contact_5": "Assunto",
      "contact_6": "Mensagem",
      "contact_7": "Enviar",
      "footer_1": "from woman to woman é uma organização suíça sem fins lucrativos. Nosso objetivo é promover a troca de conhecimentos e experiências entre mulheres. Fazemos isso fornecendo uma plataforma online que pode ser acessada gratuitamente em todo o mundo. Aqui, as mulheres são aconselhadas por outras mulheres, e esse intercâmbio sem fronteiras promove empreendimentos pessoais e profissionais, programas de desenvolvímento comunitário bem-sucedido, sustentáveis e muito mais.",
      "social_media": "Redes Sociais",
      "footer_2": "Vamos divulgar sobre como o compartilhamento colabotativo do conhecimento está fazendo a diferença entre as mulheres no mundo. Siga-nos e compartilhe sua experiência conosco!",
      "copyright": "© Copyright 2020",
      "verify_msg": "Seu e-mail foi verificado e agora você poderá iniciar a sessão e começar a utilizar nossa plataforma.",
      "congrats": "Estamos muito felizes!",
      "register": "Cadastro",
      "first_name": "Nome",
      "last_name": "Sobrenome",
      "pass": "Senha",
      "conf_pass": "Confirmação da senha",
      "country": "País",
      "choose_country": "Escolher país",
      "languages": "Idioma",
      "agree_text": "Concordo com os termos de uso da from woman to woman",
      "community_rules": "Regras da comunidade W2W",
      "and": "e",
      "terms_cond": "Termos e condições",
      "our_community_rules": "Regras da comunidade",
      "terms_conditions": "Termos e condições",
      "login": "Iniciar",
      "login_heading": "Iniciar a sessão",
      "email_address": "Correio eletrônico",
      "frgt_pass": "Você esqueceu sua senha?",
      "not_member": "Não é membro ainda? Criar uma nova conta.",
      "logout": "Sair",
      "usermenu_1": "Perfil",
      "usermenu_2": "Editar Perfil",
      "usermenu_3": "Trocar Senha",
      "usermenu_4": "Meus projetos",
      "usermenu_5": "Enviar doação",
      "usermenu_6": "Projetos que necessitam de uma assessoria",
      "view_profile_heading": "Perfil",
      "view_profile_1": "Comentários",
      "view_profile_2": "Qualificação",
      "view_profile_3": "Idiomas",
      "view_profile_4": "Detalhes do Perfil",
      "view_profile_5": "Comentários e avaliações",
      "view_profile_6": "Áreas de especialização",
      "view_profile_7": "Tenho experiência nestes setores/temas",
      "view_profile_8": "Tempo de experiência",
      "view_profile_9": "Uma breve descrição sobre mim:",
      "view_profile_10": "Ano",
      "view_profile_11": "Horas acumuladas de aconselhamento",
      "view_profile_12": "Sem comentários e sem valoração",
      "coach": "Treinadora",
      "edit_profile": "Editar perfil",
      "chg_pwd": "Trocar Senha",
      "forgot_pass": "Você esqueceu sua senha?",
      "verify_email": "Por favor, verifique seu endereço de e-mail",
      "edit_profile1": "Selecione uma foto de perfil",
      "edit_profile2": "Escolha o arquivo",
      "edit_profile3": "Você é uma consultora?",
      "edit_profile4": "Indústria",
      "edit_profile5": "Uma breve descrição sobre mim",
      "edit_profile6": "Fale um pouco sobre você. Se você é uma consultora, descreva sua experiência técnica, experiência de trabalho ou qualificação profissional.",
      "edit_profile7": "Escolha uma imagem .jpeg, jpg ou png",
      "chg_pwd1": "Senha antiga",
      "chg_pwd2": "Nova senha",
      "chg_pwd3": "Confirmar nova senha",
      "donation1": "Quantidade",
      "my_projects_heading1": "Meus",
      "my_projects_heading2": "projetos",
      "my_projects_1": "Encontrar uma consultora",
      "my_projects_2": "Aconselhar outros",
      "my_projects_3": "Adicionar novo projeto",
      "my_projects_4": "Pesquisar",
      "my_projects_5": "Áreas de especialização",
      "my_projects_6": "Cadastros>",
      "my_projects_7": "Anterior",
      "my_projects_8": "Próximo",
      "my_projects_9": "Título do projeto",
      "my_projects_10": "Preciso de conselhos sobre",
      "my_projects_11": "Escolher tipo de projeto",
      "my_projects_12": "Escolher Experiência",
      "my_projects_13": "Escolher tipo",
      "my_projects_14": "Posso receber consultoria nestes idiomas",
      "my_projects_15": "Sessões de consultoría (indicação informativa para seu orientador)",
      "my_projects_16": "Uma sessão típica de consultoria dura entre 45 e 60 minutos.",
      "my_projects_17": "Descreva o projeto para o seu orientador.",
      "my_projects_18": "Quais são seus objetivos? Quais as habilidades que deseja alcançar? Etc.",
      "my_projects_19": "Adicionar",
      "my_projects_20": "Título",
      "my_projects_21": "Área de experiência",
      "my_projects_22": "No.",
      "my_projects_23": "Título",
      "my_projects_24": "Sessões",
      "my_projects_25": "Status",
      "my_projects_26": "Ação",
      "my_projects_27": "Adicionar projeto",
      "my_projects_28": "Editar projeto",
      "my_projects_29": "Excluir projeto",
      "my_projects_30": "Tem Certeza?",
      "my_projects_31": "Você tem certeza que quer deletar este projeto?",
      "my_projects_32": "Cancelar",
      "my_projects_33": "Você quer fechar este projeto",
      "my_projects_34": "sim",
      "my_projects_35": "não",
      "my_projects_36": "Fechar projeto",
      "my_projects_37": "Deixe um comentário público para o sua consultora",
      "my_projects_38": "Conte-nos como foi trabalhar com seu consultor",
      "my_projects_39": "Tempo total de consultoria (horas)",
      "my_projects_40": "Por exemplo, 8 sessões de consultoria de 45 minutos cada uma = 6 horas de consultoria total.",
      "my_projects_41": "Mensagens",
      "my_projects_42": "Escrever mensagens",
      "my_projects_43": "Enviar",
      "my_projects_44": "Escolher idioma",
      "my_projects_45": "Lista de consultores",
      "my_projects_46": "Solicitação enviada",
      "my_projects_47": "Tem certeza que deseja enviar a solicitação?",
      "my_projects_48": "Tem certeza de que deseja cancelar a solicitação?",
      "my_projects_49": "Aceito e pendente",
      "my_projects_50": "Todos os projetos",
      "my_projects_51": "Rejeitado",
      "my_projects_52": "Finalizado",
      "my_projects_53": "Aceitar / Rejeitar pedido",
      "my_projects_54": "Escolher Status",
      "my_projects_55": "Projetos para os quais estou procurando",
      "my_projects_56": "um assessor",
      "my_projects_57": "Tipo de projeto",
      "my_projects_58": "Detalhes do projeto",
      "my_projects_59": "Enviar oferta",
      "my_projects_60": "Você gostaria de propor sua assistência como assessor para este projeto?",
      "my_projects_61": "Todos os idiomas",
      "my_projects_62": "Beneficiária",
      "my_projects_63": "Visualizar de perfil",
      "my_projects_64": "Fim da parceria",
      "my_projects_65": "Cancelar",
      "my_projects_66": "Editar",
      "my_projects_67": "Excluir",
      "my_projects_68": "Ver oferta",
      "my_projects_69": "Nenhum projeto encontrado",
      "my_projects_70": "Detalhes do assessor",
      "my_projects_71": "Aceitar ou rejeitar o pedido",
      "my_projects_72": "Você quer cancelar este projeto?",
      "my_projects_73": "Você tem certeza de que deseja cancelar?",
      "my_projects_74": "Se você deseja finalizar as sessões de aconselhamento (e dar a seu conselheiro a possibilidade de comentar sobre os benefícios recebidos), seu conselheiro pode encerrar seu projeto com sucesso.",
      "update": "Atualização",
      "close": "Fechar",
      "description": "Descrição",
      "accept": "Aceitar",
      "reject": "Rejeitar",
      "already_act": "Remover/Cancelar/Descartar",
      "my_projects_75": "Você pode aceitar ou recusar a oferta.",
      "my_projects_76": "Nomes da indústria",
      "my_projects_77": "Horas investidas",
      "my_projects_78": "Aceitar/Rejeitar",
      "by": "Por",
      "date": "Data",
      "reg_email_content_1": "Por",
      "reg_email_content_2": "Data",
      "reg_email_content_3": "Obrigada por se cadastrar na plataforma from woman to woman",
      "reg_email_content_4": "Você pode clicar no botão abaixo para fazer login no site e começar a usá-lo:",
      "reg_email_content_5": "Clique aquí para fazer o login",
      "reg_email_content_6": "Se você tem dúvidas, entre em contato conosco através da nossa página inicial.",
      "reg_email_content_7": "Esperamos que seja o início de muitos projetos e sonhos juntos. Aproveite essa troca de conhecimentos e ideias que temos em nossa plataforma.",
      "reg_email_content_8": "Equipe from woman to woman",
      "forgot_pwd_content_1": "Redefinir a minha senha",
      "forgot_pwd_content_2": "Olá",
      "forgot_pwd_content_3": "Solicitação de nova senha foi enviada.",
      "forgot_pwd_content_4": "Redefinir senha.",
      "forgot_pwd_content_5": "Este link é válido por 24 horas.",
      "forgot_pwd_content_6": "Se você não fez esta solicitação, ignore este e-mail e nos informe imediatamente.",
      "forgot_pwd_content_7": "Sua senha não será alterada até que você crie uma nova.",
      "sendcancelreq_email_content_1": "Novo projeto",
      "sendcancelreq_email_content_2": "Um novo pedido foi solicitado por",
      "sendcancelreq_email_content_3": "Detalhes do solicitante:",
      "sendcancelreq_email_content_4": "Detalhes do projeto:",
      "sendcancelreq_email_content_5": "A solicitação enviada por John foi cancelada.",
      "sendcancelreq_email_content_6": "Obrigado!",
      "sendcancelreq_email_content_7": "Pedido cancelado!",
      "reqexpired_email_content_1": "A solicitação enviada por você para John expirou, porque o prazo de 3 dias passou.",
      "reqexpired_email_content_2": "Por favor, envie um pedido para um novo consultor",
      "reqexpired_email_content_3": "Obrigado!",
      "reqexpired_email_content_4": "A solicitação enviada por você para John expirou, porque o prazo de 3 dias passou.",
      "reqexpired_email_content_5": "Solicitação vencida.",
      "reqexpired_email_content_6": "Olá",
      "error_msg_1": "Você foi cadastrado com sucesso.",
      "error_msg_2": "Ocorreu um problema.",
      "error_msg_3": "Este email já está cadastrado.",
      "error_msg_4": "Por favor, insira seu nome.",
      "error_msg_5": "Por favor, insira seu sobrenome.",
      "error_msg_6": "Por favor, insira seu e-mail.",
      "error_msg_7": "Por favor, insira sua senha.",
      "error_msg_8": "Por favor, confirme sua senha.",
      "error_msg_9": "A senha e a senha de confirmação não correspondem.",
      "error_msg_10": "Por favor, selecione seu país.",
      "error_msg_11": "Por favor, selecione um idioma.",
      "error_msg_12": "Favor selecionar a caixa de seleção acima.",
      "error_msg_13": "ID ou senha inválidos.",
      "error_msg_14": "Desculpa ocorreu um erro. Tente novamente.",
      "error_msg_15": "Para alterar sua senha, enviamos um link para o seu email.",
      "error_msg_16": "Este e-mail não está cadastrado.",
      "error_msg_17": "Por favor, insira uma nova senha.",
      "error_msg_18": "Por favor, confirme sua senha.",
      "error_msg_19": "A senha e a senha de confirmação não corespondem.",
      "error_msg_20": "A senha foi atualizada com sucesso.",
      "error_msg_21": "Token inválido",
      "error_msg_22": "Este token expirou",
      "error_msg_23": "Por favor, digite sua senha antiga.",
      "error_msg_24": "A senha foi alterada com sucesso.",
      "error_msg_25": "A senha antiga esta incorreta.",
      "error_msg_26": "Por favor, insira seu nome.",
      "error_msg_27": "Por favor, insira seu e-mail.",
      "error_msg_28": "Por favor, insira seu país.",
      "error_msg_29": "Por favor, selecione um idioma.",
      "error_msg_30": "Por favor, selecione suas áreas de conhecimento.",
      "error_msg_31": "Por favor, selecione a área na qual você tem experiência.",
      "error_msg_32": "Por favor, insira o total de anos de experiência.",
      "error_msg_33": "Por favor, insira sua biografia.",
      "error_msg_34": "Perfil atualizado.",
      "error_msg_35": "Obrigada por sua doação.",
      "error_msg_36": "Por favor, insira o título do projeto.",
      "error_msg_37": "Por favor, escolha a área de especialização.",
      "error_msg_38": "Por favor, selecione o tipo do projeto.",
      "error_msg_39": "Por favor, selecione um idioma.",
      "error_msg_40": "Por favor, descreva seu projeto.",
      "error_msg_41": "Projeto adicionado com sucesso.",
      "error_msg_42": "Projeto removido com sucesso.",
      "error_msg_43": "Projeto atualizado com sucesso.",
      "error_msg_44": "Você já enviou uma solicitação a um consultor. Aguarde a resposta ou cancele a última solicitação.",
      "error_msg_45": "Mensagem enviada com sucesso.",
      "error_msg_46": "Solicitação enviada com sucesso.",
      "error_msg_47": "Solicitação cancelada com sucesso.",
      "error_msg_48": "Avalie o consultor (números de estrelas)",
      "error_msg_49": "Por favor, dê sua opinião.",
      "error_msg_50": "Por favor, insira a quantidade de horas de consultoria que obteve.",
      "error_msg_51": "Obrigado pelos seus comentários.",
      "error_msg_52": "Status alterado com sucesso.",
      "error_msg_53": "Você tem certeza de que deseja apresentar o pedido?",
      "error_msg_54": "Você tem certeza de que quer cancelar o pedido?",
      "error_msg_55": "Você já enviou uma solicitação a um consultor. Se o conselheiro potencial não responder dentro de três dias, o pedido expira. Você pode então enviar uma nova aplicação para outro mentor em potencial.",
      "error_msg_56": "Um assessor já lhe enviou uma oferta de apoio para este projeto.",
      "error_msg_57": "Você tem certeza de que quer aceitar esta oferta?",
      "error_msg_58": "Você tem certeza de que quer recusar esta oferta?",
      "error_msg_59": "Oferta aceita",
      "error_msg_60": "Oferta rejeitada",
      "error_msg_61": "Agora você pode se comunicar com seu assessor através de mensagens de chat.",
      "error_msg_62": "Agora você pode consultar a lista de conselheiros e enviar um pedido de aconselhamento a outro conselheiro.",
      "thanks": "Obrigado!",
      "error_msg_63": "Sua senha deve ter pelo menos 8 caracteres, deve conter pelo menos 1 letra maiúscula, 1 número e 1 símbolo (/[!@##$%^&*()_++\\-=[\\};:\\|,.<>/?]/) e não pode conter dois caracteres consecutivos.",
      "error_msg_64": "Por favor escolha seu assunto",
      "error_msg_65": "Por favor, digite sua mensagem",
      "error_msg_66": "Por favor, escolha o formato jpeg, jpg e png de sua imagem.",
      "error_msg_67": "Escolha seu status.",
      "error_msg_68": "Captcha inválido. Por favor, tente novamente",
      "community": "Nossa comunidade",
      "rules": "Regras",
      "pass_note": "Sua senha deve ter pelo menos 8 caracteres, deve conter pelo menos 1 letra maiúscula, 1 número e 1 símbolo (por exemplo, Abcd123@ ou 1234Abc$) e não pode ter dois caracteres consecutivos.",
      "created_on": "Projeto criado em",
      "guidelines_1": "Projeto atualizado em agosto de 2020",
      "guidelines_2": "Projetos excluídos, proibição de captação de recursos e compensação",
      "guidelines_3": "A plataforma de Woman to Woman (W2W) é um site onde as mulheres recebem conselhos gratuitos para seus projetos pessoais ou profissionais. Tais projetos não podem tratar, conter ou fazer referência a:",
      "guidelines_4": "Atividades sexualmente explícitas, prostituição, pornografia e afins.",
      "guidelines_5": "Violência e comportamento destrutivo contra humanos, animais ou natureza",
      "guidelines_6": "Finalidades biológicas, crime organizado, terrorismo e atividades relacionadas",
      "guidelines_7": "Armas, munições e artigos similares, incluindo acessórios",
      "guidelines_8": "Captação de recursos, esquemas financeiros, apostas, jogos de azar, loteria, jogos de azar e similares.  Substâncias controladas, medicamentos e similares.",
      "guidelines_9": "Restos humanos ou animais",
      "guidelines_10": "Toda e qualquer atividade ilegal no país do requerente e/ou consultor e/ou no domicílio da W2W",
      "guidelines_11": "Outras atividades imorais ou antiéticas consideradas pela equipe da W2W, incluindo iniciativas sectárias em conflito com os direitos humanos e/ou os valores da W2W.",
      "guidelines_12": "Esta lista não está completa.",
      "guidelines_13": "Se você acredita que um usuário (assessor ou apoiador) ou projeto viola nossas Regras Comunitárias, não hesite em nos contatar imediatamente através do formulário em nossa página inicial.",
      "guidelines_14": "Além disso, nossa plataforma não é de captação de recursos. Nenhum usuários pode solicitar dinheiro, ou outras contribuições de qualquer tipo, publicidade ou, de fato, qualquer tipo de compensação, recompensa ou benefício para si mesmos ou para seus projetos. Nos informe imediatamente se um usuário violar esta regra. Em caso de não conformidade, a W2W, dependendo do grau de violação avaliado, emitirá avisos contra os usuários ou removerá e/ou bloqueará usuários e/ou projetos indefinidamente.",
      "guidelines_15": "A escolha de consultor e de responsabilidade do solicitante de apoio.",
      "guidelines_16": "O aconselhamento é o recebimento de conselhos de um consultor solicitado por outra pessoa. Os consultores, em nossa plataforma, estão comprometidos em agir de acordo com nossa definição de aconselhamento: um processo de aprendizado compartilhado. Ao compartilhar sua experiência, conhecimento e uma perspectiva externa imparcial, um técnico apóia e capacita a pessoa que procura ajuda para compreender e definir um determinado problema e criar suas próprias soluções. Nesta relação, a mulher que procura ajuda é sempre aquela que decide, age e lidera; ela é responsável pelo resultado de seu projeto. Um conselheiro nunca toma decisão por um buscador de ajuda. Ao contrário, ela escuta atentamente, tem uma atitude positiva, faz perguntas, mostra um contexto mais amplo, relaciona sua própria experiência e desafia o buscador de ajuda a pensar mais profundamente (fora da caixa). Se você sente que seu conselheiro não age de acordo com este credo, não hesite em nos contatar imediatamente através do formulário em nossa página inicial.",
      "guidelines_17": "Funções dos usuários",
      "guidelines_18": "Em nossa plataforma, temos duas funções:",
      "guidelines_19": "Toda mulher ou grupo (organização, instituição, cooperativa, associação, etc.) em busca de ajuda em um projeto privado ou profissional é uma candidata a ajuda. Exemplos de tais projetos poderiam ser:",
      "guidelines_20": "Como criar um orçamento financeiro para minha casa/família ou para meu negócio.",
      "guidelines_21": "Como me preparar melhor para meus estudos futuros como enfermeira registrada.",
      "guidelines_22": "Escrever um plano de negócios para programa de desenvolvimento comunitário.",
      "guidelines_23": "Os candidatos criam seu projeto em nossa plataforma, descrevendo seus objetivos e a ajuda que precisam. Então podem escolher um consultor que esteja disponível em nossa plataforma.",
      "guidelines_24": "Os conselheiros são o principal recurso desta plataforma. Todos eles são voluntários e compartilham seus conhecimentos e experiências com os que procuram ajuda gratuitamente. Como os que procuram ajuda, nossos assessores vêm de uma grande variedade de setores/indústrias e países. Os conselheiros não são contratados ou pagos por nossa organização, eles são simplesmente usuários desta plataforma. Eles agem de acordo com nossos valores de consultor (ver item 2)",
      "guidelines_25": "Uso gratuito do website e proteção de dados",
      "guidelines_26": "A equipe de Woman to Woman (W2W) está comprometida em fornecer gratuitamente os recursos e as funcionalidades da nossa plataforma online. Também garantimos que não transferimos ou vendemos quaisquer dados a terceiros. Nossos termos e condições explicam em detalhes abaixo nossa política de proteção de dados e direitos do usuário.",
      "guidelines_27": "Como é financiado de mulher para mulher(W2W)?",
      "guidelines_28": "Todos os nossos custos são cobertos por pessoas que acreditam em nossa missão e fazem doações à nossa causa. Se você também deseja doar, poderá fazer on-line quando se tornar membro de nossa plataforma; isto não afetará seu uso do site e as funcionalidades disponíveis. Agradecemos sinceramente cada contribuição. Cada doação e muito bem-vinda.",
      "guidelines_29": "",
      "guidelines_30": "",
      "guidelines_31": "",
      "guidelines_32": "",
      "terms": "Termos e",
      "conditions": "condições",
      "service_1": "Atualizado em agosto de 2020",
      "service_2": "Nota: No caso de diferenças na tradução, prevalecerá a versão em inglês destes Termos e Condições, conforme publicada em nosso site.",
      "service_3": "Estes Termos e Condições (os 'Termos') regem o uso do site e serviços (os 'Serviços') de Woman to Woman ('W2W'), uma associação sem fins lucrativos registrada na Suíça, e quaisquer termos suplementares aplicáveis a quaisquer características particulares, conteúdo e funcionalidade dos Serviços, que os termos suplementares são incorporados por referência aos Termos de Uso. Ao utilizar os Serviços, os Usuários concordam em estar vinculados por estes 'Termos e Condições', nossa política de privacidade, todas as leis aplicáveis e quaisquer condições ou políticas aqui mencionadas. A Woman to Woman pode modificar os Termos a qualquer momento, colocando uma versão revisada dos Termos em nossos serviços. Seu acesso ou uso contínuo dos Serviços após a data de vigência dos Termos revisados constitui sua aceitação dos Termos revisados.",
      "service_4": "Definições",
      "service_5": "Nestes Termos, nos referimos aos candidatos a apoio como 'Proprietários de Projetos' e suas iniciativas como 'Projetos'. Nos referimos àqueles que fornecem conhecimentos técnicos/experiência e afins como 'Conselheiros' e aos conhecimentos técnicos/experiência e afins que fornecem como 'Contribuições'. Os proprietários do projeto, conselheiros e outros visitantes dos Serviços são referidos coletivamente como \"Usuários\".",
      "service_6": "De Woman to Woman (W2W) é uma plataforma on-line",
      "service_7": "De Woman to Woman (W2W) é uma plataforma on-line para mulheres e organizações que buscam compartilhar conhecimentos/experiências e gostam de desenvolver e implementar iniciativas/projetos, ajudando umas às outras a alcançar seu pleno potencial através de sessões de tutoria.",
      "service_8": "De Woman to Woman (W2W) não é responsável pela qualidade, segurança, moralidade, legalidade de qualquer Projeto, Contribuição ou pela veracidade e exatidão de todo o Conteúdo do Usuário (conforme definido abaixo) postado nos Serviços. De Woman to Woman (W2W) não endossa que os Projetos entregarão os resultados descritos na Descrição do Projeto. Os usuários utilizam os Serviços por sua própria conta e risco. Os proprietários dos projetos são sempre responsáveis pelo resultado de seus próprios projetos. Os Serviços não incluem a oferta ou venda de títulos ou garantias.",
      "service_9": "Elegibilidade para usar os serviços",
      "service_10": "Usuários menores de 18 anos não são elegíveis para usar os Serviços, somente com o consentimento e supervisão dos pais ou tutor legal que tenha pelo menos 18 anos de idade, desde que tais pais ou tutor legal também concordem em estar vinculados aos Termos e concordem em ser responsáveis pelo uso dos Serviços.",
      "service_11": "Os usuários que estão suspensos não são elegíveis para usar os Serviços novamente. De Woman to Woman (W2W) reserva-se o direito de recusar o uso dos Serviços a qualquer pessoa e de recusar, cancelar, terminar, interromper, remover ou suspender qualquer Projeto, Contribuição ou Serviços a qualquer momento, por qualquer motivo, sem responsabilidade.",
      "service_12": "Campanhas Proibidas",
      "service_13": "De Woman to Woman (W2W) não é uma plataforma de captação de recursos: os proprietários ou consultores de projetos não podem solicitar dinheiro ou outros meios ou recursos financeiros, direta ou indiretamente.",
      "service_14": "Além disso, os proprietários de projetos não podem criar um projeto que peça ajuda na forma de conhecimentos técnicos ou similares que: finjam fazer o impossível ou simplesmente não sejam verdadeiros, em tais casos, por favor, não o publiquem. E se como conselheiro você achar difícil apoiar um projeto, por favor, não ofereça conselhos para ele.",
      "service_15": "Os usuários devem cumprir todas as leis e regulamentos aplicáveis em relação a seus Projetos, incluindo a oferta de suporte técnico. Os proprietários de projetos não devem fazer declarações falsas ou enganosas relacionadas aos seus projetos.",
      "service_16": "Os proprietários do projeto não devem: (1) incluir conteúdo ameaçador, abusivo, assediador, difamatório ou profano em qualquer projeto ou conteúdo que invada a privacidade de outra pessoa; (2) imitar qualquer pessoa ou entidade, incluindo qualquer funcionário ou representante de Woman to Woman (W2W); ou (3) infringir qualquer patente, marca registrada, segredo comercial, direito autoral, direito de publicidade, direito de qualquer outra pessoa ou entidade, ou violar qualquer lei ou contrato.",
      "service_17": "As recompensas são proibidas",
      "service_18": "Os usuários não podem oferecer, solicitar ou aceitar recompensas de nenhuma forma. Todo e qualquer aconselhamento, troca de experiência/conhecimento, perícia e sessões similares fornecidas entre o consultor e o proprietário do projeto e acordadas através da plataforma de Woman to Woman (W2W) serão oferecidas, fornecidas e recebidas completamente gratuitas e sem qualquer forma de compensação.",
      "service_19": "Regras comunitárias",
      "service_20": "Nota: Em caso de diferenças na tradução, prevalecerá a versão inglesa das Regras Comunitárias, conforme publicadas em nosso site.",
      "service_21": "De Woman to Woman (W2W) não é uma plataforma para ódio, abuso, discriminação, desrespeito, profanação, mesquinhez, assédio ou spam. Não utilize os Serviços para:",
      "service_22": "promover violência, degradação, subjugação, discriminação ou ódio contra indivíduos ou grupos com base em raça, etnia, religião, deficiência, gênero, idade, condição de veterano, orientação sexual ou identidade de gênero;",
      "service_23": "postar imagens, vídeos ou comentários/declarações que sejam sexualmente explícitos ou postar links para sites que contenham material sexualmente explícito ou que mostrem pessoas ou animais sendo feridos ou degradados;",
      "service_24": "enviar qualquer ofertas não solicitadas ou não autorizadas de bens e serviços, publicidade, material promocional, lixo eletrônico, spam ou cartas em cadeia, ou mensagens inadequadas;",
      "service_25": "se envolver em qualquer atividade que interfira ou perturbe o bom funcionamento dos Serviços ou qualquer atividade conduzida nos Serviços; ou contornar medidas implementadas de Woman to Woman (W2W) para usar ou restringir o acesso ao Serviço;",
      "service_25_1": "executar qualquer forma de resposta automatizada ou 'spam' no Serviço; usar software, dispositivos ou outros processos manuais ou automatizados para “raquear” qualquer página do Site; ou realizar qualquer ação que imponha, Woman to Woman (W2W) a critério exclusivo, uma sobrecarga na infra-estrutura do nosso site.",
      "service_25_2": "representar produtos ou iniciativas criadas por outros como sua própria criação.",
      "service_26": "",
      "service_27": "",
      "service_28": "De Woman to Woman (W2W) se reserva o direito de remover Projetos, a qualquer momento, e/ou encerrar contas de usuários para tais atividades, sem responsabilidade ou explicação.",
      "service_29": "Elementos Visuais Proibidos em Imagens de Projetos",
      "service_30": "Os proprietários do projeto não podem usar as cores ou elementos de marca da plataforma (e da organização) de Woman to Woman (W2W) em seus projetos, imagens e perfil de usuário. Isto inclui, mas não está limitado, logotipos, molduras coloridas, bordas ou crachás. Qualquer elemento visual que possa ser interpretado como sendo produzido por woman to woman (W2W) como banners, barras de progresso ou estatísticas de financiamento ou qualquer elemento visual que viole nossas diretrizes nas seções \"Campanhas Proibidas\" e \"Regras da Comunidade\".",
      "service_31": "Código de conduta de aconselhamento",
      "service_32": "De mulher para mulher (W2W) não é responsável por todo e qualquer conselho dado, ou conhecimento, perícia, experiência e similares compartilhados ou omitidos entre consultores e donos de projetos. Os proprietários dos projetos são totalmente responsáveis pelo resultado de seus próprios projetos.",
      "service_33": "Porque queremos que o processo de aconselhamento seja bem sucedido e tenha um impacto positivo nos Projetos, estabelecemos um Código de Conduta de Aconselhamento que deve ser lido e aceito por todos os nossos conselheiros. A participação na plataforma está vinculada ao aceite deste código e, em caso de violação, o usuário pode ser suspenso.",
      "service_33_1": "O aconselhamento é o recebimento de conselhos de um consultor solicitado por outra pessoa. Os consultores, em nossa plataforma, estão comprometidos em agir de acordo com nossa definição de aconselhamento: um processo de aprendizado compartilhado. Ao compartilhar sua experiência, conhecimento e uma perspectiva externa imparcial, um técnico apóia e capacita a pessoa que procura ajuda para compreender e definir um determinado problema e criar suas próprias soluções. Nesta relação, a mulher que procura ajuda é sempre aquela que decide, age e lidera; ela é responsável pelo resultado de seu projeto. Um conselheiro nunca toma decisão por um buscador de ajuda. Ao contrário, ela escuta atentamente, tem uma atitude positiva, faz perguntas, mostra um contexto mais amplo, relaciona sua própria experiência e desafia o buscador de ajuda a pensar mais profundamente (fora da caixa). Se você sente que seu conselheiro não age de acordo com este credo, não hesite em nos contatar imediatamente através do formulário em nossa página inicial.",
      "service_34": "Disputas entre proprietários de projetos e assessores",
      "service_35": "De Woman to Woman (W2W) não tem obrigação de se envolver em disputas entre Proprietários e Assessores do Projeto, ou Usuários e qualquer terceiro. Além disso, Woman to Woman (W2W) não tem obrigação de se envolver em disputas relativas à determinação do legítimo proprietário do projeto, e não será obrigado a fazer qualquer alteração nas contas do proprietário do projeto ou transferência de propriedade. No caso de qualquer disputa e a pedido exclusivo do proprietário do projeto e do consultor, podemos fornecer informações de contato a ambas as partes para que ambas as partes possam resolver sua disputa.",
      "service_36": "Contas de usuário",
      "service_37": "Todas as informações fornecidas em uma conta de Usuário nos nossos  Serviços devem ser precisas e verdadeiras. Os usuários concordam em notificar Woman to Woman (W2W) imediatamente se sua Conta de Usuário foi utilizada sem autorização ou se houve qualquer outra violação da segurança da Conta de Usuário. Cada Usuário também concorda em fornecer tais informações adicionais Woman to Woman (W2W), conforme possa razoavelmente solicitar e responder verdadeira e completamente qualquer pergunta Woman to Woman (W2W) que possa solicitar para verificar a identidade de tal Usuário.",
      "service_38": "Woman to Woman (W2W) Licença de usuário e uso de marca registrada",
      "service_39": "De Woman to Woman (W2W) concede a cada Usuário uma licença limitada, não exclusiva, intransferível e revogável para usar os Serviços, sujeito à elegibilidade de tal Usuário e ao cumprimento contínuo destes Termos. Com exceção do Conteúdo de Usuário (definido no parágrafo Conteúdo de Usuário), todo o conteúdo disponível através da plataforma Woman to Woman (W2W), como, mas não limitado a, texto, gráficos, logotipos, ícones de botões, imagens, clipes de áudio e software, é propriedade de Woman to Woman (W2W) e é protegido pelas leis suíças e internacionais de direitos autorais.",
      "service_40": "A marca registrada Woman to Woman (W2W) é uma marca registrada. Os usuários estão estritamente proibidos de registrar nomes de domínio, contas de mídia social, nomes de usuário, identificadores, marcas registradas, direitos autorais, aplicações, nomes comerciais ou nomes corporativos que incorporem a marca registrada \" Woman to Woman \" (W2W), ou que sejam similares às marcas registradas \" Woman to Woman\" (W2W).",
      "service_41": "Taxas de serviço",
      "service_42": "A criação de uma conta em Serviços é gratuita e suas funcionalidades/uso são totalmente acessíveis a todas as mulheres.",
      "service_43": "Woman to Woman (W2W) reserva-se o direito de mudar sua estrutura de taxas no futuro e de abandonar, alterar ou introduzir taxas novas ou existentes. Entretanto, os funções básicas serão sempre gratuitas e acessíveis a todas as mulheres. Woman to Woman (W2W) pode oferecer características premium aos usuários ou organizações por taxas adicionais, neste caso serão feitos acordos separados sobre o cumprindo os termos acordados entre as partes envolvidas.",
      "service_44": "As alterações nas Taxas de Serviço entram em vigor assim que a notificação das alterações nos Serviços for publicada.",
      "service_45": "Conteúdo do usuário",
      "service_46": "Ao utilizar os Serviços, os Usuários podem publicar fotos, vídeos, textos, gráficos, logotipos, obras de arte e outros materiais áudio ou visuais (coletivamente, 'Conteúdo do Usuário').",
      "service_47": "Entre usuários e Woman to Woman (W2W), os usuários continuam a ter todos os direitos de propriedade em seu conteúdo de usuário. Cada Usuário representa e garante que seu Conteúdo de Usuário e nosso uso de tal Conteúdo de Usuário não infringirá os direitos de propriedade intelectual, direitos de propriedade, direitos de privacidade, confidencialidade, direitos de publicidade de qualquer terceiro ou violará estes Termos ou a lei aplicável.",
      "service_48": "Promoções",
      "service_49": "Os proprietários do projeto não podem oferecer nenhum concurso, competição, sorteio ou atividade similar sobre os Serviços.",
      "service_50": "Sites, Anunciantes ou Serviços de Terceiros",
      "service_51": "Os Serviços não podem conter links para sites de terceiros, anunciantes ou serviços que não são de propriedade ou controlados Woman to Woman (W2W). O acesso ou uso de sites ou serviços de terceiros é por conta e risco de cada usuário. Exceto quando expressamente declarado de outra forma neste documento. Woman to Woman (W2W), não será responsável por qualquer perda ou dano de qualquer tipo relacionado às negociações do Usuário com tais sites ou serviços de terceiros.",
      "service_52": "Encorajamos os Usuários a estarem cientes quando deixarem os Serviços e a lerem os termos e política de privacidade de qualquer site ou serviço de terceiros que visitarem.",
      "service_53": "Serviços pagos",
      "service_54": "Embora o uso básico de nossa plataforma seja e sempre será gratuito para todos os Usuários, podem ser aplicadas taxas para recursos avançados (premium). A fim de liquidar tais taxas, os Usuários que aceitam e utilizam o PayPal em conexão com um serviço premium oferecido Woman to Woman (W2W) reconhecem e concordam em cumprir as Políticas de financiamento de Usuários e Multidões do PayPal.",
      "service_55": "Woman to Woman (W2W) os serviços de processamento de pagamento com cartão de crédito são fornecidos pela Stripe, Inc. e estão sujeitos ao Acordo de Conta Conectada Stripe, que inclui os Termos de Serviço Stripe (coletivamente, o \"Acordo de Serviços Stripe\"). Assim, você concorda em estar vinculado ao Acordo de Serviços Stripe, pois esta pode ser alterada de tempos em tempos. Como condição para permitir serviços de processamento de pagamento com cartão de crédito Woman to Woman (W2W) através da Stripe, você concorda em fornecer informações precisas e completas sobre você e sua organização, e autoriza Woman to Woman (W2W) a compartilhar tais informações com a Stripe. Da mesma forma, informações sobre as transações relacionadas ao seu uso dos serviços de processamento de pagamentos fornecidos pela Stripe. Em todos os casos, aplicam-se taxas padrão de cartão de crédito ou outras taxas de processamento de terceiros, além das taxas para o Serviço.",
      "service_56": "Não somos responsáveis pela execução de nenhum processamento de cartão de crédito de terceiros ou serviços de pagamento de terceiros.",
      "service_57": "Disputas relativas a serviços pagos",
      "service_58": "Os litígios entre usuários e Woman to Woman (W2W) em conexão com um serviço pago / serviço premium devem ser resolvidos entre as duas partes amigavelmente, por arbitragem ou, como último recurso, por um tribunal na jurisdição de Woman to Woman (W2W).",
      "service_59": "Indenização",
      "service_60": "Cada usuário concorda em defender, indenizar e isentar de danos Woman to Woman (W2W), suas subsidiárias e empresas afiliadas, e seus diretores, membros da diretoria, funcionários, empreiteiros e agentes contra toda e qualquer reclamação, causas de ação, danos, obrigações, perdas, responsabilidades, custos ou dívidas e despesas (incluindo honorários e custos advocatícios) e todos os valores pagos em liquidação decorrentes de ou relacionados ao seu uso dos Serviços, sua violação destes Termos ou sua violação de qualquer lei. Woman to Woman (W2W) pode assumir a defesa e o controle exclusivo de qualquer assunto pelo qual os Usuários concordaram em indenizar Woman to Woman (W2W) na defesa ou resolução de qualquer assunto desse tipo.",
      "service_61": "Sem Garantia",
      "service_62": "Woman to Woman (W2W) não tem nenhum dever fiduciário a nenhum usuário. Os Serviços são fornecidos \"como estão\" e \"como disponíveis\" sem qualquer garantia, expressa ou implícita, incluindo, sem limitação. As garantias implícitas de comerciabilidade ou adequação a um determinado propósito, ou curso de desempenho. O uso dos Serviços é por conta e risco do próprio usuário.",
      "service_63": "Limitação de responsabilidade",
      "service_64": "Woman to Woman (W2W) (incluindo suas afiliadas, diretores, membros do conselho, funcionários, empregados, agentes, subcontratados ou licenciadores) não é responsável por quaisquer danos indiretos, punitivos, incidentais, especiais, consequentes ou exemplares, em qualquer caso, por danos superiores a cem francos suíços (CHF 100,00) ou pelos honorários pagos Woman to Woman (W2W) pela transação da qual surgiu a causa da ação. Esta seção de limitação de responsabilidade aplica-se quer a suposta responsabilidade seja baseada em contrato, delito, negligência, responsabilidade estrita ou qualquer outra base, mesmo que Woman to Woman (W2W) tenha sido avisada da possibilidade de tais danos e na extensão máxima permitida por lei na jurisdição aplicável.",
      "service_65": "Woman to Woman (W2W) não é responsável pela completude ou precisão do conteúdo do usuário (tais como, mas não limitado a, descrição de projeto, identidade dos usuários, resultados desejados do projeto, histórico do projeto, etc.). Woman to Woman (W2W) não tem obrigação de verificar as informações de usuário fornecidas, mas pode fazê-lo para jurisdições que não permitem Woman to Woman (W2W) limitar sua responsabilidade.",
      "service_66": "Não obstante, qualquer disposição dos Termos para Usuários em uma jurisdição que tenha disposições específicas de isenção ou responsabilidade que entrem em conflito com o anterior, a responsabilidade da mulher é limitada na menor extensão permitida por lei. Especificamente, naquelas jurisdições com disposições específicas, Woman to Woman (W2W) se isenta de responsabilidade por: (a) morte ou danos pessoais causados por sua negligência ou de qualquer de seus diretores, membros da diretoria, funcionários ou agentes; ou (b) deturpação fraudulenta ou má conduta intencional; ou (c) qualquer responsabilidade que não seja agora ou no futuro legal excluir.",
      "service_67": "Atribuição",
      "service_68": "Os Termos, e quaisquer direitos e licenças aqui concedidos, não podem ser transferidos ou cedidos por nenhum Usuário, mas podem ser cedidos a Woman to Woman (W2W) sem restrição ou consentimento.",
      "service_69": "Rescisão; Sobrevivência",
      "service_70": "A rescisão pode resultar na perda de todas as informações associadas a qualquer Conta de Usuário. Os usuários podem encerrar sua Conta de Usuário seguindo as instruções dos Serviços, mas Woman to Woman (W2W) podem reter informações da Conta de Usuário após o encerramento, de acordo com os procedimentos regulamentares, contábeis e de conformidade legal. Todas as disposições dos Termos que, por sua natureza, devem sobreviver à rescisão, incluindo, mas não se limitando às disposições de propriedade, isenções de garantia, indenização e limitações de responsabilidade.",
      "service_71": "Lei aplicável",
      "service_72": "Os usuários concordam que: (a) os Serviços serão considerados como baseados exclusivamente na Suíça (o principal local de negócios e sede de Woman to Woman (W2W)); e (b) os Serviços serão considerados como responsabilidades que não dão origem a jurisdição pessoal de Woman to Woman (W2W), seja específica ou geral, em jurisdições diferentes da Suíça. O presente Acordo será regido pelas leis da Suíça, em respeito a seus princípios de conflito de leis.",
      "service_73": "Arbitragem",
      "service_74": "Woman to Woman (W2W) e o Usuário concordam que toda e qualquer disputa ou reclamação será resolvida exclusivamente através de arbitragem final e vinculativa, e não em tribunal, exceto que um Usuário, a seu critério, pode apresentar reclamações em tribunal de pequenas causas, se as reclamações do Usuário se qualificarem de outra forma para julgamento naquele tribunal.",
      "service_75": "Woman to Woman (W2W) e o consentimento do Usuário para arbitrar através da prestação e uso dos Serviços. As reivindicações de arbitragem são aquelas afirmadas de Woman to Woman (W2W) contra o Usuário, e afirmadas pelo Usuário contra a Woman to Woman (W2W), qualquer entidade relacionada ou afiliada, e os dirigentes, diretores, conselheiros, agentes ou funcionários de qualquer um deles. Na medida máxima permitida por lei, as Partes concordam em arbitrar as reivindicações somente em base individual, e renunciam a qualquer direito de iniciar, participar ou recuperar sob uma ação de classe, coletiva, consolidada ou representativa. O árbitro aplicará à disputa aos Termos e a mesma lei substantiva que um tribunal aplicaria, e a mesma lei de recursos. Para iniciar um processo de arbitragem de Woman to Woman (W2W) ou uma parte relacionada, um Usuário deve enviar uma carta solicitando a arbitragem e descrevendo a reivindicação de Woman to Woman (W2W).",
      "service_76": "Isenções de responsabilidade",
      "service_77": "Os proprietários de projetos e outros Usuários devem estar cientes de que muitos países impõem leis de solicitação de caridade para orientar atividades de solicitação direta ou indireta nesses países. Embora as provisões variem de país para país, essas leis nacionais geralmente incluem exigências de registro e relatórios. De Woman to Woman (W2W) simplesmente fornece uma plataforma tecnológica que permite que as donas de projetos se conectem com as assessorias de mulheres. Não somos uma plataforma de captação de recursos e os Usuários não solicitarão meios financeiros diretos ou indiretos ou outros recursos, seja de outros Usuários ou de Woman to Woman (W2W). Os usuários que acessam ou utilizam os Serviços o fazem de sua livre e espontânea vontade e são totalmente responsáveis pelo cumprimento da lei aplicável.",
      "service_78": "Transferência de dados",
      "service_79": "Por uma questão de princípio, não transferimos nenhum dado de usuário a terceiros, exceto os dados necessários para o processamento e manuseio de pagamentos. Você tem o direito, a qualquer momento e gratuitamente, de obter informações sobre seus dados armazenados e de corrigir, bloquear ou apagar os mesmos. Para fazer isso, entre em contato conosco através de nosso formulário no site.",
      "service_80": "Estatísticas de dados anônimos",
      "service_81": "Ao utilizar os Serviços, os Usuários concordam que todo e qualquer dado inserido e gerado através do uso dos Serviços pode ser utilizado por Woman to Woman (W2W) de forma anônima com o propósito de criar estatísticas mostrando as freqüências dos Usuários e o impacto positivo de nossos Serviços e plataforma.",
      "service_82": "A proteção de seus dados e a declaração de privacidade",
      "service_83": "De acordo com o artigo 13 da Constituição Federal Suíça e as disposições da Lei de Proteção de Dados suíça, todos têm o direito à proteção da privacidade, bem como proteção contra o uso indevido de seus dados pessoais. Cumprimos rigorosamente estes regulamentos. Os dados pessoais são mantidos estritamente confidenciais e não serão divulgados, transferidos, vendidos ou repassados a terceiros. Em estreita cooperação com nossos provedores de hospedagem, fazemos todos os esforços para proteger nossos bancos de dados contra acesso externo, perda de dados, uso indevido ou falsificação.",
      "service_84": "Ao acessar nossos sites, os seguintes dados são salvos em arquivos de log: endereço IP, data, hora, solicitação do navegador e informações geralmente transmitidas do sistema operacional. Estes parâmetros de dados do usuário são a base para análises estatísticas e anônimas que nos ajudam a reconhecer tendências a fim de melhorar ainda mais nossa oferta de produtos. Além disso, cumprimos a Regulamentação Geral de Proteção de Dados da UE (EU GDPR). A qualquer momento, um Usuário pode a) acessar seus dados, b) corrigir erros em seus dados pessoais, c) apagar seus dados pessoais, d) se opor ao processamento de seus dados pessoais, e e) exportar seus dados pessoais. Além disso, f) em caso de violação de privacidade, todos os usuários serão informados imediatamente, g) tomamos todas as medidas necessárias e razoáveis para proteger os dados dos usuários, e h) nomeamos e treinamos um responsável pela proteção de dados (um de nossos membros do Conselho), que é o ponto de contato para todas as consultas relacionadas à proteção de dados; você pode usar nosso formulário no site para contatá-lo.",
      "service_85": "Prevalência da versão em inglês",
      "service_86": "No caso de qualquer conflito entre a versão em língua inglesa dos Termos e qualquer versão em língua estrangeira de tradução dos Termos, a versão em língua inglesa dos Termos deverá reger e prevalecer. Todas as disputas, reclamações e causas de ação (e procedimentos relacionados) devem ser comunicadas em inglês.",
      "service_87": "Cooperação com as autoridades de aplicação da lei e a polícia",
      "service_88": "Cooperaremos com as autoridades responsáveis pela aplicação da lei, conforme exigido por lei. Cooperaremos com as agências de aplicação da lei em qualquer investigação de suspeita de atividade ilegal com respeito ao uso dos Serviços, mediante solicitação.",
      "service_89": "Procedimentos de Notificação",
      "service_90": "Os usuários concordam que Woman to Woman (W2W) podem fornecer avisos a tal usuário por e-mail, por escrito ou impresso, ou pela publicação conspícua de tal aviso em nosso site.",
      "service_91": "Os usuários podem optar por não receber certos meios de aviso ou receber certos avisos.",
      "service_92": "Envios de sugestões",
      "service_93": "Ficamos sempre felizes em ouvir de nossos usuários, seus comentários ou sugestões. Entretanto, produtos, serviços e características desenvolvidos pela Woman to Woman (W2W) ou seus Usuários podem ser similares ou mesmo idênticos a sugestões recebidas. Quando nos referimos a uma \"submissão\" neste parágrafo, queremos dizer: qualquer submissão, comentário ou sugestão (incluindo, sem limitação, ideias, produtos ou serviços e alterações sugeridas) feita na plataforma de Woman to Woman (W2W) sobre um produto ou serviço existente na plataforma, uma característica ou uma proposta de adição à plataforma de Woman to Woman (W2W).",
      "service_94": "Com respeito às apresentações/contribuições dos usuários sobre a plataforma",
      "service_95": "Todas as contribuições sobre a plataforma são não-confidenciais e não-proprietárias e serão tratadas desta forma.",
      "service_96": "Woman to Woman (W2W) e seus Usuários não terão nenhuma obrigação ou responsabilidade expressa ou implícita de qualquer tipo com relação às submissões, incluindo, por exemplo, qualquer uso ou divulgação de submissões; e Woman to Woman (W2W) e seus Usuários têm direito ao uso irrestrito ou divulgação de submissões para qualquer finalidade, tudo sem compensação ao Usuário que fez a submissão.",
      "service_97": "Acordo integral / Divisibilidade",
      "service_98": "Estes Termos são o acordo completo entre cada Usuário e Woman to Woman (W2W) com respeito ao uso da Plataforma. Se qualquer disposição destes Termos for considerada inválida por um tribunal de jurisdição competente, a invalidade de tal disposição não afetará a validade das demais disposições deste Contrato, as quais permanecerão em pleno vigor e efeito. A falha Woman to Woman (W2W) em fazer valer qualquer direito ou disposição sob este Contrato não constituirá uma renúncia a tal direito ou disposição.",
      "service_99": "Mudanças nos termos",
      "service_100": "Woman to Woman (W2W) pode mudar os Termos a qualquer momento, portanto, por favor, revise-os com frequência. Se alguma alteração for feita, Woman to Woman (W2W) poderá notificar os Usuários sobre os Serviços, por e-mail, por um aviso sobre os Serviços, ou em outros lugares que julgarmos apropriados. Uma \"alteração\" será determinada a critério de cada mulher (W2W), de boa fé e usando o bom senso e o julgamento razoável.",
      "service_101": "Google Analytics",
      "service_102": "Este site usa o Google Analytics, um serviço de análise da web fornecido pela Google, Inc. (\"Google\"). O Google Analytics utiliza os chamados 'cookies', que são arquivos de texto colocados em seu computador para ajudar o site a analisar como os usuários utilizam o site. As informações geradas pelo cookie sobre seu uso do site (incluindo seu endereço IP) serão transmitidas e armazenadas pelo Google em servidores nos Estados Unidos. Em caso de ativação da anonimização do IP, o Google truncará/anonimizará o último octeto do endereço IP para os Estados-Membros da União Européia, assim como para outras partes do Acordo sobre o Espaço Econômico Europeu. Somente em casos excepcionais, os servidores Google nos EUA enviarão e encurtarão o endereço IP completo. O Google utilizará estas informações com o propósito de avaliar seu uso do site, compilando relatórios sobre a atividade do site para os operadores e fornecendo outros serviços relacionados à atividade do site e ao uso da Internet. O Google também pode transferir estas informações para terceiros quando exigido por lei, ou quando tais terceiros processarem as informações em nome do Google. O Google não associará seu endereço IP com quaisquer outros dados mantidos pelo Google. Você pode recusar o uso de cookies selecionando as configurações apropriadas em seu navegador, no entanto, observe que se você fizer isso você pode não ser capaz de usar a funcionalidade completa deste site. Ao utilizar nosso site e nossos serviços, você consente com o processamento de dados sobre você pelo Google da maneira e para os propósitos acima expostos.",
      "service_103": "",
      "service_104": ""
    }
  ]
