import React from 'react';
// import vedio from './../about_us.mp4';
// import vedio1 from './../about_us_spanish.mp4';
// import post from './../images/post.jpg';
// import post1 from './../images/post1.JPG';
import icon from './../images/icon.png';
import { useStateValue } from './StateProvider.js';
// import { Element } from 'react-scroll'
function About(){
	const [{ global }, dispatch] = useStateValue();
	return(
	<section>
		<div style={{background:"#f6fcfd"}} name="about_us" className="active">
			<div id="about_us" className="scroll_div"></div>
			<div className="about_div">
				<h1 className="head_text">{global.siteText[0].how_it} <span style={{color:"#0ea5df"}}>{global.siteText[0].works}</span></h1>	
				<div className="divider text-center">
					<span className="outer-line"></span>
					<span className="image"><img src={icon} alt=""/></span>
					<span className="outer-line"></span>
				</div>
				<div className="row" style={{padding:"40px",margin:"0",width:"100%"}}>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" id="">
						<div className="about_text wow fadeInLeft" id="anim3">
							<p style={{paddingTop:"20px"}}>{global.siteText[0].about1}</p>
							<p>{global.siteText[0].about2}</p>
							<ul>
								<li>{global.siteText[0].about3}</li>
								<li>{global.siteText[0].about4}</li>
								<li>{global.siteText[0].about5}</li>
							</ul>
							<p>{global.siteText[0].about6}</p>
							<p>{global.siteText[0].about7}</p>
							<p style={{fontSize:"14px",lineHeight:"23px"}}>{global.siteText[0].about8}</p>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
						<div className="video_div wow fadeInLeft" style={{height:"100%"}}>
							{global.showSiteLang=='English'?
								(<div style={{height:"100%"}}><iframe width="100%" height="100%" src="https://www.youtube.com/embed/FCG3CF91LEE?rel=0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>)
								:(<div style={{height:"100%"}}><iframe width="100%" height="100%" src="https://www.youtube.com/embed/FgKuU-VXstg?rel=0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>)
							}	
						</div>
					</div>
				</div>
			</div>
		</div>
			  
	</section>
	)
}
export default About;