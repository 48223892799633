import React,{useState,useRef} from 'react';
import { Path } from './Path.js';
import {Button,Modal} from 'react-bootstrap';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import Languages from './Languages.js';
import CDetails from './CoachDetails.js';
import Pagination from './Pagination.js';
import { useStateValue } from './StateProvider.js';
const View_coaches=(props)=>{
	const [{ global }, dispatch] = useStateValue();
    const get_exp = useRef(null);
    const get_lang = useRef(null);
    const [view_coaches, setView_coaches] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [loader, setLoader] = useState(true);
    const [request_div, setRequest_div] = useState(false);
    const [cancelNotify, setCancelNotify] = useState(false);
    const [tableOpacity, setTableOpacity] = useState('');
    const [coach_id, setCoach_id] = useState('');
    const [coachDetails, setCoachDetails] = useState(false);
    const [searchData, setSearchData] = useState(false);
    const [totalCoaches, setTotalCoaches] = useState('');
    const [viewCoachId, setViewCoachId] = useState('');
    const [all_categories, setAll_categories] = useState('');
    const [all_categories_spanish, setAll_categories_spanish] = useState('');
    const [notification_div,setNotification_div] = useState(false);
    const [notification_div1,setNotification_div1] = useState(false);
    const [errors, setErrors] = useState('');
    const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
    const [page, setPage] = useState('1');
    const [startFrom, setStartFrom] = useState('');
	const [totalPages, setTotalpages] = useState('');
    const [coachRecords, setCoachRecords] = useState('');
    const [BtnStyle, setBtnStyle] = useState({Opacity:'1',PointerEvents:''});	
	const [Loader, setLoaderr] = useState(false);
    const request_modal=(coach_id)=>{
		setCoach_id(coach_id);
		setRequest_div(true);
    }
    const CoachDetails=(coach_id)=>{
		setViewCoachId(coach_id);
		setCoachDetails(true);
    }
    const handleValidation=()=>{
        let formIsValid = false;
        if(get_exp.current.value===''){
		   setErrors(global.siteText[0].error_msg_30);		   
        }else if(get_lang.current.value===''){
			setErrors(global.siteText[0].error_msg_29);
		}else {
			setErrors('');
			formIsValid = true;
		}	
        return formIsValid;
   }
	const Coaches_data=(pagee)=>{	
        setLoader(true);
        setTableOpacity('0.5');
        setSearchData(false);		
		fetch(Path+"ajax_get_coaches.php", { 
			method: "POST",       
			body: JSON.stringify({
                project_id: props.post_id,
                token:token,
                page:pagee
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
            setLoader(false);
		    setTableOpacity('');
            setView_coaches(response.coach_data);
            setTotalCoaches(response.pagination_data.total_records);
            setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			setCoachRecords(response.pagination_data.total_records);				
			}
        )
        .catch(err => {
            return err;
        })		
    }
    const SearchCoaches=(Pagee)=>{
        if(handleValidation()){			
            setLoader(true);
            setTableOpacity('0.5');
            setSearchData(true);	
            fetch(Path+"ajax_get_coaches.php", { 
                method: "POST",       
                body: JSON.stringify({
                    exp_id: get_exp.current.value,
                    project_id: props.post_id,
                    languages: get_lang.current.value,
                    token:token,
                    page:Pagee
                }), 	
            })
            .then(res => res.json())
            .then(response=>{
                setLoader(false);
                setTableOpacity('');
                setView_coaches(response.coach_data);
                setTotalCoaches(response.pagination_data.total_records);
                setTotalpages(response.pagination_data.total_pages);
                setPage(response.pagination_data.page);
                setStartFrom(response.pagination_data.start_from);
                setCoachRecords(response.pagination_data.total_records);				
                }
            )
            .catch(err => {
                return err;
            })
        }		
    }
    const Category_data=()=>{		
		fetch(Path+"get_category.php", {     			
		})
		.then(res => res.json())
		.then(response=>{
			setAll_categories(response.categories);	
			setAll_categories_spanish(response.categories_spanish);					
			}
		)		
	}
    const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
    }
    const show_notification=(response)=>{
        if(response.failed!=null){
            setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
        }else if(response.success!=null){
            setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});
        }
        Coaches_data(page);
        setTimeout(hide_notification, 4000);
    }
    const Send_request=()=>{
        setLoaderr(true);
        setBtnStyle(prevState => ({
            ...prevState,
            Opacity: '0.5'
            }));
        setBtnStyle(prevState => ({
            ...prevState,
            PointerEvents: 'none'
            }));	        
		fetch(Path+"ajax_send_request.php", { 
			method: "POST",       
			body: JSON.stringify({
                project_id: props.post_id,
                page:props.page,
                coach_id: coach_id,
                token:token
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
                setLoaderr(false);
                setBtnStyle(prevState => ({
                    ...prevState,
                    Opacity: ''
                }));
                setBtnStyle(prevState => ({
                    ...prevState,
                    PointerEvents: ''
                }));
                props.onUpdate(response.project_data);
				props.onTotalPages(response.pagination_data.total_pages);
				props.onStartFrom(response.pagination_data.start_from);
				props.onPage(response.pagination_data.page);
				props.onTotalRecords(response.pagination_data.total_records);
                setRequest_div(!request_div);
                show_notification(response.notifications);			
            }
        )
        .catch(err => {
            return err;
        })		
    }
    const Cancel_request=()=>{
        setLoaderr(true);
        setBtnStyle(prevState => ({
            ...prevState,
            Opacity: '0.5'
            }));
        setBtnStyle(prevState => ({
            ...prevState,
            PointerEvents: 'none'
            }));	        
		fetch(Path+"ajax_cancel_request.php", { 
			method: "POST",       
			body: JSON.stringify({
                project_id: props.post_id,
                page:props.page,
                coach_id: coach_id,
                token:token
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
                setLoaderr(false);
                setBtnStyle(prevState => ({
                    ...prevState,
                    Opacity: ''
                    }));
                setBtnStyle(prevState => ({
                    ...prevState,
                    PointerEvents: ''
                    }));
                props.onUpdate(response.project_data);
				props.onTotalPages(response.pagination_data.total_pages);
				props.onStartFrom(response.pagination_data.start_from);
				props.onPage(response.pagination_data.page);
				props.onTotalRecords(response.pagination_data.total_records);
                setCancelNotify(!cancelNotify);
                show_notification(response.notifications);			
            }
        )
        .catch(err => {
            return err;
        })		
	}
	React.useEffect(() => {
        Category_data();
		Coaches_data(page);
	},[]);	
		return(
		<section>
            {request_div ? 
            (<div className="row err_div">
				<div className="col-xl-10"><p>{global.siteText[0].error_msg_53}</p></div>								
				<div className="col-xl-2"><span className="btn btn-primary cnfrm" onClick={Send_request} style={{opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].my_projects_34}</span><span className="btn btn-primary ncnfrm" onClick={() => setRequest_div(!request_div)}>{global.siteText[0].my_projects_35}</span></div>
			</div>) : ('')}
            {cancelNotify ? 
            (<div className="row err_div">
				<div className="col-xl-10"><p>{global.siteText[0].error_msg_54}</p></div>								
				<div className="col-xl-2"><span className="btn btn-primary cnfrm" onClick={Cancel_request} style={{opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].my_projects_34}</span><span className="btn btn-primary ncnfrm" onClick={() => setCancelNotify(!cancelNotify)}>{global.siteText[0].my_projects_35}</span></div>
			</div>) : ('')}
            {notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
                <button type="button" className="close" data-dismiss="alert"></button>
            </div>) : ('')}		
            {notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
                <button type="button" className="close" data-dismiss="alert"></button>
            </div>) : ('')}
            {notification_div ? (<div className="alert alert-danger" id="danger2">{global.siteText[0].error_msg_55}
                <button type="button" className="close" data-dismiss="alert"></button>
            </div>) : ('')}
            {notification_div1 ? (<div className="alert alert-danger" id="danger2">{global.siteText[0].error_msg_56}
                <button type="button" className="close" data-dismiss="alert"></button>
            </div>) : ('')}
            {errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
            <div className="row" style={{margin:"0",marginTop:"0px"}}>
                <div className="col-xl-5 col-lg-6 col-md-6 col-xs-6 col-sm-6" style={{padding:"0",margin:"auto"}}>
                <h5 style={{fontSize:"17px",fontWeight:"600",marginBottom:"0"}}>{global.siteText[0].my_projects_6} {totalCoaches}</h5>
                </div>
                <div className="col-xl-7 col-lg-6 col-md-6 col-xs-6 col-sm-6" style={{padding:"0",textAlign:"right"}}>
                    <div className="row" style={{margin:"0"}}>
                        <div style={{marginRight:"16px",width:"178px"}}>
                            {global.showSiteLang=='English' ?
                            (<select className="form-control" name="Category" ref={get_exp}>
                                <option value="">{global.siteText[0].my_projects_12}</option>
                                <option value="all">{global.siteText[0].my_projects_5}</option>
                                {all_categories?Object.keys(all_categories).map((key,i)=>
                                <React.Fragment key={i}>
                                <option value={all_categories[key].id}>{all_categories[key].name}</option>
                                </React.Fragment>):('')}
                            </select>):
                            (
                                <select className="form-control" name="Category" ref={get_exp}>
                                <option value="">{global.siteText[0].my_projects_12}</option>
                                <option value="all">{global.siteText[0].my_projects_5}</option>
                                {all_categories_spanish?Object.keys(all_categories_spanish).map((key,i)=>
                                <React.Fragment key={i}>
                                <option value={all_categories_spanish[key].id}>{all_categories_spanish[key].name_spanish}</option>
                                </React.Fragment>):('')}
                            </select>
                            )}
                        </div>
                        <div className="" style={{paddingRight:"0",marginRight:"16px",width:"184px"}}>	
                            <select className="form-control" name="language" ref={get_lang}>
                                <option value="">{global.siteText[0].my_projects_44}</option>
                                <option value="all">{global.siteText[0].my_projects_61}</option>
                                <React.Fragment>
                                    <Languages/>
                                </React.Fragment>
                            </select>    
                        </div>
                        <div className="">
                            <Button variant="primary" className="add_btn" onClick={() => SearchCoaches(page)}>{global.siteText[0].my_projects_4}</Button>
                        </div>                       	
                    </div>
                </div>
            </div>
			<div className="table-responsive div_show" id="no-more-tables" style={{position:"relative"}}>
					{loader?(<div className="spinner-border" role="status" style={{position:"absolute",top:"50%",left:"50%"}}>
					  <span className="sr-only">Loading...</span>
					</div>):('')}
					<table className="table coach_table" style={{opacity:tableOpacity}}>
					  <thead className="thh">
						<tr>
							<th>{global.siteText[0].my_projects_22}</th>
							<th></th>
							<th>{global.siteText[0].contact_3}</th>							
							<th>{global.siteText[0].country}</th>							
							<th>{global.siteText[0].my_projects_26}</th>
						</tr>
					  </thead>
					  <tbody id="tbo">{view_coaches?Object.keys(view_coaches).map((key,i)=>
					  <tr key={i}>
						<td className='count_rec' data-title={global.siteText[0].my_projects_22}>{startFrom+i+1}</td>
						<td className='count_rec' data-title="Image">
                            {view_coaches[key].image ? 
                                (<img src={Path+`uploads/${view_coaches[key].image}`} style={{height:"44px",width:"44px",objectFit:"cover"}}></img>):
                                (<span style={{backgroundColor:"rgb(14, 165, 223)",marginRight:"4px",padding:"8px 10px 8px 10px"}}><b className="top_letter" style={{minWidth:"24px"}}>{view_coaches[key].name.charAt(0)}</b></span>)
                            }
                        </td>
						<td className='count_rec coach_name' data-title={global.siteText[0].contact_3} onClick={() => CoachDetails(view_coaches[key].coach_id)}>{view_coaches[key].name}</td>
						<td className='count_rec'  data-title={global.siteText[0].country}><img src={Path+`flags/${view_coaches[key].flag}.png`} style={{height:"20px"}}></img> {global.showSiteLang=='English' ? view_coaches[key].country : view_coaches[key].country_spanish}</td>
						<td className='count_rec' data-title={global.siteText[0].my_projects_26}>
                            {view_coaches[key].status=='no' ?
                                (<div>
                                   {view_coaches[key].project_status=='open' || view_coaches[key].project_status=='rejected' || view_coaches[key].project_status=='expired'? 
                                        (<span className='delete' title='Send request' onClick={() => request_modal(view_coaches[key].coach_id)}><i className='fa fa-send  request_icon action_icons' aria-hidden='true'></i></span>):
                                        (
                                            <div>
                                                {view_coaches[key].project_status=='pending' && view_coaches[key].total_offers==null ?
                                                    (<span className='delete' title='Send request' onClick={() => setNotification_div(!notification_div)}><i className='fa fa-send  request_icon action_icons' aria-hidden='true'></i></span>):
                                                    (<span className='delete' title='Send request' onClick={() => setNotification_div1(!notification_div1)}><i className='fa fa-send  request_icon action_icons' aria-hidden='true'></i></span>)
                                                }
                                            </div>
                                        )
                                    }
                                    </div>)
                                :(<span style={{color:"green"}}>{view_coaches[key].project_status=='accepted'?'Accepted by the coach.':(<span>
                                    {view_coaches[key].total_offers==null?'Request sent':'receive offer'}
                                </span>)}
                                
                                {view_coaches[key].project_status=='pending' && view_coaches[key].total_offers=='0'?
                                    (<i className='fa fa-unlink  request_icon action_icons' title='Cancel request' style={{marginLeft:"8px"}} onClick={() => setCancelNotify(!cancelNotify)}></i>):('')}
                                </span>)
                            }
						</td>
					  </tr>):(<tr><td colSpan="7" style={{textAlign:"center"}}>No coaches found.</td></tr>)}
					  </tbody>
					</table>
				</div>
                <Pagination page={page} totalpages={totalPages} onUpdatePage={searchData==true ?SearchCoaches : Coaches_data}/>
                <div>
                    <Modal show={coachDetails} animation={false} dialogClassName="edit_div">
                        <Modal.Header closeButton onClick={() => setCoachDetails(!coachDetails)} className="modal_head">
                        <Modal.Title className="modal_title">{global.siteText[0].my_projects_70}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CDetails coach_id={viewCoachId}/>		
                        </Modal.Body>
                    </Modal>
                </div>
		</section>
		)
}
export default View_coaches;