import React,{useState,useRef} from 'react';
import { Path } from './Path.js';
import {Button,Modal} from 'react-bootstrap';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import Languages from './Languages.js';
import CDetails from './CoachDetails.js';
import Pagination from './Pagination.js';
import ProjectOffer from './ProjectOffer.js';
import { useStateValue } from './StateProvider.js';
const ViewOffer=(props)=>{
	const [{ global }, dispatch] = useStateValue();
    const get_status = useRef(null);	
    const [view_coaches, setView_coaches] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [loader, setLoader] = useState(true);
    const [tableOpacity, setTableOpacity] = useState('');
    const [coachId, setCoachId] = useState('');
    const [coachDetails, setCoachDetails] = useState(false);
    const [projectRequest, setProjectRequest] = useState(false);
    const [showDiv, setShowDiv] = useState(true);
    const [totalCoaches, setTotalCoaches] = useState('');
    const [showAcceptMsg, setShowAcceptMsg] = useState('');
    const [showRejectMsg, setShowRejectMsg] = useState('');
    const [viewCoachId, setViewCoachId] = useState('');
    const [errors, setErrors] = useState('');
    const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
    const [page, setPage] = useState('1');
    const [startFrom, setStartFrom] = useState('');
    const [show_word, setShowWord] = useState('accept');
	const [totalPages, setTotalpages] = useState('');
    const [coachRecords, setCoachRecords] = useState('');
    const [BtnStyle, setBtnStyle] = useState({Opacity:'1',PointerEvents:''});	
    const [Loader, setLoaderr] = useState(false);
    const ShowDiv=()=>{
        if(get_status.current.value=='accepted'){
            setShowWord('accept');
        }else{
            setShowWord('reject');
        }
        setProjectRequest(!projectRequest);
    }
   const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
   }
   const show_notification=(response)=>{
	   if(response.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
	   }else if(response.success!=null){
		   setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});
	   }
	   setTimeout(hide_notification, 4000);
   }
   const change_status=()=>{ 
        setProjectRequest(!projectRequest);
        setLoader(true);
        setBtnStyle(prevState => ({
            ...prevState,
            Opacity: '0.5'
        }));
        setBtnStyle(prevState => ({
            ...prevState,
            PointerEvents: 'none'
        }));
        fetch(Path+"ajax_accept_offer.php", {
            method: "POST",
            body: JSON.stringify({
                project_id:props.project_id,
                offer_id:view_coaches.offer_id,
                status:get_status.current.value,
                token:token
            }), 		
        })
        .then(res => res.json())
        .then(response=>{
            console.log(response);	
            setLoader(false);
            setBtnStyle(prevState => ({
                ...prevState,
                Opacity: ''
            }));
            setBtnStyle(prevState => ({
                ...prevState,
                PointerEvents: ''
            }));
                setShowDiv(false);
                if(response.notification.success=='accepted'){
                    setShowRejectMsg('');
                    setShowAcceptMsg('show');
                }else{
                    setShowAcceptMsg('');
                    setShowRejectMsg('show');
                }
                props.onUpdate(response.project_data);
				props.onTotalPages(response.pagination_data.total_pages);
				props.onStartFrom(response.pagination_data.start_from);
				props.onPage(response.pagination_data.page);
                props.onTotalRecords(response.pagination_data.total_records); 
            }
        )
        .catch(err => {
            return err;
        })
    }
    const view_request_modal=(coach_id)=>{
		setCoachId(coach_id);
	}
    const CoachDetails=(coach_id)=>{
		setViewCoachId(coach_id);
		setCoachDetails(true);
    }
    
	const Coaches_data=(pagee)=>{	
        setLoader(true);
        setTableOpacity('0.5');	
		fetch(Path+"get_coach_offer.php", { 
			method: "POST",       
			body: JSON.stringify({
                project_id: props.project_id,
                token:token,
                page:pagee
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
            setLoader(false);
		    setTableOpacity('');
            setView_coaches(response);				
			}
        )
        .catch(err => {
            return err;
        })		
    }
	React.useEffect(() => {
		Coaches_data(page);
	},[]);	
		return(
		<section>
            {projectRequest ? 
            (<div className="row err_div">
				<div className="col-xl-9">
                    {show_word=='accept'?
                    (<p>{global.siteText[0].error_msg_57}</p>):
                    (<p>{global.siteText[0].error_msg_58}</p>)}
                </div>								
				<div className="col-xl-3"><span className="btn btn-primary cnfrm" onClick={change_status} style={{opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].my_projects_34}</span><span className="btn btn-primary ncnfrm" onClick={() => setProjectRequest(!projectRequest)}>{global.siteText[0].error_msg_35}</span></div>
			</div>) : ('')}
            {notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}		
				{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}
				{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
                {showDiv ? 
                (<div>                   
                    <div className="div_show" id="no-more-tables" style={{position:"relative"}}>
                        {loader?(<div className="spinner-border" role="status" style={{position:"absolute",top:"50%",left:"50%"}}>
                        <span className="sr-only">Loading...</span>
                        </div>):('')}
                        

                        <div className="row" style={{margin:0}}>
                            <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 col-sm-12" style={{paddingLeft:"0px"}}>
                                <div className="prf_img">      
                                    {view_coaches.image ? 
                                        (<img src={Path+`uploads/${view_coaches.image}`}></img>):
                                        (<img src={Path+`uploads/placeholder.png`}></img>)
                                    }                       
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 col-sm-12" style={{paddingLeft:"0"}}>
                            <table className="project_det">
                                <tbody>
                                    <tr className="table_row">
                                        <td className="project_head" style={{width:"108px"}}>{global.siteText[0].contact_3}:</td>
                                        <td className="project_detail">{view_coaches.name} {view_coaches.lname}</td>
                                    </tr>                                   
                                    <tr className="table_row">
                                        <td className="project_head" style={{width:"108px"}}>{global.siteText[0].country}:</td>
                                        <td className="project_detail"><img src={Path+`flags/${view_coaches.flag}.png`} style={{height:"20px"}}></img> {global.showSiteLang=='English' ? view_coaches.country : view_coaches.country_spanish}</td>
                                    </tr>
                                    <tr className="">
                                        <td className="project_head" colSpan="2">{global.siteText[0].view_profile_3}</td>
                                    </tr>
                                    <tr className="table_row">
                                        <td className="project_detail" colSpan="2">{global.showSiteLang=='English' ?view_coaches.show_languages : view_coaches.show_languages_spanish}</td>
                                    </tr>
                                    <tr className="">
                                        <td className="project_head" colSpan="2">{global.siteText[0].view_profile_6}</td>
                                    </tr>
                                    <tr className="table_row">
                                        <td className="project_detail" colSpan="2">{global.showSiteLang=='English' ? view_coaches.category_name : view_coaches.category_name_spanish}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div> 

                        <div className="col-md-12 col-sm-12 offer_boxx">
                            <div className="row offer_box">
                                <div className="col-sm-8">
                                    <label>{global.siteText[0].my_projects_75}</label>
                                    <select className="form-control" ref={get_status}>
                                        <option value="accepted">{global.siteText[0].accept}</option>
                                        <option value="rejected">{global.siteText[0].reject}</option>
                                    </select>
                                </div>
                                <div className="col-sm-4" style={{marginTop:"30px"}}>
                                    <Button variant="primary" className="modal_btn" onClick={ShowDiv}>
                                    {global.siteText[0].contact_7} 
                                    </Button>
                                </div>
                            </div>                           
                        </div>
                    </div>
                </div>):('')}
                {showAcceptMsg=='show'?
                (<div className="accept_msg">
                    <h4>{global.siteText[0].error_msg_59}</h4>
                    <p>{global.siteText[0].error_msg_61}</p>
                    <p>{global.siteText[0].thanks}</p>
                </div>):('')}
                {showRejectMsg=='show'?
                (<div className="accept_msg">
                    <h4>{global.siteText[0].error_msg_60}</h4>
                    <p>{global.siteText[0].error_msg_62}</p>
                    <p>{global.siteText[0].thanks}</p>
                </div>):('')}
                <div>
                    <Modal show={coachDetails} animation={false} dialogClassName="edit_div">
                        <Modal.Header closeButton onClick={() => setCoachDetails(!coachDetails)} className="modal_head">
                        <Modal.Title className="modal_title">{global.siteText[0].my_projects_70}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CDetails coach_id={viewCoachId}/>		
                        </Modal.Body>
                    </Modal>
                </div>
		</section>
		)
}
export default ViewOffer;