import React from 'react';
import mem1 from './../images/mem1.png';
import mem2 from './../images/mem22.jpg';
import mem6 from './../images/mem66.jpg';
import mem7 from './../images/mem7.jpg';
import mem5 from './../images/mem55.jpg';
import icon from './../images/icon.png';
import { useStateValue } from './StateProvider.js';
function Team(){
	const [{ global }, dispatch] = useStateValue();
	return(
	<section>
	<div style={{background:"#f6fcfd"}} className="teamm">
		<div id="team_div" className="scroll_div"></div>
		<div className="container container-width team_div">
			<h1 className="head_text">{global.siteText[0].our} <span style={{color:"#0ea5df"}}>{global.siteText[0].team}</span></h1>
			<div className="divider text-center">
				<span className="outer-line"></span>
				<span className="image"><img src={icon} alt=""/></span>
				<span className="outer-line"></span>
			</div>
			<br></br>
			<div className="row">
				<div className="col-xl-2 col-lg-12 col-md-12 col-xs-12 col-sm-12"></div>
				<div className="col-xl-8 col-lg-12 col-md-12 col-xs-12 col-sm-12">
					<div className="row team_member wow fadeInUp">
						<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12 col-sm-12">
							<img src={mem6} alt="" />
							<h4 className="mem_h4">{global.siteText[0].team1_5}</h4>
						</div>
						<div className="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-12">
							<div className="members">
								<h3>{global.siteText[0].team1_1}</h3>
								<h5>{global.siteText[0].team1_2}</h5>
								 <span>{global.siteText[0].team1_3}</span>
								<p>{global.siteText[0].team1_4}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-lg-12 col-md-12 col-xs-12 col-sm-12"></div>
			</div><br></br>
			<div className="row">
				<div className="col-xl-6 col-lg-12 col-md-12 col-xs-12 col-sm-12 mem" id="anim1">
					<div className="row team_member wow fadeInLeft">
						<div className="col-xl-4 col-lg-3 col-md-3 col-xs-12 col-sm-12">
							<img src={mem1} alt="" />
							<h4 className="mem_h4">{global.siteText[0].team2_5}</h4>
						</div>
						<div className="col-xl-8 col-lg-9 col-md-9 col-xs-12 col-sm-12">
							<div className="members">
								<h3>{global.siteText[0].team2_1}</h3>
								<h5>{global.siteText[0].team2_2}</h5>
								<span>{global.siteText[0].team2_3}</span>
								<p>{global.siteText[0].team2_4}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-6 col-lg-12 col-md-12 col-xs-12 col-sm-12 mem">
					<div className="row team_member wow fadeInRight">
						<div className="col-xl-4 col-lg-3 col-md-3 col-xs-12 col-sm-12">
							<img src={mem5} alt="" />
							<h4 className="mem_h4">{global.siteText[0].team3_5}</h4>
						</div>
						<div className="col-xl-8 col-lg-9 col-md-9 col-xs-12 col-sm-12">
							<div className="members">
								<h3>{global.siteText[0].team3_1}</h3>
								<h5>{global.siteText[0].team3_2}</h5>
								 <span>{global.siteText[0].team3_3}</span>
								<p>{global.siteText[0].team3_4}</p>
							</div>
						</div>
					</div>
				</div>				
				<div className="col-xl-6 col-lg-12 col-md-12 col-xs-12 col-sm-12 mem">
					<div className="row team_member wow fadeInRight">
						<div className="col-xl-4 col-lg-3 col-md-3 col-xs-12 col-sm-12">
							<img src={mem2} alt="" />
							<h4 className="mem_h4">{global.siteText[0].team4_5}</h4>
						</div>
						<div className="col-xl-8 col-lg-9 col-md-9 col-xs-12 col-sm-12">
							<div className="members">
								<h3>{global.siteText[0].team4_1}</h3>
								<h5>{global.siteText[0].team4_2}</h5>
								<span>{global.siteText[0].team4_3}</span>
								<p>{global.siteText[0].team4_4}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-6 col-lg-12 col-md-12 col-xs-12 col-sm-12 mem">
					<div className="row team_member wow fadeInLeft">
						<div className="col-xl-4 col-lg-3 col-md-3 col-xs-12 col-sm-12">
							<img src={mem7} alt="" />
							<h4 className="mem_h4">{global.siteText[0].team5_5}</h4>
						</div>
						<div className="col-xl-8 col-lg-9 col-md-9 col-xs-12 col-sm-12">
							<div className="members">
								<h3>{global.siteText[0].team5_1}</h3>
								<h5>{global.siteText[0].team5_2}</h5>
								<span>{global.siteText[0].team5_3}</span>
								<p>{global.siteText[0].team5_4}</p>
							</div>
						</div>
					</div>
				</div>
			</div>			
		</div>
	</div>
			  
	</section>
	)
}
export default Team;