import React,{useState,useRef} from 'react';
import {Button} from 'react-bootstrap';
import { Path } from './Path.js';
import { useStateValue } from './StateProvider.js';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
const ProjectRequest=(props)=>{
    const [{ global }, dispatch] = useStateValue();
    const get_reqStatus = useRef(null);
    const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
    const [errors, setErrors] = useState('');
    const [BtnStyle, setBtnStyle] = useState({Opacity:'1',PointerEvents:''});	
    const [Loader, setLoader] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const handleValidation=()=>{
        let formIsValid = false;
        if(get_reqStatus.current.value===''){
		   setErrors(global.siteText[0].error_msg_67);		   
        }else {
			setErrors('');
			formIsValid = true;
		}	
        return formIsValid;
   }
   const hide_notification=()=>{
        setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
        dispatch({
            type: 'changeProjectRequestModal',
            newProjectRequestModal: {projectRequestModal: !global.projectRequestModal}
        })
   }
   const show_notification=(response)=>{
	   if(response.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
	   }else if(response.success!=null){
            setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});            
       }        
	   setTimeout(hide_notification, 4000);
   }
   const Change_status=()=>{
    const data = new FormData();
    data.append('status', get_reqStatus.current.value); 
    data.append('project_id', props.request_id);
    data.append('project_status', props.project_status);
    data.append('page', props.page);     
    data.append('token', token);          
    if(handleValidation()){	
        setLoader(true);
        setBtnStyle(prevState => ({
            ...prevState,
            Opacity: '0.5'
         }));
        setBtnStyle(prevState => ({
            ...prevState,
            PointerEvents: 'none'
         }));
        fetch(Path+"ajax_accept_request.php", {
            method: "POST",       
            body: data				
        })
        .then(res => res.json())
        .then(response=>{
            console.log(response);	
            setLoader(false);
            document.getElementById("add_proj").reset();
            setBtnStyle(prevState => ({
                ...prevState,
                Opacity: ''
             }));
            setBtnStyle(prevState => ({
                ...prevState,
                PointerEvents: ''
             }));
             show_notification(response.notifications);
             props.onUpdate(response.project_data);
             props.onTotalPages(response.pagination_data.total_pages);
             props.onStartFrom(response.pagination_data.start_from);
             props.onPage(response.pagination_data.page);
             props.onTotalRecords(response.pagination_data.total_records);    
            }
        )
        .catch(err => {
            return err;
        })
    }
   }
		return(
		<section>
			<form id="add_proj" className="formm contact_formm" onSubmit={Change_status}>
				{notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}		
				{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}
				{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
				<div className="row">
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].my_projects_78}</label>
						<select className="form-control" name="reg_status" ref={get_reqStatus}>
							<option value="">{global.siteText[0].my_projects_54}</option>
                            <option value="accepted">{global.siteText[0].accept}</option>
                            <option value="rejected">{global.siteText[0].reject}</option>							
						</select>
					</div>
                    <div className="col-md-12 col-sm-12 text-right" style={{marginTop:"20px"}}>
						<Button variant="primary" className="modal_btn" onClick={Change_status} style={{opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>
							{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].contact_7}
						</Button>
					</div>
                </div> 
            </form>       
		</section>
		)
}
export default ProjectRequest;