import { polyfill } from 'es6-promise';
import React,{useState,useRef} from 'react';
import {Modal} from 'react-bootstrap';
import { Path, lang_en, lang_sp, lang_pt} from './Path.js';
import parser from 'html-react-parser';
// import ReactDOM from 'react-dom';
 import logo from './../images/logoo.png';
 import eng from './../images/eng.png';
 import spa from './../images/spa.png';
 import pt from './../images/pt.png';
// import profile_img from './../images/profile.png';
import Register from './Register.js';
import Login from './Login.js';
import Donation from './Donation.js';
import Edit_profile from './Edit_profile.js';
import View_profile from './View_profile.js';
import VerifyEmail from './VerifyEmail.js';
import Change_Password from './Change_Password.js';
 import { useHistory } from 'react-router-dom';
 import { HashLink as Link } from 'react-router-hash-link';
 import { useStateValue } from './StateProvider.js';
import Forgot_pass from './Forgot_pass.js';
import ChangeForgotPassword from './ChangeForgotPassword.js';
const Navbar=()=> {	
	polyfill();
	const [{ global }, dispatch] = useStateValue();
	let history=useHistory();
	const get_site_lang = useRef(null);	
	const [showChangePass, setChangePass] = useState(false);
	const [langDiv, setLangDiv] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [showEditProfile, setEditProfile] = useState(false);
	const [viewProfile, setViewProfile] = useState(false);
	const [Dropdown, setDropdown] = useState(false);
	//const [siteText, setSiteText] = useState('');
	const [donation, setDonation] = useState(false);
	const [token, setToken] = useState(localStorage.getItem('token'));	
	const logout=()=>{
		localStorage.setItem('token', '');
		dispatch({
			type: 'changeToken',
			newToken: { token: ''}
		})
		dispatch({
			type: 'changeUserName',
			newUserName: { showUserName: ''},newUserImg: { showUserImg: ''}
		})
		localStorage.clear();
		setIsLoggedIn(false);
		history.push('/'); 
	}
	const ShowUserMenuPage=(value)=>{
		if(value=='view_profile'){
			setViewProfile(!viewProfile,value);
		}else if(value=='edit_profile'){
			setEditProfile(!showEditProfile);
		}else if(value=='change_pwd'){
			setChangePass(!showChangePass);
		}else if(value=='donation'){
			setDonation(!donation)
		}else if(value=='my_projects'){
			history.push('/MyProjects');
		}else if(value=='all_projects'){
			history.push('/ViewProjects');
		}		
		dispatch({
			type: 'changeUserMenu',
			newUserMenu: {showUserMenu: !global.showUserMenu}
			})
	}
	const change_language=(value)=>{
		setLangDiv(false);
		dispatch({
			type: 'changeShowSiteLang',
			newSiteLang: {showSiteLang: value}
		})
		if(value=='English'){
			localStorage.setItem('website_langg', lang_en);
			localStorage.setItem('website_text', 'English');
			dispatch({
				type: 'changeSiteText',
				newSiteText: {siteText: lang_en}
			})
		}else if(value=='Spanish'){
			localStorage.setItem('website_langg', lang_sp);
			localStorage.setItem('website_text', 'Spanish');
			dispatch({
				type: 'changeSiteText',
				newSiteText: {siteText: lang_sp}
			})
		}else if(value=='Portuguese'){
			localStorage.setItem('website_langg', lang_pt);
			localStorage.setItem('website_text', 'Portuguese');
			dispatch({
				type: 'changeSiteText',
				newSiteText: {siteText: lang_pt}
			})
		}
	}
	const ChangeModal=()=>{
		dispatch({
		type: 'changeRegModal',
		newRegModal: {showRegModal: false}
		})
		dispatch({
		type: 'changeLogModal',
		newLogModal: {showLogModal: true}
		})
	}
	const get_profile_image=()=>{
		fetch(Path+"get_profile_image.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: global.token
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
				if( response.login_page=='no'){
					dispatch({
						type: 'changeUserName',
						newUserName: { showUserName: response.name},newUserImg: { showUserImg: response.image}
					})	
				}else{
					localStorage.setItem('token', '');
					dispatch({
						type: 'changeToken',
						newToken: { token: ''}
					})
					dispatch({
						type: 'changeUserName',
						newUserName: { showUserName: ''},newUserImg: { showUserImg: ''}
					})
					localStorage.clear();
					setIsLoggedIn(false);
					history.push('/'); 
				}		
			}
		)
		.catch(err => {
			console.log('get profile err',err);
			return err;
		})		
	}
	const show_modal=()=>{
		console.log('hhhhh');
		dispatch({
			type: 'changeVerifyEmailModal',
			newVerifyEmailModal: {verifyEmailModal: !global.verifyEmailModal}
		})
		dispatch({
			type: 'changeLogModal',
			newLogModal: {showLogModal: !global.showLogModal}
		})
	   }
	const show_msg_modal=()=>{
		dispatch({
			type: 'changeVerifyEmailModal',
			newVerifyEmailModal: {verifyEmailModal: true}
		})
		fetch(Path+"ajax_verify_email.php", {
			method: "POST",       
			body: JSON.stringify({
				verify_token: global.getEmailVerifyToken
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
			setTimeout(show_modal, 4000);			
		}
		)
		.catch(err => {
			console.log('get profile err',err);
			return err;
		})
	}
	React.useEffect(() => {
		if(global.token!='' && global.token!=undefined && global.token!=null){
			setIsLoggedIn(true);
			get_profile_image();
		}
		fetch(Path+"check_verify_email.php", {
			method: "POST",       
			body: JSON.stringify({
				verify_token: global.getEmailVerifyToken
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
			if((response==0) && (global.getEmailVerifyToken!=null)){
				show_msg_modal();
			}else{
				dispatch({
					type: 'changeVerifyEmailModal',
					newVerifyEmailModal: {verifyEmailModal: false}
				})
			}		
		}
		)
		.catch(err => {
			console.log('get profile err',err);
			return err;
		})
		if(localStorage["website_langg"]){
			if(localStorage.getItem('website_text')=='English'){
				dispatch({
					type: 'changeSiteText',
					newSiteText: {siteText: lang_en}
				})
			}else{
				dispatch({
					type: 'changeSiteText',
					newSiteText: {siteText: lang_sp}
				})
			}
			dispatch({
				type: 'changeShowSiteLang',
				newSiteLang: {showSiteLang: localStorage.getItem('website_text')}
			})
		}else{
			dispatch({
				type: 'changeSiteText',
				newSiteText: {siteText: lang_en}
			})
			dispatch({
				type: 'changeShowSiteLang',
				newSiteLang: {showSiteLang: 'English'}
			})
		}
	},[global.token]);
	return (
		<section style={{position: "fixed",zIndex:"20",top:"0",width:"100%",background:"white",boxShadow: "0 0 30px rgba(0,0,0,0.10)"}}>
			<div className="row" style={{background:"#991870",padding:"4px 0px",width:"100%",margin:"0px"}}>
				<div className="container container-width">
					<div className="row">
					<div className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6 main_left_div">
					
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6 main_right_div">
						<ul className="social-icons standardd">
						
						<li>
							<div className="site_lang" onClick={(e) =>setLangDiv(!langDiv)}>
							{global.showSiteLang=='English'?(<img src={eng} alt="eng"/> ):''}							
							{global.showSiteLang=='Spanish'?(<img src={spa} alt="spa"/> ):''}					
							{global.showSiteLang=='Portuguese'?(<img src={pt} alt="pt"/> ):''}
							{global.showSiteLang}<i className="fa fa-caret-down"></i>
							</div>
							{langDiv?
							(<ul className="site_lang_ul">
							<li onClick={(e) =>change_language('English')}><img src={eng} alt="eng"/> English</li>
							<li onClick={(e) =>change_language('Spanish')}><img src={spa} alt="spa"/> Spanish</li>
							<li onClick={(e) =>change_language('Portuguese')}><img src={pt} alt="pt"/> Portuguese </li>
							</ul>):('')}
						</li>
						</ul>
						</div>
					</div>	
				</div>
			</div>
			<div className="container container-width">
				<div className="row top_nav_row">
					<div className="col-xl-2 col-lg-2 col-md-12 col-xs-12 col-sm-12 top_left_div">
						<Link to="/#demo"><img src={logo} className="app_logo" alt="logo"/></Link>
					</div>
					<div className="col-xl-7 col-lg-6 col-md-12 col-xs-12 col-sm-12 top_right_div">
					<nav className="navbar navbar-expand-lg head_nav">

					{isLoggedIn?
						  (<ul className="ul1" style={{display:"inline-flex",listStyle: "none",position:"absolute",right:"68px",top:"-54px"}}><li className="nav-item small_screen" style={{position:"relative"}}>
							<a className="nav-link effect-underline head_user" id="contact" onClick={() => dispatch({
							type: 'changeUserMenu',
							newUserMenu: {showUserMenu: !global.showUserMenu}
							})}>
								{global.showUserImg !='' && global.showUserImg !=undefined && global.showUserImg !=null ? 
									(<span className="top_img"><img src={Path+`uploads/${global.showUserImg}`}></img></span>): 
									(<span style={{backgroundColor:"rgb(14, 165, 223)",padding:"3px 4px 5px 4px",marginRight:"4px"}}><b className="top_letter">{global.showUserName?global.showUserName.charAt(0):('')}</b></span>)
								}
							<span className="top_user_name">{global.showUserName}</span> <i className="fa fa-caret-down"></i>
							</a>
							{global.showUserMenu ? (<div className="dropdown-menu1" style={{top:"88%"}}>
								<i className="fa fa-caret-up"></i>
								<a className="dropdown-item" target="_blank" onClick={() => ShowUserMenuPage('view_profile')}><i className="fa fa-user"></i> {global.siteText[0].usermenu_1} </a>
								<a className="dropdown-item" target="_blank" onClick={() => ShowUserMenuPage('edit_profile')}><i className="fa fa-edit"></i> {global.siteText[0].usermenu_2}</a>						
								<a className="dropdown-item" target="_blank" onClick={() => ShowUserMenuPage('change_pwd')}><i className="fa fa-key"></i> {global.siteText[0].usermenu_3}</a>
								{localStorage.getItem('coach')=='true' ?
									(<a className="dropdown-item" onClick={() => ShowUserMenuPage('all_projects')}><span className="proj_span1"><i className="fa fa-list"></i> {global.siteText[0].usermenu_6}</span></a>):('')
								}
								<a className="dropdown-item" onClick={() => ShowUserMenuPage('my_projects')}><span className="proj_span"><i className="fa fa-file"></i> {global.siteText[0].usermenu_4}</span></a>
								{/* <a className="dropdown-item" target="_blank" onClick={() => ShowUserMenuPage('donation')}><i className="fa fa-money"></i> {global.siteText[0].usermenu_5}</a> */}
							</div>):''}
							</li>
							  <li className="nav-item small_screen" style={{margin:"auto"}}>
								<a className="nav-link effect-underline reg_btn logout_btn logout_btn" id="contact" onClick={logout}>
								{global.siteText[0].logout}
								</a>
								<a className="nav-link effect-underline reg_btn logout_btn logout_icon" id="contact" onClick={logout} style={{display:"none"}}>
								  <i className="fa fa-power-off"></i>
								</a>
						  </li></ul>):
						  (<ul style={{display:"inline-flex",listStyle: "none",position:"absolute",right:"68px",top:"-42px"}}>
								<li className="nav-item small_screen">
								<a className="nav-link effect-underline reg_btn top_btns" id="contact" onClick={() => dispatch({
								type: 'changeRegModal',
								newRegModal: {showRegModal: !global.showRegModal}
								})}>
								  {global.siteText[0].reg_menu}
								</a>
								<a className="nav-link effect-underline reg_btn top_iconss" id="contact" onClick={() => dispatch({
								type: 'changeRegModal',
								newRegModal: {showRegModal: !global.showRegModal}
								})} style={{display:"none"}}>
								  <i className="fa fa-user"></i>
								</a>
							  </li>
							  <li className="nav-item small_screen">
								<a className="nav-link effect-underline log_btn top_btns" id="contact" onClick={() => dispatch({
								type: 'changeLogModal',
								newLogModal: {showLogModal: !global.showLogModal}
								})}>
								  <i className="fa fa-lock"></i> {global.siteText[0].login_menu}
								</a>
								<a className="nav-link effect-underline log_btn top_iconss" id="contact" onClick={() => dispatch({
								type: 'changeLogModal',
								newLogModal: {showLogModal: !global.showLogModal}
								})} style={{display:"none"}}>
								  <i className="fa fa-lock"></i>
								</a>
							  </li>
							</ul> )
						  }

					  <button className="navbar-toggler collapsed top_nav_btn" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" data-toggle="collapse" data-target="#navbarSupportedContent">
						<span className="navbar-toggler-icon top_bars"><i className="fa fa-bars"></i></span>
					  </button>
					  <div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav mr-auto head_ul">
						  <li className="nav-item active">
							<Link className="nav-link" to="/#demo">
								{global.siteText[0].menu1}
							</Link>
						  </li>
						  <li className="nav-item">							
							  <Link	to="/#about_us" className="nav-link">{global.siteText[0].menu2}</Link>
						  </li>
						  <li className="nav-item">
							<Link className="nav-link" to="/#stories">
							{global.siteText[0].menu3}
							</Link>
						  </li>	
						   <li className="nav-item">							
							  <Link	to="/#team_div" className="nav-link">{global.siteText[0].menu4}</Link>
						   </li>
						   <li className="nav-item">							
							  <Link	to="/#resources" className="nav-link">{global.siteText[0].menu7}</Link>
						   </li>
						  <li className="nav-item">
							  <Link to="/#faq" className="nav-link">{global.siteText[0].menu5}</Link>
						  </li>						  						  					  
						  <li className="nav-item">
							<Link to="/#contact_box" className="nav-link">{global.siteText[0].menu6}</Link>
						  </li>
						</ul>
					  </div>
					</nav>
				</div>
				<div className="col-xl-3 col-lg-4 top_right_div large_screen">
				{isLoggedIn?
				(<ul className="justify-content-end ul3" style={{margin:"auto"}}>
				   <li className="nav-item" style={{position:"relative"}}>
					<a className="nav-link effect-underline head_user" id="contact" onClick={() => dispatch({
					type: 'changeUserMenu',
					newUserMenu: {showUserMenu: !global.showUserMenu}
					})}>
						{global.showUserImg !='' && global.showUserImg !=undefined && global.showUserImg !=null ? 
							(<span className="top_img"><img src={Path+`uploads/${global.showUserImg}`}></img></span>): 
							(<span style={{backgroundColor:"rgb(14, 165, 223)",padding:"3px 4px 5px 4px",marginRight:"4px"}}><b className="top_letter">{global.showUserName?global.showUserName.charAt(0):('')}</b></span>)
						}
					 {global.showUserName} <i className="fa fa-caret-down"></i>
					</a>
					{global.showUserMenu ? (<div className="dropdown-menu1">
						<i className="fa fa-caret-up"></i>
						<a className="dropdown-item" target="_blank" onClick={() => ShowUserMenuPage('view_profile')}><i className="fa fa-user"></i> {global.siteText[0].usermenu_1} </a>
						<a className="dropdown-item" target="_blank" onClick={() => ShowUserMenuPage('edit_profile')}><i className="fa fa-edit"></i> {global.siteText[0].usermenu_2}</a>						
						<a className="dropdown-item" target="_blank" onClick={() => ShowUserMenuPage('change_pwd')}><i className="fa fa-key"></i> {global.siteText[0].usermenu_3}</a>
						{localStorage.getItem('coach')=='true' ?
							(<a className="dropdown-item" onClick={() => ShowUserMenuPage('all_projects')}><span className="proj_span1"><i className="fa fa-list"></i> {global.siteText[0].usermenu_6}</span></a>):('')
						}
						<a className="dropdown-item" onClick={() => ShowUserMenuPage('my_projects')}><span className="proj_span"><i className="fa fa-file"></i> {global.siteText[0].usermenu_4}</span></a>
						{/* <a className="dropdown-item" target="_blank" onClick={() => ShowUserMenuPage('donation')}><i className="fa fa-money"></i> {global.siteText[0].usermenu_5}</a> */}
				    </div>):''}
				  </li>
				  <li className="nav-item">
					<a className="nav-link effect-underline reg_btn logout_btn" id="contact" onClick={logout}>
					{global.siteText[0].logout}
					</a>
				  </li>
				</ul>):
				(
					<ul className="justify-content-end ul3" style={{margin:"auto"}}>
					<li className="nav-item">
					<a className="nav-link effect-underline reg_btn" id="contact" onClick={() => dispatch({
					type: 'changeRegModal',
					newRegModal: {showRegModal: !global.showRegModal}
					})}>
						{global.siteText[0].reg_menu}
					</a>
					</li>
					<li className="nav-item">
					<a className="nav-link effect-underline log_btn" id="contact" onClick={() => dispatch({
					type: 'changeLogModal',
					newLogModal: {showLogModal: !global.showLogModal}
					})}>
						<i className="fa fa-lock"></i> {global.siteText[0].login_menu}
					</a>
					</li>
				</ul>)

				}				
											
				</div>				
					
				</div>
			</div>
			<div>
				<Modal show={global.showRegModal} animation={false}>
					<Modal.Header closeButton onClick={() => dispatch({
						type: 'changeRegModal',
						newRegModal: {showRegModal: !global.showRegModal}
						})} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].register}</Modal.Title>
					</Modal.Header>
					<Modal.Body id="reg_modal_body">
						<Register/>				
					</Modal.Body>
					<div style={{textAlign:"center",width:"100%"}}>
						<p style={{color: "black",cursor:"pointer",marginBottom: "0",fontSize:"17px",paddingBottom:"16px"}} onClick={ChangeModal}> {global.siteText[0].already_act}</p>
					</div>
				</Modal>
			</div>
			<div>
				<Modal show={global.showLogModal} animation={false}>
					<Modal.Header closeButton onClick={() => dispatch({
						type: 'changeLogModal',
						newLogModal: {showLogModal: !global.showLogModal}
						})} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].login_heading}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Login/>		
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={donation} animation={false}>
					<Modal.Header closeButton onClick={() => setDonation(!donation)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].usermenu_5}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<iframe src={`${Path}pay_donation.php?amount_text=${global.siteText[0].donation1}`} width="460" height="200" style={{border:"none"}}></iframe>
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={showChangePass} animation={false}>
					<Modal.Header closeButton onClick={() => setChangePass(!showChangePass)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].chg_pwd}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Change_Password/>		
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={showEditProfile} animation={false}  dialogClassName="edit_div">
					<Modal.Header closeButton onClick={() => setEditProfile(!showEditProfile)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].edit_profile}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Edit_profile/>		
					</Modal.Body>
				</Modal>
        	</div>
			<div>
				<Modal show={viewProfile} animation={false}  dialogClassName="edit_div">
					<Modal.Header closeButton onClick={() => setViewProfile(!viewProfile)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].view_profile_heading}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<View_profile/>		
					</Modal.Body>
				</Modal>
        	</div>
		<div>
			<Modal show={global.showForgotModal} animation={false}>
				<Modal.Header closeButton className="modal_head" onClick={() => dispatch({
					type: 'changeForgotModal',
					newForgotModal: {showForgotModal: !global.showForgotModal}
					})}>
				<Modal.Title className="modal_title">{global.siteText[0].forgot_pass}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Forgot_pass/>
				</Modal.Body>
			</Modal>
		</div> 
		<div>
			<Modal show={global.verifyEmailModal} animation={false}>				
				<Modal.Body style={{padding:"40px",textAlign:"center",position:"relative"}}>
					<i class="fa fa-close" style={{position:"absolute",right:"10px",top:"10px",cursor:"pointer",fontSize:"22px"}}  onClick={() => dispatch({
						type: 'changeVerifyEmailModal',
						newVerifyEmailModal: {verifyEmailModal: false}
					})}></i>
					<p style={{marginBottom:"4px",fontSize:"24px",color:"green",fontWeight:"bold"}}>{global.siteText[0].congrats}</p>
                    <p style={{marginBottom:"0px",color:"green"}}>{global.siteText[0].verify_msg}</p>
				</Modal.Body>
			</Modal>
		</div>
		<div>
			<Modal show={global.showForgotPasswordModal} animation={false}>
				<Modal.Header closeButton className="modal_head" onClick={() => dispatch({
					type: 'changeForgotPasswordModal',
					newForgotPasswordModal: {showForgotPasswordModal: !global.showForgotPasswordModal}
					})}>
				<Modal.Title className="modal_title">{global.siteText[0].chg_pwd}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ChangeForgotPassword/>
				</Modal.Body>
			</Modal>
		</div>	
		</section>
	)
	
}
export default Navbar;