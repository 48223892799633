import React,{useState} from 'react';
import {Button} from 'react-bootstrap';
import { Path} from './Path.js';
import loading from './../images/loading.gif';
import { useStateValue } from './StateProvider.js';
const Change_Password=()=>{
	const [{ global }, dispatch] = useStateValue();
	const [OldPass, setOldPass] = useState('');
	const [NewPass, setNewPass] = useState('');
	const [NewConPass, setNewConPass] = useState('');
	const [Opacity, setOpacity] = useState('');
	const [showPassValue, setShowPassValue] = useState('');
	const [showPassValue1, setShowPassValue1] = useState('');
	const [showPassValue2, setShowPassValue2] = useState('');
	const [PointerEvents, setPointerEvents] = useState('');
	const [SuccessError, setSuccessError] = useState(false);
	const [FailedError, setFailedError] = useState(false);
	const [FailedError1, setFailedError1] = useState(false);
	const [Loader, setLoader] = useState(false);
	const [errors, setErrors] = useState('');
	const [token, setToken] = useState(localStorage.getItem('token'));
	
   	const handleValidation=()=>{
		let formIsValid = false;
		if(OldPass===''){
			setErrors(global.siteText[0].error_msg_23);		   
		}else if(NewPass===''){
			setErrors(global.siteText[0].error_msg_17);
		}else if(NewConPass<8){
			setErrors(global.siteText[0].error_msg_63);
		}
		// else if(NewConPass.includes('  ')){
		// 	setErrors('Multiple spaces are not allowed.');
		// }
		else if(/(.)\1\1/.test(NewConPass)){
			setErrors(global.siteText[0].error_msg_63);
		}else if (NewConPass.search(/\d/) == -1) {
			setErrors(global.siteText[0].error_msg_63);
		}else if (NewConPass.search(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/) == -1) {
			setErrors(global.siteText[0].error_msg_63);
		}else if (NewConPass.search(/[A-Z]/) == -1) {
			setErrors(global.siteText[0].error_msg_63);
		}else if(NewConPass===''){
			setErrors(global.siteText[0].error_msg_8);
		}else if(NewConPass!=NewPass){
			setErrors(global.siteText[0].error_msg_9);
		}else {
			setErrors('');
			formIsValid = true;
		}	
		return formIsValid;
	}
	const seterror=()=>{		
	    setSuccessError(false);
	    setFailedError(false);
	    setFailedError1(false);	
	} 
	const seterror1=()=>{
		setErrors('');	
	}  
	const Password_data=()=>{
	if(handleValidation()){
		setLoader(true);
		setOpacity('0.5');
		setPointerEvents('none');
			fetch(Path+"ajax_change_pass.php", {
				method: "POST",       
				body: JSON.stringify({
					OldPass: OldPass,
					token: token,
					NewPass: NewPass
				}), 
				headers: { 
					"Content-type": "application/json; charset=UTF-8"
				} 
			})
			.then(res =>res.json())								
			.then(response=>{
					setLoader(false);
					setOpacity('');
					setPointerEvents('');
					console.log(response);					
					if(response.error=='1'){
						setSuccessError(true);						
						setTimeout(seterror, 4000);
						document.getElementById("change_pwd").reset();
					}else if(response.error=='0'){
						setFailedError(true);
						setTimeout(seterror, 4000);
					}else if(response.error=='00'){
						setFailedError1(true);
						setTimeout(seterror, 4000);
						
					}
				}
			)
			.catch(err => {
				setLoader(false);
				setOpacity('');
				setPointerEvents('');
				setErrors(global.siteText[0].error_msg_14);
				setTimeout(seterror1, 4000);
				return err;
			})
		}
	}
	
		return(
		<section>
			<form id="change_pwd" className="formm contact_formm" onSubmit={Password_data}>
				{SuccessError? (<div className="alert alert-success" id="success2">{global.siteText[0].error_msg_20}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>):('')}				
				{FailedError? (<div className="alert alert-danger" id="danger2">{global.siteText[0].error_msg_2}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>):('')}
				{FailedError1? (<div className="alert alert-danger" id="danger2">{global.siteText[0].error_msg_25}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>):('')}
				{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
				<div className="row">
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].chg_pwd1}</label>
						<input type={showPassValue!='' ? 'text' : 'password'} className="form-control" name="OldPass" onChange={event => setOldPass(event.target.value)}/>
						{showPassValue!='' ? 
						(<i className="fa fa-eye-slash show_pass" onClick={event => setShowPassValue('')}></i>):
						(<i className="fa fa-eye show_pass"  onClick={event => setShowPassValue('true')}></i>)}
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].chg_pwd2}</label>
						<input type={showPassValue1!='' ? 'text' : 'password'} className="form-control" name="NewPass" onChange={event => setNewPass(event.target.value)}/>
						{showPassValue1!='' ? 
						(<i className="fa fa-eye-slash show_pass" onClick={event => setShowPassValue1('')}></i>):
						(<i className="fa fa-eye show_pass"  onClick={event => setShowPassValue1('true')}></i>)}
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].chg_pwd3}</label>
						<input type={showPassValue2!='' ? 'text' : 'password'} className="form-control" name="NewConPass" onChange={event => setNewConPass(event.target.value)}/>
						{showPassValue2!='' ? 
						(<i className="fa fa-eye-slash show_pass" onClick={event => setShowPassValue2('')}></i>):
						(<i className="fa fa-eye show_pass"  onClick={event => setShowPassValue2('true')}></i>)}
					</div>
					<div className="col-md-12 col-sm-12 text-right" style={{marginTop:"20px"}}>
						<Button variant="primary" className="modal_btn" onClick={Password_data} style={{opacity:Opacity,pointerEvents:PointerEvents}}>
							{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].contact_7}
						</Button>						
					</div>					
				</div>
			</form>	
		</section>
		)
}
export default Change_Password;