import React,{useEffect} from 'react';
import icon from './../images/icon.png';
import { useStateValue } from './StateProvider.js';
function Services(){
	const [{ global }, dispatch] = useStateValue();	
	return(
	<section>	
		<div className="container" style={{maxWidth:"1100px",paddingBottom:"40px"}}>
		<div className="main_guide">
			<h1 className="head_text">{global.siteText[0].terms} & <span style={{color:"#0ea5df"}}>{global.siteText[0].conditions}</span></h1>	
			<div className="divider text-center" style={{marginBottom:"20px"}}>
				<span className="outer-line"></span>
				<span className="image"><img src={icon} alt=""/></span>
				<span className="outer-line"></span>
			</div>
			<div className="guide">
				<p>{global.siteText[0].service_1}</p>
				<p>{global.siteText[0].service_2}.</p>
				<p>{global.siteText[0].service_3}</p>
				<p>{global.siteText[0].service_4}</p>
				<p>{global.siteText[0].service_5}</p>
				<p>{global.siteText[0].service_6}. </p>
				<p>{global.siteText[0].service_7}</p>
				<p>{global.siteText[0].service_8}.</p>
				<p>{global.siteText[0].service_9}</p>
				<p>{global.siteText[0].service_10}:</p>
				<ul>
					<li>{global.siteText[0].guidelines_4}</li>
					<li>{global.siteText[0].guidelines_5}</li>
					<li>{global.siteText[0].guidelines_6}</li>
					<li>{global.siteText[0].guidelines_7}</li>
					<li>{global.siteText[0].guidelines_8}</li>
					<li>{global.siteText[0].guidelines_9}</li>
					<li>{global.siteText[0].guidelines_10}</li>
					<li>{global.siteText[0].guidelines_11}</li>
					<li>{global.siteText[0].guidelines_12}.</li>
				</ul>
				<p>{global.siteText[0].service_11}. </p>
				<p>{global.siteText[0].service_12}.</p>
				<p>{global.siteText[0].service_13}</p>
				<p>{global.siteText[0].service_14}.</p>
				<p>{global.siteText[0].service_15}</p>
				<p>{global.siteText[0].service_16}:</p>
				<ul>
					<li>{global.siteText[0].service_17}</li>
					<li>{global.siteText[0].service_18}</li>
					<li>{global.siteText[0].service_19}</li>
					<li>{global.siteText[0].service_20}</li>
					<li>{global.siteText[0].service_21}</li>
					<li>{global.siteText[0].service_22}</li>
					<li>{global.siteText[0].service_23}</li>
					<li>{global.siteText[0].service_24}</li>
					<li>{global.siteText[0].service_25} {global.siteText[0].service_25_1} {global.siteText[0].service_25_2}</li>					
				</ul>
				<p>{global.siteText[0].service_26}.</p>
				<p>{global.siteText[0].service_27}</p>
				<p>{global.siteText[0].service_28}:</p>
				<ul>
					<li>{global.siteText[0].service_29};</li>
					<li>{global.siteText[0].service_30};</li>
					<li>{global.siteText[0].service_31};</li>
					<li>{global.siteText[0].service_32}.</li>
				</ul>
				<p>{global.siteText[0].service_33}</p>
				<p>{global.siteText[0].service_33_1}</p>
				<p>{global.siteText[0].service_34}:</p>
				<p>{global.siteText[0].service_35}.</p>
				<p>{global.siteText[0].service_36}.</p>
				<p>{global.siteText[0].service_40}</p>
				<p>{global.siteText[0].service_41}.</p>
				<p>{global.siteText[0].service_42}</p>
				<p>{global.siteText[0].service_43}.</p>
				<p>{global.siteText[0].service_44}</p>
				<p>{global.siteText[0].service_45}.</p>
				<p>{global.siteText[0].service_46}.</p>
				<p>{global.siteText[0].service_47}.</p>
				<p>{global.siteText[0].service_48}</p>
				<p>{global.siteText[0].service_49}.</p>
				<p>{global.siteText[0].service_50}.</p>
				<p>{global.siteText[0].service_51}.</p>
				<p>{global.siteText[0].service_52}</p>
				<p>{global.siteText[0].service_53}.</p>
				<p>{global.siteText[0].service_54}</p>
				<p>{global.siteText[0].service_55}.</p>
				<p>{global.siteText[0].service_56}</p>
				<p>{global.siteText[0].service_57}.</p>
				<p>{global.siteText[0].service_58}</p>
				<p>{global.siteText[0].service_59}.</p>
				<p>{global.siteText[0].service_60}</p>
				<p>{global.siteText[0].service_61}.</p>
				<p>{global.siteText[0].service_62}</p>
				<p>{global.siteText[0].service_63}.</p>
				<p>{global.siteText[0].service_64}</p>
				<p>{global.siteText[0].service_65}.</p>
				<p>{global.siteText[0].service_66}</p>
				<p>{global.siteText[0].service_67}.</p>
				<p>{global.siteText[0].service_68}. </p>
				<p>{global.siteText[0].service_69}.</p>
				<p>{global.siteText[0].service_70}</p>
				<p>{global.siteText[0].service_71}.</p>
				<p>{global.siteText[0].service_72}</p>
				<p>{global.siteText[0].service_73}.</p>
				<p>{global.siteText[0].service_74}</p>
				<p>{global.siteText[0].service_75}.</p>
				<p>{global.siteText[0].service_76}</p>
				<p>{global.siteText[0].service_77}.</p>
				<p>{global.siteText[0].service_78}</p>
				<p>{global.siteText[0].service_79}. </p>
				<p>{global.siteText[0].service_80}</p>
				<p>{global.siteText[0].service_81}.</p>
				<p>{global.siteText[0].service_82}</p>
				<p>{global.siteText[0].service_83}.</p>
				<p>{global.siteText[0].service_84}</p>
				<p>{global.siteText[0].service_85}.</p>
				<p>{global.siteText[0].service_86}.</p>
				<p>{global.siteText[0].service_87}</p>
				<p>{global.siteText[0].service_88}.</p>
				<p>{global.siteText[0].service_89}</p>
				<p>{global.siteText[0].service_90}.</p>
				<p>{global.siteText[0].service_91}</p>
				<p>{global.siteText[0].service_92}.</p>
				<p>{global.siteText[0].service_93}</p>
				<p>{global.siteText[0].service_94}.</p>
				<p>{global.siteText[0].service_95}:</p>
				<ul>
					<li>(1) {global.siteText[0].service_96};</li>
					<li>(2) {global.siteText[0].service_97},</li>
					<li>(3) {global.siteText[0].service_98}.</li>
				</ul>
				<p>{global.siteText[0].service_99}</p>
				<p>{global.siteText[0].service_100}.</p>
				<p>{global.siteText[0].service_101}</p>
				<p>{global.siteText[0].service_102}.</p>
				<p>{global.siteText[0].service_103}</p>
				<p>{global.siteText[0].service_104}.</p>
			</div>
			</div>
		</div>			  
	</section>
	)
}
export default Services;