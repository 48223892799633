import React,{useState} from 'react';
import { Path } from './Path.js';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import { useStateValue } from './StateProvider.js';
const Project_details=(props)=>{
	const [{ global }, dispatch] = useStateValue();
    const [project_detail, setProject_detail] = useState('');
	const Category_data=()=>{		
		fetch(Path+"ajax_project_details.php", { 
			method: "POST",       
			body: JSON.stringify({
				show_id: props.showId
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
            setProject_detail(response);				
			}
        )
        .catch(err => {
            return err;
        })		
	}
	React.useEffect(() => {
		Category_data();
	},[]);	
		return(
		<section>
			<div>
                <table className="project_det">
                    <tbody>
                        <tr>
                            <td className="project_head">{global.siteText[0].my_projects_20}</td>
                        </tr>
                        <tr className="table_row">
                            <td className="project_detail" colSpan="2">{project_detail.title}</td>
                        </tr>
                        <tr className="table_row">
                            <td className="project_head">{global.siteText[0].my_projects_21}:</td>
                            <td className="project_detail">{global.showSiteLang=='English' ? project_detail.expert : project_detail.expert_spanish}</td>
                        </tr>
                        <tr className="table_row">
                            <td className="project_head">{global.siteText[0].my_projects_57}</td>
                            <td className="project_detail">{global.showSiteLang=='English' ? project_detail.type : project_detail.type_spanish}</td>
                        </tr>
                        <tr className="table_row">
                            <td className="project_head">{global.siteText[0].languages}:</td>
                            <td className="project_detail">{global.showSiteLang=='English' ? project_detail.show_languages : project_detail.show_languages_spanish}</td>
                        </tr>
                        <tr>
                            <td className="project_head">{global.siteText[0].description}:</td>
                        </tr>
                        <tr className="table_row">
                            <td className="project_detail"  colSpan="2">{project_detail.desc}</td>
                        </tr>
                    </tbody>
                </table>
                <p style={{fontSize:"13px",marginBottom:"0px",marginTop:"20px"}}>{global.siteText[0].created_on} {project_detail.date}</p>
            </div>
		</section>
		)
}
export default Project_details;