import React,{useState,useRef} from 'react';
import {Button,Modal} from 'react-bootstrap';
import icon from './../images/icon.png';
import Project_details from './Project_details.js';
import { Path } from './Path.js';
import ProjectRequest from './ProjectRequest.js';
import CoachMsgBox from './CoachMsgBox.js';
import Pagination from './Pagination.js';
import { useHistory } from 'react-router-dom';
import { useStateValue } from './StateProvider.js';
import { HashLink as Link } from 'react-router-hash-link';
import CoachDetails from './CoachDetails';
const ReqProjects=()=>{
	const [{ global }, dispatch] = useStateValue();
	let history = useHistory();
	const get_project_status = useRef(null);	
	const [showId, setShowId] = useState('');
	const [request_id, setRequestId] = useState('');
	const [allProjects, setAllProjects] = useState('');
	const [allMsgs, setAllMsgs] = useState('');
	const [projectIds, setProjectIds] = useState('');
	const [page, setPage] = useState('1');
	const [loader, setLoader] = useState(true);
	const [BtnStyle, setBtnStyle] = useState({Opacity:'1',PointerEvents:''});	
	const [Loader, setLoaderr] = useState(false);
	const [tableOpacity, setTableOpacity] = useState('');
	const [startFrom, setStartFrom] = useState('');
	const [projectStatus, setProjectStatus] = useState('');
	const [msgId, setMsgId] = useState('');
	const [totalPages, setTotalpages] = useState('');
	const [cancelOfferModal,setCancelOfferModal] = useState(false);
	const [cancelProjectId,setCancelProjectId] = useState('');
	const [projectRecords, setProjectRecords] = useState('');
	const [all_categories, setAll_categories] = useState('');
	const [viewId, setViewId] = useState('');
	const [show_project_details, setProjectDetails] = useState(false);
	//const [projectRequest, setProjectRequest] = useState(false);
	const [cancelModal, setCancelModal] = useState(false);
	const [cancelRegId, setCancelRegId] = useState('');
	const [viewMessages, setViewMessages] = useState(false);
	const [viewUserDetail, setViewUserDetail] = useState(false);
	const [token, setToken] = useState(localStorage.getItem('token'));
	const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
	let show_ids=[];
	const view_details_modal=(show_id)=>{
		setProjectDetails(!show_project_details);
		setShowId(show_id);
	}
	const view_request_modal=(req_id)=>{
		//setProjectRequest(!projectRequest);
		setProjectStatus(get_project_status.current.value);
		setRequestId(req_id);
		dispatch({
			type: 'changeProjectRequestModal',
			newProjectRequestModal: {projectRequestModal: !global.projectRequestModal}
		})
	}
	const cancel_offers=(project_id)=>{
		setCancelProjectId(project_id);
		setCancelOfferModal(!cancelOfferModal);
	}
	const cancel_request_modal=(cancel_id)=>{
		setCancelRegId(cancel_id);
		setCancelModal(true);
	}
	const view_messages=(project_id)=>{
		setMsgId(project_id);
		setProjectStatus(get_project_status.current.value);
		setViewMessages(!viewMessages);
	}
	const view_details=(user_id)=>{
		setViewUserDetail(!viewUserDetail);
		setViewId(user_id);
	}				
	const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
	}
	
    const show_notification=(response)=>{	   
	   if(response.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
	   }else if(response.success!=null){
		   setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});
	   }
	   setCancelModal(false);
	   setTimeout(hide_notification, 4000);
	}
	const CancelRequest=()=>{
		setLoaderr(true);
        setBtnStyle(prevState => ({
            ...prevState,
            Opacity: '0.5'
         }));
        setBtnStyle(prevState => ({
            ...prevState,
            PointerEvents: 'none'
         }));
        fetch(Path+"cancel_accepted_project.php", {
			method: "POST", 
			body: JSON.stringify({
				token: token,
				project_id:cancelRegId,
				project_status:get_project_status.current.value,
				page: page
			}), 				
        })
        .then(res => res.json())
        .then(response=>{	
            setLoaderr(false);
            setBtnStyle(prevState => ({
                ...prevState,
                Opacity: ''
             }));
            setBtnStyle(prevState => ({
                ...prevState,
                PointerEvents: ''
             }));
            setAllProjects(response.project_data);
			setAll_categories(response.all_categories);		
			setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			setProjectRecords(response.pagination_data.total_records);
			show_notification(response.notifications);    
            }
        )
        .catch(err => {
            return err;
        })
	}	
	const Search_projects=()=>{
		setLoader(true);
		setTableOpacity('0.5');
		fetch(Path+"get_req_projects.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				project_status:get_project_status.current.value,
				page: page
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			setLoader(false);
			setTableOpacity('');
			setAllProjects(response.project_data);
			setAll_categories(response.all_categories);		
			setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			setProjectRecords(response.pagination_data.total_records);
		})
		.catch(err => {
            return err;
        })
	}
		
	const get_project_data=(pagee)=>{
		setLoader(true);
		setTableOpacity('0.5');
		fetch(Path+"get_req_projects.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				project_status:get_project_status.current.value,
				page: pagee
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			setLoader(false);
			setTableOpacity('');
			var project_count=Object.keys(response.project_data).length;
			for (var j = 1; j <= project_count; j++) {
				show_ids.push(response.project_data[j].project_id);
			}	
			setProjectIds(show_ids);
			load_msgs();			
			setAllProjects(response.project_data);
			setAll_categories(response.all_categories);		
			setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			setProjectRecords(response.pagination_data.total_records);
		})
		.catch(err => {
            return err;
        })		
		
	}
	const CancelProjectOffer=()=>{
		setLoaderr(true);
		setBtnStyle(prevState => ({
			...prevState,
			Opacity: '0.5'
			}));
		setBtnStyle(prevState => ({
			...prevState,
			PointerEvents: 'none'
			}));
		fetch(Path+"cancel_offer.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				project_id:cancelProjectId,
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			setLoaderr(false);
			setBtnStyle(prevState => ({
				...prevState,
				Opacity: ''
				}));
			setBtnStyle(prevState => ({
				...prevState,
				PointerEvents: ''
				}));
			setCancelOfferModal(!cancelOfferModal);
			get_project_data(page);
			show_notification(response.notifications);
		})
		.catch(err => {
			setLoader(false);
			setTableOpacity('');
            return err;
        })
	}
	const load_msgs=()=>{
		fetch(Path+"load_coach_msg.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				project_ids:projectIds
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			//console.log(response);
			setAllMsgs(response);
		})
		.catch(err => {
            return err;
        })		
		
	}
	const Onload=()=>{
		{localStorage.getItem('token')!=null ? (history.push('/RequestedProjects')):(history.push('/'))}
	}		
	React.useEffect(() => {
		Onload();
		get_project_data(page);
		const timer = setInterval(() => {
			load_msgs();
		}, 7000);
		return () => clearInterval(timer);
	},[startFrom]);
	return(
		<section style={{background:"rgb(246, 252, 253)",marginTop:"85px"}}>
			<h1 className="head_text" style={{paddingTop:"30px"}}>{global.siteText[0].my_projects_heading1} <span style={{color:"#0ea5df"}}>{global.siteText[0].my_projects_heading2}</span></h1>	
			<div className="divider text-center">
				<span className="outer-line"></span>
				<span className="image"><img src={icon} alt=""/></span>
				<span className="outer-line"></span>
			</div>
			<div className="container container-width" style={{marginTop:"28px"}}>
				<ul className="nav nav-tabs justify-content-center" role="tablist" style={{width:"100%"}}>
					<li className="nav-item">
						<Link to="/MyProjects" className="nav-link">{global.siteText[0].my_projects_1}</Link>
					</li>
					<li className="nav-item">
						<Link to="/RequestedProjects" className="nav-link active">{global.siteText[0].my_projects_2}</Link>
					</li>
				</ul>
			</div>			
			<div className="container container-width" style={{paddingBottom:"40px"}}>
				{notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}		
				{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}
				<div className="row coach_filter" style={{margin:"0",marginTop:"40px"}}>
					<div className="col-xl-7 col-lg-6 col-md-4 col-sm-4 col-xs-3" style={{padding:"0",margin:"auto"}}>
						<h4 style={{fontSize:"17px",fontWeight:"600",marginBottom:"0"}}>{global.siteText[0].my_projects_6} {allProjects ? <span>{projectRecords}</span>:''}</h4>
					</div>
					<div className="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-9" style={{padding:"0",textAlign:"right"}}>
					<div className="row" style={{margin:"0"}}>
						<div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
							<select className="form-control" ref={get_project_status} style={{width:"200px",float:"right"}}>
								<option value="1_0">Accepted & Pending</option>
								<option value="">All projects</option>
								<option value="0">Pending</option>
								<option value="1">Accepted</option>
								<option value="2">Rejected</option>
								<option value="4">Closed</option>
								<option value="5">Expired</option>
								<option value="3">Cancelled</option>
							</select>
						</div>
						<div className="col-xl-2 col-lg-2 col-md-2 col-xs-2 col-sm-2" style={{padding:"0",textAlign:"right"}}>
							<Button variant="primary" className="add_btn" onClick={Search_projects}>{global.siteText[0].my_projects_4}</Button>
							<i className="fa fa-search sear_coach" style={{display:"none"}}onClick={Search_projects}></i>
						</div>	
					</div>
					</div>	
				</div>
				<div className="table-responsive div_show" id="no-more-tables" style={{position:"relative"}}>
					{loader?(<div className="spinner-border" role="status" style={{position:"absolute",top:"50%",left:"50%"}}>
					  <span className="sr-only">Loading...</span>
					</div>):('')}
					<table className="table" style={{opacity:tableOpacity}}>
					  <thead className="thh">
						<tr>
							<th>{global.siteText[0].my_projects_22}</th>
							<th>{global.siteText[0].my_projects_23}</th>								
							<th>{global.siteText[0].my_projects_21}</th>
							<th>{global.siteText[0].my_projects_57}</th>
							<th>{global.siteText[0].my_projects_24}</th>
							<th>{global.siteText[0].my_projects_25}</th>
							<th style={{width:"204px"}}>{global.siteText[0].my_projects_62}</th>							
							<th>{global.siteText[0].my_projects_26}</th>
						</tr>
					  </thead>
					  <tbody id="tbo">{allProjects?Object.keys(allProjects).map((key,i)=>
					  <tr key={i}>
						<td className='count_rec' data-title={global.siteText[0].my_projects_22} style={{width:"100px"}}>{startFrom+i+1}</td>
						<td className='count_rec proj_title' data-title={global.siteText[0].my_projects_23} style={{width:"320px"}} onClick={() =>view_details_modal(allProjects[key].project_id)}>{allProjects[key].title}</td>
						<td className='count_rec' data-title={global.siteText[0].my_projects_21}>{global.showSiteLang=='English' ? allProjects[key].name : allProjects[key].name_spanish}</td>
						<td className='count_rec' data-title={global.siteText[0].my_projects_57}>{global.showSiteLang=='English' ? allProjects[key].type : allProjects[key].type_spanish}</td>
						<td className='count_rec' data-title={global.siteText[0].my_projects_24}>{allProjects[key].sessions}</td>
						<td className='count_rec' data-title={global.siteText[0].my_projects_25}>{allProjects[key].status}</td>
						{allProjects[key].status=='Accepted'?
						(<td className='count_rec' data-title={global.siteText[0].my_projects_62} style={{width:"136px",color:"#0772e4",cursor:"pointer"}} onClick={() =>view_details(allProjects[key].user_id)}>{global.siteText[0].my_projects_63}</td>):
						(<td className='count_rec' data-title={global.siteText[0].my_projects_62}></td>)}
						<td className='count_rec action_icon' data-title={global.siteText[0].my_projects_26} style={{width:"200px"}}>
							{allProjects[key].status=='Accepted'?
							(<span className='delete' title={global.siteText[0].my_projects_64} onClick={() =>cancel_request_modal(allProjects[key].project_id)} style={{marginRight:"10px"}}><i className='fa fa-unlink req_icon action_icons' aria-hidden='true' style={{background:"#dc3545"}}></i></span>):('')}
							{allProjects[key].status=='Pending' && allProjects[key].offered=='0'?
							(<span className='delete' title={global.siteText[0].my_projects_53} onClick={() =>view_request_modal(allProjects[key].project_id)} style={{marginRight:"10px"}}><i className='fa fa-bookmark req_icon action_icons' aria-hidden='true'></i></span>):('')
					  		}

							{allProjects[key].status=='Pending' && allProjects[key].offered=='1'?
							(<span className='delete' title={global.siteText[0].my_projects_65} onClick={() =>cancel_offers(allProjects[key].project_id)}><i className='fa fa-remove del_icon action_icons' aria-hidden='true' style={{marginRight:"10px",background:"purple"}}></i></span>):('')
					  		}

							{allProjects[key].status=='Accepted' || allProjects[key].status=='Pending'?
							(<span className='edit' title={global.siteText[0].my_projects_41} onClick={() => view_messages(allProjects[key].project_id)} style={{position:"relative"}}><i className='fa fa-envelope chat_icon action_icons' aria-hidden='true'></i>
							{allMsgs[allProjects[key].project_id]?
							(<span>
							{allMsgs[allProjects[key].project_id].total_msgs!=null ? (<span className="msg_notify" style={{top:"-11px",right:"-14px",left:"auto"}}>{allMsgs[allProjects[key].project_id].total_msgs}</span>):''}</span>):('')}</span>):('')}

						</td>
					  </tr>):(<tr><td colSpan="6" style={{textAlign:"center"}}>{global.siteText[0].my_projects_69}.</td></tr>)}
					  </tbody>
					</table>
				</div>
			</div>
			<Pagination page={page} totalpages={totalPages} onUpdatePage={get_project_data}/>
			<div>
				<Modal show={show_project_details} animation={false}>
					<Modal.Header closeButton onClick={() => setProjectDetails(!show_project_details)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_58}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Project_details showId={showId}/>		
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={global.projectRequestModal} animation={false}>
					<Modal.Header closeButton onClick={() => dispatch({
					type: 'changeProjectRequestModal',
					newProjectRequestModal: {projectRequestModal: !global.projectRequestModal}
					})} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_71}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ProjectRequest request_id={request_id} page={page} onUpdate={setAllProjects} onTotalRecords={setProjectRecords} onTotalPages={setTotalpages} onStartFrom={setStartFrom} onPage={setPage} project_status={projectStatus}/>		
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={viewMessages} animation={false}>
					<Modal.Header closeButton onClick={() => setViewMessages(!viewMessages)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_41}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<CoachMsgBox proj_id={msgId} current_page={page} onUpdate={setAllProjects} onTotalRecords={setProjectRecords} onTotalPages={setTotalpages} onStartFrom={setStartFrom} onPage={setPage} project_status={projectStatus} />	
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={cancelOfferModal} animation={false}>
					<Modal.Header closeButton onClick={() => setCancelOfferModal(!cancelOfferModal)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_65}</Modal.Title>
					</Modal.Header>
					<Modal.Body className="delete_modal">
						<h4>{global.siteText[0].my_projects_30}</h4>
						<p>{global.siteText[0].my_projects_72}</p>
					</Modal.Body>
					<Modal.Footer className="delete_modal_footer">
						<Button variant="primary" className="modal_del_btn" onClick={CancelProjectOffer} style={{backgroundColor:"green",borderColor:"green",opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].my_projects_34}</Button>
						<Button variant="secondary" className="modal_cencel_btn" onClick={() => setCancelOfferModal(!cancelOfferModal)}>{global.siteText[0].my_projects_35}</Button>
					  </Modal.Footer>
				</Modal>
			</div>
			<div>
				<Modal show={viewUserDetail} animation={false}  dialogClassName="edit_div">
					<Modal.Header closeButton onClick={() => setViewUserDetail(!viewUserDetail)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].sendcancelreq_email_content_3}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<CoachDetails coach_id={viewId}/>
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={cancelModal} animation={false}>
					<Modal.Header closeButton onClick={() => setCancelModal(!cancelModal)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_64}</Modal.Title>
					</Modal.Header>
					<Modal.Body className="delete_modal">
						<h4>{global.siteText[0].my_projects_73}</h4>
						<p style={{fontSize:"15px"}}>{global.siteText[0].my_projects_74}</p>
					</Modal.Body>
					<Modal.Footer className="delete_modal_footer">
						<Button variant="primary" className="modal_del_btn del_btnn" onClick={CancelRequest} style={{opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].my_projects_34}</Button>
						<Button variant="secondary" className="modal_cencel_btn" onClick={() => setCancelModal(!cancelModal)}>{global.siteText[0].my_projects_35}</Button>
					</Modal.Footer>
				</Modal>
			</div>			
		</section>
	);
}
export default ReqProjects;