import React,{useRef,useState} from 'react';
import {Button,Modal} from 'react-bootstrap';
import { Path } from './Path.js';
import Countries from './Countries.js';
import Languages1 from './Languages1.js';
import Guidelines from './Guidelines.js';
import Services from './Services.js';
import { useStateValue } from './StateProvider.js';
const Register=()=>{
	const [{ global }, dispatch] = useStateValue();
	const [username, setUserName] = useState('');
	const [lname, setLastName] = useState('');
	const [user_email, setEmail] = useState('');
	const [user_pwd, setPwd] = useState('');
	const [user_cpwd, setCPwd] = useState('');
	const [country, setCountry] = useState('');
	const [countrySpanish, setCountrySpanish] = useState('');
	const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
	const [showPassValue, setShowPassValue] = useState('');
	const [showPassValue1, setShowPassValue1] = useState('');
	const [Loader, setLoader] = useState(false);
	const [errors, setErrors] = useState('');
	const [guidelines, setGuidelines] = useState(false);
	const [terms, setTerms] = useState(false);
	const [show_countries, setCountries] = useState(false);
	const [show_countriesSpanish, setCountriesSpanish] = useState(false);
	const [selectedLangs, setSelectedLangs] = useState([]);
	const [langs, setLangs] = useState([]);
	const [condition, setCondition] = useState(false);
	const [token, setToken] = useState(localStorage.getItem('token'));
	const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
   	const handleValidation=()=>{
		let formIsValid = false;
		if(username===''){
			setErrors(global.siteText[0].error_msg_4);		   
		}else if(lname===''){
			setErrors(global.siteText[0].error_msg_5);
		}else if(user_email==''){
			setErrors(global.siteText[0].error_msg_6);
		}else if(user_pwd==''){
			setErrors(global.siteText[0].error_msg_7);
		}else if(user_pwd.length<8){
			setErrors(global.siteText[0].error_msg_63);
		}else if(/(.)\1\1/.test(user_pwd)){
			setErrors(global.siteText[0].error_msg_63);
		}
		else if (user_pwd.search(/\d/) == -1) {
			setErrors(global.siteText[0].error_msg_63);
		}
		else if (user_pwd.search(/[A-Z]/) == -1) {
			setErrors(global.siteText[0].error_msg_63);
		}
		else if (user_pwd.search(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/) == -1) {
			setErrors(global.siteText[0].error_msg_63);
		}
		else if(user_cpwd==''){
			setErrors(global.siteText[0].error_msg_18);
        }
		else if(user_pwd!=user_cpwd){
			setErrors(global.siteText[0].error_msg_19);
        }
		else if(country==''){
			setErrors(global.siteText[0].error_msg_28);
        }else if(selectedLangs.length==0){
			setErrors(global.siteText[0].error_msg_29);
		}
		else if(terms==false){
			setErrors(global.siteText[0].error_msg_12);
		}else {
			setErrors('');
			formIsValid = true;
		}	
		return formIsValid;
	}
	const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
		setErrors('');
	}
    const show_notification=(response)=>{	   
	   if(response.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
	   }else if(response.success!=null){
		   setNotification({success:response.success,failed:'',show_failed:false,show_success:true});
		   setSelectedLangs([]);
		   setLangs([]);
		   setTerms(false);
		   document.getElementById("reg_form").reset();
	   }
	   setTimeout(hide_notification, 4000);
    }
	const seterror1=()=>{
		setErrors('');	
	}  
	const get_countries=()=>{
		fetch(Path+"get_countries.php", { 	
		})
		.then(res => res.json())
		.then(response=>{
				setCountries(response.countries);
				setCountriesSpanish(response.countries_spanish);
				//console.log('data',);
			}
		)
		.catch(err => {
			return err;
		})		
	} 
	const Register_data=()=>{				
		if(handleValidation()){						
			setLoader(true);
			setOpacity('0.5');
			setPointerEvents('none');
			fetch(Path+"ajax_register.php", {
				method: "POST",       
				body: JSON.stringify({
					username: username,
					lname: lname,
					user_email: user_email,
					user_pwd: user_pwd,
					languages: selectedLangs,
					country: country,
					site_lang:global.showSiteLang
				}), 
				headers: { 
					"Content-type": "application/json; charset=UTF-8"
				} 
			})
			.then(res => res.json())
			.then(response=>{
				setLoader(false);
				setOpacity('');
				setPointerEvents('');
				show_notification(response.notifications);
				// var elmnt = document.getElementById("reg_form");
				// elmnt.scrollIntoView(); 
			}		
			).catch(err => {
				setLoader(false);
				setOpacity('');
				setPointerEvents('');
				setErrors('Some problem occured. Please try again!');
				setTimeout(hide_notification, 4000);
				return err;
			})
		}		
	} 
	React.useLayoutEffect(() => {
		get_countries();
	},[]);
		return(
		<section>
			<form className="formm contact_formm reg_form" id="reg_form" onSubmit={Register_data}>				
				<div className="row">
					<div className="row" style={{width:"100%",margin:"0"}}>
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].first_name}</label>
							<input type="text" className="form-control" name="username" id="username" refs="username" onChange={event => setUserName(event.target.value)}/>
						</div>
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].last_name}</label>
							<input type="text" className="form-control" name="lname" id="lname" refs="lname" onChange={event => setLastName(event.target.value)} />
						</div>
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].email_address}</label>
						<input type="text" className="form-control" name="email" id="user_email" refs="user_email" onChange={event => setEmail(event.target.value)} />
					</div>
					<div className="row" style={{width:"100%",margin:"0"}}>
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].pass}</label>
							<input type={showPassValue!='' ? 'text': 'password'} className="form-control" name="pwd" id="user_pwd"  onChange={event => setPwd(event.target.value)} />
							{showPassValue!='' ? 
							(<i className="fa fa-eye-slash show_pass" onClick={event => setShowPassValue('')}></i>):
							(<i className="fa fa-eye show_pass" onClick={event => setShowPassValue('true')}></i>)}
						</div>
						<div className="col-md-6 col-sm-6">
							<label>{global.siteText[0].conf_pass}</label>
							<input type={showPassValue1!='' ? 'text': 'password'} className="form-control" name="cpwd" id="user_cpwd" onChange={event => setCPwd(event.target.value)} />
							{showPassValue1!='' ? 
							(<i className="fa fa-eye-slash show_pass" onClick={event => setShowPassValue1('')}></i>):
							(<i className="fa fa-eye show_pass"  onClick={event => setShowPassValue1('true')}></i>)}
						</div>
						<div className="col-md-12 col-sm-12">
							<p style={{fontSize:"14px",marginBottom:"0px"}}>{global.siteText[0].pass_note}</p>
						</div>
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].country}</label>
						{global.showSiteLang=='English' ?
						(<select className="form-control" name="country"  onChange={event => setCountry(event.target.value)}   id="country" style={{borderColor:"black"}}>
						<option value="">{global.siteText[0].choose_country} </option>
							{show_countries?Object.keys(show_countries).map((key,i)=>
							<React.Fragment key={i}>
							<option value={show_countries[key].countrycode}>{show_countries[key].countryname}</option>
							</React.Fragment>):('')}
						</select>)
						:
						(<select className="form-control" name="country"  onChange={event => setCountry(event.target.value)} id="country" style={{borderColor:"black"}}>
						<option value="">{global.siteText[0].choose_country} </option>
							{show_countriesSpanish?Object.keys(show_countriesSpanish).map((key,i)=>
							<React.Fragment key={i}>
							<option value={show_countriesSpanish[key].countrycode}>{show_countriesSpanish[key].countryname_spanish}</option>
							</React.Fragment>):('')}
						</select>)}
					</div>					
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].languages}</label>	
						<Languages1 selectedLangs={selectedLangs} langs={langs}/>
					</div>
					<div className="row" style={{margin:"0",padding:"16px"}}>						
						<div className="col-md-1 col-sm-1" style={{padding:"0"}}>
							<input type="checkbox" className="form-control" name="terms" onClick={event => setTerms(!terms)} style={{width: "25px",height: "25px"}}/>
						</div>
						<div className="col-md-11 col-sm-11" style={{margin:"auto",padding:"0"}}>
							<p style={{fontSize:"15px"}}>{global.siteText[0].agree_text} <span className="termss" onClick={event => setGuidelines(!guidelines)}>{global.siteText[0].community_rules}</span> {global.siteText[0].and} <span className="termss" onClick={event => setCondition(!condition)}>{global.siteText[0].terms_cond}</span></p>							 
						</div>
					</div>
					{notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
					<button type="button" className="close" data-dismiss="alert"></button>
					</div>) : ('')}		
					{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
						<button type="button" className="close" data-dismiss="alert"></button>
					</div>) : ('')}
					{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
					<div className="col-md-12 col-sm-12 text-right">
						<Button variant="primary" onClick={Register_data} className="modal_btn" style={{opacity:Opacity,pointerEvents:PointerEvents}}>
							{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')} {global.siteText[0].contact_7}
						</Button>
					</div>			
				</div>
			</form>
			<div>
				<Modal show={guidelines} animation={false} dialogClassName="guide_div">
					<Modal.Header closeButton onClick={() => setGuidelines(!guidelines)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].our_community_rules}</Modal.Title>
					</Modal.Header>
					<Modal.Body id="reg_modal_body">
						<Guidelines/>				
					</Modal.Body>
				</Modal>
			</div>		
			<div>
				<Modal show={condition} animation={false} dialogClassName="guide_div">
					<Modal.Header closeButton onClick={() => setCondition(!condition)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].terms_conditions}</Modal.Title>
					</Modal.Header>
					<Modal.Body id="reg_modal_body">
						<Services/>				
					</Modal.Body>
				</Modal>
			</div>	
		</section>
		)
}
export default Register;