import React,{useState,useRef} from 'react';
import {Button,Modal} from 'react-bootstrap';
import icon from './../images/icon.png';
import Add_Project from './Add_Project.js';
import Project_details from './Project_details.js';
import Edit_Project from './Edit_Project.js';
import CDetails from './CoachDetails.js';
import Feedback from './Feedback.js';
import View_Coaches from './View_coaches.js';
import ViewOffer from './ViewOffer.js';
import Chatbox from './Chatbox.js';
import { Path } from './Path.js';
import Languages from './Languages.js';
import Pagination from './Pagination.js';
import { useHistory } from 'react-router-dom';
import { useStateValue } from './StateProvider.js';
import { HashLink as Link } from 'react-router-hash-link';
const Project_Table=()=>{
	const [{ global }, dispatch] = useStateValue();
	let history = useHistory();	
	const get_expert = useRef(null);
    const get_lang = useRef(null);	
	const [allProjects, setAllProjects] = useState('');
	const [allMsgs, setAllMsgs] = useState('');
	const [projectIds, setProjectIds] = useState('');
	const [delProjectId, setDelProjectId] = useState('');
	const [showId, setShowId] = useState('');
	const [editProjectId, setEditProjectId] = useState('');
	const [page, setPage] = useState('1');
	const [loader, setLoader] = useState(true);
	const [tableOpacity, setTableOpacity] = useState('');
	const [BtnStyle, setBtnStyle] = useState({Opacity:'1',PointerEvents:''});	
	const [Loader, setLoaderr] = useState(false);
	const [startFrom, setStartFrom] = useState('');
	const [totalPages, setTotalpages] = useState('');
	const [post_id, setPost_id] = useState('');
	const [projectRecords, setProjectRecords] = useState('');
	const [close_projectid, setCloseProjectId] = useState('');
	const [project_msgid, setProject_msgid] = useState('');
	const [viewCoaches, setViewCoaches] = useState(false);
	const [viewOffers, setViewOffers] = useState(false);
	const [projectId, setProjectId] = useState(false);
	const [userCoach, setUserCoach] = useState(false);
	const [viewMessages, setViewMessages] = useState(false);			
	const [showAddProject, setAddProject] = useState(false);
	const [closeproject, setCloseProject] = useState(false);
	const [show_project_details, setProjectDetails] = useState(false);
	const [showEditProject, setEditProject] = useState(false);
	const [showfeedback, setFeedback] = useState(false);
	const [showDelProject, setDelProject] = useState(false);
	const [all_categories, setAll_categories] = useState('');
	const [all_categories_spanish, setAll_categories_spanish] = useState('');
	const [token, setToken] = useState(localStorage.getItem('token'));
	const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
    const [coachDetails, setCoachDetails] = useState(false);
    const [viewCoachId, setViewCoachId] = useState('');
	let show_ids=[];
	const view_del_modal=(del_id)=>{
		setDelProject(!showDelProject);
		setDelProjectId(del_id);
	}
	const CoachDetails=(coach_id)=>{
		setViewCoachId(coach_id);
		setCoachDetails(true);
    }
	const view_close_modal=(project_id)=>{
		setCloseProject(!closeproject);
		setCloseProjectId(project_id);
	}
	const view_details_modal=(show_id)=>{
		setProjectDetails(!show_project_details);
		setShowId(show_id);
	}
	const view_Edit_modal=(edit_id)=>{
		setEditProject(!showEditProject);
		setEditProjectId(edit_id);
	}
	const view_messages=(receive_id)=>{
		setProject_msgid(receive_id);
		setViewMessages(!viewMessages);
	}
	const view_offers=(project_id)=>{
		setProjectId(project_id);
		setViewOffers(!viewOffers);
	}		
	const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
	}
	const view_coaches_modal=(project_id)=>{
		setPost_id(project_id);
		setViewCoaches(!viewCoaches);
	}
    const show_notification=(response)=>{	   
	   if(response.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response.failed],show_failed:true,show_success:false});
	   }else if(response.success!=null){
		   setNotification({success:global.siteText[0][response.success],failed:'',show_failed:false,show_success:true});
	   }
	   setTimeout(hide_notification, 4000);
	}		
	const get_project_data=(pagee)=>{
		setLoader(true);
		setTableOpacity('0.5');
		fetch(Path+"get_project_data.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				page: pagee
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			setLoader(false);
			setTableOpacity('');
			var project_count=Object.keys(response.project_data).length;
			for (var j = 1; j <= project_count; j++) {
				show_ids.push(response.project_data[j].project_id);
			}	
			setProjectIds(show_ids);
			//console.log('view_data:',projectIds);
			load_msgs();			
			setAllProjects(response.project_data);
			setAll_categories(response.all_categories);
			setAll_categories_spanish(response.all_categories_spanish);			
			setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			setProjectRecords(response.pagination_data.total_records);
			if(response.pagination_data.coach=='1'){
				setUserCoach(true);
				dispatch({
					type: 'changeCoachTab',
					newCoachTab: {showCoachTab: true}
				})
			}
		})
		.catch(err => {
            return err;
        })		
		
	}
	const load_msgs=()=>{
		fetch(Path+"load_msg.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				project_ids:projectIds
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			//console.log(response);
			setAllMsgs(response);
		})
		.catch(err => {
            return err;
        })		
		
	}
	const search_project=()=>{
		setLoader(true);
		setTableOpacity('0.5');
		fetch(Path+"search_project_data.php", { 
			method: "POST",       
			body: JSON.stringify({
				token: token,
				expert_id: get_expert.current.value,
				language: get_lang.current.value,
				page: 1
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			setLoader(false);
			setTableOpacity('');			
			setAllProjects(response.project_data);	
			setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			setProjectRecords(response.pagination_data.total_records);			
		})
		.catch(err => {
            return err;
        })		
		
	}
	const Onload=()=>{
		{localStorage.getItem('token')!=null ? (history.push('/MyProjects')):(history.push('/'))}
	}	
	React.useEffect(() => {
		Onload();
		get_project_data(page);		
		const timer = setInterval(() => {
			load_msgs();
		}, 7000);
		return () => clearInterval(timer);

	},[startFrom]);
	const feedback_modal=()=>{
		setCloseProject(!closeproject);
		setFeedback(!showfeedback);
	}
		const delete_project=()=>{
		setLoader(true);
		setTableOpacity('0.5');
		fetch(Path+"ajax_delete_project.php", { 
			method: "POST",       
			body: JSON.stringify({
				del_project_id: delProjectId,
				token: token,
				page: page
			}), 	
		})
		.then(res => res.json())
		.then(response => {
			setDelProject(!showDelProject);
			setLoader(false);
			setTableOpacity('');
			setTotalpages(response.pagination_data.total_pages);
			setPage(response.pagination_data.page);
			setStartFrom(response.pagination_data.start_from);
			show_notification(response.notifications);
			setAllProjects(response.project_data);
			setProjectRecords(response.pagination_data.total_records);
		})
		.catch(err => {
            return err;
        })		
		
	}	
	return(
		<section style={{background:"rgb(246, 252, 253)",marginTop:"85px"}}>
			<h1 className="head_text" style={{paddingTop:"30px"}}>{global.siteText[0].my_projects_heading1}<span style={{color:"#0ea5df"}}>{global.siteText[0].my_projects_heading2}</span></h1>	
			<div className="divider text-center">
				<span className="outer-line"></span>
				<span className="image"><img src={icon} alt=""/></span>
				<span className="outer-line"></span>
			</div>
			{localStorage.getItem('coach')=='true' ? 
			(<div className="container container-width" style={{marginTop:"28px"}}>
				<ul className="nav nav-tabs justify-content-center" role="tablist" style={{width:"100%"}}>
					<li className="nav-item">
						<Link to="/MyProjects" className="nav-link active">{global.siteText[0].my_projects_1}</Link>
					</li>
					<li className="nav-item">
						<Link to="/RequestedProjects" className="nav-link">{global.siteText[0].my_projects_2}</Link>
					</li>
				</ul>
			</div>):('')}			
			<div className="container container-width" style={{paddingBottom:"40px"}}>
				{notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}		
				{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}
				<div className="row" style={{margin:"0",marginTop:"40px"}}>
					<div className="left_div" style={{padding:"0"}}>
						<h4 style={{fontSize:"17px",fontWeight:"600",marginBottom:"0"}}>{global.siteText[0].my_projects_6} {allProjects ? <span>{projectRecords}</span>:''}</h4>
					</div>
					<div className="right_div" style={{padding:"0",textAlign:"right"}}>
						<div className="row" style={{margin:"0",float:"right"}}>
							<div className="" style={{paddingRight:"8px"}}>
								{global.showSiteLang=='English' ?
								(<select className="form-control" name="Category" ref={get_expert}>
									<option value="">{global.siteText[0].my_projects_5}</option>
									{all_categories?Object.keys(all_categories).map((key,i)=>
									<React.Fragment key={i}>
									<option value={all_categories[key].id}>{all_categories[key].name}</option>
									</React.Fragment>):('')}
								</select>):
								(
									<select className="form-control" name="Category" ref={get_expert}>
									<option value="">{global.siteText[0].my_projects_5}</option>
									{all_categories_spanish?Object.keys(all_categories_spanish).map((key,i)=>
									<React.Fragment key={i}>
									<option value={all_categories_spanish[key].id}>{ all_categories_spanish[key].name_spanish}</option>
									</React.Fragment>):('')}
								</select>
								)}
							</div>
							<div className="" style={{paddingRight:"0",marginRight:"16px",width:"184px"}}>	
								<select className="form-control" name="language" ref={get_lang}>
									<option value="">{global.siteText[0].my_projects_61}</option>
									<React.Fragment>
										<Languages/>
									</React.Fragment>
								</select>    
							</div>
							<div className="" style={{paddingLeft:"0px"}}>
								<Button variant="primary" className="add_btn" onClick={search_project}>{global.siteText[0].my_projects_4}</Button>
							</div>
							<div className="" style={{paddingRight:"0",marginLeft:"12px"}}>	
								<Button variant="primary" className="add_btn btn_stylee1" onClick={() => setAddProject(!showAddProject)}>{global.siteText[0].my_projects_3}</Button>
							</div>	
						</div>
					</div>
				</div>
				<div className="table-responsive div_show" id="no-more-tables" style={{position:"relative"}}>
					{loader?(<div className="spinner-border" role="status" style={{position:"absolute",top:"50%",left:"50%"}}>
					  <span className="sr-only">Loading...</span>
					</div>):('')}
					<table className="table" style={{opacity:tableOpacity}}>
					  <thead className="thh">
						<tr>
							<th>{global.siteText[0].my_projects_22}</th>
							<th>{global.siteText[0].my_projects_23}</th>								
							<th>{global.siteText[0].my_projects_21}</th>
							<th>{global.siteText[0].my_projects_57}</th>
							<th>{global.siteText[0].my_projects_24}</th>
							<th>{global.siteText[0].my_projects_25}</th>
							<th style={{width:"120px"}}>{global.siteText[0].coach}</th>									
							<th style={{width:"292px"}}>{global.siteText[0].my_projects_26}</th>
						</tr>
					  </thead>
					  <tbody id="tbo">
						{allProjects?
							  Object.keys(allProjects).map((key,i)=>
								<tr key={i}>
									<td className='count_rec' data-title={global.siteText[0].my_projects_22} style={{width:"100px"}}>{startFrom+i+1} </td>
									<td className='count_rec proj_title' data-title={global.siteText[0].my_projects_23} style={{width:"320px"}} onClick={() =>view_details_modal(allProjects[key].project_id)}>{allProjects[key].title}</td>
									<td className='count_rec' data-title={global.siteText[0].my_projects_21}>{global.showSiteLang=='English' ? allProjects[key].name : allProjects[key].name_spanish}</td>
									<td className='count_rec' data-title={global.siteText[0].my_projects_57}>{global.showSiteLang=='English' ? allProjects[key].type : allProjects[key].type_spanish}</td>
									<td className='count_rec' data-title={global.siteText[0].my_projects_24}>{allProjects[key].sessions}</td>
									<td className='count_rec' data-title={global.siteText[0].my_projects_25}>{allProjects[key].status}</td>
									<td className='count_rec coach_profile_link' data-title={global.siteText[0].coach} onClick={() => CoachDetails(allProjects[key].coach_id)}>
									{allProjects[key].status=='Accepted' ? 'View profile' :''}
									</td>
									<td className='count_rec action_icon'  data-title={global.siteText[0].my_projects_26}  style={{width:"200px"}}>
										{allProjects[key].status=='Accepted' || allProjects[key].status=='Pending'?
										(<span className='edit' title={global.siteText[0].my_projects_41} onClick={() => view_messages(allProjects[key].project_id)} style={{position:"relative"}}><i className='fa fa-envelope chat_icon action_icons' aria-hidden='true'></i>
										{allMsgs[allProjects[key].project_id]?
										(<span>
										{allMsgs[allProjects[key].project_id].total_msgs!=null ? (<span className="msg_notify">{allMsgs[allProjects[key].project_id].total_msgs}</span>):''}</span>):('')}

										</span>):('')}

										{allProjects[key].status!='Closed' && allProjects[key].status!='Accepted' ? (<span className='edit' title={global.siteText[0].my_projects_45} onClick={() =>view_coaches_modal(allProjects[key].project_id)}><i className='fa fa-eye view_icon action_icons' aria-hidden='true'></i></span>
										):('')}
										<span className='edit' title={global.siteText[0].my_projects_66} onClick={() =>view_Edit_modal(allProjects[key].project_id)}><i className='fa fa-pencil edit_icon action_icons' aria-hidden='true'></i></span>

										{allProjects[key].status=='Accepted'?
										(<span className='edit' title={global.siteText[0].my_projects_36} onClick={() =>view_close_modal(allProjects[key].project_id)}><i className='fa fa-unlink close_icon action_icons' aria-hidden='true'></i></span>)
										:(<span className='delete' title={global.siteText[0].my_projects_67} id={allProjects[key].project_id} onClick={() =>view_del_modal(allProjects[key].project_id)}><i className='fa fa-trash  del_icon action_icons' aria-hidden='true'></i></span>)}
										{allProjects[key].status=='Pending' && allProjects[key].total_offers!='0'?
										(<span><span className='delete' title={global.siteText[0].my_projects_68} onClick={() =>view_offers(allProjects[key].project_id)}><i className='fa fa-list-alt del_icon action_icons' aria-hidden='true' style={{marginLeft:"10px",background:"rgb(14, 165, 223)"}}></i></span></span>):('')
										}							
									</td>
								</tr>
							)
							:(<tr><td colSpan="5" style={{textAlign:"center"}}>{global.siteText[0].my_projects_69}.</td></tr>)
						}
					  </tbody>
					</table>
				</div>
			</div>
			<Pagination page={page} totalpages={totalPages} onUpdatePage={get_project_data}/>
			<div>
				<Modal show={showAddProject} animation={false}>
					<Modal.Header closeButton onClick={() => setAddProject(!showAddProject)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_27}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Add_Project current_page={page} onUpdate={setAllProjects} onTotalRecords={setProjectRecords} onTotalPages={setTotalpages} onStartFrom={setStartFrom} onPage={setPage} post_id={setPost_id} add_project_close={setAddProject} view_coaches_modal={setViewCoaches}/>		
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={viewCoaches} animation={false}  dialogClassName="coaches_modal">
					<Modal.Header closeButton onClick={() => setViewCoaches(!viewCoaches)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_45}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<View_Coaches post_id={post_id} page={page} onUpdate={setAllProjects} onTotalRecords={setProjectRecords} onTotalPages={setTotalpages} onStartFrom={setStartFrom} onPage={setPage}/>		
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={viewOffers} animation={false}  dialogClassName="view_offers">
					<Modal.Header closeButton onClick={() => setViewOffers(!viewOffers)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_68}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ViewOffer project_id={projectId} current_page={page} onUpdate={setAllProjects} onTotalRecords={setProjectRecords} onTotalPages={setTotalpages} onStartFrom={setStartFrom} onPage={setPage}/>		
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={viewMessages} animation={false}>
					<Modal.Header closeButton onClick={() => setViewMessages(!viewMessages)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_41}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Chatbox project_msgid={project_msgid} onUpdate={setAllProjects} />	
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={showDelProject} animation={false}>
					<Modal.Header closeButton onClick={() => setDelProject(!showDelProject)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_29}</Modal.Title>
					</Modal.Header>
					<Modal.Body className="delete_modal">
						<i className="fa fa-exclamation"></i>
						<h4>{global.siteText[0].my_projects_30}</h4>
						<p>{global.siteText[0].my_projects_31}</p>
					</Modal.Body>
					<Modal.Footer className="delete_modal_footer">
						<Button variant="primary" className="modal_del_btn" onClick={delete_project}>{global.siteText[0].my_projects_67}</Button>
						<Button variant="secondary" className="modal_cencel_btn" onClick={() => setDelProject(!showDelProject)}>{global.siteText[0].my_projects_32}</Button>
					  </Modal.Footer>
				</Modal>
			</div>
			<div>
				<Modal show={closeproject} animation={false}>
					<Modal.Header closeButton onClick={() => setCloseProject(!closeproject)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_36}</Modal.Title>
					</Modal.Header>
					<Modal.Body className="delete_modal">
						<h4>{global.siteText[0].my_projects_30}</h4>
						<p>{global.siteText[0].my_projects_33}</p>
					</Modal.Body>
					<Modal.Footer className="delete_modal_footer">
						<Button variant="primary" className="modal_del_btn" onClick={feedback_modal} style={{backgroundColor:"green",borderColor:"green"}}>{global.siteText[0].my_projects_34}</Button>
						<Button variant="secondary" className="modal_cencel_btn" onClick={() => setCloseProject(!closeproject)}>{global.siteText[0].my_projects_35}</Button>
					  </Modal.Footer>
				</Modal>
			</div>
			<div>
				<Modal show={showfeedback} animation={false}>
					<Modal.Header closeButton onClick={() => setFeedback(!showfeedback)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_37}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Feedback close_projectid={close_projectid}/>			
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={showEditProject} animation={false}>
					<Modal.Header closeButton onClick={() => setEditProject(!showEditProject)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_28}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Edit_Project editProjectId={editProjectId} current_page={page} onUpdate={setAllProjects} onTotalPages={setTotalpages} onTotalRecords={setProjectRecords} onStartFrom={setStartFrom} onPage={setPage}/>		
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={show_project_details} animation={false}>
					<Modal.Header closeButton onClick={() => setProjectDetails(!show_project_details)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_58}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Project_details showId={showId}/>		
					</Modal.Body>
				</Modal>
			</div>
			<div>
				<Modal show={coachDetails} animation={false} dialogClassName="edit_div">
					<Modal.Header closeButton onClick={() => setCoachDetails(!coachDetails)} className="modal_head">
					<Modal.Title className="modal_title">{global.siteText[0].my_projects_70}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<CDetails coach_id={viewCoachId}/>		
					</Modal.Body>
				</Modal>
			</div>
		</section>
	);
}
export default Project_Table;