import React,{useState} from 'react';
import { Path } from './Path.js';
import { useStateValue } from './StateProvider.js';
function Article2(){
	const [{ global }, dispatch] = useStateValue();
	return(
	<section>	
		<div className="row">
            <div className="art_modal_detail">
                <img src={Path+`art_images/art2.jpg`}></img>
                <h4>{global.siteText[0].art_modal2_1}</h4>
                <p>{global.siteText[0].art_modal2_2}</p>
                <p>{global.siteText[0].art_modal2_3}</p>
                <p>{global.siteText[0].art_modal2_4}</p>
                <p>{global.siteText[0].art_modal2_5}</p>
                <p>{global.siteText[0].art_modal2_6}</p>
                <p>{global.siteText[0].art_modal2_7}</p>
                <p>{global.siteText[0].art_modal2_8}</p>
                <p style={{fontSize:"14px"}}><i>{global.siteText[0].art_modal1_7}</i></p>
            </div>
        </div>
	</section>
	)
}
export default Article2;