import React from 'react';
import mem1 from './../images/mem1.png';
import mem2 from './../images/mem22.jpg';
import mem6 from './../images/mem66.jpg';
import mem7 from './../images/mem7.jpg';
import mem5 from './../images/mem55.jpg';
import icon from './../images/icon.png';
import { useStateValue } from './StateProvider.js';
function Resources(){
	const [{ global }, dispatch] = useStateValue();
	return(
	<section>
	<div style={{background:"#f6fcfd"}} className="teamm">
		<div id="resources" className="scroll_div"></div>
		<div className="container container-width team_div">
			<h1 className="head_text"><span style={{color:"#0ea5df"}}>{global.siteText[0].resources}</span></h1>
			<div className="divider text-center">
				<span className="outer-line"></span>
				<span className="image"><img src={icon} alt=""/></span>
				<span className="outer-line"></span>
			</div>
			<br></br>
			<div className="row">
				<div className="col-xl-6 col-lg-12 col-md-12 col-xs-12 col-sm-12 mem" id="anim1">
					<div className="row team_member wow fadeInLeft">
						<div className="col-xl-4 col-lg-3 col-md-3 col-xs-12 col-sm-12">
							{/* <img src={mem1} alt="" /> */}
							{/* <h4 className="mem_h4">{global.siteText[0].team2_5}</h4> */}
						</div>
						<div className="col-xl-8 col-lg-9 col-md-9 col-xs-12 col-sm-12">
							<div className="members">
								<h3> An Introduction to Coaching </h3>
								<h5> We believe in the power of your experience! </h5>
								<p> At from woman to woman, we believe that every woman has a unique set of skills and know-how that can be valuable to other women.
									All it takes is your willingness to be a coach and some fundamental knowledge about coaching... and this is what this course is about. </p>
								<a href="https://resources.fromwomantowoman.org/" target="_blank"> Click here </a>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-6 col-lg-12 col-md-12 col-xs-12 col-sm-12 mem" id="anim1">
					<div className="row team_member wow fadeInLeft">
						<div className="col-xl-4 col-lg-3 col-md-3 col-xs-12 col-sm-12">
							{/* <img src={mem1} alt="" /> */}
							{/* <h4 className="mem_h4">{global.siteText[0].team2_5}</h4> */}
						</div>
						<div className="col-xl-8 col-lg-9 col-md-9 col-xs-12 col-sm-12">
							<div className="members">
								<h3> Introducción al Coaching </h3>
								<h5> ¡Nosotras creemos en el poder de tu experiencia! </h5>
								<p> En from woman to woman, nosotras creemos que cada mujer posee un conjunto de habilidades y know- how únicos que puede ser valioso para otras mujeres.

Todo lo que se necesita es su voluntad de ser una coach y algunos conocimientos fundamentales sobre coaching... y de esto se trata este curso. </p>
								<a href="https://resources.sp.fromwomantowoman.org/" target="_blank"> Click aquí </a>
							</div>
						</div>
					</div>
				</div>
			</div>			
		</div>
	</div>
			  
	</section>
	)
}
export default Resources;