import React,{useState,useEffect,useRef} from 'react';
import {Button} from 'react-bootstrap';
import { Path } from './Path.js';
import Languages1 from './Languages1.js';
import { useStateValue } from './StateProvider.js';
const Edit_Project=(props)=>{
	const [{ global }, dispatch] = useStateValue();
	const get_title = useRef(null);
	const get_category = useRef(null);	
	const get_desc = useRef(null);
	const project_type = useRef(null);
	const get_sessions = useRef(null);
	const [projectTypes, setProjectTypes] = useState('');
	const [projectTypesSpanish, setProjectTypesSpanish] = useState('');
	const [BtnStyle, setBtnStyle] = useState({Opacity:'1',PointerEvents:''});
	const [editProjectId, setEditProjectId] = useState('');
	const [Loader, setLoader] = useState(false);
	const [all_categories, setAll_categories] = useState('');
	const [all_categories_spanish, setAll_categories_spanish] = useState('');
	const [errors, setErrors] = useState('');
	const [notification, setNotification] = useState({success:'',failed:'',show_success:false,show_failed:false});
	const [token, setToken] = useState(localStorage.getItem('token'));
	const [selectedLangs, setSelectedLangs] = useState([]);
	const [langs, Setlanguages] = useState([]);
	const [show_session, SetSession] = useState('');
	var sessions=[];
	const handleValidation=()=>{
        let formIsValid = false;
        if(get_title.current.value===''){
		   setErrors(global.siteText[0].error_msg_36);		   
        }else if(get_category.current.value===''){
			setErrors(global.siteText[0].error_msg_37);
		}else if(project_type.current.value===''){
			setErrors(global.siteText[0].error_msg_38);
		}else if(selectedLangs.length===0){
			setErrors(global.siteText[0].error_msg_39);
		}else if(get_desc.current.value===''){
			setErrors(global.siteText[0].error_msg_40);
        }else {
			setErrors('');
			formIsValid = true;
		}	
        return formIsValid;
   }
   const hide_notification=()=>{
		setNotification({sucess:'',failed:'',show_failed:false,show_success:false});
		setErrors('');
   }
   const show_notification=(response1)=>{
	   console.log('res:',response1.success);
	   if(response1.failed!=null){
		   setNotification({success:'',failed:global.siteText[0][response1.failed],show_failed:true,show_success:false});
	   }else if(response1.success!=null){
		   setNotification({success:global.siteText[0][response1.success],failed:'',show_failed:false,show_success:true});
	   }
	   setTimeout(hide_notification, 4000);
   }
	const get_edit_project_data=()=>{		
		fetch(Path+"get_edit_project_data.php", { 
			method: "POST",       
			body: JSON.stringify({
				token:token,
				edit_project_id: props.editProjectId
			}), 	
		})
		.then(res => res.json())
		.then(response=>{
				setAll_categories(response.all_categories);
				setProjectTypes(response.all_types);
				setAll_categories_spanish(response.all_categories_spanish);
				setProjectTypesSpanish(response.all_types_spanish);
				get_title.current.value=response.edit_data.title;
				get_category.current.value=response.edit_data.cat_id;
				get_desc.current.value=response.edit_data.desc;
				project_type.current.value=response.edit_data.type;
				get_sessions.current.value=response.edit_data.sessions;
				Setlanguages(response.edit_data.languages.split(","));
				setSelectedLangs(response.edit_data.languages.split(","));
				console.log('lang:',response.edit_data.languages.split(","));
			}
		)
		.catch(err => {
            return err;
        })		
	}
	React.useEffect(() => {
		for (var j = 1; j < 11; j++) {
			sessions.push(<option value={j} key={j}>{j}</option>);
		}
		SetSession(sessions);
		get_edit_project_data();
	},[]);    
	const Edit_project_data=()=>{
		const data = new FormData();
		data.append('title', get_title.current.value);
		data.append('category', get_category.current.value);
		data.append('Desc', get_desc.current.value);
		data.append('editProjectId', props.editProjectId);
		data.append('page', props.current_page);
		data.append('token', token);
		data.append('type', project_type.current.value);		
		data.append('languages', selectedLangs);
		data.append('sessions',get_sessions.current.value);
		if(handleValidation()){	
			setLoader(true);
			setBtnStyle(prevState => ({
				...prevState,
				Opacity: '0.5'
			 }));
			setBtnStyle(prevState => ({
				...prevState,
				PointerEvents: 'none'
			 }));
			fetch(Path+"ajax_edit_project.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{	
				setLoader(false);
				setBtnStyle(prevState => ({
					...prevState,
					Opacity: ''
				 }));
				setBtnStyle(prevState => ({
					...prevState,
					PointerEvents: ''
				 }));
				show_notification(response.notifications);
				props.onUpdate(response.project_data);
				props.onTotalPages(response.pagination_data.total_pages);
				props.onStartFrom(response.pagination_data.start_from);
				props.onPage(response.pagination_data.page);
				props.onTotalRecords(response.pagination_data.total_records);
				}
			)
			.catch(err => {
				setLoader(false);
				setBtnStyle(prevState => ({
					...prevState,
					Opacity: ''
				 }));
				setBtnStyle(prevState => ({
					...prevState,
					PointerEvents: ''
				 }));
				setErrors(global.siteText[0].error_msg_14);
				setTimeout(hide_notification, 4000);
				return err;
			})
		}
	}		
		return(
		<section>
			<form id="add_proj" className="formm contact_formm" onSubmit={Edit_project_data}>
				{notification.show_success ? (<div className="alert alert-success" id="success2">{notification.success}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}		
				{notification.show_failed ? (<div className="alert alert-danger" id="danger2">{notification.failed}
					<button type="button" className="close" data-dismiss="alert"></button>
				</div>) : ('')}
				{errors ?(<div className="alert alert-danger" style={{color: "#721c24"}}>{errors}</div>):('')}
				<div className="row">
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].my_projects_9}</label>
						<input type="text" className="form-control" name="Title" ref={get_title}/>
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].my_projects_10}</label>
						{global.showSiteLang=='English' ?
						(<select className="form-control" name="Category" ref={get_category}>
							<option value="">{global.siteText[0].my_projects_12}</option>
							{all_categories?Object.keys(all_categories).map((key,i)=>
							<React.Fragment key={i}>
							<option value={all_categories[key].id}>{all_categories[key].name}</option>
							</React.Fragment>):('')}
						</select>):
						(
							<select className="form-control" name="Category" ref={get_category}>
								<option value="">{global.siteText[0].my_projects_12}</option>
								{all_categories_spanish?Object.keys(all_categories_spanish).map((key,i)=>
								<React.Fragment key={i}>
								<option value={all_categories_spanish[key].id}>{all_categories_spanish[key].name_spanish}</option>
								</React.Fragment>):('')}
							</select>
						)}
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].my_projects_11}</label>
						{global.showSiteLang=='English' ?
						(<select className="form-control" name="Category" ref={project_type}>
							<option value="">{global.siteText[0].my_projects_13}</option>
							{projectTypes?Object.keys(projectTypes).map((key,i)=>
							<React.Fragment key={i}>
							<option value={projectTypes[key].id}>{projectTypes[key].type_name}</option>
							</React.Fragment>):('')}
						</select>):
						(<select className="form-control" name="Category" ref={project_type}>
						<option value="">{global.siteText[0].my_projects_13}</option>
						{projectTypesSpanish?Object.keys(projectTypesSpanish).map((key,i)=>
						<React.Fragment key={i}>
						<option value={projectTypesSpanish[key].id}>{projectTypesSpanish[key].type_name_spanish}</option>
						</React.Fragment>):('')}
					</select>)}
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].my_projects_14}</label>	
						<Languages1 selectedLangs={selectedLangs} langs={langs}/>	
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].my_projects_15}</label>
						<select className="form-control" name="sessions" ref={get_sessions}>
							{show_session}
						</select>
						<p style={{marginBottom:"0",fontSize:"13px"}}>{global.siteText[0].my_projects_16}</p>
					</div>
					<div className="col-md-12 col-sm-12">
						<label>{global.siteText[0].my_projects_17}</label>
						<p style={{marginBottom:"0",fontSize:"13px"}}>{global.siteText[0].my_projects_18}</p>
						<textarea type="text" className="form-control" name="Desc" ref={get_desc} style={{height:"100px"}}></textarea>
					</div>
					<div className="col-md-12 col-sm-12 text-right" style={{marginTop:"20px"}}>
						<Button variant="primary" className="modal_btn" onClick={Edit_project_data} style={{opacity:BtnStyle.Opacity,pointerEvents:BtnStyle.PointerEvents}}>
							{Loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>): ('')}{global.siteText[0].update}
						</Button>
					</div>					
				</div>
			</form>	
		</section>
		)
}
export default Edit_Project;