import { lang_en,lang_sp } from './Path.js';
var url = window.location;
var access_token = '';
const getParameterByName = function(url, token){
	var results = new RegExp('[/?&]' + token + '=([^&#]*)').exec(url);
    if (results==null) {
      return access_token=null;
   }
   return access_token=decodeURI(results[1]) || 0;
}
getParameterByName(url,'token');
var showForgotPasswordModal1=access_token!=null?true:false;

var email_verified_token = '';
const getParameterByName1 = function(url, email_verify_token){
	var results1 = new RegExp('[/?&]' + email_verify_token + '=([^&#]*)').exec(url);
    if (results1==null) {
      return email_verified_token=null;
   }
   return email_verified_token=decodeURI(results1[1]) || 0;
}
getParameterByName1(url,'email_verify_token');
console.log('email_verify_token:',email_verified_token);
var verifyEmailModal1=email_verified_token!=null?true:false;

export const initialState = {
    global: { token: localStorage.getItem('token'),
	showLogModal:false,
	showRegModal:false,
	showForgotModal:false,
	projectRequestModal:false,
	showLoader:false,
	showForgotPasswordModal:showForgotPasswordModal1,
	verifyEmailModal:false,
	showFpToken:access_token,
	getEmailVerifyToken:email_verified_token,
	showUserName:'',
	showUserImg:'',
	showCoachTab:localStorage.getItem('coach'),
	showUserMenu:false,
	siteKey:'6LdGUvMZAAAAALqoj6AaoD1l8rkEbmozvtYjGhkS',
	//siteKey:'6LdSqu8ZAAAAAHypRsmr3aS6IboEEnWmKPj0UQCU',
	siteText:lang_en,
	showSiteLang:'English',
	}
  };
  
export const reducer = (state, action) => {
	console.log('call received',action);
	console.log('call state',state);
	switch (action.type) {
	  case 'changeToken':
	  console.log('call changeToken',action);
	  var a={
		global: {...state.global,
			token:action.newToken.token}
		};
		console.log('done changeToken',a);
		return a;		
		break;
		case 'changeLogModal':
		console.log('call changeLogModal',action);
		return {
			global: {...state.global,
				showLogModal:action.newLogModal.showLogModal}
			};
		break;
		case 'changeProjectRequestModal':
	  	console.log('call changeProjectRequestModal',action);
		return {
			global: {...state.global,
				projectRequestModal:action.newProjectRequestModal.projectRequestModal}
			};
		break;
	  case 'changeRegModal':
	  console.log('call changeRegModal',action);
		return {
		  global: {...state.global,
			showRegModal:action.newRegModal.showRegModal}
	  	};
		break;
	  case 'changeForgotModal':
	  console.log('call changeForgotModal',action);
		return {
			global: {...state.global,
				showForgotModal:action.newForgotModal.showForgotModal}
		};
		break;
		
	  case 'changeForgotPasswordModal':
	  console.log('call changeForgotPasswordModal',action);
		return {
			global: {...state.global,
				showForgotPasswordModal:action.newForgotPasswordModal.showForgotPasswordModal}
		};
		break;
		case 'changeVerifyEmailModal':
	  console.log('call changeVerifyEmailModal',action);
		return {
			global: {...state.global,
				verifyEmailModal:action.newVerifyEmailModal.verifyEmailModal}
		};
		break;
	  case 'changeFpToken':
	  console.log('call changeFpToken',action);
		return {
			global: {...state.global,
				showFpToken:action.newFpToken.showFpToken}
		};
		break;
	  case 'changeUserName':
	  console.log('call changeUserName',action);
		var b= {
			global: {...state.global,
				showUserName:action.newUserName.showUserName,
				showUserImg:action.newUserImg.showUserImg}
		};
		console.log('done changeUserName',b);
		console.log('done initialState',initialState);
		return b;
		break;
		case 'changeUserImg':
	  console.log('call changeUserImg',action);
		var c= {
			global: {...state.global,
				showUserImg:action.newUserImg.showUserImg}
		};
		console.log('done changeUserImg',c);
		return c;
		break;
	  case 'changeLoader':
	  console.log('call changeLoader',action);
		return {
			global: {...state.global,
				showLoader:action.newLoader.showLoader}
		};
		break;
		case 'changeCoachTab':
	  	console.log('call changeCoachTab',action);
		return {
			global: {...state.global,
				showCoachTab:action.newCoachTab.showCoachTab}
		};
		break;
		case 'changeUserMenu':
		  console.log('call changeUserMenu',action);
		  console.log('chk before initialState',initialState);
		  console.log('chk before State',state);

		return {
		  	global: {...state.global,
			showUserMenu:action.newUserMenu.showUserMenu}
		};
		break;
		case 'changeSiteText':
		return {
		  	global: {...state.global,
			siteText:action.newSiteText.siteText}
		};
		break;	  		
		case 'changeShowSiteLang':
		return {
		  	global: {...state.global,
			showSiteLang:action.newSiteLang.showSiteLang}
		};
		break;
	    default:
		return state;
	}
};