import React from 'react';
import logoo from './../images/logo3.jpg';
import logoo1 from './../images/foot_logo.png';
import { useStateValue } from './StateProvider.js';

function Footer(){
	const [{ global }, dispatch] = useStateValue();
	return(
	<section>	
		<div className="row footer">
			<div style={{width:"100%"}}>
			<div className="foot_cont container container-width">
			<div className="row" style={{margin:"0"}}>
				<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pull-left foot_logo">
					<img src={logoo1} />
					<p>{global.siteText[0].footer_1}</p><br></br>
				</div>
				<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pull-left foot_div4" style={{marginBottom:"20px"}}>
					<h5>{global.siteText[0].social_media}</h5>
					<p>{global.siteText[0].footer_2}</p>
					<ul className="social-icons standard ">
						<li className="twitter"><a href="https://twitter.com/W2WCommunity" target="_blank"><i className="fa fa-twitter"></i></a></li>
						<li className="facebook"><a href="https://www.facebook.com/W2WCommunity/" target="_blank"><i className="fa fa-facebook"></i></a></li>
						<li className="instagram"><a href="https://www.instagram.com/w2wcommunity/?hl=en" target="_blank"><i className="fa fa-instagram"></i></a></li>
					</ul>
				</div>
				<div className="copyy col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">{global.siteText[0].copyright}</div>
			</div>	
			</div>
		</div>
		</div>			  
	</section>
	)
}
export default Footer;